/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';

// App
import Navigation from 'modules/App/components/Navigation';
import CrewJobNavigation from 'modules/Job/Crew/components/CrewJobNavigation';
import JobPageBlock from 'modules/Job/Show/components/JobPageBlock';
import JobPage from 'modules/Job/components/JobPage';
import ViewerJobBanner from 'modules/Job/components/ViewerJobBanner';

const Container = Styled.View`
  flex: 1;
`;

const Space = Styled.View`
  height: 100px;
`;

const hasJob = (data) => !!data && !!data.job;

const ShowJobPageContent = ({data, navigator, refetch}) => {
  if (!hasJob(data)) {
    return null;
  }

  return (
    <Container>
      <ScrollView style={{flex: 1}}>
        {data.job.organization.crewJobPageBlock.blocks.map((block, index) => (
          <JobPageBlock
            key={index}
            block={block}
            index={index}
            job={data.job}
            viewer={data.viewer}
            refetch={refetch}
          />
        ))}
        <Space />
      </ScrollView>
    </Container>
  );
};

const ShowJobPage = () => {
  return (
    <JobPage
      fetchPolicy={'network-only'}
      getVariables={({params}) => ({uuid: params.uuid})}
      query={ShowJobPage.query}
      onFocus={({refetch}) => refetch()}
      navigation={(props) => (
        <React.Fragment>
          <ViewerJobBanner />
          {hasJob(props.data) && (
            <CrewJobNavigation
              {...props}
              renderLeft={() => (
                <Navigation.CloseButton onPress={() => props.navigator.navigate('ListJobs')} />
              )}
            />
          )}
        </React.Fragment>
      )}
    >
      {({navigator, data, refetch}) => (
        <ShowJobPageContent data={data} navigator={navigator} refetch={refetch} />
      )}
    </JobPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ShowJobPage.query = gql`
  ${CrewJobNavigation.fragment}
  ${JobPage.fragment}
  ${JobPageBlock.fragment}

  query ShowJobPage($uuid: String!) {
    ${gql.query}
    viewer {
      id
      ...JobPageBlock_Viewer
    }
    job(uuid: $uuid) {
      id
      isComplete
      organization {
        id
        crewJobPageBlock: block(kind: "CREW_JOB_PAGE") {
          blocks {
            kind
          }
        }
      }
      ...CrewJobNavigation
      ...JobPage
      ...JobPageBlock_Job
    }
  }
`;

export default ShowJobPage;
