// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import StartEstimatedRangeJobForm from '@shared/modules/Dispatch/forms/StartEstimatedRangeJobForm';

const useStartEstimatedRangeJobMutation = ({startEstimatedRangeJobForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      startEstimatedRangeJobForm: StartEstimatedRangeJobForm.toForm(startEstimatedRangeJobForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useStartEstimatedRangeJobMutation.mutation,
    variables: {
      startEstimatedRangeJobForm: StartEstimatedRangeJobForm.toMutation(
        form.values.startEstimatedRangeJobForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useStartEstimatedRangeJobMutation.mutation = gql`
  mutation useStartEstimatedRangeJobMutation(
    $startEstimatedRangeJobForm: StartEstimatedRangeJobForm!
  ) {
    response: startEstimatedRangeJob(startEstimatedRangeJobForm: $startEstimatedRangeJobForm) {
      ${gql.errors}
      job {
        id
        uuid
      }
    }
  }
`;

export default useStartEstimatedRangeJobMutation;
