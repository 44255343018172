// Libraries
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';

// Components

const Container = Styled.View`
  background-color: ${(props) => (props.isSelected ? colors.blue.interactive : colors.white)};
`;

const Content = Styled.View`
  flex-direction: row;
  align-items: center;
  height: 60px;
  padding-horizontal: 20px;
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const Info = Styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

const Name = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Details = Styled.H7`
  margin-left: 10px;
  color: ${colors.gray.primary};
`;

const Button = Styled.LoadingButton`
  margin-left: 40px;
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const shouldShowButton = ({loading, isSelected}) => {
  if (!loading) {
    return true;
  }
  return loading && isSelected;
};

const OrganizationTruckItem = ({isFirst, isSelected, loading, truck, onSelect}) => (
  <Container isSelected={isSelected}>
    {isFirst && <Line />}
    <Content>
      <Info>
        <Name>{truck.name}</Name>
        {!!truck.size && <Details>{`(${truck.size})`}</Details>}
      </Info>
      {shouldShowButton({loading, isSelected}) && (
        <Button loading={loading} onPress={onSelect}>
          <Text>Select</Text>
        </Button>
      )}
    </Content>
    <Line />
  </Container>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationTruckItem.fragment = gql`
  fragment OrganizationTruckItem on Truck {
    id
    name
    size
  }
`;

export default OrganizationTruckItem;
