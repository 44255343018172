/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {FlatList, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {JobUser} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import Notification from 'modules/App/components/Notification';
import JobUserItem from 'modules/Job/components/JobUserItem';

const FlexContainer = Styled.View`
  flex: ${1 / 8};
`;

const JobUserWrapper = Styled.View`
  padding-vertical: 5px;
  padding-horizontal: 5px;
`;

const ActiveJobUsersList = ({job}) => {
  return (
    <FlatList
      data={JobUser.getSelected(job.activeJobUsers)}
      keyExtractor={(jobUser) => jobUser.id}
      numColumns={8}
      renderItem={({item: jobUser, index}) => (
        <FlexContainer>
          <JobUserWrapper>
            <JobUserItem jobUser={jobUser} />
          </JobUserWrapper>
        </FlexContainer>
      )}
      ListEmptyComponent={() => (
        <Notification color={colors.orange.status} text={`No assigned crew members.`} />
      )}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ActiveJobUsersList.fragment = gql`
  ${JobUserItem.fragment}

  fragment ActiveJobUsersList on Job {
    id
    activeJobUsers {
      id
      status
      ...JobUserItem
    }
  }
`;

export default ActiveJobUsersList;
