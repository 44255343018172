// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {KeyboardView, RadioInput, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigation, useResponsive, useState} from '@supermove/hooks';
import {Job} from '@supermove/models';
import {colors, fontWeight, Typography} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import ErrorCallout from '@shared/design/components/Callout/ErrorCallout';
import ErrorModal from '@shared/design/components/Modal/SmallModal/ErrorModal';
import PayEngineCreditCardInput from '@shared/modules/Payment/components/PayEngineCreditCardInput';
import BeginPaymentV3Form from '@shared/modules/Payment/forms/BeginPaymentV3Form';
import {
  transactionErrorMessage,
  transactionContactMessage,
} from '@shared/modules/Payment/hooks/payengineErrors';
import useChargePayEngineCreditCard from '@shared/modules/Payment/hooks/useChargePayEngineCreditCard';
import Navigation from 'core/Navigation';
import PageFooter from 'modules/App/components/PageFooter';
import PageV2 from 'modules/App/components/PageV2';
import PaymentAmountSection from 'modules/Project/Billing/Payment/components/PaymentAmountSection';

const Container = Styled.View`
  flex: 1;
`;

const TitleContainer = Styled.View`
  align-items: center;
`;

const OptionsContainer = Styled.View`
  paddingBottom: 16px;
`;

const CardOption = Styled.View`
  flex: 1;
  flex-direction: row;
`;

const CardLabel = Styled.Text`
  ${Typography.Body2}
  color: ${colors.black};
  flex-basis: 50%;
  padding-vertical: 16px;
`;

const CardExpiry = Styled.Text`
  ${Typography.Body2}
  color: ${colors.gray.secondary};
  text-align: right;
  flex-basis: 50%;
  padding-vertical: 16px;
  padding-right: 16px;
`;

const CardInputOption = Styled.View`
  flex: 1;
  flex-direction: column;
  padding-right: 16px;
`;

const Button = Styled.LoadingButton`
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const LoadingIndicator = Styled.Loading`
  position: absolute;
  left: 112px;
  top: 18px;
`;

const ProjectBillingPayEngineCreditCardPaymentSection = ({job}) => {
  const {params, navigator} = useNavigation();

  // Manages loading state of the PayEngine form fields in the webview
  const [isFormLoaded, setIsFormLoaded] = React.useState(false);
  const onLoad = React.useCallback(() => {
    setIsFormLoaded(true);
  }, [setIsFormLoaded]);

  const [tokenizeErrorMessage, setTokenizeErrorMessage] = useState('');
  const [chargeErrorMessage, setChargeErrorMessage] = useState('');
  const errorModal = useModal();

  const beginPaymentV3Form = BeginPaymentV3Form.new({
    billId: job.project.currentPrimaryBill.id,
    customerId: job.project.currentPrimaryBill.customerId,
    name: params.paymentName,
    amount: params.paymentAmount,
    method: 'PAYENGINE_CREDIT_CARD',
    tipAmount: params.tipAmount,
    tipName: Job.getTipName(job),
    jobId: job.id,
  });
  const {submitting, handleTokenizeCreditCard, handleChargeCreditCard, setCreditCardClient} =
    useChargePayEngineCreditCard({
      beginPaymentV3Form,
    });

  const [optionsIndex, setOptionsIndex] = React.useState(0);
  const cardOptions = (job.project.billingClient.payengineCreditCards || []).map((card) => {
    return {
      card,
      handleTokenizeCreditCard: async () => {
        // Stubbed tokenizer func for saved cards. Signature matches `handleTokenizeCreditCard` returned by
        // useChargePayEngineCreditCard
        return {
          env: card.env,
          token: card.token,
          card_data: {
            address_zip: card.addressZip,
            brand: card.brand,
            exp_month: card.expMonth,
            exp_year: card.expYear,
            id: card.cardId,
            last_4: card.last4,
            name: card.name,
          },
        };
      },
    };
  });
  cardOptions.push({
    card: undefined,
    handleTokenizeCreditCard,
  });

  const onPress = async () => {
    const cardOption = cardOptions[optionsIndex];
    let card;
    try {
      setTokenizeErrorMessage('');
      card = await cardOption.handleTokenizeCreditCard();
    } catch (error) {
      console.error(`Error tokenizing credit card: `, error);
      setTokenizeErrorMessage(`Please fix the invalid fields.`);
      return;
    }

    try {
      await handleChargeCreditCard({
        variables: {
          form: {
            beginPaymentV3Form: BeginPaymentV3Form.toMutation(
              BeginPaymentV3Form.toForm(beginPaymentV3Form),
            ),
            createCardResponse: {
              env: card.env,
              token: card.token,
              cardData: {
                addressZip: card.card_data.address_zip,
                brand: card.card_data.brand,
                expMonth: card.card_data.exp_month,
                expYear: card.card_data.exp_year,
                id: card.card_data.id,
                last4: card.card_data.last_4,
                name: card.card_data.name,
              },
            },
            jobId: job.id,
            // Only save the card to the client when it's a new card
            saveToClientId: cardOption.card ? undefined : job.project.billingClient.id,
          },
        },
      });
      await Navigation.navigateFromBillSuccess({
        navigator,
        params,
        bill: job.project.currentPrimaryBill,
      });
    } catch (error) {
      console.error(`Error charging credit card: `, error);
      setChargeErrorMessage(
        `${transactionErrorMessage(error)} ${transactionContactMessage(job.organization.name)}`,
      );
      errorModal.handleOpen();
    }
  };

  const responsive = useResponsive();
  return (
    <Container responsive={responsive}>
      <KeyboardView>
        <ScrollView
          style={{flex: 1, paddingBottom: 16, paddingHorizontal: responsive.mobile ? '0' : '20%'}}
        >
          <TitleContainer>
            <PageV2.Title>Please enter your card information.</PageV2.Title>
            <PageV2.Subtitle>Your payment will be processed shortly.</PageV2.Subtitle>
            <Space height={32} />
            <PaymentAmountSection
              title={'Payment Amount:'}
              amount={Currency.display(params.paymentAmount)}
            />
            <Space height={32} />
          </TitleContainer>
          <OptionsContainer>
            {cardOptions.map((option, index) => {
              const isSelected = optionsIndex === index;
              return (
                <RadioInput
                  key={index}
                  disabled={submitting}
                  isSelected={isSelected}
                  isSelectedNonTouchable
                  color={colors.blue.interactive}
                  value={option}
                  onSelect={() => {
                    setOptionsIndex(index);
                  }}
                  touchableStyle={{
                    paddingLeft: 16,
                    borderRadius: 4,
                    backgroundColor: isSelected ? colors.blue.accent : colors.white,
                  }}
                  outlineStyle={{
                    marginTop: 18,
                    alignSelf: 'flex-start',
                  }}
                >
                  {option.card ? (
                    <CardOption>
                      <CardLabel
                        style={{textTransform: `capitalize`}}
                      >{`${option.card.brand} •••• ${option.card.last4}`}</CardLabel>
                      <CardExpiry>
                        Expires {`${option.card.expMonth}`.padStart(2, '0')}/{option.card.expYear}
                      </CardExpiry>
                    </CardOption>
                  ) : (
                    <CardInputOption>
                      <CardLabel>Add new card</CardLabel>
                      {!!tokenizeErrorMessage && isSelected && (
                        <>
                          <ErrorCallout text={tokenizeErrorMessage} />
                          <Space height={16} />
                        </>
                      )}
                      <PayEngineCreditCardInput
                        setCreditCardClient={setCreditCardClient}
                        mode={job.organization.mode}
                        onLoad={onLoad}
                        styleWeb={{
                          margin: -4,
                          paddingBottom: 16,
                          ...(!isSelected && {display: 'none'}),
                        }}
                        styleNative={{
                          // TODO(atsu): Styling is mega janky for native, clean this up after all
                          //  crew app flows are using this page
                          marginTop: -15,
                          marginLeft: -20,
                          marginBottom: -100,
                          ...(!isSelected && {display: 'none'}),
                        }}
                      />
                      {!isFormLoaded && isSelected && (
                        <LoadingIndicator color={colors.gray.tertiary} />
                      )}
                    </CardInputOption>
                  )}
                </RadioInput>
              );
            })}
          </OptionsContainer>
        </ScrollView>
        <PageFooter>
          <PageFooter.Row style={{justifyContent: 'space-between'}}>
            {Navigation.isMainFlowPayment({params}) ? <PageFooter.BackButton /> : <Space />}
            <Button
              disabled={!isFormLoaded}
              loading={submitting}
              color={colors.blue.interactive}
              onPress={_.throttle(onPress, 3000)}
            >
              <Text>Submit</Text>
            </Button>
          </PageFooter.Row>
        </PageFooter>
      </KeyboardView>
      <ErrorModal
        isOpen={errorModal.isOpen}
        handleClose={errorModal.handleClose}
        title={'Unable to process credit card.'}
        subtitle={chargeErrorMessage}
      />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectBillingPayEngineCreditCardPaymentSection.fragment = gql`
  ${Job.getTipName.fragment}
  ${Navigation.navigateFromBillSuccess.fragment}

  fragment ProjectBillingCreditCardPaymentSection on Job {
    id
    organization {
      id
      mode
      name
      account {
        id
      }
    }
    project {
      id
      currentPrimaryBill {
        id
        customerId
        ...Navigation_navigateFromBillSuccess
      }
      billingClient {
        id
        payengineCreditCards {
          id
          displayName
          env
          token
          addressZip
          brand
          expMonth
          expYear
          cardId
          last4
          name
        }
      }
    }
    customer {
      id
    }
    ...Job_getTipName
  }
`;

export default ProjectBillingPayEngineCreditCardPaymentSection;
