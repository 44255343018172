// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Bill, BillRule, BillItem} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Currency, Percent} from '@supermove/utils';

const Row = Styled.View`
  flex-direction: row;
`;

const FlexContainer = Styled.View`
  flex: ${(props) => props.flex};
`;

const Line = Styled.View`
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
  border-style: ${(props) => (props.dashed ? 'dashed' : 'solid')};
`;

const CaptionText = Styled.Text`
  ${Typography.Body3}
  color: ${(props) => (props.color ? props.color : colors.gray.secondary)};
`;

const BillItemName = Styled.Text`
  ${Typography.Body2}
  color: ${colors.gray.primary};
  flex: 4;
`;

const BillItemDetailContainer = Styled.View`
  align-items: flex-end;
  flex: ${(props) => props.flex};
`;

const BillItemDetailText = Styled.Text`
  ${Typography.Body2}
  color: ${(props) => props.color};
  text-align: right;
`;

const BillTotalContainer = Styled.View`
  flex: 8;
  align-items: flex-end;
`;

const BillTotalText = Styled.Text`
  ${Typography.Label1}
  color: ${colors.gray.primary};
`;

const BillRuleContainer = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  background-color: ${colors.gray.background};
  border-radius: 4px;
  padding: 8px;
`;

const BillRuleMainText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.gray.primary};
`;

const IconCircle = Styled.View`
  height: 14px;
  width: 14px;
  border-radius: 7;
  background-color: ${colors.orange.status};
  align-items: center;
  justify-content: center;
`;

const ListEmptyMessageContainer = Styled.View`
  flex-direction: row;
  background-color: ${colors.gray.background};
  border-radius: 4px;
  padding: 8px;
  align-items: center;
`;

const ListEmptyActionText = Styled.Text`
  ${Typography.Label3}
  color: ${colors.orange.status};
`;

const getBillRules = ({bill, isEditable, showAllBillRules}) => {
  // If the bill is editable, then it is not a customer facing bill.
  // Only moving company facing bills are editable, so these bills
  // should not hide bill rules based on isVisibleToCustomer.
  // showAllBillRules allows all bill rules to be shown without being editable.
  if (isEditable || showAllBillRules) {
    return bill.billRules;
  }
  return _.filter(bill.billRules, 'isVisibleToCustomer');
};

const BillItemDetail = ({icon, children, color, flex}) => {
  return (
    <BillItemDetailContainer color={color} flex={flex}>
      <Row>
        {icon}
        <BillItemDetailText color={color}>{children}</BillItemDetailText>
      </Row>
    </BillItemDetailContainer>
  );
};

const DiscountTag = () => {
  return (
    <React.Fragment>
      <Icon
        source={Icon.Tag}
        size={10}
        // TODO(dan) This rotate works for native, but find a way to work on web as well.
        style={{transform: [{rotate: '90deg'}], marginTop: 6}}
        color={colors.green.status}
      />
      <Space width={4} />
    </React.Fragment>
  );
};

const FlexSpaceHolder = ({isPriceVisible}) => {
  // This fills any additional space created from fields being disabled.
  // For every field we take away we add to the flex value to evenly compensate.
  let totalFlexSpace = 0;
  if (!isPriceVisible) {
    totalFlexSpace += 2;
  }
  return <BillItemDetailContainer flex={totalFlexSpace} />;
};

const Description = ({description}) => {
  return (
    <Row>
      <FlexContainer flex={5}>
        <CaptionText color={colors.gray.secondary}>{description}</CaptionText>
      </FlexContainer>
      <FlexContainer flex={5} />
    </Row>
  );
};

const BillItemPreSubtotal = ({billItem, isEditable, showPrices, isEnabledTbdBillItems}) => {
  const isPriceVisible = isEditable || showPrices;
  return (
    <React.Fragment>
      <Row>
        <BillItemName>{billItem.name}</BillItemName>
        <FlexSpaceHolder isPriceVisible={isPriceVisible} />
        <BillItemDetail color={colors.gray.secondary} flex={2}>
          {BillItem.getEstimateQuantity(billItem, {isEnabledTbdBillItems})}
        </BillItemDetail>
        {isPriceVisible && (
          <BillItemDetail color={colors.gray.secondary} flex={2}>
            {BillItem.getDisplayPrice(billItem, {isEnabledTbdBillItems})}
          </BillItemDetail>
        )}
        <BillItemDetail
          flex={2}
          color={billItem.isCredit ? colors.green.status : colors.gray.primary}
          icon={billItem.isCredit ? <DiscountTag /> : null}
        >
          {BillItem.getEstimateTotal(billItem, {isEnabledTbdBillItems})}
        </BillItemDetail>
      </Row>
      {!!billItem.description && <Description description={billItem.description} />}
    </React.Fragment>
  );
};

const BillSubtotal = ({bill}) => {
  const {estimateSubtotalMin: min, estimateSubtotalMax: max} = bill;
  return (
    <Row>
      <BillItemDetail flex={8} color={colors.black}>
        <BillTotalText>Subtotal</BillTotalText>
      </BillItemDetail>
      <BillItemDetail flex={2} color={colors.gray.primary}>
        <BillTotalText>
          {bill.isSubtotalAvailable ? Currency.formatRange({min, max}) : 'TBD'}
        </BillTotalText>
      </BillItemDetail>
    </Row>
  );
};

const BillItemPostSubtotal = ({billItem, bill}) => {
  return (
    <React.Fragment>
      <Space height={4} />
      <Row>
        <BillItemDetail flex={8} color={colors.black}>
          {billItem.name}
        </BillItemDetail>
        <BillItemDetail
          flex={2}
          color={billItem.isCredit ? colors.green.status : colors.gray.primary}
          icon={billItem.isCredit ? <DiscountTag /> : null}
        >
          {BillItem.getEstimateTotalPostSubtotal(billItem, {
            isSubtotalAvailable: bill.isSubtotalAvailable,
            isEnabledTbdBillItems: bill.organization.features.isEnabledTbdBillItems,
          })}
        </BillItemDetail>
      </Row>
    </React.Fragment>
  );
};

const BillTip = ({bill}) => {
  return (
    <React.Fragment>
      <Space height={4} />
      <Row>
        <BillItemDetail flex={8} color={colors.black}>
          Tip
        </BillItemDetail>
        <BillItemDetail flex={2} color={colors.gray.primary}>
          {Currency.format({value: bill.tip})}
        </BillItemDetail>
      </Row>
    </React.Fragment>
  );
};

const BillSalesTax = ({bill}) => {
  const min = bill.estimateSalesTaxAmountMin;
  const max = bill.estimateSalesTaxAmountMax;
  return (
    <React.Fragment>
      <Space height={4} />
      <Row>
        <BillItemDetail flex={8} color={colors.black}>
          {`Sales Tax (${Percent.display(bill.project.salesTaxRate)})`}
        </BillItemDetail>
        <BillItemDetail flex={2} color={colors.gray.primary}>
          {Currency.formatRange({min, max})}
        </BillItemDetail>
      </Row>
    </React.Fragment>
  );
};

const BillSubtotalAndBillItemsPostSubtotal = ({bill, hasSalesTax, isEditable, isCollapsible}) => {
  return (
    <React.Fragment>
      <Space height={8} />
      <Line dashed />
      <Space height={isEditable || isCollapsible ? 12 : 8} />
      <BillSubtotal bill={bill} />
      {bill.billItemsPostSubtotal.map((billItem, index) => {
        return <BillItemPostSubtotal billItem={billItem} bill={bill} key={index} />;
      })}
      {bill.tip > 0 && <BillTip bill={bill} />}
      {hasSalesTax && <BillSalesTax bill={bill} />}
    </React.Fragment>
  );
};

const BillRuleRow = ({billRule}) => {
  return (
    <BillRuleContainer>
      <FlexContainer flex={1}>
        <BillRuleMainText>{billRule.name}</BillRuleMainText>
        <CaptionText color={colors.gray.tertiary}>{billRule.description}</CaptionText>
      </FlexContainer>
      <BillRuleMainText>{BillRule.getDisplayValues(billRule)}</BillRuleMainText>
    </BillRuleContainer>
  );
};

const NoBillItemsActionMessage = () => {
  return (
    <ListEmptyMessageContainer>
      <IconCircle>
        <Icon source={Icon.Question} color={colors.white} size={8} />
      </IconCircle>
      <Space width={8} />
      <ListEmptyActionText>Press the edit button to start adding to this bill</ListEmptyActionText>
    </ListEmptyMessageContainer>
  );
};

const NoBillItemsPlaceholderMessage = () => {
  return (
    <ListEmptyMessageContainer>
      <CaptionText color={colors.gray.tertiary}>No items added to this bill yet.</CaptionText>
    </ListEmptyMessageContainer>
  );
};

const ProjectBillsListItemContent = ({
  bill,
  isEditable,
  isCollapsible,
  isLastItem,
  showPrices,
  showBillRules,
  showAllBillRules,
}) => {
  const hasSalesTax = bill.estimateSalesTaxAmountMin > 0 || bill.estimateSalesTaxAmountMax > 0;
  const showSubtotal = hasSalesTax || bill.tip > 0 || bill.billModifiers.length > 0;
  const isShowingBillRules = showBillRules || showAllBillRules;
  const billRules = getBillRules({bill, isEditable, showAllBillRules});
  // console.log(billRules);
  // const billRules = bill.billRules;

  return (
    <React.Fragment>
      <Space height={12} />
      <Line />
      <Space height={8} />
      {bill.billItemsPreSubtotal.map((billItem, index) => {
        return (
          <React.Fragment key={index}>
            {index > 0 && <Space height={4} />}
            <BillItemPreSubtotal
              billItem={billItem}
              key={index}
              isEditable={isEditable}
              showPrices={showPrices}
              isEnabledTbdBillItems={bill.organization.features.isEnabledTbdBillItems}
            />
          </React.Fragment>
        );
      })}
      {_.isEmpty(bill.billItemsPreSubtotal) && isEditable && <NoBillItemsActionMessage />}
      {_.isEmpty(bill.billItemsPreSubtotal) && !isEditable && <NoBillItemsPlaceholderMessage />}
      {showSubtotal && (
        <BillSubtotalAndBillItemsPostSubtotal
          bill={bill}
          hasSalesTax={hasSalesTax}
          isEditable={isEditable}
          isCollapsible={isCollapsible}
        />
      )}
      <Space height={8} />
      <Line dashed={isEditable || isCollapsible} />
      <Space height={isEditable || isCollapsible ? 12 : 8} />
      <Row>
        <BillTotalContainer>
          <BillTotalText>{`Total${bill.title ? ` ${bill.title}` : ''}`}</BillTotalText>
        </BillTotalContainer>
        <BillItemDetail flex={2} color={colors.black}>
          <BillTotalText>
            {bill.isEstimateAvailable ? Bill.getEstimateBalance(bill) : 'TBD'}
          </BillTotalText>
        </BillItemDetail>
      </Row>
      {isShowingBillRules && billRules.length > 0 && <Space height={8} />}
      {isShowingBillRules &&
        billRules.map((billRule, index) => {
          return (
            <React.Fragment key={index}>
              {index > 0 && <Space height={8} />}
              <BillRuleRow billRule={billRule} />
            </React.Fragment>
          );
        })}
      {!isEditable && !isCollapsible && billRules.length > 0 && (
        <Space height={isLastItem ? 0 : 16} />
      )}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

ProjectBillsListItemContent.fragment = gql`
  ${Bill.getEstimateBalanceWithoutTip.fragment}
  ${BillItem.getDisplayPrice.fragment}
  ${BillItem.getEstimateQuantity.fragment}
  ${BillItem.getEstimateTotal.fragment}
  ${BillItem.getEstimateTotalPostSubtotal.fragment}
  ${BillRule.getDisplayValues.fragment}

  fragment ProjectBillsListItemContent on Bill {
    id
    title
    isEstimateAvailable
    isSubtotalAvailable
    estimateSubtotalMin
    estimateSubtotalMax
    estimateSalesTaxAmountMin
    estimateSalesTaxAmountMax
    tip
    billItemsPreSubtotal {
      id
      name
      description
      isCredit
      ...BillItem_getDisplayPrice
      ...BillItem_getEstimateQuantity
      ...BillItem_getEstimateTotal
    }
    billItemsPostSubtotal {
      id
      name
      isCredit
      ...BillItem_getDisplayPrice
      ...BillItem_getEstimateTotalPostSubtotal
    }
    billRules {
      name
      description
      isVisibleToCustomer
      values
      ...BillRule_getDisplayValues
    }
    project {
      id
      salesTaxRate
    }
    organization {
      id
      features {
        isEnabledTbdBillItems: isEnabled(feature: "TBD_BILL_ITEMS")
      }
    }
    ...Bill_getEstimateBalanceWithoutTip
  }
`;

export default ProjectBillsListItemContent;
