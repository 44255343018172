// Libraries
import {GraphQLError} from 'graphql';
import React from 'react';
import {ExecutionResult} from 'react-apollo';

import {MutationError, Form, MutationResponse} from '@supermove/hooks/src/forms/types';

import {PayEngine} from '@shared/modules/Payment/components/PayEngineCreditCardInput';
// @ts-ignore
import useBeginPaymentV3Mutation from '@shared/modules/Payment/hooks/useBeginPaymentV3Mutation';
// @ts-ignore
import useFinalizePaymentMutation from '@shared/modules/Payment/hooks/useFinalizePaymentMutation';
import {BeginPaymentV3Form} from '@shared/modules/Payment/types';

// TODO(atsu): Convert hooks for begin/finalize payment to Typescript & move type definitions there.
//   Need to clean up all the 'any' types too.

type BeginPaymentV3MutationResponse = {
  payment: {
    id: string;
  };
};

type FinalizePaymentMutationResponse = {
  payment: {
    id: string;
  };
};

type Args = {
  beginPaymentV3Form: BeginPaymentV3Form;
  onSuccess: (response: FinalizePaymentMutationResponse) => void;
  onError: (errors: MutationError[] | GraphQLError[] | Error) => void;
  onTokenizeError: (error: PayEngine.TokenizeError) => void;
  jobId?: number;
};

type Result = {
  form: Form<{
    beginPaymentV3Form: any;
  }>;
  submitting: boolean | undefined;
  handleSubmit: () => Promise<ExecutionResult<MutationResponse<Record<string, any>>> | undefined>;
  setCreditCardClient: React.Dispatch<React.SetStateAction<PayEngine.CreditCardClient>>;
};

const useBeginPaymentV3WithPayEngine = ({
  beginPaymentV3Form,
  onSuccess,
  onError,
  onTokenizeError,
  jobId,
}: Args): Result => {
  const [creditCardClient, setCreditCardClient] = React.useState<PayEngine.CreditCardClient>({
    createCard: () => {
      throw Error('PayEngine credit card client is not initialized');
    },
  });
  const [tokenizeIsSubmitting, setTokenizeIsSubmitting] = React.useState(false);

  const beginPaymentMutation = useBeginPaymentV3Mutation({
    beginPaymentV3Form,
    onSuccess: (response: BeginPaymentV3MutationResponse) => {},
    onError,
  });

  const finalizePaymentMutation = useFinalizePaymentMutation({
    jobId,
    onSuccess,
    onError,
    response: 'DOCUMENT_NAVIGATION',
  });

  const submitting =
    tokenizeIsSubmitting || beginPaymentMutation.submitting || finalizePaymentMutation.submitting;

  const handleSubmit = async () => {
    try {
      setTokenizeIsSubmitting(true);
      const card = await creditCardClient.createCard();
      await finalizePaymentMutation.form.setFieldValue('paymentMethodToken', card.token);
    } catch (error) {
      setTokenizeIsSubmitting(false);
      onTokenizeError(error as PayEngine.TokenizeError);
      return;
    }
    setTokenizeIsSubmitting(false);

    try {
      const beginPaymentResponse = await beginPaymentMutation.handleSubmit();
      if (beginPaymentResponse.errors) {
        onError(beginPaymentResponse.errors);
        return;
      }
      if (!beginPaymentResponse.data?.response?.payment?.id) {
        onError(new Error('GraphQL response did not contain Payment ID'));
        return;
      }

      await finalizePaymentMutation.form.setFieldValue(
        'paymentId',
        beginPaymentResponse.data.response.payment.id,
      );

      return await finalizePaymentMutation.handleSubmit();
    } catch (error) {
      onError(error as Error);
      return;
    }
  };

  return {
    form: beginPaymentMutation.form,
    submitting,
    handleSubmit,
    setCreditCardClient,
  };
};

export default useBeginPaymentV3WithPayEngine;
