// Libraries
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

// Components

const Button = Styled.LoadingButton`
  height: 60px;
  width: 400px;
`;

const Name = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const PaymentOption = ({loading, name, onPress}) => (
  <Button loading={loading} onPress={onPress}>
    <Name>{name}</Name>
  </Button>
);

export default PaymentOption;
