// Libraries
import React from 'react';

// App
import {KeyboardView, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';

// Components
import DocumentStepKind from '@shared/modules/Document/enums/DocumentStepKind';
import {
  RiskyMoveDocumentActions,
  RiskyMoveDocumentItemInput,
} from 'modules/Job/Document/RiskyMove/components';
import {CreateDocumentForm} from 'modules/Job/Document/components';
import {JobNavigation, JobPage} from 'modules/Job/components';

const Container = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
  margin-vertical: 20px;
  margin-horizontal: 40px;
`;

const Info = Styled.View`
`;

const Title = Styled.H2`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Subtitle = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Items = Styled.View`
  margin-top: 20px;
`;

const Footer = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px;
  background-color: ${colors.gray.background};
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
`;

const Button = Styled.LoadingButton`
  align-self: stretch;
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const RiskyMoveDocumentJobPage = () => (
  <JobPage
    getVariables={({params}) => ({uuid: params.uuid})}
    query={RiskyMoveDocumentJobPage.query}
    navigation={(props) => <JobNavigation {...props} />}
  >
    {({navigator, params, data}) => (
      <CreateDocumentForm
        jobId={data.job.id}
        name={`Risky Move for ${data.job.customer.fullName}`}
        template={'RISKY_MOVE'}
        step={DocumentStepKind.DURING_MOVE}
        additionalInfo={{
          items: ['', '', ''],
        }}
        filterAdditionalInfo={(additionalInfo) => ({
          // Filter out empty strings.
          items: additionalInfo.items.filter((item) => !!item),
        })}
        onSuccess={(document) => {
          navigator.navigate('SignDocumentJob', {
            uuid: params.uuid,
            documentUuid: document.uuid,
            success: params.success,
          });
        }}
        onError={(errors) => console.log({errors})}
      >
        {(form) => (
          <Container>
            <KeyboardView>
              <ScrollView alwaysBounceVertical={false} style={{flex: 1}}>
                <Content>
                  <Info>
                    <Title>Risky Move Agreement</Title>
                    <Subtitle>Add a list of items that are make up this "risky move".</Subtitle>
                  </Info>
                  <Items>
                    {form.values.additionalInfo.items.map((item, index) => (
                      <RiskyMoveDocumentItemInput
                        key={index}
                        isFirst={index === 0}
                        isLast={index === form.values.additionalInfo.items.length - 1}
                        name={`additionalInfo.items.${index}`}
                        placeholder={'Enter the name of an item'}
                        index={index}
                        values={form.values}
                        setFieldValue={form.setFieldValue}
                        setFieldTouched={form.setFieldTouched}
                      />
                    ))}
                    <RiskyMoveDocumentActions addText={'Add Item'} form={form} />
                  </Items>
                </Content>
              </ScrollView>
            </KeyboardView>
            <Footer>
              <Button
                loading={form.loading}
                color={colors.blue.interactive}
                onPress={form.handleSubmit}
              >
                <Text>Submit</Text>
              </Button>
            </Footer>
          </Container>
        )}
      </CreateDocumentForm>
    )}
  </JobPage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
RiskyMoveDocumentJobPage.query = gql`
  ${JobPage.fragment}
  ${JobNavigation.fragment}

  query RiskyMoveDocumentJobPage($uuid: String!) {
    ${gql.query}
    job(uuid: $uuid) {
      id
      customer {
        id
        fullName
      }
      ...JobPage
      ...JobNavigation
    }
  }
`;

export default RiskyMoveDocumentJobPage;
