// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

const Container = Styled.View`
`;

const Content = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const Button = Styled.Button`
  height: 40px;
  padding-horizontal: 10px;
`;

const Text = Styled.Text`
  ${Typography.Label2}
  color: ${colors.white};
`;

const DocumentAdditionalInfoGridActions = ({name, form, emptyRow}) => (
  <Container>
    <Content>
      <Button
        onPress={() => {
          const items = _.get(form, `values.${name}`);
          const newItems = _.concat(items, [emptyRow]);
          form.setFieldValue(name, newItems);
        }}
      >
        <Text>+ Add Custom</Text>
      </Button>
    </Content>
  </Container>
);

export default DocumentAdditionalInfoGridActions;
