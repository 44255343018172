// Libraries
import React from 'react';

// Supermove
import {FlatList, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Organization} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';

// App
import Notification from 'modules/App/components/Notification';

// Components
import BillLineItemItem from './BillLineItemItem';

const Item = Styled.View`
`;

const Header = Styled.View`
  align-self: stretch;
  flex-direction: row;
  padding-vertical: 5px;
  padding-horizontal: 10px;
  margin-bottom: 10px;
  background-color: ${colors.gray.background};
`;

const Cell = Styled.View`
  width: 140px;
`;

const CellText = Styled.H6`
  ${fontWeight(500)}
  color: ${colors.gray.secondary};
  text-align: right;
`;

const LineItemHeader = ({hidePrices}) => (
  <Header>
    <Cell
      style={{
        flex: 1,
      }}
    >
      <CellText
        style={{
          textAlign: 'left',
        }}
      >
        Item
      </CellText>
    </Cell>
    <Cell>
      <CellText>Quantity</CellText>
    </Cell>
    {hidePrices ? null : (
      <React.Fragment>
        <Cell>
          <CellText>Price</CellText>
        </Cell>
        <Cell
          style={{
            alignItems: 'flex-end',
          }}
        >
          <CellText>Total</CellText>
        </Cell>
      </React.Fragment>
    )}
  </Header>
);

const BillLineItemsList = ({hidePrices, bill}) => {
  return (
    <FlatList
      data={bill.lineItems}
      extraData={bill}
      listKey={'BillLineItemsList'}
      keyExtractor={(lineItem, index) => String(index)}
      renderItem={({item: lineItem}) => (
        <Item>
          <BillLineItemItem hidePrices={hidePrices} lineItem={lineItem} />
        </Item>
      )}
      ListHeaderComponent={() => <LineItemHeader hidePrices={hidePrices} />}
      ListEmptyComponent={() => (
        <Notification color={colors.orange.status} text={`No billing items.`} />
      )}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillLineItemsList.fragment = gql`
  ${BillLineItemItem.fragment}
  ${Organization.getAdditionalSupplies.fragment}

  fragment BillLineItemsList on Bill {
    id
    kind
    discountPercentage
    lineItems {
      kind
      ...BillLineItemItem
    }
    organization {
      id
      ...Organization_getAdditionalSupplies
    }
  }
`;

export default BillLineItemsList;
