// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {colors, fontWeight, Typography} from '@supermove/styles';

const Container = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-horizontal: ${({isSmall}) => (isSmall ? '3px' : '12px')};
  padding-vertical: ${({isSmall}) => (isSmall ? '2px' : '8px')};
  border-radius: ${({isSmall}) => (isSmall ? '2px' : '5px')};
  background-color: ${(props) => props.color};
`;

const Text = Styled.Text`
  ${Typography.Body3}
  color: ${(props) => props.color};
`;

const SmallText = Styled.H8`
  color: ${(props) => props.color};
  ${fontWeight(500)}
`;

const TextBadge = ({text, color, isSmall, icon}) => {
  return (
    <Container color={colors.getBackgroundColor(color)} isSmall={isSmall}>
      {icon && (
        <React.Fragment>
          <Icon source={icon} color={color} size={isSmall ? 12 : 14} />
          <Space width={8} />
        </React.Fragment>
      )}
      {isSmall ? <SmallText color={color}>{text}</SmallText> : <Text color={color}>{text}</Text>}
    </Container>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
TextBadge.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  isSmall: PropTypes.bool,
};

TextBadge.defaultProps = {
  isSmall: false,
};

export default TextBadge;
