// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Loading, Query} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useIsFocused, useNavigation} from '@supermove/hooks';

// Components
import JobInProgressBanner from './JobInProgressBanner';

const getView = ({isCurrent, view}) => {
  // Special case for when we normally show the "Tap to view" banner BUT we're on the current job
  // already. In this case, we show the employee mode.
  return isCurrent && view === 'NONE' ? 'EMPLOYEE' : view;
};

const hasCurrentJob = (data) => {
  // HACK(mark): Protects against network errors when `data` is undefined.
  return !!data && !!data.viewer && !!data.viewer.currentJob;
};

const ViewerJobBannerContent = ({loading, data, refetch, view}) => {
  const {params} = useNavigation();

  useIsFocused({
    onFocus: () => refetch(),
  });

  return (
    <Loading loading={loading}>
      {() =>
        hasCurrentJob(data) ? (
          <JobInProgressBanner
            view={getView({isCurrent: params.uuid === data.viewer.currentJob.uuid, view})}
            job={data.viewer.currentJob}
          />
        ) : null
      }
    </Loading>
  );
};

const ViewerJobBanner = ({view}) => (
  <Query fetchPolicy={'network-only'} query={ViewerJobBanner.query}>
    {({loading, data, refetch}) => (
      <ViewerJobBannerContent loading={loading} data={data} refetch={refetch} view={view} />
    )}
  </Query>
);

// --------------------------------------------------
// Props
// --------------------------------------------------
ViewerJobBanner.propTypes = {
  view: PropTypes.string,
};

ViewerJobBanner.defaultProps = {
  view: 'NONE',
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ViewerJobBanner.query = gql`
  ${JobInProgressBanner.fragment}

  query ViewerJobBanner {
    ${gql.query}
    viewer {
      id
      currentJob {
        id
        uuid
        ...JobInProgressBanner
      }
    }
  }
`;

export default ViewerJobBanner;
