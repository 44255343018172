// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

// App
import EditBillItemsModal from 'modules/Project/Billing/Edit/components/EditBillItemsModal';
import ProjectBillsListItemContent from 'modules/Project/Billing/components/ProjectBillsListItemContent';
import ProjectBillsListItemHeader from 'modules/Project/Billing/components/ProjectBillsListItemHeader';

const Container = Styled.View`
  flex-direction: row;
`;

const BillContainer = Styled.View`
  padding-horizontal: ${(props) => (props.hasBorder ? '12px' : '0px')};
  border-width: ${(props) => (props.hasBorder ? '1px' : '0px')};
  border-color: ${colors.gray.border};
  border-radius: 8px;
  flex: 1;
`;

const EditButtonColumn = Styled.View`
  width: 108px;
  align-items: flex-end;
`;

const EditButtonContainerTouchable = Styled.Touchable`
  height: 36px;
  width: 100px;
  border-radius: 4px;
  background-color: ${colors.alpha(colors.blue.interactive, 0.1)};
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

const EditButtonText = Styled.H6`
    ${fontWeight(700)}
    color: ${colors.blue.interactive};
`;

const ProjectBillsListItem = ({
  bill,
  isEditable,
  isCollapsible,
  refetch,
  isLastItem,
  showPrices,
  showBillRules,
  showAllBillRules,
  isExpanded,
  expandedBills,
  setExpandedBills,
  isHighlighted,
  index,
}) => {
  const editBillItemsModal = useModal({name: 'Edit Bill Items Modal'});
  return (
    <Container>
      <BillContainer hasBorder={isEditable || isCollapsible}>
        {(isEditable || isCollapsible) && <Space height={12} />}
        <ProjectBillsListItemHeader
          bill={bill}
          isHighlighted={isHighlighted}
          isEditable={isEditable}
          isCollapsible={isCollapsible}
          isExpanded={isExpanded}
          expandedBills={expandedBills}
          setExpandedBills={setExpandedBills}
          index={index}
        />
        {isExpanded && (
          <ProjectBillsListItemContent
            bill={bill}
            isEditable={isEditable}
            isCollapsible={isCollapsible}
            isLastItem={isLastItem}
            showPrices={showPrices}
            showBillRules={showBillRules}
            showAllBillRules={showAllBillRules}
          />
        )}
        {(isEditable || isCollapsible) && <Space height={12} />}
      </BillContainer>
      {isEditable && (
        <EditButtonColumn>
          <EditButtonContainerTouchable activeOpacity={0.8} onPress={editBillItemsModal.handleOpen}>
            {bill.organization.features.isEnabledSuppliesButtonCrewApp ? (
              <React.Fragment>
                <Icon source={Icon.Plus} size={12} color={colors.blue.interactive} />
                <Space width={8} />
                <EditButtonText>Supplies</EditButtonText>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Icon source={Icon.Pen} size={12} color={colors.blue.interactive} />
                <Space width={8} />
                <EditButtonText>Edit</EditButtonText>
              </React.Fragment>
            )}
          </EditButtonContainerTouchable>
        </EditButtonColumn>
      )}

      <EditBillItemsModal
        key={editBillItemsModal.key}
        isOpen={editBillItemsModal.isOpen}
        handleClose={editBillItemsModal.handleClose}
        bill={bill}
        refetch={refetch}
      />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

ProjectBillsListItem.fragment = gql`
  ${EditBillItemsModal.fragment}
  ${ProjectBillsListItemContent.fragment}
  ${ProjectBillsListItemHeader.fragment}

  fragment ProjectBillsListItem on Bill {
    id
    organization {
      id
      features {
        isEnabledSuppliesButtonCrewApp: isEnabled(feature: "SUPPLIES_BUTTON_CREW_APP")
      }
    }
    ...EditBillItemsModal
    ...ProjectBillsListItemContent
    ...ProjectBillsListItemHeader
  }
`;

export default ProjectBillsListItem;
