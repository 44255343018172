// Libraries
import React from 'react';

// Utils
import {Form} from '@supermove/components';
import {Currency} from '@supermove/utils';

// Components

const PartialPaymentForm = ({amount, children}) => (
  <Form
    enableReinitialize
    initialValues={{
      amount: Currency.toForm(amount),
    }}
    onSubmit={(values) => {}}
  >
    {(form) => children(form)}
  </Form>
);

export default PartialPaymentForm;
