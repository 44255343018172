// Libraries
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

// Components

const Container = Styled.View`
`;

const Content = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 10px;
  padding-horizontal: 10px;
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const Index = Styled.H5`
  margin-right: 20px;
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Value = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const RiskyMoveDocumentItem = ({isFirst, isLast, index, item}) => (
  <Container>
    {isFirst && <Line />}
    <Content>
      <Index>{`${index + 1}.`}</Index>
      <Value numberOfLines={2}>{item}</Value>
    </Content>
    <Line />
  </Container>
);

export default RiskyMoveDocumentItem;
