// Libraries
import {gql} from '@supermove/graphql';
import {useMutation, useMutationCallbacks} from '@supermove/hooks';

const MUTATION = gql`
  mutation SkipSignatureForAllDocumentsForStepMutation (
    $jobId: Int!,
    $step: String!,
  ) {
    response: skipSignatureForAllDocumentsForStep(
      jobId: $jobId,
      step: $step,
    ) {
      ${gql.errors}
      job {
        id
        filteredDocuments(step: $step) {
          id
          isStepCompleted(step: $step)
        }
      }
    }
  }
`;

const useSkipSignatureForAllDocumentsForStepMutation = ({jobId, step, onSuccess, onError}) => {
  const [handleSubmit, {loading, data}] = useMutation(MUTATION, {
    variables: {
      jobId,
      step,
    },
  });

  useMutationCallbacks({data, onSuccess, onError});

  return {
    submitting: loading,
    handleSubmit,
  };
};

export default useSkipSignatureForAllDocumentsForStepMutation;
