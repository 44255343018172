// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Form, Notification, Styled, TimeInput} from '@supermove/components';
import {Organization, TimeRange} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';

// Components
import {Field, Modal} from 'modules/App/components';

const Container = Styled.View`
  padding: 40px;
  width: 600px;
  background-color: ${colors.white};
`;

const Title = Styled.H4`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
  text-align: center;
`;

const Subtitle = Styled.H6`
  margin-top: 3px;
  color: ${colors.gray.primary};
  text-align: center;
`;

const Content = Styled.View`
  margin-vertical: 20px;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const Label = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.gray.secondary};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const FieldSpace = Styled.View`
  width: 10px;
`;

const TextInput = Styled.TextInput.H7`
  margin-top: 3px;
`;

const Actions = Styled.View`
  margin-top: 20px;
  flex-direction: row;
  justify-content: center;
`;

const Button = Styled.LoadingButton`
  height: 40px;
  width: 100px;
`;

const ButtonSpace = Styled.View`
  width: 10px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const GroupButton = Styled.Touchable`
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 30px;
  background-color: ${(props) => (props.isSelected ? colors.blue.interactive : colors.white)};
  border-width: 1px;
  border-color: ${(props) =>
    props.isSelected ? colors.blue.interactive : colors.blue.interactive};
`;

const GroupText = Styled.H7`
  ${fontWeight(700)}
  color: ${({vars}) => (vars.isSelected ? colors.white : colors.blue.interactive)};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const KindButton = ({isSelected, kind, onPress}) => (
  <GroupButton isSelected={isSelected} activeOpacity={1} onPress={onPress}>
    <GroupText vars={{isSelected}}>{kind}</GroupText>
  </GroupButton>
);

const addTimeRange = ({parentForm, form, timeRange, handleClose}) => {
  const {timeRanges} = parentForm.values;
  const newTimeRanges = [...timeRanges, timeRange];

  // We submit the parentForm with the new timeRange without adding to the parentForm's existing timeRanges.
  const variables = {
    timeRanges: newTimeRanges.map((timeRange) => TimeRange.toMutation(timeRange)),
  };

  // On success, we add to the array of timeRanges which resets the form state and closes the modal.
  parentForm.handleSubmit({variables}).then(
    ({
      data: {
        trackTimeForMove: {move, errors},
      },
    }) => {
      if (move) {
        // Update the parent state to the new set of timeRanges.
        parentForm.setFieldValue('timeRanges', newTimeRanges);
        handleClose();
      } else {
        // We also don't have access to the `form.setMutationErrors` helper so we do it manually.
        form.setErrors({});

        // HACK(mark): We need to change the errors from `time_fields.1.start` --> `start`.
        const getFieldName = ({field}) => {
          const parts = field.split('.');
          return _.camelCase(_.last(parts));
        };

        _.forEachRight(errors, (error) => form.setFieldError(getFieldName(error), error.message));
      }
    },
  );
};

const MoveAddTimeRangeModal = ({initialStart, organization, parentForm, trigger}) => (
  <Modal trigger={trigger}>
    {({handleClose}) => (
      <Form
        initialValues={{
          name: '',
          kind: '',
          start: initialStart,
          end: '',
        }}
        onSubmit={(timeRange, form) => addTimeRange({parentForm, form, timeRange, handleClose})}
      >
        {(form) => (
          <Container>
            <Title>Add Time</Title>
            <Subtitle>Enter details about this event.</Subtitle>
            <Content>
              <Label>Event</Label>
              <Row style={{marginTop: 3, marginBottom: 20}}>
                {Organization.getTimeRangeKinds(organization).map((kind) => (
                  <KindButton
                    key={kind}
                    isSelected={kind === form.values.kind}
                    kind={kind}
                    onPress={() =>
                      form.setValues({
                        ...form.values,
                        name: TimeRange.getName({kind}),
                        kind,
                      })
                    }
                  />
                ))}
              </Row>
              <Row>
                <Field
                  {...form}
                  component={TimeInput}
                  name={'start'}
                  label={'Start Time'}
                  input={{
                    disabled: !!initialStart,
                    component: TextInput,
                    placeholder: 'Start Time',
                    setFieldValue: form.setFieldValue,
                    style: {
                      width: 200,
                    },
                  }}
                />
                <FieldSpace />
                <Field
                  {...form}
                  component={TimeInput}
                  name={'end'}
                  label={'End Time'}
                  input={{
                    component: TextInput,
                    placeholder: 'End Time',
                    setFieldValue: form.setFieldValue,
                    style: {
                      width: 200,
                    },
                  }}
                />
              </Row>
            </Content>
            {TimeRange.getIsLong(form.values) && (
              <Notification.Warning
                text={'This time is longer than 12 hours. Please double check.'}
              />
            )}
            <Actions>
              <Button color={colors.gray.secondary} onPress={handleClose}>
                <Text>Cancel</Text>
              </Button>
              <ButtonSpace />
              <Button
                disabled={!form.values.kind || !form.values.start}
                loading={form.loading}
                onPress={form.handleSubmit}
              >
                <Text>Save</Text>
              </Button>
            </Actions>
          </Container>
        )}
      </Form>
    )}
  </Modal>
);

export default MoveAddTimeRangeModal;
