// Libraries
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

// Components

const Container = Styled.View`
`;

const Content = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 10px;
  padding-horizontal: 10px;
`;

const Actions = Styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
`;

const Button = Styled.Button`
  margin-left: 10px;
  height: 40px;
  width: 100px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const MoveAddTimeRangeItem = ({hasAllEndTimes, onOpen}) =>
  hasAllEndTimes ? (
    <Container>
      <Content>
        <Actions>
          <Button onPress={onOpen}>
            <Text>Add Time</Text>
          </Button>
        </Actions>
      </Content>
    </Container>
  ) : null;

export default MoveAddTimeRangeItem;
