// Libraries
import React from 'react';

// App
import NewPaymentBillingProjectJobDefaultPage from 'modules/Job/Project/Billing/Payment/New/components/NewPaymentBillingProjectJobDefaultPage';

const CashMethodNewPaymentBillingProjectJobPage = () => {
  return (
    <NewPaymentBillingProjectJobDefaultPage
      paymentMethod={'CASH'}
      title={'Please pay the amount in full.'}
      subtitle={'When you are ready, the crew will handle your payment.'}
      button={'Paid'}
    />
  );
};

export default CashMethodNewPaymentBillingProjectJobPage;
