// Libraries
import React from 'react';

// App
import NewPaymentBillingProjectJobDefaultPage from 'modules/Job/Project/Billing/Payment/New/components/NewPaymentBillingProjectJobDefaultPage';

const ExternalMethodNewPaymentBillingProjectJobPage = () => {
  return (
    <NewPaymentBillingProjectJobDefaultPage
      paymentMethod={'EXTERNAL'}
      title={'Please hand your credit card to the crew.'}
      subtitle={'Your payment will be processed shortly.'}
      button={'Paid'}
    />
  );
};

export default ExternalMethodNewPaymentBillingProjectJobPage;
