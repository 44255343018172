// Libraries
import React from 'react';

// Supermove
import {ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation} from '@supermove/hooks';
import {DeviceInfo} from '@supermove/sdk';
import {colors, fontWeight} from '@supermove/styles';

// App
import {AppInfo} from 'config';
import {LogOutAction, ModalPage, Navigation} from 'modules/App/components';
import {SettingsFieldValues} from 'modules/Settings/components';

const Container = Styled.View`
  flex: 1;
`;

const Button = Styled.LoadingButton`
  height: 40px;
  width: 120px;
`;

const Text = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const SettingsPage = () => {
  const {navigator} = useNavigation();

  return (
    <ModalPage
      query={SettingsPage.query}
      getVariables={() => ({
        uniqueId: DeviceInfo.getUniqueId(),
        appName: 'Crew',
        appPlatform: DeviceInfo.getPlatform(),
        releaseBuild: AppInfo.getBuildNumber(),
      })}
      navigation={({loading, data, params}) => (
        <Navigation
          getSideWidth={({responsive}) => 130}
          title={'Settings'}
          subtitle={'View app information'}
          renderLeft={() => <Navigation.CloseButton onPress={() => navigator.goBack()} />}
          renderRight={() => (
            <LogOutAction>
              {({handleLogOut}) => (
                <Button color={colors.blue.interactive} onPress={handleLogOut}>
                  <Text>Sign Out</Text>
                </Button>
              )}
            </LogOutAction>
          )}
        />
      )}
    >
      {({data}) => (
        <Container>
          <ScrollView style={{flex: 1}}>
            <SettingsFieldValues data={data} />
          </ScrollView>
        </Container>
      )}
    </ModalPage>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
SettingsPage.propTypes = {};

SettingsPage.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
SettingsPage.query = gql`
  ${SettingsFieldValues.fragment}

  query SettingsPage(
    $uniqueId: String!,
    $appName: String!,
    $appPlatform: String!,
    $releaseBuild: Int!,
  ) {
    ${gql.query}
    ...SettingsFieldValues
  }
`;

export default SettingsPage;
