// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation} from '@supermove/hooks';
import {Job} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';

// App
import {Notification} from 'modules/App/components';
import {ConfirmUnsetCurrentJobModal} from 'modules/User/components';

const Actions = Styled.View`
`;

const RemoveButton = Styled.LoadingButton`
  flex-direction: row;
  align-items: center;
  height: 40px;
  padding-horizontal: 15px;
`;

const RemoveText = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const NotCurrentJobNotification = ({viewer, refetch}) => {
  const {navigator} = useNavigation();

  return (
    <Notification
      color={colors.red.warning}
      text={
        `You are currently on ${Job.getFullName(viewer.currentJob)}. ` +
        `If you'd like to stop and start a new job, press "Stop Job".`
      }
    >
      <Actions>
        <Space width={12} />
        <ConfirmUnsetCurrentJobModal
          userId={viewer.id}
          onSuccess={() => navigator.navigate('ListJobs')}
          onError={(errors) => console.log({errors})}
          trigger={({handleOpen}) => (
            <RemoveButton color={colors.red.warning} onPress={handleOpen}>
              <RemoveText>{'Stop Job'}</RemoveText>
            </RemoveButton>
          )}
        />
      </Actions>
    </Notification>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
NotCurrentJobNotification.fragment = gql`
  ${Job.getFullName.fragment}

  fragment NotCurrentJobNotification on User {
    id
    currentJob {
      id
      ...Job_getFullName
    }
  }
`;

export default NotCurrentJobNotification;
