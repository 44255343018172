// Libraries
import React from 'react';

// Components
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';

import {Page} from 'modules/App/components';
import {ViewerJobBanner} from 'modules/Job/components';

const Container = Styled.View`
  flex: 1;
`;

const CustomerJobPage = ({getVariables, query, onFocus, children}) => (
  <Page fetchPolicy={'network-only'} getVariables={getVariables} query={query} onFocus={onFocus}>
    {(props) => (
      <Container>
        <ViewerJobBanner view={'CUSTOMER'} />
        {children(props)}
      </Container>
    )}
  </Page>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
CustomerJobPage.fragment = gql`
  fragment CustomerJobPage on Job {
    id
  }
`;

export default CustomerJobPage;
