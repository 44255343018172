// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';

const Container = Styled.View`
`;

const Content = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-vertical: 10px;
  padding-horizontal: 10px;
`;

const Button = Styled.Button`
  height: 40px;
  padding-horizontal: 10px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const RiskyMoveDocumentActions = ({addText, form}) => {
  return (
    <Container>
      <Content>
        <Button
          onPress={() => {
            const {items} = form.values.additionalInfo;
            form.setFieldValue(`additionalInfo.items`, _.concat(items, ''));
          }}
        >
          <Text>{addText}</Text>
        </Button>
      </Content>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
RiskyMoveDocumentActions.fragment = gql`
  fragment RiskyMoveDocumentActions on Job {
    id
  }
`;

export default RiskyMoveDocumentActions;
