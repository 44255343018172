// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';

// App
import EditBillsAndAddPaymentsButton from 'modules/Project/Billing/components/EditBillsAndAddPaymentsButton';
import ProjectBillsList from 'modules/Project/Billing/components/ProjectBillsList';

const Container = Styled.View`
  padding-vertical: 20px;
  padding-horizontal: 30px;
`;

const SplitRow = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

const Title = Styled.H4`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Content = Styled.View`
  padding-top: 10px;
`;

const JobMainBillBlock = ({job}) => {
  return (
    <Container>
      <Content>
        <SplitRow>
          <Title>Billing</Title>
          {job.organization.features.isEnabledAdditionalPaymentsCrewApp &&
            job.hasJobFeatureEditBillingAndPayments && (
              <EditBillsAndAddPaymentsButton jobUuid={job.uuid} />
            )}
        </SplitRow>
        <ProjectBillsList
          project={job.project}
          isCollapsible
          showAllBillRules
          showGrandTotal
          showBalance
        />
      </Content>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobMainBillBlock.fragment = gql`
  ${ProjectBillsList.fragment}

  fragment JobMainBillBlock on Job {
    id
    uuid
    hasJobFeatureEditBillingAndPayments: hasJobFeature(kind: "CREW_EDIT_BILLING_AND_PAYMENTS")
    project {
      id
      ...ProjectBillsList
    }
    organization {
      id
      features {
        isEnabledAdditionalPaymentsCrewApp: isEnabled(feature: "ADDITIONAL_PAYMENTS_CREW_APP")
      }
    }
  }
`;

export default JobMainBillBlock;
