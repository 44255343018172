// Libraries
import React from 'react';

// Supermove
import {MaintenancePage, SupermoveApp} from '@supermove/app';
import {StatusBar, UpdateChecker, ToastContainer} from '@supermove/components';
import {useMountEffect} from '@supermove/hooks';
import {Environment, ScreenOrientation} from '@supermove/sdk';
import {colors} from '@supermove/styles';

// App
import {AppInfo} from 'config';
import ErrorModal from 'modules/App/components/ErrorModal';
import UpdateAppView from 'modules/Settings/Update/components/UpdateAppView';

const App = ({router: Router}) => {
  useMountEffect(() => {
    // NOTE(cooper): We lock the screen orientation to landscape mode for the entire app
    // Many crew app views are compatible with portrait mode, but some are not, in the future
    // we may address these specific views and unlock the screen orientation at that time
    ScreenOrientation.lockToLandscape();
  });

  return Environment.get('SHOW_MAINTENANCE_PAGE') ? (
    <MaintenancePage />
  ) : (
    <React.Fragment>
      <StatusBar barStyle={'light-content'} backgroundColor={colors.black} />
      <ErrorModal
        trigger={({handleOpen}) => (
          <SupermoveApp
            name={'Crew'}
            version={AppInfo.getVersion()}
            buildNumber={AppInfo.getBuildNumber()}
            onError={() => handleOpen()}
          >
            {() => (
              <React.Fragment>
                <UpdateChecker showLoading name={'Crew'} buildNumber={AppInfo.getBuildNumber()}>
                  {({isSkipped, isVisible, isRequired, onSkip}) =>
                    isVisible && !isSkipped ? (
                      <UpdateAppView isRequired={isRequired} onSkip={onSkip} />
                    ) : (
                      <Router />
                    )
                  }
                </UpdateChecker>
                <ToastContainer />
              </React.Fragment>
            )}
          </SupermoveApp>
        )}
      />
    </React.Fragment>
  );
};

export default App;
