// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import JobEventForm from '@shared/modules/Job/forms/JobEventForm';
import AddTimesheetBillableBlockModal from '@shared/modules/Timesheet/components/AddTimesheetBillableBlockModal';
import TimesheetBillableEntry from '@shared/modules/Timesheet/components/TimesheetBillableEntry';
import useCreateJobEventJobTimesheetUpdatedMutation from '@shared/modules/Timesheet/hooks/useCreateJobEventJobTimesheetUpdatedMutation';
import JobMoveTimesheetView from 'modules/Job/Crew/Timesheet/components/JobMoveTimesheetView';

const Container = Styled.View`
  padding-vertical: 20px;
  padding-horizontal: 30px;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Title = Styled.H4`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Content = Styled.View`
  padding-top: 10px;
`;

const JobTimesheetBlock = ({job, refetch}) => {
  const addTimesheetBillableBlockModal = useModal({name: 'Add Timesheet Billable Block Modal'});
  const createJobEventTimesheetUpdated = useCreateJobEventJobTimesheetUpdatedMutation({
    jobEventForm: JobEventForm.new({jobId: job.id}),
    onSuccess: () => {},
    onError: (errors) => console.log({errors}),
  });

  return (
    <Container>
      <Row>
        <Title>Timesheet</Title>
        {job.project.projectType.features.timesheetsV2 && (
          <React.Fragment>
            <Space style={{flex: 1}} />
            <Button
              text={'Add Time'}
              onPress={addTimesheetBillableBlockModal.handleOpen}
              isResponsive
            />
          </React.Fragment>
        )}
      </Row>
      <Content>
        {job.project.projectType.features.timesheetsV2 ? (
          job.timesheetBillableEntries.map((timesheetBillableEntry, index) => (
            <React.Fragment key={timesheetBillableEntry.id}>
              {index > 0 && <Space height={24} />}
              <TimesheetBillableEntry
                job={job}
                timesheetBillableEntry={timesheetBillableEntry}
                refetch={refetch}
                onUpdate={createJobEventTimesheetUpdated.handleSubmit}
              />
            </React.Fragment>
          ))
        ) : (
          <JobMoveTimesheetView job={job} />
        )}
      </Content>
      <AddTimesheetBillableBlockModal
        key={addTimesheetBillableBlockModal.key}
        isOpen={addTimesheetBillableBlockModal.isOpen}
        handleClose={addTimesheetBillableBlockModal.handleClose}
        job={job}
        refetch={refetch}
        onUpdate={createJobEventTimesheetUpdated.handleSubmit}
      />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobTimesheetBlock.fragment = gql`
  ${AddTimesheetBillableBlockModal.fragment}
  ${JobMoveTimesheetView.fragment}
  ${TimesheetBillableEntry.fragment}

  fragment JobTimesheetBlock on Job {
    id
    project {
      id
      projectType {
        id
        features {
          timesheetsV2
        }
      }
    }
    ...AddTimesheetBillableBlockModal
    ...TimesheetBillableEntry
    ...JobMoveTimesheetView
  }
`;

export default JobTimesheetBlock;
