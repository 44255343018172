// Supermove
import {useForm} from '@supermove/hooks';

// App
import PaymentForm from '@shared/modules/Payment/forms/PaymentForm';

const usePaymentForm = ({paymentForm}) => {
  const form = useForm({
    enableReinitialize: true,
    initialValues: {
      paymentForm: PaymentForm.toForm(paymentForm),
    },
  });

  return {
    form,
  };
};

export default usePaymentForm;
