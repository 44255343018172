const JobRate = {
  toMutation: (jobRate) => {
    return {
      name: jobRate.name,
      percentage: jobRate.percentage,
      amount: jobRate.amount,
    };
  },
};

export default JobRate;
