// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Components
import {FlatList} from '@supermove/components';

const OrganizationUsersGrid = ({users, extraData, renderUser, renderEmpty}) => (
  <FlatList
    data={users}
    keyExtractor={(user) => user.id}
    numColumns={6}
    extraData={extraData}
    renderItem={({item: user}) => renderUser({user})}
    ListEmptyComponent={renderEmpty}
  />
);

// --------------------------------------------------
// Props
// --------------------------------------------------
OrganizationUsersGrid.propTypes = {
  renderUser: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  extraData: PropTypes.any,
  renderEmpty: PropTypes.func,
};

OrganizationUsersGrid.defaultProps = {
  extraData: undefined,
  renderEmpty: () => null,
};

export default OrganizationUsersGrid;
