// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {KeyboardView, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigation} from '@supermove/hooks';
import {Job} from '@supermove/models';
import {CreditCardInput, StripeForm} from '@supermove/payments';
import {colors, fontWeight} from '@supermove/styles';
import {Currency, Json} from '@supermove/utils';

// App
import BillTipForm from '@shared/modules/Billing/forms/BillTipForm';
import useCreateBillTipMutation from '@shared/modules/Billing/hooks/useCreateBillTipMutation';
import CreateCustomerPaymentProfileForm from '@shared/modules/Payment/forms/CreateCustomerPaymentProfileForm';
import StripePaymentForm from '@shared/modules/Payment/forms/StripePaymentForm';
import {transactionContactMessage} from '@shared/modules/Payment/hooks/payengineErrors';
import useSavePaymentWithStripe from '@shared/modules/Payment/hooks/useSavePaymentWithStripe';
import CustomerForm from '@shared/modules/User/forms/CustomerForm';
import Navigation from 'core/Navigation';
import PageFooter from 'modules/App/components/PageFooter';
import PageV2 from 'modules/App/components/PageV2';
import PaymentErrorModal from 'modules/Project/Billing/Payment/New/components/PaymentErrorModal';
import PaymentAmountSection from 'modules/Project/Billing/Payment/components/PaymentAmountSection';

const Container = Styled.View`
  flex: 1;
`;

const TitleContainer = Styled.View`
  align-items: center;
`;

const InputContainer = Styled.View`
  padding-horizontal: 40px;
`;

const Button = Styled.LoadingButton`
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const getStripeAccountId = ({job}) => {
  const credentials = _.get(job, 'organization.account.stripeConnectAccountCredentials', '{}');
  const json = Json.toCamelCaseKeys(Json.parse(credentials));
  return _.get(json, 'stripeUserId');
};

const ProjectBillingSaveCardPaymentSection = ({job}) => {
  const {params} = useNavigation();
  const paymentErrorModal = useModal();
  const stripeAccountId = getStripeAccountId({job});
  const billId = job.project.currentPrimaryBill.id;

  const createCustomerPaymentProfileForm = CreateCustomerPaymentProfileForm.new({
    customerId: job.customer.id,
  });
  const stripePaymentForm = StripePaymentForm.new({stripeAccountId});
  const customerForm = CustomerForm.edit(job.customer);

  const createBillTipMutation = useCreateBillTipMutation({
    billTipForm: BillTipForm.new({
      billId,
      name: Job.getTipName(job),
      amount: params.tipAmount,
      jobId: job.id,
    }),
    onSuccess: () => {},
    onError: (errors) => {
      console.log({errors});
    },
  });

  const {form, submitting, handleSubmit} = useSavePaymentWithStripe({
    createCustomerPaymentProfileForm,
    stripePaymentForm,
    customerForm,
    billId,
    jobId: job.id,
    onSuccess: createBillTipMutation.handleSubmit,
    onError: paymentErrorModal.handleOpen,
  });

  const isMainFlow = Navigation.isMainFlowPayment({params});

  return (
    <Container>
      <TitleContainer>
        <PageV2.Title>Please enter your card information.</PageV2.Title>
        <PageV2.Subtitle>You will NOT be charged right now.</PageV2.Subtitle>
        <Space height={20} />
        <PaymentAmountSection
          title={'Payment Amount:'}
          amount={Currency.display(params.paymentAmount)}
        />
      </TitleContainer>
      <StripeForm stripeAccountId={stripeAccountId}>
        {({stripe}) => (
          <React.Fragment>
            <ScrollView style={{flex: 1}}>
              <KeyboardView>
                <InputContainer>
                  <CreditCardInput
                    onChange={({isValid, number, expMonth, expYear, cvc, card}) => {
                      // We use lodash's merge here because we do NOT want to override
                      // when a value is undefined.
                      const values = _.merge({}, form.values.stripePaymentForm, {
                        creditCardIsValid: isValid,
                        number,
                        expMonth,
                        expYear,
                        cvc,
                        card,
                      });

                      form.setFieldValue('stripePaymentForm', values);
                    }}
                  />
                </InputContainer>
              </KeyboardView>
            </ScrollView>
            <PageFooter>
              <PageFooter.Row style={{justifyContent: 'space-between'}}>
                {isMainFlow ? <PageFooter.BackButton /> : <Space />}
                <Button
                  disabled={!form.values.stripePaymentForm.creditCardIsValid}
                  loading={submitting}
                  color={colors.blue.interactive}
                  onPress={() => handleSubmit(stripe)}
                >
                  <Text>Submit</Text>
                </Button>
              </PageFooter.Row>
            </PageFooter>
          </React.Fragment>
        )}
      </StripeForm>
      <PaymentErrorModal
        isOpen={paymentErrorModal.isOpen}
        handleClose={paymentErrorModal.handleClose}
        title={'Unable to Save Credit Card'}
        subtitle={`We were unable to save this credit card. ${transactionContactMessage(
          job.organization.name,
        )}`}
      />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectBillingSaveCardPaymentSection.fragment = gql`
  ${CustomerForm.edit.fragment}
  ${Job.getFullName.fragment}
  ${Job.getTipName.fragment}

  fragment ProjectBillingSaveCardPaymentSection on Job {
    id
    customer {
      id
      ...CustomerForm_edit
    }
    organization {
      id
      name
      account {
        id
        stripeConnectAccountCredentials
      }
    }
    project {
      id
      currentPrimaryBill {
        id
      }
    }
    ...Job_getFullName
    ...Job_getTipName
  }
`;

export default ProjectBillingSaveCardPaymentSection;
