import {Environment} from '@supermove/sdk';

export const getMoverAppUrl = ({inventoryUuid, jobUuid, authToken}) => {
  const appEnv = Environment.getAppEnv();

  if (Environment.isLocal()) {
    return `http://localhost:9005/inventories/${inventoryUuid}?jobUuid=${jobUuid}&hideHeader=true&token=${authToken}`;
  }

  switch (appEnv) {
    case 'production':
      return `https://mover.supermove.co/inventories/${inventoryUuid}?jobUuid=${jobUuid}&hideHeader=true&token=${authToken}`;
    case 'staging':
      return `https://mover.staging.supermove.co/inventories/${inventoryUuid}?jobUuid=${jobUuid}&hideHeader=true&token=${authToken}`;
    default:
      return `https://mover.development.supermove.co/inventories/${inventoryUuid}?jobUuid=${jobUuid}&hideHeader=true&token=${authToken}`;
  }
};
