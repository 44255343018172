// Libraries
import React from 'react';

// Components
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

const UpdateBillWithJobRateMutation = ({jobId, onSuccess, onError, children}) => (
  <Mutation
    variables={{
      jobId,
    }}
    mutation={UpdateBillWithJobRateMutation.mutation}
    onCompleted={({updateJobBillsWithJobRate: {job, errors}}) => {
      if (job) {
        onSuccess(job);
      } else {
        onError(errors);
      }
    }}
  >
    {(handleSubmit, {loading, error}) => children({loading, handleSubmit})}
  </Mutation>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateBillWithJobRateMutation.mutation = gql`
  mutation UpdateBillWithJobRateMutation(
    $jobId: Int!,
    $jobRate: JobRateInput!,
  ) {
    updateJobBillsWithJobRate(
      jobId: $jobId,
      jobRate: $jobRate,
    ) {
      ${gql.errors}
      job {
        id
      }
    }
  }
`;

export default UpdateBillWithJobRateMutation;
