// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import CreateCrewEmployeeModal from './CreateCrewEmployeeModal';

const Button = Styled.LoadingButton`
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const CreateCrewEmployeeButton = ({organization, onClose}) => {
  const {isOpen, count, handleOpen, handleClose} = useModal();

  return (
    <React.Fragment>
      <Button color={colors.gray.secondary} onPress={handleOpen}>
        <Text>+ New</Text>
      </Button>
      <CreateCrewEmployeeModal
        key={count}
        isOpen={isOpen}
        organization={organization}
        onClose={() => {
          handleClose();
          onClose();
        }}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateCrewEmployeeButton.fragment = gql`
  ${CreateCrewEmployeeModal.fragment}
  fragment CreateCrewEmployeeButton on Organization {
    id
    ...CreateCrewEmployeeModal
  }
`;

export default CreateCrewEmployeeButton;
