// Libraries
import React from 'react';

// Supermove
import {Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation, useQuery} from '@supermove/hooks';
import {Job, Project} from '@supermove/models';
import {Currency} from '@supermove/utils';

// App
import BillTipForm from '@shared/modules/Billing/forms/BillTipForm';
import useCreateBillTipMutation from '@shared/modules/Billing/hooks/useCreateBillTipMutation';
import useFinishJobPostMovePaymentsMutation from '@shared/modules/Job/hooks/useFinishJobPostMovePaymentsMutation';
import Navigation from 'core/Navigation';
import PageFooter from 'modules/App/components/PageFooter';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import ProjectBillingDefaultPaymentSection from 'modules/Project/Billing/Payment/New/components/ProjectBillingDefaultPaymentSection';
import ProjectBillingHeader from 'modules/Project/Billing/components/ProjectBillingHeader';

const Container = Styled.View`
  flex: 1;
`;

const InvoiceMethodNewPaymentBillingProjectJobContent = ({job}) => {
  const {params} = useNavigation();
  const billId = job.project.currentPrimaryBill.id;
  const createBillTipMutation = useCreateBillTipMutation({
    billTipForm: BillTipForm.new({
      billId,
      name: Job.getTipName(job),
      amount: params.tipAmount,
      jobId: job.id,
    }),
    onSuccess: () => {},
    onError: (errors) => {
      console.log({errors});
    },
  });
  const {submitting, handleSubmit} = useFinishJobPostMovePaymentsMutation({
    jobId: job.id,
    paymentMethod: 'INVOICE',
    onSuccess: createBillTipMutation.handleSubmit,
  });

  const isMainFlow = Navigation.isMainFlowPayment({params});

  return (
    <Container>
      <ProjectBillingHeader
        leftButtonType={isMainFlow ? null : 'BACK'}
        title={Project.getName(job.project)}
        subtitle={Job.getFullName(job)}
      />
      <Container>
        <ScrollView>
          <Space height={20} />
          <ProjectBillingDefaultPaymentSection
            handleSubmit={handleSubmit}
            submitting={submitting || createBillTipMutation.submitting}
            title={'No payment is required at this time.'}
            subtitle={'We will send you an invoice with the following amount.'}
            payment={Currency.display(params.paymentAmount)}
            button={'Confirm'}
          />
          <Space height={20} />
        </ScrollView>
      </Container>
      {isMainFlow && (
        <PageFooter>
          <PageFooter.Row>
            <PageFooter.BackButton />
          </PageFooter.Row>
        </PageFooter>
      )}
    </Container>
  );
};

const InvoiceMethodNewPaymentBillingProjectJobPage = () => {
  const {params} = useNavigation();
  const {loading, data, refetch} = useQuery(InvoiceMethodNewPaymentBillingProjectJobPage.query, {
    fetchPolicy: 'network-only',
    variables: {
      jobUuid: params.jobUuid,
    },
  });

  return (
    <Loading loading={loading} as={PageLoadingIndicator}>
      {() =>
        data ? (
          <InvoiceMethodNewPaymentBillingProjectJobContent job={data.job} refetch={refetch} />
        ) : null
      }
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InvoiceMethodNewPaymentBillingProjectJobPage.query = gql`
  ${Job.getFullName.fragment}
  ${Job.getTipName.fragment}
  ${Project.getName.fragment}

  query InvoiceMethodNewPaymentBillingProjectJobPage($jobUuid: String!) {
    ${gql.query}
    job(uuid: $jobUuid) {
      id
      project {
        id
        currentPrimaryBill {
          id
        }
        ...Project_getName
      }
      ...Job_getFullName
      ...Job_getTipName
    }
  }
`;

export default InvoiceMethodNewPaymentBillingProjectJobPage;
