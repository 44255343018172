// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Components
import {Loading, Styled} from '@supermove/components';

import Page from './Page';
import PageLoadingIndicator from './PageLoadingIndicator';

const Container = Styled.View`
  flex: 1;
`;

const ModalPage = ({fetchPolicy, getVariables, query, navigation, onFocus, children}) => (
  <Page fetchPolicy={fetchPolicy} getVariables={getVariables} query={query} onFocus={onFocus}>
    {({isFocused, responsive, loading, ...props}) => (
      <Container {...responsive}>
        {navigation({isFocused, responsive, loading, ...props})}
        <Loading alwaysUpdate loading={loading || !isFocused} as={PageLoadingIndicator}>
          {() => children({isFocused, responsive, ...props})}
        </Loading>
      </Container>
    )}
  </Page>
);

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
ModalPage.propTypes = {
  navigation: PropTypes.func,
};

ModalPage.defaultProps = {
  navigation: () => null,
};

export default ModalPage;
