// Libraries
import React from 'react';

// App
import {ExternalLink, Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

// Components

const Container = Styled.View`
`;

const Label = Styled.Text`
  ${Typography.Label2}
  color: ${colors.gray.secondary};
  letter-spacing: 0.5;
  text-transform: uppercase;
`;

const Value = Styled.H6`
  margin-top: 5px;
  color: ${({vars = {}}) => (vars.isLink ? colors.blue.accentDark : colors.gray.primary)};
`;

const Empty = Styled.H6`
  margin-top: 5px;
  font-style: italic;
  color: ${colors.gray.secondary};
`;

const Content = ({newTab, numberOfLines, value, link, empty}) => {
  if (!value) {
    return <Empty numberOfLines={numberOfLines}>{empty}</Empty>;
  }

  if (link) {
    return (
      <ExternalLink url={link}>
        <Value vars={{isLink: true}} numberOfLines={numberOfLines}>
          {value}
        </Value>
      </ExternalLink>
    );
  }

  return <Value numberOfLines={numberOfLines}>{value}</Value>;
};

const FieldValue = ({newTab, numberOfLines, label, value, link, empty, style}) => (
  <Container style={style}>
    <Label>{label}</Label>
    <Content
      newTab={newTab}
      numberOfLines={numberOfLines}
      value={value}
      link={link}
      empty={empty}
    />
  </Container>
);

export default FieldValue;
