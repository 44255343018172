// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Components
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

const BeginPaymentMutation = ({
  billId,
  customerId,
  paymentMethod,
  partialAmount,
  name,
  description,
  onSubmit,
  onSuccess,
  onError,
  children,
}) => (
  <Mutation
    variables={{
      billId,
      customerId,
      paymentMethod,
      partialAmount,
      name,
      description,
    }}
    mutation={BeginPaymentMutation.mutation}
    onCompleted={({beginPayment: {payment, errors}}) => {
      if (payment) {
        onSuccess(payment);
      } else {
        onError(errors);
      }
    }}
  >
    {(handleSubmit, {loading, error}) =>
      children({
        loading,
        handleSubmit: (...args) => {
          onSubmit();
          handleSubmit(...args);
        },
      })
    }
  </Mutation>
);

// --------------------------------------------------
// Props
// --------------------------------------------------
BeginPaymentMutation.propTypes = {
  onSubmit: PropTypes.func,
};

BeginPaymentMutation.defaultProps = {
  onSubmit: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BeginPaymentMutation.mutation = gql`
  mutation BeginPaymentMutation(
    $billId: Int!,
    $customerId: Int!,
    $paymentMethod: String!,
    $partialAmount: Int,
    $name: String!,
    $description: String!,
  ) {
    beginPayment(
      billId: $billId,
      customerId: $customerId,
      paymentMethod: $paymentMethod,
      partialAmount: $partialAmount,
      name: $name,
      description: $description,
    ) {
      ${gql.errors}
      payment {
        id
        stripePaymentIntentId
        stripePaymentClientSecret
      }
    }
  }
`;

export default BeginPaymentMutation;
