// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {Job} from '@supermove/models';

// App
import CommercialMoveTimesheetTable from 'modules/Job/Document/components/CommercialMoveTimesheetTable';

// Components
import EmployeeMoveTimesChart from './EmployeeMoveTimesChart';

const JobMoveTimesheetView = ({job}) => {
  switch (job.kind) {
    case Job.KIND.COMMERCIAL:
      return <CommercialMoveTimesheetTable job={job} />;
    default:
      return (
        <EmployeeMoveTimesChart
          hasConfirmedTimes={_.get(job, 'move.hasConfirmedTimes')}
          timeRanges={_.get(job, 'move.timeRanges', [])}
        />
      );
  }
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobMoveTimesheetView.fragment = gql`
  ${CommercialMoveTimesheetTable.fragment}

  fragment JobMoveTimesheetView on Job {
    id
    kind
    move {
      id
      hasConfirmedTimes
      timeRanges {
        name
        kind
        start
        end
      }
    }
    ...CommercialMoveTimesheetTable
  }
`;

export default JobMoveTimesheetView;
