// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Form, Notification, Styled, Switch, TimeInput} from '@supermove/components';
import {Platform} from '@supermove/sdk';
import {colors, fontWeight} from '@supermove/styles';

// Components
import {Field, Modal} from 'modules/App/components';

const Container = Styled.View`
  padding: 40px;
  width: 600px;
  background-color: ${colors.white};
`;

const Title = Styled.H4`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
  text-align: center;
`;

const Content = Styled.View`
  margin-vertical: 20px;
`;

const Row = Styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const AbsentText = Styled.H7`
  margin-right: 5px;
  color: ${colors.gray.primary};
`;

const TextInput = Styled.TextInput.H7`
  margin-top: 3px;
`;

const Actions = Styled.View`
  margin-top: 20px;
  flex-direction: row;
  justify-content: center;
`;

const Button = Styled.LoadingButton`
  height: 40px;
  width: 80px;
`;

const ButtonSpace = Styled.View`
  width: 10px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const getSelectedJobUserTimesheetForms = ({commercialJobTimesheetForm}) => {
  return commercialJobTimesheetForm.jobUserTimesheetForms.filter((jobUserTimesheetForm) => {
    return jobUserTimesheetForm.isSelected;
  });
};

const getCount = ({commercialJobTimesheetForm}) => {
  return getSelectedJobUserTimesheetForms({commercialJobTimesheetForm}).length;
};

const setTimeAndSubmit = ({
  isFirst,
  isLast,
  index,
  kind,
  time,
  commercialJobTimesheetForm,
  form,
  onUpdate,
}) => {
  const {jobUserTimesheetForms} = commercialJobTimesheetForm;
  const selectedJobUserTimesheetForms = getSelectedJobUserTimesheetForms({
    commercialJobTimesheetForm,
  });
  const newCommercialJobTimesheetForm = _.cloneDeep(commercialJobTimesheetForm);
  const commercialJobTimesheetFormErrors = _.cloneDeep(form.errors.commercialJobTimesheetForm);

  selectedJobUserTimesheetForms.forEach((selectedJobUserTimesheetForm) => {
    // Find the index for the selected form within ALL forms.
    const jobUserIndex = _.findIndex(jobUserTimesheetForms, (jobUserTimesheetForm) => {
      return jobUserTimesheetForm.jobUserId === selectedJobUserTimesheetForm.jobUserId;
    });

    // Form the path within the commercialJobTimesheetForm based on the selected index.
    const jobUserTimesheetFormPath = `jobUserTimesheetForms.${jobUserIndex}`;
    const timeRangeFormsPath = `${jobUserTimesheetFormPath}.timeRangeForms`;

    // Set the kind, adjust the index for the last TimeRange. Only update if there is a new
    // `kind` set.
    if (kind) {
      const timeRangeIndex = isLast ? index - 1 : index;
      _.set(newCommercialJobTimesheetForm, `${timeRangeFormsPath}.${timeRangeIndex}.kind`, kind);
    }

    // Set the time(s).
    if (isFirst) {
      // This is the very first column, we want to set the `start` time only.
      _.set(newCommercialJobTimesheetForm, `${timeRangeFormsPath}.${index}.start`, time);
    } else if (isLast) {
      // This is the very last column, we want to set the `end` time only.
      _.set(newCommercialJobTimesheetForm, `${timeRangeFormsPath}.${index - 1}.end`, time);
    } else {
      // This is setting the end time of `index - 1` and the start time of `index`.
      _.set(newCommercialJobTimesheetForm, `${timeRangeFormsPath}.${index - 1}.end`, time);
      _.set(newCommercialJobTimesheetForm, `${timeRangeFormsPath}.${index}.start`, time);
    }

    // Whenever an edit is made, mark that that form is no longer not valid
    // (doesn't mean it is valid but we hide the error background-color).
    _.set(commercialJobTimesheetFormErrors, jobUserTimesheetFormPath, undefined);
  });

  form.setFieldValue('commercialJobTimesheetForm', newCommercialJobTimesheetForm);
  form.setFieldError('commercialJobTimesheetForm', commercialJobTimesheetFormErrors);
  setTimeout(() => onUpdate(), 0);
};

const CommercialJobTimesheetSetTimeModal = ({
  isFirst,
  isLast,
  index,
  initialTime,
  timeRange,
  commercialJobTimesheetForm,
  form,
  onUpdate,
  trigger,
}) => {
  const timeRangeName = isLast ? timeRange.endName : timeRange.startName;

  return (
    <Modal trigger={trigger}>
      {({handleClose}) => (
        <Form
          initialValues={{
            kind: undefined,
            time: initialTime,
          }}
          onSubmit={(values) => {
            const {kind, time} = values;
            setTimeAndSubmit({
              isFirst,
              isLast,
              index,

              // If a kind was not provided, default to the TimeRange.kind.
              kind: kind || timeRange.kind,
              time,
              commercialJobTimesheetForm,
              form,
              onUpdate,
            });
          }}
        >
          {(form) => (
            <Container>
              <Title>{`Set Time: ${timeRangeName}`}</Title>
              <Notification.Info
                text={
                  <Notification.Text style={{textAlign: 'center'}}>
                    {`Updating time for ${getCount({commercialJobTimesheetForm})} crew.`}
                  </Notification.Text>
                }
                style={{
                  marginTop: 10,
                }}
              />
              <Content>
                <Row>
                  <AbsentText>Skip this time?</AbsentText>
                  <Switch
                    isOn={form.values.kind === 'SKIP'}
                    onTintColor={colors.blue.interactive}
                    thumbTintColor={Platform.select({
                      // On iOS, we want to keep the drop shadow which gets disabled if we set a color.
                      ios: undefined,
                      android: colors.white,
                    })}
                    onChange={(isOn) => {
                      form.setValues({
                        // If we turn the SKIP option off, we want to reset to the timeRange.kind.
                        kind: isOn ? 'SKIP' : timeRange.kind,
                        time: isOn ? '' : form.values.time,
                      });
                    }}
                  />
                </Row>
                <Row
                  style={{
                    marginTop: 10,
                  }}
                >
                  <Field
                    {...form}
                    component={TimeInput}
                    name={'time'}
                    label={''}
                    input={{
                      disabled: form.values.kind === 'SKIP',
                      roundMinutes: 15,
                      component: TextInput,
                      placeholder: 'Time',
                      setFieldValue: form.setFieldValue,
                      style: {
                        width: 200,
                      },
                    }}
                  />
                </Row>
              </Content>
              <Actions>
                <Button color={colors.gray.secondary} onPress={handleClose}>
                  <Text>Cancel</Text>
                </Button>
                <ButtonSpace />
                <Button loading={form.loading} onPress={form.handleSubmit}>
                  <Text>Save</Text>
                </Button>
              </Actions>
            </Container>
          )}
        </Form>
      )}
    </Modal>
  );
};

export default CommercialJobTimesheetSetTimeModal;
