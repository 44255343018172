// Supermove
import {gql} from '@supermove/graphql';
import {Datetime, withFragment} from '@supermove/utils';

// App
import ConfirmJobUsersForJobForm from '@shared/modules/Dispatch/forms/ConfirmJobUsersForJobForm';

const _new = withFragment(
  (job, {appInstallId}) => ({
    parentJobId: job.id,
    date: Datetime.today,
    confirmJobUsersForJobForm: ConfirmJobUsersForJobForm.initialize(job, {appInstallId}),
  }),
  gql`
    ${ConfirmJobUsersForJobForm.initialize.fragment}

    fragment StartEstimatedRangeJobForm_new on Job {
      id
      ...ConfirmJobUsersForJobForm_initialize
    }
  `,
);

const toForm = ({parentJobId, date, confirmJobUsersForJobForm}) => ({
  parentJobId,
  date: Datetime.toFormDate(date),
  confirmJobUsersForJobForm: ConfirmJobUsersForJobForm.toForm(confirmJobUsersForJobForm),
});

const toMutation = ({parentJobId, date, confirmJobUsersForJobForm}) => ({
  parentJobId,
  confirmJobUsersForJobForm: ConfirmJobUsersForJobForm.toMutation(confirmJobUsersForJobForm),
  date: Datetime.toMutationDate(date),
});

const StartEstimatedRangeJobForm = {
  new: _new,
  toForm,
  toMutation,
};

export default StartEstimatedRangeJobForm;
