// Libraries
import React from 'react';

// App
import {FlatList, Lifecycle, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {StripeTerminal} from '@supermove/payments';
import {Environment} from '@supermove/sdk';
import {colors, fontWeight} from '@supermove/styles';

// Components

import {ModalPage, Navigation} from 'modules/App/components';
import {StripeReaderItem} from 'modules/Settings/Stripe/Reader/components';
import {StripeTerminalWrapper} from 'modules/Settings/Stripe/components';

const Container = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
  margin-vertical: 20px;
  margin-horizontal: 40px;
`;

const Title = Styled.H2`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Subtitle = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Indicator = Styled.Loading`
  margin-top: 20px;
`;

const Readers = Styled.View`
  flex: 1;
`;

const Reader = Styled.View`
`;

const Button = Styled.LoadingButton`
  height: 40px;
  width: 200px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const NoReaders = Styled.View`
  align-items: center;
  padding-horizontal: 30px;
  padding-vertical: 80px;
`;

const NoReadersMessage = Styled.H5`
  color: ${colors.gray.primary};
  text-align: center;
`;

const getIsCurrentReader = ({currentReader, reader}) => {
  return !!currentReader && currentReader.serialNumber === reader.serialNumber;
};

const getIsSelected = ({isConnected, currentReader, reader}) => {
  return isConnected && getIsCurrentReader({currentReader, reader});
};

const getIsLoading = ({isConnecting, currentReader, reader}) => {
  return isConnecting && getIsCurrentReader({currentReader, reader});
};

const ConnectReaderStripeSettingsPage = () => (
  <StripeTerminalWrapper>
    {({
      isInitialized,
      status,

      // Discover Readers
      isDiscovering,
      isDiscovered,
      readers,

      // Connect to Reader
      isConnecting,
      isConnected,
      reader: currentReader,

      // Handlers
      discoverReaders: discoverReadersWithParams,
      connectReader,
    }) => {
      const discoverReaders = () =>
        discoverReadersWithParams({
          // In development, use the simulator options. Override this to test
          // with actual readers.
          simulator: Environment.isLocal(),

          // Search for 10 seconds.
          timeout: 10,

          // Right now, we only support the Chipper2X device.
          deviceType: StripeTerminal.DeviceTypes.Chipper2X,
        });

      return (
        <ModalPage
          query={ConnectReaderStripeSettingsPage.query}
          navigation={({loading, data, navigator, params}) => (
            <Navigation
              getSideWidth={({responsive}) => 210}
              title={'Credit Card Reader'}
              subtitle={'Select a card reader from the list below'}
              renderLeft={() => <Navigation.CloseButton onPress={() => navigator.goBack()} />}
              renderRight={() => (
                <Button loading={isDiscovering} onPress={() => discoverReaders()}>
                  <Text>Discover Readers</Text>
                </Button>
              )}
            />
          )}
        >
          {({navigator, params, data}) => (
            <Container>
              <Content>
                <Title>Connect a Card Reader</Title>
                <Subtitle>
                  Here are the nearby card readers. Press "connect" to pair with this device.
                </Subtitle>
              </Content>
              {isInitialized ? (
                <Lifecycle onMount={() => discoverReaders()}>
                  <Readers>
                    {isDiscovering ? (
                      <Indicator size={'large'} color={colors.gray.secondary} />
                    ) : (
                      <FlatList
                        data={readers}
                        keyExtractor={(reader, index) => String(index)}
                        extraData={currentReader}
                        renderItem={({item: reader, index}) => (
                          <Reader>
                            <StripeReaderItem
                              isFirst={index === 0}
                              isSelected={getIsSelected({isConnected, currentReader, reader})}
                              loading={getIsLoading({isConnecting, currentReader, reader})}
                              reader={reader}
                              onConnect={() => connectReader(reader)}
                            />
                          </Reader>
                        )}
                        ListEmptyComponent={() =>
                          isDiscovered ? (
                            <NoReaders>
                              <NoReadersMessage>
                                Could not find any card readers. Please try discovering again.
                              </NoReadersMessage>
                            </NoReaders>
                          ) : null
                        }
                      />
                    )}
                  </Readers>
                </Lifecycle>
              ) : (
                <Indicator size={'large'} color={colors.gray.secondary} />
              )}
            </Container>
          )}
        </ModalPage>
      );
    }}
  </StripeTerminalWrapper>
);

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
ConnectReaderStripeSettingsPage.propTypes = {};

ConnectReaderStripeSettingsPage.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
ConnectReaderStripeSettingsPage.query = gql`
  query ConnectReaderStripeSettingsPage {
    ${gql.query}
    viewer {
      id
    }
  }
`;

export default ConnectReaderStripeSettingsPage;
