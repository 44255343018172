// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {colors} from '@supermove/styles';
import {countDecimalPlaces} from '@supermove/utils';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';

const Container = Styled.View`
  z-index: ${({index}) => 100 - index};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Button = Styled.ButtonV2`
  height: 36px;
  width: 36px;
  border-radius: 4px;
  background-color: ${(props) => props.color};
  align-items: center;
  justify-content: center;
`;

const StepperInput = ({
  form,
  field,
  onDecrement,
  onIncrement,
  disabledDecrement,
  disabledIncrement,
  disabledField,
  required,
  onChangeText,
  fieldWidth,
  index,
  style,
  placeholder = '0',
}) => {
  const handleDecrement = () => {
    const quantity = _.get(form.values, field);
    if (quantity <= 1) {
      form.setFieldValue(field, '0');
    } else {
      const getPrecision = countDecimalPlaces(Number(quantity));
      const newQuantity = _.subtract(Number(quantity), 1).toFixed(getPrecision);
      form.setFieldValue(field, String(newQuantity));
    }
  };

  const handleIncrement = () => {
    const quantity = _.get(form.values, field);
    const getPrecision = countDecimalPlaces(Number(quantity));
    const newQuantity = _.add(Number(quantity), 1).toFixed(getPrecision);
    form.setFieldValue(field, String(newQuantity));
  };

  return (
    <Container index={index}>
      <Button
        onPress={() => {
          handleDecrement();
          onDecrement();
        }}
        disabled={disabledDecrement}
        color={disabledDecrement ? colors.gray.border : colors.blue.interactive}
      >
        <Icon source={Icon.Minus} size={16} color={colors.white} />
      </Button>
      <Space width={8} />
      <FieldInput
        {...form}
        name={field}
        input={{
          disabled: disabledField,
          editable: !disabledField,
          required,
          placeholder,
          keyboardType: 'numeric',
          onChangeText: (quantity) => {
            form.setFieldValue(field, String(quantity));
            onChangeText();
          },
          style: {textAlign: 'center'},
        }}
        style={{flex: 1, width: fieldWidth, height: 36, alignSelf: 'flex-start', ...style}}
      />
      <Space width={8} />
      <Button
        onPress={() => {
          handleIncrement();
          onIncrement();
        }}
        disabled={disabledIncrement}
        color={disabledIncrement ? colors.gray.border : colors.blue.interactive}
      >
        <Icon source={Icon.Plus} size={16} color={colors.white} />
      </Button>
    </Container>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
StepperInput.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  onDecrement: PropTypes.func,
  onIncrement: PropTypes.func,
  disabledDecrement: PropTypes.bool,
  disabledIncrement: PropTypes.bool,
  disabledField: PropTypes.bool,
  required: PropTypes.bool,
  onChangeText: PropTypes.func,
  fieldWidth: PropTypes.number,
  index: PropTypes.number,
  style: PropTypes.object,
};

StepperInput.defaultProps = {
  onDecrement: () => {},
  onIncrement: () => {},
  disabledDecrement: false,
  disabledIncrement: false,
  disabledField: false,
  required: false,
  onChangeText: () => {},
  fieldWidth: 100,
  index: 0,
  style: {},
};

export default StepperInput;
