// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Components
import {Form, Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

const AssignTruckToJobForm = ({jobId, truckId, onSuccess, onError, children}) => (
  <Form
    enableReinitialize
    initialValues={{
      jobId,
      truckId,
    }}
    onSubmit={(values) => {}}
  >
    {({values, submitForm, setMutationErrors, ...form}) => (
      <Mutation
        variables={{
          jobId: values.jobId,
          truckId: values.truckId,
        }}
        mutation={AssignTruckToJobForm.mutation}
        onCompleted={({assignTruckToJob: {jobTruck, errors}}) => {
          submitForm();
          setMutationErrors(errors);

          if (jobTruck) {
            onSuccess(jobTruck);
          } else {
            onError(errors);
          }
        }}
      >
        {(handleSubmit, {loading, error}) =>
          children({
            ...form,
            loading,
            values,
            handleSubmit,
          })
        }
      </Mutation>
    )}
  </Form>
);

// --------------------------------------------------
// Props
// --------------------------------------------------
AssignTruckToJobForm.propTypes = {
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};

AssignTruckToJobForm.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AssignTruckToJobForm.mutation = gql`
  mutation AssignTruckToJobForm(
    $jobId: Int!,
    $truckId: Int!,
  ) {
    assignTruckToJob(
      jobId: $jobId,
      truckId: $truckId,
    ) {
      ${gql.errors}
      jobTruck {
        id
      }
    }
  }
`;

export default AssignTruckToJobForm;
