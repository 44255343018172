// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ConfirmJobUsersForJobForm from '@shared/modules/Dispatch/forms/ConfirmJobUsersForJobForm';

const useConfirmJobUsersForJobMutation = ({confirmJobUsersForJobForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      confirmJobUsersForJobForm: ConfirmJobUsersForJobForm.toForm(confirmJobUsersForJobForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useConfirmJobUsersForJobMutation.mutation,
    variables: {
      confirmJobUsersForJobForm: ConfirmJobUsersForJobForm.toMutation(
        form.values.confirmJobUsersForJobForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useConfirmJobUsersForJobMutation.mutation = gql`
  mutation useConfirmJobUsersForJobMutation(
    $confirmJobUsersForJobForm: ConfirmJobUsersForJobForm!
  ) {
    response: confirmJobUsersForJob(confirmJobUsersForJobForm: $confirmJobUsersForJobForm) {
      ${gql.errors}
      job {
        id
      }
    }
  }
`;

export default useConfirmJobUsersForJobMutation;
