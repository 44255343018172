// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Query} from '@supermove/components';
import {useIsFocused, useNavigation} from '@supermove/hooks';

const PageContent = ({loading, data, params, navigator, onFocus, refetch, children}) => {
  const isFocused = useIsFocused({
    onFocus: () => onFocus({refetch}),
  });

  return children({isFocused, params, navigator, loading, data, refetch});
};

const Page = ({fetchPolicy, getVariables, query, onFocus, children}) => {
  const {navigator, params} = useNavigation();

  return (
    <Query
      notifyOnNetworkStatusChange
      fetchPolicy={fetchPolicy}
      variables={getVariables({navigator, params})}
      query={query}
    >
      {({loading, data, error, refetch}) => (
        <PageContent
          loading={loading}
          data={data}
          params={params}
          navigator={navigator}
          onFocus={onFocus}
          refetch={refetch}
          children={children}
        />
      )}
    </Query>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
Page.propTypes = {
  getVariables: PropTypes.func,
  onFocus: PropTypes.func,
};

Page.defaultProps = {
  getVariables: () => {},
  onFocus: () => {},
};

export default Page;
