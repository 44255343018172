// Libraries
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// Components
import StartTimeModal from './StartTimeModal';

const Touchable = Styled.Touchable`
`;

const Container = Styled.View`
`;

const Content = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 10px;
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const Name = Styled.H5`
  flex: 1;
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Value = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Placeholder = Styled.H5`
  font-style: italic;
  color: ${colors.gray.tertiary};
`;

const Button = Styled.Button`
  margin-left: 30px;
  height: 40px;
  width: 80px;
`;

const EditText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.blue.interactive};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const AddText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const getStartTimeValue = ({timeRange}) => {
  return Datetime.convertToDisplayTime(timeRange.start, Datetime.FORM_TIME);
};

const StartTimeItem = ({timeRange, form}) => (
  <StartTimeModal
    index={0}
    form={form}
    timeRange={timeRange}
    trigger={({handleOpen}) => (
      <Touchable activeOpacity={1} onPress={handleOpen}>
        <Container>
          <Line />
          <Content>
            <Name>{timeRange.name}</Name>
            {timeRange.start ? (
              <Value>{getStartTimeValue({timeRange})}</Value>
            ) : (
              <Placeholder>Enter a time</Placeholder>
            )}
            {timeRange.start ? (
              <Button color={colors.blue.interactive} onPress={handleOpen}>
                <EditText>Edit</EditText>
              </Button>
            ) : (
              <Button color={colors.blue.interactive} onPress={handleOpen}>
                <AddText>Add</AddText>
              </Button>
            )}
          </Content>
          <Line />
        </Container>
      </Touchable>
    )}
  />
);

export default StartTimeItem;
