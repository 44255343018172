import React from 'react';

// Supermove
import {PageContext} from '@supermove/analytics';
import {useActiveRoute, useTrackPageview} from '@supermove/hooks';
import {CodePush} from '@supermove/sdk';

const RouterWrapper = ({navigation, children}) => {
  const {route} = useActiveRoute({navigation});
  const {routeName} = route;
  useTrackPageview({routeName});
  return <PageContext.Provider value={{pageName: routeName}}>{children}</PageContext.Provider>;
};

const options = {
  // We check manually on the Start page when the app is started.
  checkFrequency: CodePush.CheckFrequency.MANUAL,
};

export default CodePush(options)(RouterWrapper);
