// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// App
import {Responsive, Space, Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

// Components

const Container = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  background-color: ${colors.white};
  border-bottom-width: 1px;
  border-bottom-color: ${colors.gray.border};
  z-index: 100;
`;

const Center = Styled.View`
  flex: 1;
  align-items: center;
  margin-horizontal: 10px;
`;

const Title = Styled.Text`
  ${({vars}) => (vars.mobile ? Typography.Body4 : Typography.Body3)}
  color: ${colors.gray.secondary};
`;

const Subtitle = Styled.Text`
  ${({vars}) => (vars.mobile ? Typography.Body3 : Typography.Body2)}
  color: ${colors.gray.primary};
`;

const Side = Styled.View`
  width: ${(props) => props.width}px;
`;

const Navigation = ({getSideWidth, title, subtitle, renderLeft, renderRight}) => (
  <Responsive>
    {(responsive) => (
      <Container>
        <Side width={getSideWidth({responsive})}>{renderLeft()}</Side>
        <Center>
          <Title vars={responsive} numberOfLines={1}>
            {title}
          </Title>
          <Space height={2} />
          <Subtitle vars={responsive} numberOfLines={1}>
            {subtitle}
          </Subtitle>
        </Center>
        <Side width={getSideWidth({responsive})}>{renderRight()}</Side>
      </Container>
    )}
  </Responsive>
);

Navigation.propTypes = {
  renderLeft: PropTypes.func,
  renderRight: PropTypes.func,
  getSideWidth: PropTypes.func,
};

Navigation.defaultProps = {
  renderLeft: () => null,
  renderRight: () => null,
  getSideWidth: () => 0,
};

const Touchable = Styled.Touchable`
`;

const CloseIcon = Styled.Image`
  width: ${({responsive}) => (responsive.mobile ? 20 : 30)}px;
  height: ${({responsive}) => (responsive.mobile ? 20 : 30)}px;
  margin: ${({responsive}) => (responsive.mobile ? 10 : 15)}px;
`;

Navigation.CloseButton = ({onPress}) => (
  <Responsive>
    {(responsive) => (
      <Touchable onPress={onPress}>
        <CloseIcon source={require('./assets/close.png')} responsive={responsive} />
      </Touchable>
    )}
  </Responsive>
);

Navigation.Button = Styled.Button`
  height: 40px;
  width: ${(props) => props.width}px;
  ${(props) => props.style};
`;

Navigation.ButtonText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

export default Navigation;
