// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useMountEffect, useNavigation, useQuery} from '@supermove/hooks';

// App
import {useWatchDevice} from 'modules/App/logic';

import UserAnalyticsWrapper from './UserAnalyticsWrapper';

const AuthenticatedWrapperContent = ({viewer, children}) => {
  const {navigator} = useNavigation();

  // If the user is not authenticated, we should take them to the login page.
  useMountEffect(() => {
    if (!viewer) {
      navigator.navigate('OrganizationAuthentication');
    }
  });

  if (!viewer) {
    return null;
  }

  return (
    <React.Fragment>
      <UserAnalyticsWrapper viewer={viewer} />
      {children}
    </React.Fragment>
  );
};

const AuthenticatedWrapper = ({children}) => {
  const {loading, data} = useQuery(AuthenticatedWrapper.query, {
    fetchPolicy: 'network-only',
  });

  // Set up the listener to register the device and pull the location.
  useWatchDevice({userId: _.get(data, 'viewer.id')});

  if (loading) {
    return null;
  }

  return <AuthenticatedWrapperContent viewer={data.viewer} children={children} />;
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AuthenticatedWrapper.query = gql`
  ${UserAnalyticsWrapper.fragment}

  query AuthenticatedWrapper {
    ${gql.query}
    viewer {
      id
      ...UserAnalyticsWrapper
    }
  }
`;

export default AuthenticatedWrapper;
