// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// App
import {Form, Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {TimeRange} from '@supermove/models';

// Components

const getInitialTimeRanges = ({move, initialTimeRanges}) => {
  if (move.timeRanges && move.timeRanges.length > 0) {
    return move.timeRanges.map((timeRange) => TimeRange.toForm(timeRange));
  }

  return initialTimeRanges;
};

const EmployeeMoveTimesForm = ({
  move,
  initialTimeRanges,
  refetchQueries,
  onSuccess,
  onError,
  children,
}) => (
  <Form
    initialValues={{
      moveId: move.id,
      timeRanges: getInitialTimeRanges({move, initialTimeRanges}),
    }}
    onSubmit={(values) => {}}
  >
    {({setMutationErrors, submitForm, ...form}) => (
      <Mutation
        awaitRefetchQueries
        refetchQueries={refetchQueries}
        variables={{
          moveId: form.values.moveId,
          timeRanges: form.values.timeRanges.map((timeRange) => TimeRange.toMutation(timeRange)),
        }}
        mutation={EmployeeMoveTimesForm.mutation}
        onCompleted={({trackTimeForMove: {move, errors}}) => {
          submitForm();
          setMutationErrors(errors);

          if (move) {
            onSuccess(move);
          } else {
            onError(errors);
          }
        }}
      >
        {(handleSubmit, {loading, error}) => children({...form, loading, handleSubmit})}
      </Mutation>
    )}
  </Form>
);

// --------------------------------------------------
// Props
// --------------------------------------------------
EmployeeMoveTimesForm.propTypes = {
  initialTimeRanges: PropTypes.array,
  refetchQueries: PropTypes.array,
};

EmployeeMoveTimesForm.defaultProps = {
  initialTimeRanges: [],
  refetchQueries: [],
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EmployeeMoveTimesForm.fragment = gql`
  fragment EmployeeMoveTimesForm on Move {
    id
    timeRanges {
      name
      kind
      start
      end
    }
  }
`;

EmployeeMoveTimesForm.mutation = gql`
  mutation EmployeeMoveTimesForm(
    $moveId: Int!,
    $timeRanges: [TimeRangeInput]!,
  ) {
    trackTimeForMove(
      moveId: $moveId,
      timeRanges: $timeRanges,
    ) {
      ${gql.errors}
      move {
        id
      }
    }
  }
`;

export default EmployeeMoveTimesForm;
