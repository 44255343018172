// Libraries
import React from 'react';

// App
import {Loading, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation} from '@supermove/hooks';
import {JobRate} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';
import {Currency, Percent} from '@supermove/utils';

// Components
import {PageLoadingIndicator} from 'modules/App/components';
import {UpdateBillWithJobRateMutation} from 'modules/Job/Crew/Rates/components';
import {CrewJobPage, CrewJobProgress} from 'modules/Job/Crew/components';

const Container = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
  align-items: center;
  margin-horizontal: 40px;
`;

const Title = Styled.H2`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Subtitle = Styled.H5`
  width: 600px;
  ${fontWeight(500)}
  color: ${colors.gray.primary};
  text-align: center;
`;

const Options = Styled.View`
  margin-vertical: 20px;
`;

const Option = Styled.View`
  margin-vertical: 5px;
`;

const JobRateButton = Styled.LoadingButton`
  height: 80px;
  width: 350px;
`;

const JobRateContent = Styled.View`
  align-items: center;
`;

const JobRateText = Styled.H5`
  color: ${colors.white};
  ${fontWeight(700)}
`;

const JobRateSubtext = Styled.H7`
  color: ${colors.white};
  ${fontWeight(700)}
`;

const JobFooter = Styled.View`
  flex-direction: row;
  justify-content: flex-start;
  padding: 10px;
  background-color: ${colors.gray.background};
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
`;

const Button = Styled.Button`
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const getTotalText = ({jobRate}) => {
  return Currency.display(jobRate.amount);
};

const getJobRateSubtext = ({jobRate}) => {
  if (jobRate.amount > 0) {
    const totalText = getTotalText({jobRate});
    return `+${totalText} / hour`;
  }
  if (jobRate.amount < 0) {
    const totalText = getTotalText({jobRate});
    return `-${totalText} / hour`;
  }
  if (jobRate.percentage > 1) {
    return `+${Percent.toString(jobRate.percentage - 1)}`;
  }
  if (jobRate.percentage < 1) {
    return `-${Percent.toString(1 - jobRate.percentage)}`;
  }
  return 'No adjustment';
};

const RatesCrewJobPage = () => {
  const {navigator} = useNavigation();

  const handleOnSuccess = ({uuid}) => {
    navigator.navigate('BillingProjectCrewJobPage', {uuid});
  };

  return (
    <CrewJobPage
      fetchPolicy={'network-only'}
      getVariables={({params}) => ({uuid: params.uuid})}
      query={RatesCrewJobPage.query}
    >
      {({navigator, params, loading, data}) => (
        <Loading loading={loading} as={PageLoadingIndicator}>
          {() => (
            <Container>
              <CrewJobProgress selected={'rates'} items={CrewJobProgress.items.finish} />
              <ScrollView style={{flex: 1}}>
                <Content>
                  <Title>Select rate to charge the customer</Title>
                  <Subtitle>
                    {'Based on how the customer will pay, select the appropriate ' +
                      'rate. This will automatically adjust the bill on the next screen.'}
                  </Subtitle>
                  <UpdateBillWithJobRateMutation
                    jobId={data.job.id}
                    onSuccess={() => handleOnSuccess({uuid: params.uuid})}
                    onError={(errors) => console.log({errors})}
                  >
                    {({loading, handleSubmit}) => (
                      <Options>
                        {data.job.organization.settings.jobRates.map((jobRate, index) => (
                          <Option key={index}>
                            <JobRateButton
                              disabled={loading}
                              onPress={() => {
                                handleSubmit({variables: {jobRate: JobRate.toMutation(jobRate)}});
                              }}
                            >
                              <JobRateContent>
                                <JobRateText>{jobRate.name}</JobRateText>
                                <JobRateSubtext>{getJobRateSubtext({jobRate})}</JobRateSubtext>
                              </JobRateContent>
                            </JobRateButton>
                          </Option>
                        ))}
                      </Options>
                    )}
                  </UpdateBillWithJobRateMutation>
                </Content>
              </ScrollView>
              <JobFooter>
                <Button color={colors.gray.secondary} onPress={() => navigator.goBack()}>
                  <Text>Back</Text>
                </Button>
              </JobFooter>
            </Container>
          )}
        </Loading>
      )}
    </CrewJobPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
RatesCrewJobPage.query = gql`
  ${CrewJobPage.fragment}

  query RatesCrewJobPage($uuid: String!) {
    ${gql.query}
    job(uuid: $uuid) {
      id
      organization {
        id
        settings {
          id
          jobRates {
            name
            percentage
            amount
          }
        }
      }
      move {
        id
        bill {
          id
        }
      }
      ...CrewJobPage
    }
  }
`;

export default RatesCrewJobPage;
