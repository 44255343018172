// Libraries
import React from 'react';

// App
import {ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight, Typography} from '@supermove/styles';

// Components
import {Modal, Navigation} from 'modules/App/components';
import PrepareDocumentSignatureForm from 'modules/Job/Crew/Document/Show/components/PrepareDocumentSignatureForm';
import CrewPrepareDocumentSignatureFields from 'modules/Job/Crew/DocumentSignature/components/CrewPrepareDocumentSignatureFields';

const Container = Styled.View`
  flex: 1;
  align-self: stretch;
  margin: 40px;
  background-color: ${colors.white};
`;

const Header = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Title = Styled.H4`
  flex: 1;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
  text-align: center;
`;

const Space = Styled.View`
  width: 60px;
`;

const Content = Styled.View`
  margin: 20px;
`;

const Actions = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px;
  background-color: ${colors.gray.background};
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
`;

const Button = Styled.LoadingButton`
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.Text`
  ${Typography.Label2}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const CrewPrepareDocumentSignatureModal = ({documentSignature, document, refetch, trigger}) => (
  <Modal trigger={trigger}>
    {({handleClose}) => (
      <PrepareDocumentSignatureForm
        documentSignatureId={documentSignature.id}
        additionalInfo={documentSignature.additionalInfo}
        onSuccess={() => {
          handleClose();
          refetch();
        }}
        onError={(errors) => console.log({errors})}
      >
        {(form) => (
          <Container>
            <ScrollView style={{flex: 1}}>
              <Header>
                <Navigation.CloseButton onPress={handleClose} />
                <Title>{documentSignature.documentSection.title}</Title>
                <Space />
              </Header>
              <Content>
                <CrewPrepareDocumentSignatureFields
                  documentSignature={documentSignature}
                  document={document}
                  form={form}
                />
              </Content>
            </ScrollView>
            <Actions>
              <Button loading={form.loading} onPress={form.handleSubmit}>
                <Text>Save</Text>
              </Button>
            </Actions>
          </Container>
        )}
      </PrepareDocumentSignatureForm>
    )}
  </Modal>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
CrewPrepareDocumentSignatureModal.fragment = gql`
  ${CrewPrepareDocumentSignatureFields.fragment}

  fragment CrewPrepareDocumentSignatureModal_Document on Document {
    id
    ...CrewPrepareDocumentSignatureFields_Document
  }

  fragment CrewPrepareDocumentSignatureModal on DocumentSignature {
    id
    additionalInfo
    documentSection {
      title
    }
    ...CrewPrepareDocumentSignatureFields
  }
`;

export default CrewPrepareDocumentSignatureModal;
