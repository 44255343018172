// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import WarningModal from '@shared/design/components/Modal/SmallModal/WarningModal';
import AddTimesheetBillableBlockModal from '@shared/modules/Timesheet/components/AddTimesheetBillableBlockModal';
import TimesheetBillableEntry from '@shared/modules/Timesheet/components/TimesheetBillableEntry';

const Container = Styled.View`
  flex: 1;
`;

const PageHeaderRow = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const PageHeaderText = Styled.Text`
  ${Typography.Mobile.PageHeading}
`;

const Footer = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding-horizontal: 24px;
  padding-vertical: 16px;
  background-color: ${colors.gray.background};
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
`;

const useTimeRequirements = ({job, isCompletionRequired}) => {
  const timeRequirements = {isMissingTimeBlock: false, isMissingEndTime: false};

  if (isCompletionRequired) {
    const {timesheetBillableEntries} = job;
    const timesheetBlocks = _.flatten(
      timesheetBillableEntries.map((entry) => entry.timesheetBlocks),
    );

    if (_.isEmpty(timesheetBlocks)) {
      return {...timeRequirements, isMissingTimeBlock: true};
    }

    for (const timesheetBlock of timesheetBlocks) {
      if (!timesheetBlock.rangeTo) {
        return {...timeRequirements, isMissingEndTime: true};
      }
    }
  }

  return timeRequirements;
};

const PageButton = (props) => {
  return <Button {...props} width={140} isResponsive />;
};

const PageHeader = ({job, refetch, onUpdate}) => {
  const addTimesheetBlockModal = useModal({name: 'Add Timesheet Block Modal'});

  return (
    <PageHeaderRow>
      <PageHeaderText>Job Timesheet</PageHeaderText>
      <Space style={{flex: 1}} />
      <PageButton
        iconLeft={Icon.Plus}
        text={'Add Time'}
        onPress={addTimesheetBlockModal.handleOpen}
      />
      <AddTimesheetBillableBlockModal
        key={addTimesheetBlockModal.key}
        isOpen={addTimesheetBlockModal.isOpen}
        handleClose={addTimesheetBlockModal.handleClose}
        job={job}
        refetch={refetch}
        onUpdate={onUpdate}
      />
    </PageHeaderRow>
  );
};

const BillableTimesheetPageContent = ({
  job,
  handleSubmit,
  isSubmitting,
  navigator,
  onUpdate,
  refetch,
  isCompletionRequired,
}) => {
  const {isMissingTimeBlock, isMissingEndTime} = useTimeRequirements({job, isCompletionRequired});
  const warningModal = useModal();

  return (
    <Container>
      <ScrollView style={{paddingHorizontal: 24}}>
        <Space height={24} />
        <PageHeader job={job} refetch={refetch} onUpdate={onUpdate} />
        {job.timesheetBillableEntries.map((timesheetBillableEntry) => (
          <React.Fragment key={timesheetBillableEntry.id}>
            <Space height={24} />
            <TimesheetBillableEntry
              job={job}
              timesheetBillableEntry={timesheetBillableEntry}
              refetch={refetch}
              onUpdate={onUpdate}
              isLargeHeader
            />
          </React.Fragment>
        ))}
        <Space height={24} />
      </ScrollView>
      {handleSubmit && (
        <Footer>
          <PageButton
            text={'Back'}
            onPress={() => navigator.goBack()}
            color={colors.gray.secondary}
          />
          <Space style={{flex: 1}} />
          <SecondaryButton
            text={'Assign Movers'}
            isResponsive
            onPress={() => navigator.navigate('MoversCrewJob', {uuid: job.uuid})}
          />
          <Space width={16} />
          <PageButton
            text={'Next'}
            onPress={
              isMissingTimeBlock || isMissingEndTime ? warningModal.handleOpen : handleSubmit
            }
            isSubmitting={isSubmitting}
          />
        </Footer>
      )}
      <WarningModal
        isOpen={warningModal.isOpen}
        title={'Unable to proceed'}
        message={
          isMissingTimeBlock
            ? 'Please enter at least one time block.'
            : 'Please enter an end time or remove the last time block.'
        }
        handlePressOutside={warningModal.handleClose}
        handlePrimaryAction={warningModal.handleClose}
        primaryActionText={'Close'}
      />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillableTimesheetPageContent.fragment = gql`
  ${TimesheetBillableEntry.fragment}
  ${AddTimesheetBillableBlockModal.fragment}

  fragment BillableTimesheetPageContent on Job {
    id
    uuid
    ...TimesheetBillableEntry
    ...AddTimesheetBillableBlockModal
  }
`;

export default BillableTimesheetPageContent;
