// Supermove
import {JobConfirmCrewForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

const useJobConfirmCrewMutation = ({jobConfirmCrewForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      jobConfirmCrewForm: JobConfirmCrewForm.toForm(jobConfirmCrewForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useJobConfirmCrewMutation.mutation,
    variables: {
      jobConfirmCrewForm: JobConfirmCrewForm.toMutation(form.values.jobConfirmCrewForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useJobConfirmCrewMutation.mutation = gql`
  mutation JobConfirmCrewMutation($jobConfirmCrewForm: JobConfirmCrewForm!) {
    response: jobConfirmCrew(jobConfirmCrewForm: $jobConfirmCrewForm) {
      ${gql.errors}
      job {
        id
      }
    }
  }
`;

export default useJobConfirmCrewMutation;
