// Libraries
import React from 'react';

// Supermove
import {KeyboardView, Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation, useQuery} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import DocumentV2ByCategory from '@shared/modules/Document/components/DocumentV2ByCategory';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import CustomerJobPageV2 from 'modules/Job/Customer/components/CustomerJobPageV2';

const Container = Styled.View`
  flex: 1;
`;

const Header = Styled.View`
  align-items: center;
`;

const HeaderText = Styled.Text`
  ${Typography.Body2}
`;

const ContentContainer = Styled.View`
  padding-horizontal: 40px;
`;

const Button = Styled.LoadingButton`
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.Text`
  ${Typography.Label2}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const Footer = () => {
  const {navigator} = useNavigation();
  return (
    <CustomerJobPageV2.Footer style={{flexDirection: 'row', alignItems: 'center', padding: 10}}>
      <Button color={colors.gray.secondary} onPress={() => navigator.pop()}>
        <Text>Back</Text>
      </Button>
    </CustomerJobPageV2.Footer>
  );
};

const ViewDocumentV2CustomerJobPageContent = ({document}) => {
  return (
    <Container>
      <KeyboardView>
        <ScrollView alwaysBounceVertical={false} style={{flex: 1}}>
          <Space height={32} />
          <ContentContainer>
            <Header>
              <HeaderText>{document.displayName}</HeaderText>
            </Header>
            <DocumentV2ByCategory document={document} />
          </ContentContainer>
          <Space height={40} />
        </ScrollView>
      </KeyboardView>
      <Footer />
    </Container>
  );
};

const ViewDocumentV2CustomerJobPage = () => {
  const {params, navigator} = useNavigation();
  const {loading, data, refetch} = useQuery(ViewDocumentV2CustomerJobPage.query, {
    fetchPolicy: 'network-only',
    variables: {
      documentUuid: params.documentUuid,
    },
  });

  // Explicitly pass the navigator.state.key to force a re-render. Without this
  // the signature from the previous screen will still be showing.
  return (
    <CustomerJobPageV2 key={navigator.state.key} refetch={refetch}>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => {
          return <ViewDocumentV2CustomerJobPageContent job={data.job} document={data.document} />;
        }}
      </Loading>
    </CustomerJobPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ViewDocumentV2CustomerJobPage.query = gql`
  ${DocumentV2ByCategory.fragment}

  query ViewDocumentV2CustomerJobPage($documentUuid: String!) {
    ${gql.query}
    document(uuid: $documentUuid) {
      id
      displayName
      ...DocumentV2ByCategory
    }
  }
`;

export default ViewDocumentV2CustomerJobPage;
