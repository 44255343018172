// DEPRECATED

// Libraries
import React from 'react';

// Supermove
import {Loading, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight, Typography} from '@supermove/styles';

// App
import Navigation from 'core/Navigation';
import {PageLoadingIndicator} from 'modules/App/components';
import {BillSummary} from 'modules/Bill/components';
import {CustomerJobProgress, CustomerJobPage} from 'modules/Job/Customer/components';

const Container = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
  margin-horizontal: 40px;
`;

const Title = Styled.H2`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Subtitle = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Bill = Styled.View`
  margin-vertical: 20px;
`;

const Line = Styled.View`
  margin-bottom: 20px;
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const Footer = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  background-color: ${colors.gray.background};
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
`;

const Button = Styled.Button`
  align-self: stretch;
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.Text`
  ${Typography.Label2}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const getTitle = ({job, bill}) => {
  switch (bill.kind) {
    case 'JOB_MAIN':
      return `Congrats, ${job.customer.firstName}. Your move is complete.`;
    case 'JOB_ADDITIONAL':
      return `Here is a summary of your additional items.`;
    case 'CUSTOM':
    default:
      return 'View your bill below.';
  }
};

const ViewCustomerBillJobPage = () => (
  <CustomerJobPage
    getVariables={({params}) => ({uuid: params.uuid, billUuid: params.billUuid})}
    query={ViewCustomerBillJobPage.query}
  >
    {({navigator, params, loading, data}) => (
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => (
          <Container>
            <ScrollView style={{flex: 1}}>
              <CustomerJobProgress
                selected={'bill'}
                items={CustomerJobProgress.getItems(data.bill)}
              />
              <Content>
                <Title>{getTitle({job: data.job, bill: data.bill})}</Title>
                <Subtitle>Please review and confirm the details below.</Subtitle>
                <Bill>
                  <Line />
                  <BillSummary bill={data.bill} />
                </Bill>
              </Content>
            </ScrollView>
            <Footer>
              <Button color={colors.gray.secondary} onPress={() => navigator.goBack()}>
                <Text>Back</Text>
              </Button>
              <Button
                color={colors.blue.interactive}
                onPress={() => {
                  Navigation.navigateToPostMovePayments({
                    navigator,
                    job: data.job,
                  });
                }}
              >
                <Text>Confirm</Text>
              </Button>
            </Footer>
          </Container>
        )}
      </Loading>
    )}
  </CustomerJobPage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
ViewCustomerBillJobPage.query = gql`
  ${CustomerJobPage.fragment}
  ${BillSummary.fragment}
  ${Navigation.navigateToPostMovePayments.fragment}

  query ViewCustomerBillJobPage($uuid: String!, $billUuid: String!) {
    ${gql.query}
    job(uuid: $uuid) {
      id
      organization {
        id
        shouldSkipTipPrompt
      }
      customer {
        id
        firstName
      }
      ...CustomerJobPage
      ...Navigation_navigateToPostMovePayments
    }
    bill(uuid: $billUuid) {
      id
      uuid
      kind
      isComplete
      ...BillSummary
    }
  }
`;

export default ViewCustomerBillJobPage;
