// Libraries
import React from 'react';

// Supermove
import {Analytics} from '@supermove/analytics';
import {useNavigation} from '@supermove/hooks';

const LogOutAction = ({children}) => {
  const {navigator} = useNavigation();

  return (
    <Analytics.Consumer>
      {(analytics) =>
        children({
          handleLogOut: () => {
            analytics.reset();
            navigator.navigate('UsersAuthentication');
          },
        })
      }
    </Analytics.Consumer>
  );
};

export default LogOutAction;
