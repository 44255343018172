// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// App
import {Mutation} from '@supermove/components';
import {CommercialJobTimesheetForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';

// Components

const UpdateCommercialJobTimesheetMutation = ({
  commercialJobTimesheetForm,
  onSuccess,
  onError,
  children,
}) => (
  <Mutation
    awaitRefetchQueries
    refetchQueries={['EditCommercialTimesheetCrewJobPage']}
    variables={{
      commercialJobTimesheetForm: CommercialJobTimesheetForm.toMutation(commercialJobTimesheetForm),
    }}
    mutation={UpdateCommercialJobTimesheetMutation.mutation}
    onCompleted={({updateCommercialJobTimesheet: {job, errors}}) => {
      if (job) {
        onSuccess(job);
      } else {
        onError(errors);
      }
    }}
  >
    {(handleSubmit, {loading, error}) => children({loading, handleSubmit})}
  </Mutation>
);

// --------------------------------------------------
// Props
// --------------------------------------------------
UpdateCommercialJobTimesheetMutation.propTypes = {
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};

UpdateCommercialJobTimesheetMutation.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateCommercialJobTimesheetMutation.mutation = gql`
  mutation UpdateCommercialJobTimesheetMutation(
    $commercialJobTimesheetForm: CommercialJobTimesheetForm!,
  ) {
    updateCommercialJobTimesheet(
      commercialJobTimesheetForm: $commercialJobTimesheetForm,
    ) {
      ${gql.errors}
      job {
        id
      }
    }
  }
`;

export default UpdateCommercialJobTimesheetMutation;
