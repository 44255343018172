// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {CurrencyInput, Icon, Modal, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useState} from '@supermove/hooks';
import {Project} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';
import {Currency, Datetime} from '@supermove/utils';

// App
import PaymentForm from '@shared/modules/Payment/forms/PaymentForm';
import usePaymentForm from '@shared/modules/Payment/hooks/usePaymentForm';
import Field from 'modules/App/components/Field';

const ScreenContainer = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const ContentContainer = Styled.View`
  padding-horizontal: 36px;
  width: 600px;
  background-color: ${colors.white};
  border-radius: 8px;
`;

const HeaderText = Styled.H4`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
  text-align: center;
`;

const HeaderCaption = Styled.H7`
  color: ${colors.gray.primary};
  text-align: center;
`;

const ViewBillToggleRowButton = Styled.ButtonV2`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.gray.border};
  padding-vertical: 6px;
  border-radius: 4px;
`;

const ViewBillText = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
  flex: 1;
`;

const BillBreakdownContainer = Styled.View`
  border-width: 1px;
  border-radius: 4px;
  border-color: ${colors.gray.border};
`;

const BillRow = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const BillJobDateColumn = Styled.View`
  width: 60px;
`;

const BillJobDateBadge = Styled.View`
  background-color: ${colors.blue.accent};
  height: 24px;
  width: 60px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
`;

const BillTitleColumn = Styled.View`
  flex: 1;
`;

const BillTotalColumn = Styled.View`
  min-width: 100px;
  align-items: flex-end;
  padding-left: 8px;
`;

const BillText = Styled.H6`
  ${fontWeight(500)}
  color: ${(props) => props.color};
`;

const BillBoldText = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const TotalRow = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
`;

const Line = Styled.View`
  border-top-width: 1px;
  border-color: ${colors.gray.border};
`;

const LabelText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.gray.secondary};
`;

const TextInput = Styled.TextInput.H7`
`;

const Actions = Styled.View`
  flex-direction: row;
  justify-content: center;
`;

const Button = Styled.Button`
  height: 40px;
  width: 100px;
`;

const ButtonSpace = Styled.View`
  width: 10px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const ProjectBillsBreakdownToggle = ({showBills, setShowBills}) => {
  return (
    <ViewBillToggleRowButton onPress={() => setShowBills(!showBills)}>
      <Space width={8} />
      <ViewBillText>View bill breakdown</ViewBillText>
      <Icon
        source={showBills ? Icon.Times : Icon.ChevronDown}
        color={colors.gray.primary}
        size={16}
      />
      <Space width={10} />
    </ViewBillToggleRowButton>
  );
};

const BillItem = ({bill}) => {
  const {estimateTotalMin: min, estimateTotalMax: max} = bill;
  return (
    <React.Fragment>
      <Space height={8} />
      <BillRow>
        <Space width={8} />
        <BillJobDateColumn>
          <Space width={8} />
          {bill.job && (
            <BillJobDateBadge>
              <BillBoldText>
                {Datetime.convertToDisplayDate(bill.job.day.value, 'M/D')}
              </BillBoldText>
            </BillJobDateBadge>
          )}
        </BillJobDateColumn>
        <Space width={8} />
        <BillTitleColumn>
          <BillText color={colors.gray.primary} numberOfLines={1}>
            {bill.title}
          </BillText>
        </BillTitleColumn>
        <BillTotalColumn>
          <BillBoldText>
            {bill.isEstimateAvailable ? Currency.formatRange({min, max}) : 'TBD'}
          </BillBoldText>
        </BillTotalColumn>
        <Space width={8} />
      </BillRow>
    </React.Fragment>
  );
};

const ProjectTotal = ({project}) => {
  const {
    primaryBill,
    isTotalAvailable,
    minTotal: min,
    maxTotal: max,
  } = project.currentAggregateBill;
  return (
    <React.Fragment>
      {primaryBill.tip > 0 && (
        <React.Fragment>
          <TotalRow>
            <BillText color={colors.gray.tertiary}>Tip</BillText>
            <BillTotalColumn>
              <BillText color={colors.gray.tertiary}>
                {Currency.format({value: primaryBill.tip})}
              </BillText>
            </BillTotalColumn>
            <Space width={8} />
          </TotalRow>
          <Space height={4} />
        </React.Fragment>
      )}
      <TotalRow>
        <BillBoldText>Grand Total</BillBoldText>
        <BillTotalColumn>
          <BillBoldText>{isTotalAvailable ? Currency.formatRange({min, max}) : 'TBD'}</BillBoldText>
        </BillTotalColumn>
        <Space width={8} />
      </TotalRow>
    </React.Fragment>
  );
};

const ProjectPayments = ({project}) => {
  const {primaryBill, balance, isTotalAvailable} = project.currentAggregateBill;
  return (
    <React.Fragment>
      <TotalRow>
        <BillText color={colors.gray.tertiary}>Total Payments</BillText>
        <BillTotalColumn>
          <BillText color={colors.gray.tertiary}>
            {Currency.format({value: primaryBill.paymentsSum})}
          </BillText>
        </BillTotalColumn>
        <Space width={8} />
      </TotalRow>
      <Space height={4} />
      <TotalRow>
        <BillBoldText>Remaining Balance</BillBoldText>
        <BillTotalColumn>
          <BillBoldText>
            {isTotalAvailable ? Currency.format({value: balance}) : 'TBD'}
          </BillBoldText>
        </BillTotalColumn>
        <Space width={8} />
      </TotalRow>
    </React.Fragment>
  );
};

const ProjectBillsBreakdown = ({project}) => {
  return (
    <React.Fragment>
      <Space height={4} />
      <BillBreakdownContainer>
        <ScrollView style={{maxHeight: 134}}>
          {project.currentAggregateBill.bills.map((bill, index) => {
            return <BillItem key={index} bill={bill} />;
          })}
          <Space height={8} />
        </ScrollView>
        <Line />
        <Space height={4} />
        <ProjectTotal project={project} />
        <Space height={4} />
        <Line />
        <Space height={4} />
        <ProjectPayments project={project} />
        <Space height={4} />
      </BillBreakdownContainer>
    </React.Fragment>
  );
};

const PaymentAmountInput = ({form, field}) => {
  return (
    <Field
      {...form}
      name={field}
      component={CurrencyInput}
      input={{
        component: TextInput,
        autoFocus: true,
        returnKeyType: 'done',
        placeholder: 'Enter payment amount',
        setFieldValue: form.setFieldValue,
        setFieldTouched: form.setFieldTouched,
      }}
    />
  );
};

const PaymentNameInput = ({form, field}) => {
  return (
    <Field
      {...form}
      name={field}
      input={{
        returnKeyType: 'done',
        placeholder: 'Enter payment name',
      }}
    />
  );
};

const PaymentAmountModal = ({
  form,
  paymentAmountField,
  paymentNameField,
  project,
  isOpen,
  handleClose,
}) => {
  const {isEnabledAdditionalPaymentsCrewApp} = project.organization.features;
  const paymentForm = PaymentForm.new({amount: '', name: _.get(form.values, paymentNameField)});
  const {form: partialPaymentForm} = usePaymentForm({paymentForm});
  const handleSave = () => {
    const paymentAmount = Currency.toForm(
      Currency.convertToCents(_.get(partialPaymentForm.values, 'paymentForm.amount')),
    );
    form.setFieldValue(paymentAmountField, paymentAmount);
    if (isEnabledAdditionalPaymentsCrewApp) {
      form.setFieldValue(paymentNameField, _.get(partialPaymentForm.values, 'paymentForm.name'));
    }
    handleClose();
  };
  const [showBills, setShowBills] = useState(false);

  return (
    <Modal.Content isOpen={isOpen} onClose={handleClose}>
      <ScreenContainer pointerEvents={'box-none'}>
        <ContentContainer>
          <Space height={36} />
          <HeaderText>Edit Payment Amount</HeaderText>
          <Space height={4} />
          <HeaderCaption>{Project.getName(project)}</HeaderCaption>
          <Space height={16} />
          <ProjectBillsBreakdownToggle showBills={showBills} setShowBills={setShowBills} />
          {showBills && <ProjectBillsBreakdown project={project} />}
          <Space height={12} />
          <LabelText>Payment Amount</LabelText>
          <Space height={4} />
          <PaymentAmountInput form={partialPaymentForm} field={'paymentForm.amount'} />
          {isEnabledAdditionalPaymentsCrewApp ? (
            <React.Fragment>
              <Space height={12} />
              <LabelText>Payment Name</LabelText>
              <Space height={4} />
              <PaymentNameInput form={partialPaymentForm} field={'paymentForm.name'} />
            </React.Fragment>
          ) : null}
          <Space height={20} />
          <Actions>
            <Button color={colors.gray.secondary} onPress={handleClose}>
              <Text>Cancel</Text>
            </Button>
            <ButtonSpace />
            <Button onPress={handleSave}>
              <Text>Save</Text>
            </Button>
          </Actions>
          <Space height={24} />
        </ContentContainer>
      </ScreenContainer>
    </Modal.Content>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
PaymentAmountModal.fragment = gql`
  ${Project.getName.fragment}

  fragment PaymentAmountModal on Project {
    id
    currentAggregateBill {
      isTotalAvailable
      minTotal
      maxTotal
      balance
      primaryBill {
        id
        tip
        paymentsSum
      }
      bills {
        id
        title
        isEstimateAvailable
        estimateTotalMin
        estimateTotalMax
        job {
          id
          day {
            id
            value
          }
        }
      }
    }
    organization {
      id
      features {
        isEnabledAdditionalPaymentsCrewApp: isEnabled(feature: "ADDITIONAL_PAYMENTS_CREW_APP")
      }
    }
    ...Project_getName
  }
`;

export default PaymentAmountModal;
