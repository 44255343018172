// Libraries
import React from 'react';

// Supermove
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation} from '@supermove/hooks';
import {Datetime} from '@supermove/utils';

// App
import Navigation from 'core/Navigation';

const FinalizePaymentMutation = ({jobId, paymentId, onError, children}) => {
  const {navigator, params} = useNavigation();

  return (
    <Mutation
      variables={{
        jobId,
        paymentId,
        timestamp: Datetime.toTimestamp(Datetime.now),
      }}
      mutation={FinalizePaymentMutation.mutation}
      onCompleted={({finalizePayment: {payment, errors}}) => {
        if (payment) {
          Navigation.navigateFromPaymentSuccess({navigator, params, payment});
        } else {
          onError(errors);
        }
      }}
    >
      {(handleSubmit, {loading, error}) => children({loading, handleSubmit})}
    </Mutation>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
FinalizePaymentMutation.mutation = gql`
  ${Navigation.navigateFromPaymentSuccess.fragment}

  mutation FinalizePaymentMutation(
    $jobId: Int,
    $paymentId: Int!,
    $timestamp: String!,
  ) {
    finalizePayment(
      jobId: $jobId,
      paymentId: $paymentId,
      timestamp: $timestamp,
    ) {
      ${gql.errors}
      payment {
        id
        ...Navigation_navigateFromPaymentSuccess
      }
    }
  }
`;

export default FinalizePaymentMutation;
