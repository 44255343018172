// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight, Typography} from '@supermove/styles';
import {Json} from '@supermove/utils';

// App
import CommercialEquipmentsList from 'modules/Job/CommercialCatalog/components/CommercialEquipmentsList';
import CommercialMaterialsList from 'modules/Job/CommercialCatalog/components/CommercialMaterialsList';
import CommercialMoveTimesheetTable from 'modules/Job/Document/components/CommercialMoveTimesheetTable';

const ListItem = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 5px;
  padding-horizontal: 5px;
`;

const Index = Styled.H6`
  margin-right: 10px;
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const ItemLabel = Styled.H6`
  margin-right: 10px;
  width: 200px;
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const Item = Styled.H6`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const Empty = Styled.H6`
  font-style: italic;
  color: ${colors.gray.secondary};
`;

const SectionItem = Styled.View`
  padding-vertical: 10px;
`;

const Label = Styled.Text`
  ${Typography.Label2}
  margin-bottom: 5px;
  color: ${colors.gray.secondary};
  letter-spacing: 0.5;
  text-transform: uppercase;
`;

const Value = Styled.H6`
  ${({vars}) => fontWeight(vars.isSelected ? 700 : 300)}
  color: ${colors.gray.primary};
`;

const CellRow = Styled.View`
  flex-direction: row;
`;

const Cell = Styled.View`
  width: 100px;
  padding-vertical: 3px;
  padding-horizontal: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
`;

const Text = Styled.Text`
  ${Typography.Body3}
`;

const getEquipment = ({additionalInfo, name}) => {
  const items = _.get(additionalInfo, name, []);
  return items.filter((item) => {
    return Number(item.quantityTaken) > 0 || Number(item.quantityReturned) > 0;
  });
};

const DocumentAdditionalInfoFieldValues = ({additionalInfo, documentAdditionalInfo, document}) => {
  const metadata = Json.toObject(_.get(documentAdditionalInfo, 'metadata', '{}'));
  const columns = _.get(metadata, 'columns', []);

  switch (documentAdditionalInfo.kind) {
    case 'COMMERCIAL_TIMESHEET':
      return <CommercialMoveTimesheetTable job={document.job} />;
    case 'COMMERCIAL_CATALOG_EQUIPMENTS':
      return <CommercialEquipmentsList job={document.job} />;
    case 'COMMERCIAL_CATALOG_MATERIALS':
      return <CommercialMaterialsList job={document.job} />;
    case 'EQUIPMENT':
      return (
        <React.Fragment>
          <CellRow>
            <Cell style={{width: 250}}>
              <Text>Equipment</Text>
            </Cell>
            <Cell style={{width: 100}}>
              <Text># Taken</Text>
            </Cell>
            <Cell style={{width: 100}}>
              <Text># Returned</Text>
            </Cell>
          </CellRow>
          {getEquipment({additionalInfo, name: documentAdditionalInfo.name}).map((item, index) => (
            <CellRow key={index}>
              <Cell style={{width: 250}}>
                <Text>{item.name}</Text>
              </Cell>
              <Cell style={{width: 100}}>
                <Text>{item.quantityTaken}</Text>
              </Cell>
              <Cell style={{width: 100}}>
                <Text>{item.quantityReturned}</Text>
              </Cell>
            </CellRow>
          ))}
          {getEquipment({additionalInfo, name: documentAdditionalInfo.name}).length === 0 && (
            <Item>No Equipment</Item>
          )}
        </React.Fragment>
      );
    case 'GRID':
      return (
        <React.Fragment>
          <CellRow>
            {columns.map((column, index) => {
              const isHidden = _.get(column, 'isHidden', false);

              if (isHidden) {
                return null;
              }

              return (
                <Cell key={index} style={{width: 225}}>
                  <Text>{column.name}</Text>
                </Cell>
              );
            })}
          </CellRow>
          {_.get(additionalInfo, documentAdditionalInfo.name, []).map((row, rowIndex) => {
            return (
              <CellRow key={rowIndex}>
                {row.map((column, columnIndex) => {
                  const isHidden = _.get(columns, `${columnIndex}.isHidden`, false);

                  if (isHidden) {
                    return null;
                  }

                  return (
                    <Cell key={columnIndex} style={{width: 225}}>
                      <Text>{column}</Text>
                    </Cell>
                  );
                })}
              </CellRow>
            );
          })}
          {_.get(additionalInfo, documentAdditionalInfo.name, []).length === 0 && (
            <Item>No Items</Item>
          )}
        </React.Fragment>
      );
    case 'LIST':
      return (
        <React.Fragment>
          {_.get(additionalInfo, documentAdditionalInfo.name, []).map((item, index) => (
            <ListItem key={index}>
              <Index>{`${index + 1}.`}</Index>
              <Item>{item}</Item>
            </ListItem>
          ))}
          {_.get(additionalInfo, documentAdditionalInfo.name, []).length === 0 && (
            <Item>No Items</Item>
          )}
        </React.Fragment>
      );
    case 'LIST_V2':
      return (
        <React.Fragment>
          {_.zip(
            _.get(additionalInfo, documentAdditionalInfo.name, []),
            _.get(metadata, 'fields', []),
          ).map((combinedItem, index) => {
            const [item, field] = combinedItem;
            if (_.get(field, 'isHidden') === true) {
              return null;
            }
            return (
              <ListItem key={index}>
                <ItemLabel>{_.get(field, 'label')}</ItemLabel>
                <Item>{item}</Item>
              </ListItem>
            );
          })}
          {_.get(additionalInfo, documentAdditionalInfo.name, []).length === 0 && (
            <Item>No Items</Item>
          )}
        </React.Fragment>
      );
    case 'RADIO_BUTTONS':
      return (
        <React.Fragment>
          {Json.toObject(documentAdditionalInfo.values).map((value, index) => {
            const isSelected = value === _.get(additionalInfo, documentAdditionalInfo.name);

            return (
              <ListItem key={index}>
                <Value
                  vars={{
                    isSelected,
                  }}
                >
                  {value}
                </Value>
              </ListItem>
            );
          })}
        </React.Fragment>
      );
    case 'RADIO_BUTTONS_WITH_TEXT_INPUTS':
      return (
        <React.Fragment>
          {_.get(additionalInfo, documentAdditionalInfo.name, []).map((value, index) => {
            const [label, radioValue, textValue] = value;

            return (
              <React.Fragment key={index}>
                <SectionItem>
                  <Label>{label}</Label>
                  {radioValue ? <Item>{radioValue}</Item> : <Empty>None</Empty>}
                  {textValue ? <Text>{textValue}</Text> : null}
                </SectionItem>
              </React.Fragment>
            );
          })}
          {_.get(additionalInfo, documentAdditionalInfo.name, []).length === 0 && (
            <Item>No Responses</Item>
          )}
        </React.Fragment>
      );
    case 'SECTION_TEXT_AREAS':
      return (
        <React.Fragment>
          {_.get(additionalInfo, documentAdditionalInfo.name, []).map(([label, item], index) => (
            <React.Fragment key={index}>
              <SectionItem>
                <Label>{label}</Label>
                {item ? <Item>{item}</Item> : <Empty>None</Empty>}
              </SectionItem>
            </React.Fragment>
          ))}
          {_.get(additionalInfo, documentAdditionalInfo.name, []).length === 0 && (
            <Item>No Items</Item>
          )}
        </React.Fragment>
      );
    case 'SELECT_MULTIPLE':
      return (
        <React.Fragment>
          {Json.toObject(documentAdditionalInfo.values).map((value, index) => {
            const selectedValues = _.get(additionalInfo, documentAdditionalInfo.name) || [];
            const isSelected = selectedValues.includes(value);

            return (
              <ListItem key={index}>
                <Value
                  vars={{
                    isSelected,
                  }}
                >
                  {value}
                </Value>
              </ListItem>
            );
          })}
        </React.Fragment>
      );
    default:
      return null;
  }
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentAdditionalInfoFieldValues.fragment = gql`
  ${CommercialEquipmentsList.fragment}
  ${CommercialMaterialsList.fragment}
  ${CommercialMoveTimesheetTable.fragment}

  fragment DocumentAdditionalInfoFieldValues_Document on Document {
    id
    job {
      id
      ...CommercialEquipmentsList
      ...CommercialMaterialsList
      ...CommercialMoveTimesheetTable
    }
  }

  fragment DocumentAdditionalInfoFieldValues on DocumentAdditionalInfo {
    name
    kind
    values
    metadata
  }
`;

export default DocumentAdditionalInfoFieldValues;
