// TODO(mark): This is currently unused since we removed the timebars. We might add this back in
// the future or we can delete this component.

// Libraries
import React from 'react';

// Supermove
import {colors} from '@supermove/styles';

// app
import Timeline from './Timeline';

const MoveTimeline = ({selected, items}) => (
  <Timeline
    showAll
    selectedColor={colors.gray.primary}
    defaultColor={colors.gray.tertiary}
    selected={selected}
    items={items}
  />
);

// --------------------------------------------------
// Navigation
// --------------------------------------------------
MoveTimeline.items = {
  crew: [
    {
      key: 'arrival',
      name: 'Arrival',
    },
    {
      key: 'agreements',
      name: 'Agreements',
    },
    {
      key: 'pick-up',
      name: 'Pick-Up',
    },
    {
      key: 'drop-off',
      name: 'Drop-Off',
    },
    {
      key: 'payment',
      name: 'Payment',
    },
  ],
  customer: [
    {
      key: 'agreements',
      name: 'Agreements',
    },
    {
      key: 'pick-up',
      name: 'Pick-Up',
    },
    {
      key: 'drop-off',
      name: 'Drop-Off',
    },
    {
      key: 'payment',
      name: 'Payment',
    },
    {
      key: 'settled',
      name: 'Settled',
    },
  ],
};

export default MoveTimeline;
