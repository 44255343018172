/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';

// App
import JobDocumentTemplatesList from 'modules/Job/Document/List/components/JobDocumentTemplatesList';
import JobDocumentV2List from 'modules/Job/Document/List/components/JobDocumentV2List';

const Container = Styled.View`
  padding-vertical: 20px;
  padding-horizontal: 30px;
`;

const Title = Styled.H4`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Content = Styled.View`
  padding-top: 10px;
`;

const JobDocumentsBlock = ({job}) => {
  return (
    <Container>
      <Title>Documents</Title>
      <Content>
        <JobDocumentV2List job={job} />
        <JobDocumentTemplatesList job={job} success={'ShowJob'} />
      </Content>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobDocumentsBlock.fragment = gql`
  ${JobDocumentTemplatesList.fragment}
  ${JobDocumentV2List.fragment}

  fragment JobDocumentsBlock on Job {
    id
    ...JobDocumentTemplatesList
    ...JobDocumentV2List
  }
`;

export default JobDocumentsBlock;
