// Libraries
import React from 'react';

// Supermove
import {PermissionsView, Styled} from '@supermove/components';
import {useModal} from '@supermove/hooks';
import {Device, Permissions} from '@supermove/sdk';
import {colors} from '@supermove/styles';

// App
import MessageModal from '@shared/design/components/Modal/SmallModal/MessageModal';

const Container = Styled.Touchable`
  align-self: center;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 30px;
  background-color: ${colors.alpha(colors.white, 0.4)};
  border-color: ${colors.alpha(colors.white, 0.6)};
  border-width: 1px;
  border-radius: 3px;
`;

const Text = Styled.H6`
  margin-right: 5px;
  color: ${colors.gray.primary};
`;

const StatusIndicator = Styled.View`
  height: 12px;
  width: 12px;
  background-color: ${(props) => props.color};
  border-width: 1px;
  border-color: ${colors.alpha(colors.white, 0.6)};
  border-radius: 6px;
`;

const getStatusColor = (status) => {
  switch (status) {
    case Permissions.RESULTS.AUTHORIZED:
      return colors.green.status;
    case Permissions.RESULTS.DENIED:
    case Permissions.RESULTS.RESTRICTED:
      return colors.red.warning;
    case Permissions.RESULTS.UNDETERMINED:
    default:
      return colors.gray.secondary;
  }
};

const formatStatus = (status) => {
  switch (status) {
    case Permissions.RESULTS.AUTHORIZED:
      return 'On';
    case Permissions.RESULTS.DENIED:
    case Permissions.RESULTS.RESTRICTED:
      return 'Off';
    case Permissions.RESULTS.UNDETERMINED:
    default:
      return 'Unknown';
  }
};

const JobLocationStatus = () => {
  const locationSettingsModal = useModal({name: 'Location Settings Modal'});
  return (
    <PermissionsView type={Permissions.LOCATION}>
      {({status}) => (
        <React.Fragment>
          <Container onPress={locationSettingsModal.handleOpen}>
            <Text>Location</Text>
            <StatusIndicator color={getStatusColor(status)} />
          </Container>
          <MessageModal
            key={locationSettingsModal.key}
            isOpen={locationSettingsModal.isOpen}
            title={'Location Settings'}
            message={`Tracking is: ${formatStatus(status)}`}
            primaryActionText={'Open Settings'}
            handlePrimaryAction={() => Device.openSettings()}
            secondaryActionText={'Close'}
            handleSecondaryAction={locationSettingsModal.handleClose}
          />
        </React.Fragment>
      )}
    </PermissionsView>
  );
};

export default JobLocationStatus;
