// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {FlatList, Modal, Styled} from '@supermove/components';
import {JobUserForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {useForm, useQuery} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import CreateJobUserItem from 'modules/Job/User/Confirm/components/CreateJobUserItem';
import {CreateCrewEmployeeButton} from 'modules/User/components';

const Wrapper = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Container = Styled.View`
  padding-vertical: 20px;
  width: 600px;
  background-color: ${colors.white};
`;

const Indicator = Styled.Loading`
  margin-top: 20px;
`;

const Name = Styled.H4`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
  text-align: center;
`;

const Subtitle = Styled.H6`
  margin-top: 3px;
  color: ${colors.gray.primary};
  text-align: center;
`;

const Content = Styled.View`
  margin-vertical: 20px;
  height: 280px;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
`;

const Actions = Styled.View`
  flex-direction: row;
  margin-horizontal: 20px;
`;

const Group = Styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
`;

const Button = Styled.LoadingButton`
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const ButtonSpace = Styled.View`
  width: 10px;
`;

const createJobUserForm = ({form, user, jobId}) => {
  const {jobUserForms} = form.values;
  const newJobUserForms = [
    ...jobUserForms,
    JobUserForm.toForm(
      JobUserForm.new({
        jobId,
        userId: user.id,
        firstName: user.firstName,
        position: user.position,
        branchCode: user.branchCode,
        moverPositionIds: user.moverPositions.map((moverPosition) => Number(moverPosition.id)),
      }),
    ),
  ];

  form.setFieldValue('jobUserForms', newJobUserForms);
};

const removeJobUserForm = ({form, user}) => {
  const {jobUserForms} = form.values;
  const removeIndex = _.findIndex(jobUserForms, (jobUserForm) => {
    return jobUserForm.userId === user.id;
  });

  const newJobUserForms = _.filter(jobUserForms, (jobUserForm, index) => {
    return index !== removeIndex;
  });

  form.setFieldValue('jobUserForms', newJobUserForms);
};

const CreateJobUsersModalContent = ({jobId, data, onClose, onSubmit, refetch}) => {
  const form = useForm({
    initialValues: {
      jobUserForms: [],
    },
  });

  return (
    <Container>
      <Name>Add Crew</Name>
      <Subtitle>Select which crew members are on this job.</Subtitle>
      <Content>
        <FlatList
          data={data.viewer.organization.users}
          keyExtractor={(user) => user.id}
          extraData={form}
          renderItem={({item: user, index}) => {
            const jobUserForm = _.find(form.values.jobUserForms, (jobUserForm) => {
              return jobUserForm.userId === user.id;
            });
            const isSelected = !!jobUserForm;

            return (
              <CreateJobUserItem
                isSelected={isSelected}
                user={user}
                onSelect={() => {
                  if (isSelected) {
                    removeJobUserForm({form, user});
                  } else {
                    createJobUserForm({form, user, jobId});
                  }
                }}
              />
            );
          }}
        />
      </Content>
      <Actions>
        {data.viewer.organization.canCrewCreateEmployee && (
          <CreateCrewEmployeeButton
            organization={data.viewer.organization}
            onClose={() => refetch()}
          />
        )}
        <Group>
          <Button color={colors.gray.secondary} onPress={onClose}>
            <Text>Cancel</Text>
          </Button>
          <ButtonSpace />
          <Button onPress={() => onSubmit(form.values.jobUserForms)}>
            <Text>Save</Text>
          </Button>
        </Group>
      </Actions>
    </Container>
  );
};

const CreateJobUsersModal = ({jobId, isOpen, onClose, onSubmit}) => {
  const {loading, data, refetch} = useQuery(CreateJobUsersModal.query, {
    variables: {
      role: 'employee',
    },
  });

  return (
    <Modal.Content isOpen={isOpen} onClose={onClose}>
      <Wrapper pointerEvents={'box-none'}>
        {loading ? (
          <Indicator size={'large'} color={colors.gray.secondary} />
        ) : (
          <CreateJobUsersModalContent
            jobId={jobId}
            data={data}
            onClose={onClose}
            onSubmit={onSubmit}
            refetch={refetch}
          />
        )}
      </Wrapper>
    </Modal.Content>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateJobUsersModal.query = gql`
  ${CreateJobUserItem.fragment}
  ${CreateCrewEmployeeButton.fragment}

  query CreateJobUsersModal($role: String!) {
    ${gql.query}
    viewer {
      id
      organization {
        id
        canCrewCreateEmployee
        users: filteredUsers(role: $role) {
          id
          firstName
          position
          branchCode
          moverPositions {
            id
          }
          ...CreateJobUserItem
        }
        ...CreateCrewEmployeeButton
      }
    }
  }
`;

export default CreateJobUsersModal;
