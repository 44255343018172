// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useState} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';
import {Json} from '@supermove/utils';

// App
import {DocumentAdditionalInfoFieldValues} from 'modules/Job/Crew/Document/Show/components';
import PrepareDocumentSignatureForm from 'modules/Job/Crew/Document/Show/components/PrepareDocumentSignatureForm';
import {DocumentItemSection} from 'modules/Job/Document/components';
import PrepareDocumentAdditionalInfoFields from 'modules/Job/Document/components/PrepareDocumentAdditionalInfoFields';

const Container = Styled.View`
  padding-horizontal: 40px;
  background-color: ${colors.white};
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Title = Styled.H4`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const Info = Styled.View`
  flex: 1;
`;

const Description = Styled.H7`
  color: ${colors.gray.primary};
`;

const Content = Styled.View`
  margin-vertical: 20px;
`;

const Action = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.red.warning};
`;

const Toggle = Styled.Touchable`
`;

const ToggleText = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.blue.interactive};
  text-decoration-line: underline;
`;

const ToggleButton = ({isExpanded, onPress}) => {
  return (
    <Toggle onPress={onPress}>
      <ToggleText>{isExpanded ? 'Hide' : 'Show'}</ToggleText>
    </Toggle>
  );
};

const CustomerSignDocumentSignatureExpandedItem = ({
  editable,
  isFirst,
  isLast,
  documentSignature,
  document,
  refetch,
}) => {
  const [isExpanded, setIsExpanded] = useState(documentSignature.isExpanded);

  return (
    <Container>
      {!isFirst && <Line />}
      <Row style={{paddingVertical: 10}}>
        <Info>
          {isExpanded && <Space height={10} />}
          <Row>
            <Title>{documentSignature.documentSection.title}</Title>
            {documentSignature.isCustomerPrepareRequired && (
              <React.Fragment>
                <Space width={10} />
                <Action>(Action required)</Action>
              </React.Fragment>
            )}
          </Row>
          {!isExpanded && (
            <Description>{documentSignature.documentSection.description}</Description>
          )}
        </Info>
        <ToggleButton isExpanded={isExpanded} onPress={() => setIsExpanded(!isExpanded)} />
      </Row>
      {isExpanded && (
        <Content>
          {documentSignature.documentSection.documentItems.map((documentItem, index) => (
            <DocumentItemSection
              key={index}
              index={index}
              documentItem={documentItem}
              document={document}
              section={(kind) => {
                if (kind !== 'ADDITIONAL_INFO') {
                  return null;
                }

                if (editable && documentSignature.canCustomerPrepare) {
                  return (
                    <PrepareDocumentSignatureForm
                      documentSignatureId={documentSignature.id}
                      additionalInfo={documentSignature.additionalInfo}
                      onSuccess={() => refetch()}
                      onError={(errors) => console.log({errors})}
                    >
                      {(form) => (
                        <PrepareDocumentAdditionalInfoFields
                          documentAdditionalInfo={
                            documentSignature.documentSection.documentAdditionalInfo
                          }
                          document={document}
                          form={form}
                        />
                      )}
                    </PrepareDocumentSignatureForm>
                  );
                }

                return (
                  <DocumentAdditionalInfoFieldValues
                    additionalInfo={Json.toObject(documentSignature.additionalInfo)}
                    documentAdditionalInfo={
                      documentSignature.documentSection.documentAdditionalInfo
                    }
                    document={document}
                  />
                );
              }}
            />
          ))}
        </Content>
      )}
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CustomerSignDocumentSignatureExpandedItem.fragment = gql`
  ${DocumentItemSection.fragment}
  ${DocumentAdditionalInfoFieldValues.fragment}
  ${PrepareDocumentAdditionalInfoFields.fragment}

  fragment CustomerSignDocumentSignatureExpandedItem_Document on Document {
    id
    ...DocumentAdditionalInfoFieldValues_Document
    ...DocumentItemSection_Document
    ...PrepareDocumentAdditionalInfoFields_Document
  }

  fragment CustomerSignDocumentSignatureExpandedItem on DocumentSignature {
    id
    isExpanded
    isCustomerPrepareRequired
    canCustomerPrepare
    additionalInfo
    documentSection {
      title
      description
      documentAdditionalInfo {
        ...DocumentAdditionalInfoFieldValues
        ...PrepareDocumentAdditionalInfoFields
      }
      documentItems {
        ...DocumentItemSection
      }
    }
  }
`;

export default CustomerSignDocumentSignatureExpandedItem;
