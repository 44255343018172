// Libraries
import {useCurrentGeolocation} from '@supermove/hooks';

// Logic
import {useCreatePositionForDeviceMutation} from 'modules/Device/logic';

const useManualLogDeviceCurrentPosition = ({deviceId}) => {
  const {fetchLocation, location} = useCurrentGeolocation({
    timeout: 10, // Time out every 10 seconds if no response.
  });

  useCreatePositionForDeviceMutation({deviceId, location});

  return {
    fetchLocation,
  };
};

export default useManualLogDeviceCurrentPosition;
