// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

const Container = Styled.View`
`;

const Content = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-vertical: 10px;
  padding-horizontal: 10px;
`;

const Button = Styled.Button`
  height: 40px;
  padding-horizontal: 10px;
`;

const Text = Styled.Text`
  ${Typography.Label2}
  color: ${colors.white};
`;

// TODO(mark): This is hard-coded for now, should eventually turn it into a FIELDS_LIST type
// later on.
const EMPTY_EQUIPMENT = {
  name: '',
  quantityTaken: 0,
  quantityReturned: 0,
};

const DocumentAdditionalInfoEquipmentActions = ({name, form}) => (
  <Container>
    <Content>
      <Button
        onPress={() => {
          const items = _.get(form, `values.${name}`);
          const newItems = _.concat(items, _.clone(EMPTY_EQUIPMENT));
          form.setFieldValue(name, newItems);
        }}
      >
        <Text>+ Add</Text>
      </Button>
    </Content>
  </Container>
);

export default DocumentAdditionalInfoEquipmentActions;
