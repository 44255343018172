// Libraries
import React from 'react';

// App
import {Badge} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Document} from '@supermove/models';

// Components

const CrewJobDocumentStatusBadge = ({document}) => (
  <Badge color={Document.getPreparedStatusColor(document)} style={{width: 160}}>
    {Document.getPreparedStatusText(document)}
  </Badge>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
CrewJobDocumentStatusBadge.fragment = gql`
  fragment CrewJobDocumentStatusBadge on Document {
    id
    isStepPrepared(step: $step)
  }
`;

export default CrewJobDocumentStatusBadge;
