// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useModal} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {countDecimalPlaces} from '@supermove/utils';

// App
import BillItemForm from '@shared/modules/Billing/forms/BillItemForm';
import StepperInput from 'modules/App/components/StepperInput';
import EditBillItemModal from 'modules/Project/Billing/Edit/components/EditBillItemModal';

const Text = Styled.Text`
  ${Typography.Body2}
  color: ${(props) => props.color};
  min-height: 24px;
`;

const RowContainer = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const RowItemContainer = Styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
  border-top-width: ${(props) => (props.isFirstRow ? '1px' : '0px')};
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-color: ${colors.gray.border};
`;

const ItemNameContainer = Styled.View`
  flex: 1;
  padding-horizontal: 8px;
  border-right-width: 1px;
  border-color: ${colors.gray.border};
`;

const RowQuantityContainer = Styled.View`
  padding-horizontal: 12px;
  width: 200px;
`;

const Button = Styled.ButtonV2`
  height: 36px;
  width: 36px;
  border-radius: 4px;
  background-color: ${(props) => props.color};
  align-items: center;
  justify-content: center;
`;

const handleAddBillItemForm = ({form, field, billItemForm}) => {
  const billItemForms = _.get(form.values, field);
  form.setFieldValue(field, [
    ...billItemForms,
    {...billItemForm, minQuantity: '1', maxQuantity: '1', index: billItemForms.length},
  ]);
};

const handleIncrement = ({form, field, billItemForm, matchedBillItemForm}) => {
  if (!matchedBillItemForm) {
    return handleAddBillItemForm({form, field, billItemForm});
  }
  const getPrecision = countDecimalPlaces(Number(matchedBillItemForm.minQuantity));
  const quantity = _.add(Number(matchedBillItemForm.minQuantity), 1).toFixed(getPrecision);
  // minQuantity is already set in StepperInput
  form.setFieldValue(`${field}.${matchedBillItemForm.index}.maxQuantity`, String(quantity));
};

const handleDecrement = ({form, field, matchedBillItemForm, handleRemoveBillItemForm}) => {
  const getPrecision = countDecimalPlaces(Number(matchedBillItemForm.minQuantity));
  const quantity =
    Number(matchedBillItemForm.minQuantity) > 1
      ? _.subtract(Number(matchedBillItemForm.minQuantity), 1).toFixed(getPrecision)
      : Number(0);
  if (quantity === 0) {
    if (handleRemoveBillItemForm) {
      return handleRemoveBillItemForm({form, field, billItemForm: matchedBillItemForm});
    }
  }
  // minQuantity is already set in StepperInput
  form.setFieldValue(`${field}.${matchedBillItemForm.index}.maxQuantity`, String(quantity));
};

const BillItemName = ({billItemForm}) => {
  return (
    <ItemNameContainer>
      <Space height={6} />
      <Text color={colors.gray.primary} numberOfLines={2}>
        {BillItemForm.getDisplayName(billItemForm)}
      </Text>
      <Space height={2} />
      <Text color={colors.gray.tertiary}>
        {billItemForm.isEnabledTbdBillItems && billItemForm.amount === ''
          ? 'TBD'
          : billItemForm.amount}
      </Text>
      <Space height={2} />
    </ItemNameContainer>
  );
};

const BillItemQuantity = ({
  form,
  field,
  billItemForm,
  matchedBillItemForm,
  handleRemoveBillItemForm,
  isDisabled,
}) => {
  const minQuantity = _.get(matchedBillItemForm, 'minQuantity', 0);
  const stepperForm = matchedBillItemForm
    ? `${field}.${matchedBillItemForm.index}`
    : `${field}.${billItemForm.index}`;
  return (
    <RowQuantityContainer>
      <StepperInput
        form={form}
        field={`${stepperForm}.minQuantity`}
        onDecrement={() =>
          handleDecrement({form, field, matchedBillItemForm, handleRemoveBillItemForm})
        }
        onIncrement={() => handleIncrement({form, field, billItemForm, matchedBillItemForm})}
        disabledDecrement={isDisabled || minQuantity <= 0 || isNaN(minQuantity)}
        disabledIncrement={isDisabled || isNaN(minQuantity)}
        disabledField={isDisabled || !matchedBillItemForm}
        required={
          !billItemForm.isEnabledTbdBillItems && !_.get(form.values, `${stepperForm}.minQuantity`)
        }
        placeholder={billItemForm.isEnabledTbdBillItems ? '' : '0'}
        onChangeText={(minQuantity) => {
          form.setFieldValue(`${stepperForm}.maxQuantity`, minQuantity);
        }}
      />
    </RowQuantityContainer>
  );
};

const BillItemRowPreSubtotal = ({
  form,
  billItemForm,
  index,
  handleEditBillItemForm,
  handleRemoveBillItemForm,
  isEditable,
  isEnabledTbdBillItems,
}) => {
  const editBillItemModal = useModal();
  const field = 'billForm.billItemFormsPreSubtotal';
  const billItemForms = _.get(form.values, field);
  // To take into account multiple billItemForm(s) having the same name and price
  let matchedBillItemForm = _.find(
    billItemForms,
    (form) =>
      form.name === billItemForm.name &&
      form.price === billItemForm.price &&
      form.index === billItemForm.index,
  );
  // To take into account billItemForm(s) with no index but is being updated
  if (!matchedBillItemForm) {
    matchedBillItemForm = _.find(
      billItemForms,
      (form) => form.name === billItemForm.name && form.price === billItemForm.price,
    );
  }
  return (
    <RowContainer>
      <Space width={16} />
      {isEditable && (
        <React.Fragment>
          <Button onPress={editBillItemModal.handleOpen} color={colors.blue.accent}>
            <Icon source={Icon.Edit} size={18} color={colors.blue.interactive} />
          </Button>
          <Space width={16} />
        </React.Fragment>
      )}
      <RowItemContainer isFirstRow={index === 0}>
        <BillItemName billItemForm={billItemForm} />
        <BillItemQuantity
          form={form}
          field={field}
          billItemForm={billItemForm}
          matchedBillItemForm={matchedBillItemForm}
          handleRemoveBillItemForm={handleRemoveBillItemForm}
          isDisabled={billItemForm.minQuantityFormulaId || billItemForm.maxQuantityFormulaId}
        />
      </RowItemContainer>
      <Space width={16} />
      {isEditable && (
        <EditBillItemModal
          key={editBillItemModal.isOpen}
          isOpen={editBillItemModal.isOpen}
          title={'Edit Item'}
          handleClose={editBillItemModal.handleClose}
          billForm={form}
          billFormField={field}
          billItemForm={matchedBillItemForm}
          handleEditBillItemForm={handleEditBillItemForm}
          handleRemoveBillItemForm={handleRemoveBillItemForm}
          isEnabledTbdBillItems={isEnabledTbdBillItems}
        />
      )}
    </RowContainer>
  );
};

export default BillItemRowPreSubtotal;
