// Libraries
import React from 'react';

// App
import {ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';

// Components
import JobDocumentTemplatesList from 'modules/Job/Document/List/components/JobDocumentTemplatesList';
import JobDocumentV2List from 'modules/Job/Document/List/components/JobDocumentV2List';
import {JobNavigation, JobPage} from 'modules/Job/components';

const Container = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
  margin-vertical: 20px;
  margin-horizontal: 40px;
`;

const Info = Styled.View`
`;

const Title = Styled.H2`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Subtitle = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Documents = Styled.View`
  margin-top: 20px;
`;

const ListDocumentJobPage = () => (
  <JobPage
    fetchPolicy={'network-only'}
    getVariables={({params}) => ({uuid: params.uuid})}
    query={ListDocumentJobPage.query}
    onFocus={({refetch}) => refetch()}
    navigation={(props) => <JobNavigation {...props} />}
  >
    {({navigator, params, data}) => (
      <Container>
        <ScrollView style={{flex: 1}}>
          <Content>
            <Info>
              <Title>Documents</Title>
              <Subtitle>View and create new documents for the customer to sign.</Subtitle>
            </Info>
            <Documents>
              <JobDocumentV2List job={data.job} />
              <JobDocumentTemplatesList job={data.job} success={'ListDocumentJob'} />
            </Documents>
          </Content>
        </ScrollView>
      </Container>
    )}
  </JobPage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
ListDocumentJobPage.query = gql`
  ${JobPage.fragment}
  ${JobNavigation.fragment}
  ${JobDocumentV2List.fragment}
  ${JobDocumentTemplatesList.fragment}

  query ListDocumentJobPage($uuid: String!) {
    ${gql.query}
    job(uuid: $uuid) {
      id
      ...JobPage
      ...JobNavigation
      ...JobDocumentV2List
      ...JobDocumentTemplatesList
    }
  }
`;

export default ListDocumentJobPage;
