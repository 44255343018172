// Libraries
import React from 'react';

// Components
import {Lifecycle, Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';

import {Page} from 'modules/App/components';

const HomePage = () => (
  <Page fetchPolicy={'network-only'} query={HomePage.query}>
    {({responsive, navigator, loading, data}) => (
      <Loading loading={loading}>
        {() => (
          <Lifecycle
            onMount={() => {
              if (data.viewer) {
                navigator.replace('ListJobs');
              } else {
                navigator.navigate('Start');
              }
            }}
            children={null}
          />
        )}
      </Loading>
    )}
  </Page>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
HomePage.query = gql`
  query HomePage {
    ${gql.query}
    viewer {
      id
    }
  }
`;

export default HomePage;
