// Libraries
import React from 'react';

// Supermove
import {FlatList} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation} from '@supermove/hooks';

// Components
import JobCustomDocumentTemplateItem from 'modules/Job/Document/List/components/JobCustomDocumentTemplateItem';
import JobDocumentTemplateV2Item from 'modules/Job/Document/List/components/JobDocumentTemplateV2Item';

const JobDocumentTemplatesList = ({success, job}) => {
  const {navigator, params} = useNavigation();
  const documentTemplatesV2 = job.documentTemplatesV2ForStep;

  return (
    <FlatList
      data={documentTemplatesV2}
      keyExtractor={(template, index) => `${index}`}
      renderItem={({item: template, index}) => {
        return (
          <JobDocumentTemplateV2Item key={index} jobId={job.id} documentTemplateV2={template} />
        );
      }}
      ListHeaderComponent={() => (
        <React.Fragment>
          {job.organization.shouldShowRiskyMoveButton && (
            <JobCustomDocumentTemplateItem
              text={'Risky Move Form'}
              onPress={() =>
                navigator.navigate('RiskyMoveDocumentJob', {uuid: params.uuid, success: 'BACK-2'})
              }
            />
          )}
          {job.organization.features.isEnabledShowChangeOrderButton && (
            <JobCustomDocumentTemplateItem
              text={'Change Order'}
              onPress={() =>
                navigator.navigate('ChangeOrderDocumentJob', {uuid: params.uuid, success: 'BACK-2'})
              }
            />
          )}
        </React.Fragment>
      )}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobDocumentTemplatesList.fragment = gql`
  ${JobDocumentTemplateV2Item.fragment}

  fragment JobDocumentTemplatesList on Job {
    id
    documentTemplatesV2ForStep(step: "DURING_MOVE") {
      id
      ...JobDocumentTemplateV2Item
    }
    organization {
      id
      shouldShowRiskyMoveButton
      features {
        isEnabledShowChangeOrderButton: isEnabled(feature: "SHOW_CHANGE_ORDER_BUTTON")
      }
    }
  }
`;

export default JobDocumentTemplatesList;
