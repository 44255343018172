// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, FlatList, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation, useState} from '@supermove/hooks';
import {Payment, Project} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import ProjectBillsListItem from 'modules/Project/Billing/components/ProjectBillsListItem';

const Line = Styled.View`
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
  border-style: ${(props) => (props.dashed ? 'dashed' : 'solid')};
`;

const Row = Styled.View`
  flex-direction: row;
`;

const Container = Styled.View`
`;

const FlexContainer = Styled.View`
  align-items: flex-end;
  flex: ${(props) => props.flex};
`;

const FlexAlignContainer = Styled.View`
  align-items: ${(props) => props.align};
  flex: ${(props) => props.flex};
`;

const Text = Styled.H6`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const BoldText = Styled.H6`
  color: ${({color}) => color};
  ${fontWeight(900)}
`;

const TotalBoldText = Styled.H6`
  color: ${({color}) => color};
  ${fontWeight(900)}
  padding-left: 12px;
  text-align: right;
`;

const ActionButton = Styled.ButtonV2`
  width: 88px;
  height: 30px;
  background-color: ${colors.gray.border};
  border-radius: 4px;
  align-items: center;
  justify-content: center;
`;

const ActionButtonText = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const SummarySectionContainer = Styled.View`
  width: 100%;
  border-radius: 8px;
  border-color: ${colors.gray.border};
  border-width: ${({vars}) => (vars.hasBorder ? '1px' : '0px')};
  padding-horizontal: ${({vars}) => (vars.hasBorder ? '12px' : '0px')};
`;

const getIsHighlighted = ({bill, params}) => {
  const uuid = params.jobUuid || params.uuid;
  return !!uuid && _.get(bill, 'job.uuid') === uuid;
};

const ProjectBillsHeader = ({
  title,
  caption,
  showOpenAllButton,
  hasExpandedAllBills,
  openAll,
  closeAll,
}) => {
  const handlePress = () => {
    if (hasExpandedAllBills) {
      closeAll();
    } else {
      openAll();
    }
  };
  return (
    <React.Fragment>
      <Row>
        <FlexAlignContainer flex={1} align={'flex-start'}>
          {title}
        </FlexAlignContainer>
        <Row>
          {showOpenAllButton && (
            <ActionButton onPress={handlePress}>
              <ActionButtonText>{hasExpandedAllBills ? 'Close All' : 'Open All'}</ActionButtonText>
            </ActionButton>
          )}
        </Row>
      </Row>
      <Container>{caption}</Container>
    </React.Fragment>
  );
};

const ProjectTip = ({project, hasBorder}) => {
  const {tip, billTips} = project.currentAggregateBill.primaryBill;

  if (!project.organization.features.isEnabledAdditionalPaymentsCrewApp) {
    return (
      <Row>
        <FlexAlignContainer flex={1} align={hasBorder ? 'flex-start' : 'flex-end'}>
          <Text>Tip</Text>
        </FlexAlignContainer>
        <FlexContainer flex={1}>
          <Text>{Currency.format({value: tip})}</Text>
        </FlexContainer>
      </Row>
    );
  }

  return (
    <React.Fragment>
      {billTips.map((billTip, index) => {
        return (
          <React.Fragment key={billTip.id}>
            {index > 0 && <Space height={12} />}
            <Row>
              <FlexAlignContainer
                flex={hasBorder ? 2 : 1}
                align={hasBorder ? 'flex-start' : 'flex-end'}
              >
                <Text vars={{hasBorder}} numberOfLines={1}>{`Tip (${billTip.name})`}</Text>
              </FlexAlignContainer>
              <FlexContainer flex={1}>
                <Text>{Currency.display(billTip.amount)}</Text>
              </FlexContainer>
            </Row>
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

const ProjectGrandTotal = ({project, hasBorder}) => {
  const {isTotalAvailable, minTotal, maxTotal} = project.currentAggregateBill;
  return (
    <Row>
      {hasBorder ? (
        <React.Fragment>
          <Container>
            <BoldText color={colors.gray.primary}>Grand Total</BoldText>
          </Container>
          <Space width={12} />
        </React.Fragment>
      ) : (
        <FlexContainer flex={1}>
          <BoldText color={colors.gray.primary}>Grand Total</BoldText>
        </FlexContainer>
      )}
      <FlexContainer flex={1}>
        <TotalBoldText color={colors.gray.primary}>
          {isTotalAvailable ? Currency.formatRange({min: minTotal, max: maxTotal}) : 'TBD'}
        </TotalBoldText>
      </FlexContainer>
    </Row>
  );
};

const TipAndGrandTotal = ({project, isEditable, hasBorder}) => {
  return (
    <Row>
      <FlexContainer flex={6} />
      <FlexContainer flex={4}>
        <SummarySectionContainer vars={{hasBorder}}>
          {hasBorder && <Space height={12} />}
          {project.currentAggregateBill.primaryBill.tip > 0 && (
            <React.Fragment>
              <ProjectTip project={project} isEditable={isEditable} hasBorder={hasBorder} />
              <Space height={12} />
            </React.Fragment>
          )}
          <ProjectGrandTotal project={project} hasBorder={hasBorder} />
          <Space height={12} />
        </SummarySectionContainer>
      </FlexContainer>
      {isEditable && <Space width={108} />}
    </Row>
  );
};

const PaymentsList = ({payments, hasBorder}) => {
  return (
    <React.Fragment>
      {payments.map((payment, index) => {
        return (
          <React.Fragment key={index}>
            <Space height={12} />
            <Row>
              <FlexAlignContainer flex={1} align={hasBorder ? 'flex-start' : 'flex-end'}>
                <Text vars={{hasBorder}}>{`Paid By ${Payment.getDisplayMethod(payment)}`}</Text>
              </FlexAlignContainer>
              <FlexContainer flex={1}>
                <Text>{Currency.format({value: payment.totalAmount})}</Text>
              </FlexContainer>
            </Row>
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

const PaymentsAndBalance = ({project, isEditable, hasBorder}) => {
  const {isTotalAvailable, minBalance, maxBalance, primaryBill} = project.currentAggregateBill;
  const color = Project.getProjectBillBalanceColor({project});
  return (
    <Row>
      <FlexContainer flex={6} />
      <FlexContainer flex={4}>
        <SummarySectionContainer vars={{hasBorder}}>
          <PaymentsList payments={primaryBill.paidPayments} hasBorder={hasBorder} />
          <Space height={12} />
          {hasBorder && primaryBill.hasPayments && (
            <React.Fragment>
              <Line />
              <Space height={12} />
            </React.Fragment>
          )}
          <Row>
            <FlexAlignContainer flex={1} align={hasBorder ? 'flex-start' : 'flex-end'}>
              <BoldText vars={{hasBorder}} color={color}>
                Balance
              </BoldText>
            </FlexAlignContainer>
            <FlexContainer flex={1}>
              <TotalBoldText color={color}>
                {isTotalAvailable
                  ? Currency.formatRange({min: minBalance, max: maxBalance})
                  : 'TBD'}
              </TotalBoldText>
            </FlexContainer>
          </Row>
          <Space height={12} />
        </SummarySectionContainer>
      </FlexContainer>
      {isEditable && <Space width={108} />}
    </Row>
  );
};

const ProjectBillsList = ({
  project,
  title,
  caption,
  refetch,
  isEditable,
  isCollapsible,
  showPrices,
  showGrandTotal,
  showBalance,
  showBillRules,
  showAllBillRules,
  showOpenAllButton,
}) => {
  const {params} = useNavigation();
  // TODO(dan): Once all routes are consistenly using jobUuid, set static to params.jobUuid
  const jobUuid = params.jobUuid || params.uuid;
  const {bills} = project.currentAggregateBill;
  const [expandedBills, setExpandedBills] = useState(
    bills.map((bill) => {
      // Expand all project bills by default as well as any job bills that match the job the
      // user is looking at.
      const billJobUuid = _.get(bill, 'job.uuid');
      return (!isEditable && !isCollapsible) || !billJobUuid || billJobUuid === jobUuid;
    }),
  );
  const openAll = () => {
    const newExpandedBills = expandedBills.map((isExpanded) => true);
    setExpandedBills(newExpandedBills);
  };
  const closeAll = () => {
    const newExpandedBills = expandedBills.map((isExpanded) => false);
    setExpandedBills(newExpandedBills);
  };
  const hasBorder = isEditable || isCollapsible;

  return (
    <Container>
      <ProjectBillsHeader
        title={title}
        caption={caption}
        showOpenAllButton={showOpenAllButton && bills.length > 1}
        hasExpandedAllBills={_.every(expandedBills)}
        openAll={openAll}
        closeAll={closeAll}
      />
      <Space height={8} />
      <FlatList
        data={bills}
        keyExtractor={(bill) => bill.id}
        renderItem={({item: bill, index}) => {
          return (
            <ProjectBillsListItem
              bill={bill}
              refetch={refetch}
              isEditable={isEditable}
              isCollapsible={isCollapsible}
              isLastItem={index === bills.length - 1}
              isExpanded={expandedBills[index]}
              expandedBills={expandedBills}
              setExpandedBills={setExpandedBills}
              showPrices={showPrices}
              showBillRules={showBillRules}
              showAllBillRules={showAllBillRules}
              isHighlighted={getIsHighlighted({bill, params})}
              index={index}
            />
          );
        }}
        ItemSeparatorComponent={() => <Space height={12} />}
        keyboardShouldPersistTaps={'handled'}
      />
      <Space height={12} />
      {showGrandTotal && (
        <React.Fragment>
          <TipAndGrandTotal project={project} isEditable={isEditable} hasBorder={hasBorder} />
        </React.Fragment>
      )}
      {showBalance && (
        <React.Fragment>
          {hasBorder ? <Space height={12} /> : <Line />}
          <PaymentsAndBalance project={project} isEditable={isEditable} hasBorder={hasBorder} />
        </React.Fragment>
      )}
      <Space height={24} />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

ProjectBillsList.fragment = gql`
  ${ProjectBillsListItem.fragment}
  ${Project.getProjectBillBalanceColor.fragment}

  fragment ProjectBillsList on Project {
    id
    organization {
      id
      features {
        isEnabledAdditionalPaymentsCrewApp: isEnabled(feature: "ADDITIONAL_PAYMENTS_CREW_APP")
      }
    }
    currentAggregateBill {
      isTotalAvailable
      minTotal
      maxTotal
      minBalance
      maxBalance
      bills {
        id
        job {
          id
          uuid
        }
        ...ProjectBillsListItem
      }
      primaryBill {
        id
        tip
        hasPayments
        paidPayments {
          id
          method
          totalAmount
        }
        billTips {
          id
          name
          amount
        }
      }
    }
    ...Project_getProjectBillBalanceColor
  }
`;

export default ProjectBillsList;
