// Supermove
import {Float} from '@supermove/utils';

// App
import CreateCustomerPaymentProfileForm from '@shared/modules/Payment/forms/CreateCustomerPaymentProfileForm';
import useBeginPaymentV3MutationWithStripe from '@shared/modules/Payment/hooks/useBeginPaymentV3MutationWithStripe';
import useCreateCustomerPaymentProfileMutation from '@shared/modules/Payment/hooks/useCreateCustomerPaymentProfileMutation';
import useFinalizePaymentMutation from '@shared/modules/Payment/hooks/useFinalizePaymentMutation';

/**
 * The `handleSubmit` that is returned will take a stripe object and set off a set of 3 API calls.
 *
 *    1. BeginPaymentMutation - this will create the PaymentIntent on our server
 *       and pass the Payment object back with the Stripe `clientSecret`.
 *    2. We take the `clientSecret` and send it to Stripe to confirm the PaymentIntent.
 *    3. Once the PaymentIntent is confirmed with Stripe, we submit the FinalizeMoveMutation
 *       to tell our server to reconcile the transactions and store in our DB.
 *
 */

const useBeginPaymentV3WithStripe = ({
  beginPaymentV3Form,
  stripePaymentForm,
  saveCreditCard,
  onSuccess,
  onError,
  jobId,
}) => {
  const beginPaymentV3MutationWithStripe = useBeginPaymentV3MutationWithStripe({
    beginPaymentV3Form,
    stripePaymentForm,
    onSuccess: () => {},
    onError,
  });

  const finalizePaymentMutation = useFinalizePaymentMutation({
    jobId,
    onSuccess,
    onError,
    response: 'DOCUMENT_NAVIGATION',
  });

  const {form} = beginPaymentV3MutationWithStripe;
  const createCustomerPaymentProfileForm = CreateCustomerPaymentProfileForm.new({
    customerId: beginPaymentV3Form.customerId,
  });

  const createCustomerPaymentProfileMutation = useCreateCustomerPaymentProfileMutation({
    createCustomerPaymentProfileForm,
    onSuccess: () => {
      onSuccess();
    },
    onError: () => {
      // Set the loading state back to false.
      form.setFieldValue('stripePaymentForm.loading', false);
      form.setFieldValue('stripePaymentForm.creditCardIsValid', false);

      onError();
    },
  });
  const submitting =
    finalizePaymentMutation.submitting ||
    beginPaymentV3MutationWithStripe.submitting ||
    createCustomerPaymentProfileMutation.submitting ||
    form.values.stripePaymentForm.loading;

  const handleSubmit = async (stripe) => {
    try {
      // 1) Create payment intent
      const {data} = await beginPaymentV3MutationWithStripe.handleSubmit();
      if (!data.response.payment) {
        return;
      }
      await finalizePaymentMutation.form.setFieldValue('paymentId', data.response.payment.id);

      // 2) Confirm payment intent with Stripe
      form.setFieldValue('stripePaymentForm.loading', true);
      const {stripePaymentForm} = form.values;
      await stripe.setAccountId({id: stripePaymentForm.stripeAccountId});
      const {paymentIntent, error} = await stripe.confirmPaymentIntent({
        clientSecret: data.response.payment.stripePaymentClientSecret,
        params: {
          // Native uses this to submit the credit card info via the SDK.
          number: stripePaymentForm.number,
          expMonth: Float.toFloat(stripePaymentForm.expMonth),
          expYear: Float.toFloat(stripePaymentForm.expYear),
          cvc: stripePaymentForm.cvc,

          // Web uses this to pass the 'stripe-elements' component.
          card: stripePaymentForm.card,
          setupFutureUsage: saveCreditCard ? 'off_session' : null,
        },
      });
      if (error) {
        // Payment intent resulted in an error, we need to update the UI.
        form.setFieldValue('stripePaymentForm.loading', false);
        form.setFieldValue('stripePaymentForm.creditCardIsValid', false);

        onError(error);
        return;
      }

      // Set up Customer and Payment Relationship Only if Save Credit Card is on
      if (saveCreditCard) {
        await createCustomerPaymentProfileMutation.form.setFieldValue(
          'createCustomerPaymentProfileForm.paymentMethodId',
          paymentIntent.payment_method,
        );
        createCustomerPaymentProfileMutation.handleSubmit();
      }

      // 3) Finalize and save the payment
      return finalizePaymentMutation.handleSubmit();
    } catch (error) {
      // Credit card is not valid, we need to update the UI.
      form.setFieldValue('stripePaymentForm.loading', false);
      form.setFieldValue('stripePaymentForm.creditCardIsValid', false);

      // TODO(mark): Submit to Sentry to track.
      onError(error);
    }
  };

  return {
    form,
    submitting,
    handleSubmit: (stripe) => handleSubmit(stripe),
  };
};

export default useBeginPaymentV3WithStripe;
