// Libraries
import {gql} from '@supermove/graphql';
import {useMutation, useMutationCallbacks} from '@supermove/hooks';

const MUTATION = gql`
  mutation SkipSignatureForDocumentStepMutation(
    $documentId: Int!,
    $step: String!,
  ) {
    response: skipSignatureForDocumentStep(
      documentId: $documentId,
      step: $step,
    ) {
      ${gql.errors}
      document {
        id
        isStepCompleted(step: $step)
      }
    }
  }
`;

const useSkipSignatureForDocumentStepMutation = ({documentId, step, onSuccess, onError}) => {
  const [handleSubmit, {loading, data}] = useMutation(MUTATION, {
    variables: {
      documentId,
      step,
    },
  });

  useMutationCallbacks({data, onSuccess, onError});

  return {
    submitting: loading,
    handleSubmit,
  };
};

export default useSkipSignatureForDocumentStepMutation;
