// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {KeyboardView, Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useEffect, useModal, useNavigation, useState, useQuery} from '@supermove/hooks';
import {Document} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import DocumentV2ByCategory from '@shared/modules/Document/components/DocumentV2ByCategory';
import DocumentStepKind from '@shared/modules/Document/enums/DocumentStepKind';
import CompleteDocumentV2Form from '@shared/modules/Document/forms/CompleteDocumentV2Form';
import useCompleteDocumentV2Mutation from '@shared/modules/Document/hooks/useCompleteDocumentV2Mutation';
import useUpdateDocumentContentJsonForDocumentMutation from '@shared/modules/Document/hooks/useUpdateDocumentContentJsonForDocumentMutation';
import Navigation from 'core/Navigation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SkipDocumentV2Modal from 'modules/Job/Customer/DocumentV2/Sign/components/SkipDocumentV2Modal';
import CustomerJobPageV2 from 'modules/Job/Customer/components/CustomerJobPageV2';

const Container = Styled.View`
  flex: 1;
`;

const Header = Styled.View`
  padding: 16px;
  align-items: center;
  background-color: ${colors.gray.background};
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
`;

const HeaderText = Styled.Text`
  ${Typography.Body2}
`;

const ContentContainer = Styled.View`
  padding-horizontal: 40px;
`;

const Button = Styled.LoadingButton`
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.Text`
  ${Typography.Label2}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const ErrorsText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.red.warning};
`;

const handleNavigate = ({params, navigator, job}) => {
  if (params.success === 'BACK') {
    return navigator.goBack();
  }

  switch (Document.formatStepAsEnum(params.step)) {
    case DocumentStepKind.PRE_MOVE:
      return Navigation.navigateToNextIncompleteDocumentForPreMove({
        navigator,
        job,
      });
    case DocumentStepKind.AFTER_TIMESHEET:
      return Navigation.navigateToNextIncompleteDocumentForAfterTimesheet({
        navigator,
        job,
      });
    case DocumentStepKind.POST_MOVE:
      return Navigation.navigateToNextIncompleteDocumentForPostMoveProjectBilling({
        navigator,
        job,
      });
    default:
      return;
  }
};

const ErrorsMessage = ({form}) => {
  return (
    <React.Fragment>
      <ErrorsText>{`${pluralize(
        'error',
        _.get(form.values, 'completeDocumentV2Form.numberOfErrors'),
        true,
      )} found`}</ErrorsText>
      <Space width={20} />
    </React.Fragment>
  );
};

const Footer = ({job, document, handleSubmit, submitting, form}) => {
  const {params, navigator} = useNavigation();
  const hasErrors = !_.isEmpty(
    _.filter(
      _.get(form.errors, 'completeDocumentV2Form.documentForm.documentItemForms'),
      (item) => !_.isNil(item),
    ),
  );
  const skipDocumentV2Modal = useModal({name: 'Skip Document V2 Modal'});
  const isOneOffSigning = params.success === 'BACK'; // Currently only available from job summary page

  return (
    <CustomerJobPageV2.Footer style={{flexDirection: 'row', alignItems: 'center', padding: 10}}>
      <Button color={colors.gray.secondary} onPress={() => navigator.goBack()}>
        <Text>Back</Text>
      </Button>
      <Space style={{flex: 1}} />
      {hasErrors && <ErrorsMessage form={form} />}
      {job.hasJobFeatureCrewAllowSkipDocuments && !isOneOffSigning && (
        <React.Fragment>
          <Button
            disabled={submitting}
            onPress={skipDocumentV2Modal.handleOpen}
            color={colors.gray.secondary}
          >
            <Text>Skip</Text>
          </Button>
          <Space width={10} />
        </React.Fragment>
      )}
      <Button loading={submitting} onPress={handleSubmit}>
        <Text>{isOneOffSigning ? 'Submit' : 'Next'}</Text>
      </Button>
      <SkipDocumentV2Modal
        key={skipDocumentV2Modal.key}
        document={document}
        isOpen={skipDocumentV2Modal.isOpen}
        handleClose={skipDocumentV2Modal.handleClose}
        handleSkipSuccess={() => {
          skipDocumentV2Modal.handleClose();
          handleNavigate({params, navigator, job});
        }}
      />
    </CustomerJobPageV2.Footer>
  );
};

const SignDocumentV2CustomerJobPageContent = ({job, document}) => {
  const {params, navigator} = useNavigation();
  const completeDocumentV2Form = CompleteDocumentV2Form.new({
    document,
    step: params.step,
  });
  const {form, handleSubmit, submitting} = useCompleteDocumentV2Mutation({
    completeDocumentV2Form,
    onSuccess: () => {
      handleNavigate({params, navigator, job});
    },
    onError: (errors) => {
      console.log({errors});
      form.setFieldValue('completeDocumentV2Form.numberOfErrors', errors.length);
    },
  });

  return (
    <Container>
      <KeyboardView>
        <ScrollView alwaysBounceVertical={false} style={{flex: 1}}>
          <Header>
            <HeaderText>{document.displayNameInStep}</HeaderText>
          </Header>
          <ContentContainer>
            <Space height={10} />
            <DocumentV2ByCategory
              document={document}
              form={form}
              field={'completeDocumentV2Form'}
              isEditable
            />
          </ContentContainer>
          <Space height={40} />
        </ScrollView>
      </KeyboardView>
      <Footer
        job={job}
        document={document}
        handleSubmit={handleSubmit}
        submitting={submitting}
        form={form}
      />
    </Container>
  );
};

const SignDocumentV2CustomerJobPageQuery = ({jobUuid, documentUuid}) => {
  const {loading, data, refetch} = useQuery(SignDocumentV2CustomerJobPage.query, {
    fetchPolicy: 'network-only',
    variables: {
      jobUuid,
      documentUuid,
    },
  });

  return (
    <CustomerJobPageV2 refetch={refetch}>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => {
          return <SignDocumentV2CustomerJobPageContent job={data.job} document={data.document} />;
        }}
      </Loading>
    </CustomerJobPageV2>
  );
};

const UpdateDocumentContentMutation = ({documentUuid, jobUuid}) => {
  const [isMutationComplete, setIsMutationComplete] = useState(false);
  const {submitting, handleSubmit} = useUpdateDocumentContentJsonForDocumentMutation({
    documentUuid,
    onSuccess: () => {
      setIsMutationComplete(true);
    },
    onError: (errors) => {
      console.log({errors});
      setIsMutationComplete(true);
    },
  });

  // Updating the document content JSON before displaying ensures that all variables are accurate.
  // This is necessary for custom/dynamic variables that can change by user input after being generated.
  useEffect(() => {
    handleSubmit();
  }, [handleSubmit]);

  return (
    <Loading loading={submitting || !isMutationComplete} as={PageLoadingIndicator}>
      {() => {
        return <SignDocumentV2CustomerJobPageQuery jobUuid={jobUuid} documentUuid={documentUuid} />;
      }}
    </Loading>
  );
};

const SignDocumentV2CustomerJobPage = () => {
  const {params, navigator} = useNavigation();
  return (
    // Explicitly pass the navigator.state.key to force a re-render. Without this
    // the signature from the previous screen will still be showing.
    <UpdateDocumentContentMutation
      key={navigator.state.key}
      jobUuid={params.jobUuid}
      documentUuid={params.documentUuid}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SignDocumentV2CustomerJobPage.query = gql`
  ${CompleteDocumentV2Form.new.fragment}
  ${DocumentV2ByCategory.fragment}
  ${Navigation.navigateToNextIncompleteDocumentForAfterTimesheet.fragment}
  ${Navigation.navigateToNextIncompleteDocumentForPostMoveProjectBilling.fragment}
  ${Navigation.navigateToNextIncompleteDocumentForPreMove.fragment}
  ${SkipDocumentV2Modal.fragment}

  query SignDocumentV2CustomerJobPage($jobUuid: String!, $documentUuid: String!) {
    ${gql.query}
    document(uuid: $documentUuid) {
      id
      displayNameInStep
      ...CompleteDocumentV2Form_new
      ...DocumentV2ByCategory
      ...SkipDocumentV2Modal
    }
    job(uuid: $jobUuid) {
      id
      hasJobFeatureCrewAllowSkipDocuments: hasJobFeature(kind: "CREW_ALLOW_SKIP_DOCUMENTS")
      ...Navigation_navigateToNextIncompleteDocumentForAfterTimesheet
      ...Navigation_navigateToNextIncompleteDocumentForPostMoveProjectBilling
      ...Navigation_navigateToNextIncompleteDocumentForPreMove
    }
  }
`;

export default SignDocumentV2CustomerJobPage;
