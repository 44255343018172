// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';

// App
import {JobActionButton} from 'modules/Job/Actions/components';
import {JobNavigation, JobPage} from 'modules/Job/components';

const Container = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
  margin: 20px;
`;

const Info = Styled.View`
  margin-top: 10px;
`;

const Title = Styled.H2`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
  text-align: center;
`;

const Subtitle = Styled.H5`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
  text-align: center;
`;

const Actions = Styled.View`
  align-items: center;
  margin-top: 40px;
`;

const Action = Styled.View`
  margin-vertical: 5px;
`;

const Contact = Styled.H6`
  margin-top: 40px;
  color: ${colors.gray.primary};
  text-align: center;
`;

const ActionsJobPageContent = ({navigator, params, data}) => {
  const {isEnabledCrewActionsAdditionalPayments} = data.job.project.organization.features;
  const isEnabledTimesheetsV2 = data.job.project.projectType.features.timesheetsV2;

  return (
    <Container>
      <ScrollView style={{flex: 1}}>
        <Content>
          <Info>
            <Title numberOfLines={1}>{`Quick Actions`}</Title>
            <Subtitle>
              {`Use the buttons below to charge the customer, create documents, or get help.`}
            </Subtitle>
          </Info>
          <Actions>
            <Action>
              <JobActionButton
                name={'View Job Overview'}
                onPress={() => navigator.navigate('ShowJob', {uuid: params.uuid})}
              />
            </Action>
            <Action>
              <JobActionButton
                name={'View Documents'}
                onPress={() => navigator.navigate('ListDocumentJob', {uuid: params.uuid})}
              />
            </Action>
            {data.job.hasJobFeatureEditBillingAndPayments && !!_.get(data, 'job.move.bill') && (
              <Action>
                <JobActionButton
                  name={'Edit Bills & Add Payments'}
                  onPress={() => {
                    navigator.navigate('EditBillingProjectJobPage', {jobUuid: params.uuid});
                  }}
                />
              </Action>
            )}
            {isEnabledCrewActionsAdditionalPayments && (
              <Action>
                <JobActionButton
                  name={'Record Payment'}
                  onPress={() => {
                    navigator.navigate('NewPaymentBillingProjectJobPage', {
                      jobUuid: params.uuid,
                      step: 'PROJECT_BILLING_ADDITIONAL_PAYMENT',
                    });
                  }}
                />
              </Action>
            )}
            {data.job.hasJobStepCrewCaptureInventory && !_.isEmpty(data.job.driverInventory) && (
              <Action>
                <JobActionButton
                  name={'View Inventory'}
                  onPress={() => {
                    navigator.navigate('CrewCaptureInventory', {uuid: params.uuid});
                  }}
                />
              </Action>
            )}
            {isEnabledTimesheetsV2 && (
              <Action>
                <JobActionButton
                  name={'Assign Movers'}
                  onPress={() => {
                    navigator.navigate('MoversCrewJob', {uuid: params.uuid});
                  }}
                />
              </Action>
            )}
            {isEnabledTimesheetsV2 && (
              <Action>
                <JobActionButton
                  name={'View Job Timesheet'}
                  onPress={() => {
                    navigator.navigate('JobTimesheetActionJob', {uuid: params.uuid});
                  }}
                />
              </Action>
            )}
            {isEnabledTimesheetsV2 &&
              (data.job.hasJobStepCrewPreMovePayrollTimesheet ||
                data.job.hasJobStepCrewAfterMovePayrollTimesheet) && (
                <Action>
                  <JobActionButton
                    name={'View Crew Hours'}
                    onPress={() => {
                      navigator.navigate('CrewTimesheetActionJob', {uuid: params.uuid});
                    }}
                  />
                </Action>
              )}
          </Actions>
          <Contact>{`Need help? Call us at (415) 991-1969.`}</Contact>
        </Content>
      </ScrollView>
    </Container>
  );
};

const ActionsJobPage = () => {
  return (
    <JobPage
      fetchPolicy={'network-only'}
      getVariables={({params}) => ({uuid: params.uuid})}
      query={ActionsJobPage.query}
      navigation={(props) => <JobNavigation {...props} />}
    >
      {({navigator, params, data}) => (
        <ActionsJobPageContent navigator={navigator} params={params} data={data} />
      )}
    </JobPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ActionsJobPage.query = gql`
  ${JobPage.fragment}
  ${JobNavigation.fragment}

  query ActionsJobPage($uuid: String!) {
    ${gql.query}
    job(uuid: $uuid) {
      id
      hasJobFeatureEditBillingAndPayments: hasJobFeature(kind: "CREW_EDIT_BILLING_AND_PAYMENTS")
      hasJobStepCrewCaptureInventory: hasJobStep(kind: "CREW_CAPTURE_INVENTORY")
      hasJobStepCrewPreMovePayrollTimesheet: hasJobStep(kind: "CREW_PRE_MOVE_PAYROLL_TIMESHEET")
      hasJobStepCrewAfterMovePayrollTimesheet: hasJobStep(kind: "CREW_AFTER_MOVE_PAYROLL_TIMESHEET")
      move {
        id
        bill {
          id
          uuid
        }
      }
      project {
        id
        organization {
          id
          features {
            isEnabledCrewActionsAdditionalPayments: isEnabled(feature: "CREW_ACTIONS_ADDITIONAL_PAYMENTS")
          }
        }
        projectType {
          id
          features {
            timesheetsV2
          }
        }
      }
      driverInventory {
        id
      }
      ...JobPage
      ...JobNavigation
    }
  }
`;

export default ActionsJobPage;
