// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {KeyboardView, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigation} from '@supermove/hooks';
import {Job} from '@supermove/models';
import {CreditCardInput, StripeForm} from '@supermove/payments';
import {colors, fontWeight} from '@supermove/styles';
import {Currency, Json} from '@supermove/utils';

// App
import BeginPaymentV3Form from '@shared/modules/Payment/forms/BeginPaymentV3Form';
import StripePaymentForm from '@shared/modules/Payment/forms/StripePaymentForm';
import useBeginPaymentV3WithStripe from '@shared/modules/Payment/hooks/useBeginPaymentV3WithStripe';
import Navigation from 'core/Navigation';
import PageFooter from 'modules/App/components/PageFooter';
import PageV2 from 'modules/App/components/PageV2';
import PaymentErrorModal from 'modules/Project/Billing/Payment/New/components/PaymentErrorModal';
import PaymentAmountSection from 'modules/Project/Billing/Payment/components/PaymentAmountSection';

const Container = Styled.View`
  flex: 1;
`;

const TitleContainer = Styled.View`
  align-items: center;
`;

const InputContainer = Styled.View`
  padding-horizontal: 40px;
`;

const Button = Styled.LoadingButton`
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const getStripeAccountId = ({job}) => {
  const credentials = _.get(job, 'organization.account.stripeConnectAccountCredentials', '{}');
  const json = Json.toCamelCaseKeys(Json.parse(credentials));
  return _.get(json, 'stripeUserId');
};

const ProjectBillingCreditCardPaymentSection = ({job}) => {
  const {params, navigator} = useNavigation();
  const paymentErrorModal = useModal();
  const stripeAccountId = getStripeAccountId({job});
  const beginPaymentV3Form = BeginPaymentV3Form.new({
    billId: job.project.currentPrimaryBill.id,
    customerId: job.project.currentPrimaryBill.customerId,
    name: params.paymentName,
    amount: params.paymentAmount,
    method: 'STRIPE_CREDIT_CARD',
    tipAmount: params.tipAmount,
    tipName: Job.getTipName(job),
    jobId: job.id,
  });
  const stripePaymentForm = StripePaymentForm.new({stripeAccountId});
  const {form, handleSubmit, submitting} = useBeginPaymentV3WithStripe({
    jobId: job.id,
    beginPaymentV3Form,
    stripePaymentForm,
    onSuccess: ({payment}) => Navigation.navigateFromPaymentSuccess({navigator, params, payment}),
    onError: (errors) => {
      paymentErrorModal.handleOpen();
    },
  });

  const isMainFlow = Navigation.isMainFlowPayment({params});

  return (
    <Container>
      <TitleContainer>
        <PageV2.Title>Please enter your card information.</PageV2.Title>
        <PageV2.Subtitle>Your payment will be processed shortly.</PageV2.Subtitle>
        <Space height={20} />
        <PaymentAmountSection
          title={'Payment Amount:'}
          amount={Currency.display(params.paymentAmount)}
        />
      </TitleContainer>
      <StripeForm stripeAccountId={stripeAccountId}>
        {({stripe}) => (
          <React.Fragment>
            <ScrollView style={{flex: 1}}>
              <KeyboardView>
                <InputContainer>
                  <CreditCardInput
                    onChange={({isValid, number, expMonth, expYear, cvc, card}) => {
                      // We use lodash's merge here because we do NOT want to override
                      // when a value is undefined.
                      const values = _.merge({}, form.values.stripePaymentForm, {
                        creditCardIsValid: isValid,
                        number,
                        expMonth,
                        expYear,
                        cvc,
                        card,
                      });

                      form.setFieldValue('stripePaymentForm', values);
                    }}
                  />
                </InputContainer>
              </KeyboardView>
            </ScrollView>
            <PageFooter>
              <PageFooter.Row style={{justifyContent: 'space-between'}}>
                {isMainFlow ? <PageFooter.BackButton /> : <Space />}
                <Button
                  disabled={!form.values.stripePaymentForm.creditCardIsValid}
                  loading={submitting}
                  color={colors.blue.interactive}
                  onPress={_.throttle(() => handleSubmit(stripe), 3000)}
                >
                  <Text>Submit</Text>
                </Button>
              </PageFooter.Row>
            </PageFooter>
          </React.Fragment>
        )}
      </StripeForm>
      <PaymentErrorModal
        isOpen={paymentErrorModal.isOpen}
        handleClose={paymentErrorModal.handleClose}
        title={'Unable to Process Credit Card'}
        subtitle={
          'We were unable to process this credit card. ' +
          'Please call us at (415) 991-1969 to fix this as soon as possible.'
        }
      />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectBillingCreditCardPaymentSection.fragment = gql`
  ${Job.getTipName.fragment}

  fragment ProjectBillingCreditCardPaymentSection on Job {
    id
    organization {
      id
      account {
        id
        stripeConnectAccountCredentials
      }
    }
    project {
      id
      currentPrimaryBill {
        id
        customerId
      }
    }
    customer {
      id
    }
    ...Job_getTipName
  }
`;

export default ProjectBillingCreditCardPaymentSection;
