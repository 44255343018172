// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {useState, useMountEffect} from '@supermove/hooks';
import {Storage} from '@supermove/sdk';

import {getMoverAppUrl} from '@shared/modules/Inventory/components/EmbeddedInventoryCapture/utils/getMoverAppUrl';

const Container = Styled.View`
  flex: 1;
`;

const EmbeddedInventoryCapture = ({inventoryUuid, jobUuid}) => {
  const [authToken, setAuthToken] = useState('');
  const setAuth = async () => {
    const token = await Storage.getItem('token');
    setAuthToken(token);
  };

  useMountEffect(() => {
    setAuth();
  });

  return (
    <Container>
      <iframe
        title={'Embedded Inventory Capture'}
        src={getMoverAppUrl({inventoryUuid, jobUuid, authToken})}
        style={{
          flex: 1,
          borderWidth: 0,
        }}
      />
    </Container>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
EmbeddedInventoryCapture.propTypes = {
  inventoryUuid: PropTypes.string.isRequired,
};

export default EmbeddedInventoryCapture;
