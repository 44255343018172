// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

const Container = Styled.View`
`;

const Content = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 10px;
  padding-horizontal: 10px;
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const Index = Styled.H5`
  margin-right: 20px;
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const NameInput = Styled.TextInput.H7`
  width: 200px;
`;

const QuantityInput = Styled.TextInput.H7`
  margin-left: 10px;
  width: 100px;
`;

const DocumentAdditionalInfoEquipmentInput = ({
  isFirst,
  isLast,
  name,
  index,
  values,
  setFieldValue,
  setFieldTouched,
}) => (
  <Container>
    {isFirst && <Line />}
    <Content>
      <Index>{`${index + 1}.`}</Index>
      <NameInput
        name={`${name}.name`}
        placeholder={'Equipment name'}
        value={_.get(values, `${name}.name`)}
        onChangeText={(text) => setFieldValue(`${name}.name`, text)}
        onBlur={() => setFieldTouched(`${name}.name`, true)}
      />
      <QuantityInput
        keyboardType={'numeric'}
        name={`${name}.quantityTaken`}
        placeholder={'# taken'}
        value={_.get(values, `${name}.quantityTaken`)}
        onChangeText={(text) => setFieldValue(`${name}.quantityTaken`, text)}
        onBlur={() => setFieldTouched(`${name}.quantityTaken`, true)}
      />
      <QuantityInput
        keyboardType={'numeric'}
        name={`${name}.quantityReturned`}
        placeholder={'# returned'}
        value={_.get(values, `${name}.quantityReturned`)}
        onChangeText={(text) => setFieldValue(`${name}.quantityReturned`, text)}
        onBlur={() => setFieldTouched(`${name}.quantityReturned`, true)}
      />
    </Content>
    <Line />
  </Container>
);

export default DocumentAdditionalInfoEquipmentInput;
