const _new = ({jobId}) => ({
  jobId,
});

const toForm = ({jobId}) => ({
  jobId,
});

const toMutation = ({jobId}) => ({
  jobId,
});

const CompleteTimesheetStepForm = {
  new: _new,
  toForm,
  toMutation,
};

export default CompleteTimesheetStepForm;
