// Libraries
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {TimeRange} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// Components
import EmployeeMoveTimeModal from './EmployeeMoveTimeModal';

const Touchable = Styled.Touchable`
`;

const Container = Styled.View`
  background-color: ${({vars}) =>
    vars.isLong ? colors.alpha(colors.orange.status, 0.1) : colors.white};
`;

const Content = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 10px;
  padding-horizontal: 10px;
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const Circle = Styled.View`
  margin-right: 10px;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background-color: ${(props) => props.color};
`;

const Name = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Info = Styled.View`
  flex: 1;
  align-items: flex-end;
`;

const Value = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Placeholder = Styled.H5`
  font-style: italic;
  color: ${colors.gray.tertiary};
`;

const Time = Styled.H6`
`;

const LongWarning = Styled.H6`
  margin-right: 5px;
  color: ${colors.orange.status};
  text-align: right;
`;

const TimeMinutes = Styled.H6`
  ${({vars}) => (vars.isLong ? fontWeight(700) : fontWeight(300))}
  color: ${({vars}) => (vars.isLong ? colors.orange.status : colors.gray.tertiary)};
  text-align: right;
  letter-spacing: 1;
  text-transform: uppercase;
`;

const Button = Styled.Button`
  margin-left: 30px;
  height: 40px;
  width: 100px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${({vars = {}}) => (vars.isLong ? colors.orange.status : colors.blue.interactive)};
`;

const getType = ({timeRange}) => {
  if (!timeRange.start) {
    return 'add';
  } else if (!timeRange.end) {
    return 'finish';
  } else {
    return 'edit';
  }
};

const getStartTimeValue = ({timeRange}) => {
  return `${Datetime.convertToDisplayTime(timeRange.start, Datetime.FORM_TIME)} - `;
};

const getFullTimeValue = ({timeRange}) => {
  const startTimeFormatted = Datetime.convertToDisplayTime(timeRange.start, Datetime.FORM_TIME);
  const endTimeFormatted = Datetime.convertToDisplayTime(timeRange.end, Datetime.FORM_TIME);
  return `${startTimeFormatted} - ${endTimeFormatted}`;
};

const EmployeeMoveTimeItem = ({isFirst, isLast, index, timeRange, organization, form}) => (
  <EmployeeMoveTimeModal
    type={getType({timeRange})}
    index={index}
    timeRange={timeRange}
    organization={organization}
    form={form}
    trigger={({handleOpen}) => (
      <Touchable activeOpacity={1} onPress={handleOpen}>
        <Container
          vars={{
            isLong: TimeRange.getIsLong(timeRange),
          }}
        >
          {isFirst && <Line />}
          <Content>
            <Circle color={TimeRange.getColor(timeRange)} />
            <Name>{timeRange.name}</Name>
            <Info>
              {!timeRange.start ? (
                <Placeholder>Enter a time</Placeholder>
              ) : !timeRange.end ? (
                <Value>
                  {getStartTimeValue({timeRange})}
                  <Placeholder>Enter Time</Placeholder>
                </Value>
              ) : (
                <Value>{getFullTimeValue({timeRange})}</Value>
              )}
              {!!timeRange.start && !!timeRange.end && (
                <Time>
                  {TimeRange.getIsLong(timeRange) && (
                    <LongWarning>This time looks a bit long:</LongWarning>
                  )}
                  <TimeMinutes
                    vars={{
                      isLong: TimeRange.getIsLong(timeRange),
                    }}
                  >
                    {TimeRange.getMinutesText(timeRange)}
                  </TimeMinutes>
                </Time>
              )}
            </Info>
            {!timeRange.start ? (
              <Button color={colors.blue.accent} onPress={handleOpen}>
                <Text>Add</Text>
              </Button>
            ) : !timeRange.end ? (
              <Button color={colors.blue.interactive} onPress={handleOpen}>
                <Text style={{color: colors.white}}>Finish Time</Text>
              </Button>
            ) : (
              <Button
                color={
                  TimeRange.getIsLong(timeRange)
                    ? colors.alpha(colors.orange.status, 0.2)
                    : colors.blue.accent
                }
                onPress={handleOpen}
              >
                <Text
                  vars={{
                    isLong: TimeRange.getIsLong(timeRange),
                  }}
                >
                  Edit
                </Text>
              </Button>
            )}
          </Content>
          <Line />
        </Container>
      </Touchable>
    )}
  />
);

export default EmployeeMoveTimeItem;
