// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App
import JobCommercialCatalogBlock from 'modules/Job/Show/components/JobCommercialCatalogBlock';
import JobCrewBlock from 'modules/Job/Show/components/JobCrewBlock';
import JobCrewNotesBlock from 'modules/Job/Show/components/JobCrewNotesBlock';
import JobCustomerBlock from 'modules/Job/Show/components/JobCustomerBlock';
import JobDescriptionBlock from 'modules/Job/Show/components/JobDescriptionBlock';
import JobDocumentsBlock from 'modules/Job/Show/components/JobDocumentsBlock';
import JobLocationsBlock from 'modules/Job/Show/components/JobLocationsBlock';
import JobMainBillBlock from 'modules/Job/Show/components/JobMainBillBlock';
import JobNextActionBlock from 'modules/Job/Show/components/JobNextActionBlock';
import JobPhotosBlock from 'modules/Job/Show/components/JobPhotosBlock';
import JobProjectBlock from 'modules/Job/Show/components/JobProjectBlock';
import JobTimesheetBlock from 'modules/Job/Show/components/JobTimesheetBlock';

const JobPageBlock = ({index, block, job, viewer, refetch}) => {
  switch (block.kind) {
    case 'CREW_JOB_COMMERCIAL_CATALOG':
      return <JobCommercialCatalogBlock job={job} />;
    case 'CREW_JOB_CREW':
      return <JobCrewBlock job={job} />;
    case 'CREW_JOB_CREW_NOTES':
      return <JobCrewNotesBlock job={job} />;
    case 'CREW_JOB_CUSTOMER':
      return <JobCustomerBlock job={job} />;
    case 'CREW_JOB_DOCUMENTS':
      return <JobDocumentsBlock job={job} />;
    case 'CREW_JOB_DESCRIPTION':
      return <JobDescriptionBlock job={job} />;
    case 'CREW_JOB_LOCATIONS':
      return <JobLocationsBlock job={job} />;
    case 'CREW_JOB_MAIN_BILL':
      if (job.hasJobFeatureCrewHideBilling) {
        return null;
      }
      return <JobMainBillBlock job={job} />;
    case 'CREW_JOB_NEXT_ACTION':
      return <JobNextActionBlock job={job} viewer={viewer} refetch={refetch} />;
    case 'CREW_JOB_PHOTOS':
      return <JobPhotosBlock job={job} refetch={refetch} />;
    case 'CREW_JOB_PROJECT':
      return <JobProjectBlock job={job} />;
    case 'CREW_JOB_TIMESHEET':
      return <JobTimesheetBlock job={job} refetch={refetch} />;
    default:
      return null;
  }
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobPageBlock.fragment = gql`
  ${JobCommercialCatalogBlock.fragment}
  ${JobCrewBlock.fragment}
  ${JobCrewNotesBlock.fragment}
  ${JobCustomerBlock.fragment}
  ${JobDescriptionBlock.fragment}
  ${JobDocumentsBlock.fragment}
  ${JobLocationsBlock.fragment}
  ${JobMainBillBlock.fragment}
  ${JobNextActionBlock.fragment}
  ${JobPhotosBlock.fragment}
  ${JobProjectBlock.fragment}
  ${JobTimesheetBlock.fragment}

  fragment JobPageBlock_Viewer on User {
    id
    ...JobNextActionBlock_Viewer
  }

  fragment JobPageBlock_Job on Job {
    id
    hasJobFeatureCrewHideBilling: hasJobFeature(kind: "CREW_HIDE_BILLING")
    ...JobCommercialCatalogBlock
    ...JobCrewBlock
    ...JobCrewNotesBlock
    ...JobCustomerBlock
    ...JobDescriptionBlock
    ...JobDocumentsBlock
    ...JobLocationsBlock
    ...JobMainBillBlock
    ...JobNextActionBlock_Job
    ...JobPhotosBlock
    ...JobProjectBlock
    ...JobTimesheetBlock
  }
`;

export default JobPageBlock;
