// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {User} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// Components
import {Avatar} from 'modules/App/components';

const Container = Styled.View`
  align-items: center;
`;

const Name = Styled.Text`
  ${Typography.Body3}
  margin-top: 5px;
`;

const JobUserItem = ({size, jobUser}) => (
  <Container>
    <Avatar text={User.getInitials(jobUser.user)} size={size} color={colors.blue.interactive} />
    <Name numberOfLines={1}>{User.getFullName(jobUser.user)}</Name>
  </Container>
);

// --------------------------------------------------
// Props
// --------------------------------------------------
JobUserItem.propTypes = {
  jobUser: PropTypes.object.isRequired,
  size: PropTypes.number,
};

JobUserItem.defaultProps = {
  size: 60,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobUserItem.fragment = gql`
  fragment JobUserItem on JobUser {
    id
    jobId
    userId
    user {
      id
      firstName
      lastName
    }
  }
`;

export default JobUserItem;
