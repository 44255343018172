// Libraries
import React from 'react';

// App
import {CurrencyInput, Styled} from '@supermove/components';
import {colors, fontWeight, Typography} from '@supermove/styles';

// Components
import {Field, Modal} from 'modules/App/components';

const Container = Styled.View`
  padding: 40px;
  width: 600px;
  background-color: ${colors.white};
`;

const Name = Styled.H4`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
  text-align: center;
`;

const Subtitle = Styled.H6`
  margin-top: 3px;
  color: ${colors.gray.primary};
  text-align: center;
`;

const Content = Styled.View`
  margin-vertical: 20px;
`;

const TextInput = Styled.TextInput.H7`
  margin-top: 3px;
`;

const Actions = Styled.View`
  margin-top: 20px;
  flex-direction: row;
  justify-content: center;
`;

const Button = Styled.Button`
  height: 40px;
  width: 100px;
`;

const ButtonSpace = Styled.View`
  width: 10px;
`;

const Text = Styled.Text`
  ${Typography.Label2}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const PartialPaymentModal = ({form, trigger}) => (
  <Modal trigger={trigger}>
    {({handleClose}) => (
      <Container>
        <Name>Split Payment</Name>
        <Subtitle>How much would you like to pay next?</Subtitle>
        <Content>
          <Field
            {...form}
            component={CurrencyInput}
            name={'amount'}
            label={'Amount'}
            input={{
              autoFocus: true,
              returnKeyType: 'done',
              component: TextInput,
              placeholder: 'Enter amount',
              setFieldValue: form.setFieldValue,
              setFieldTouched: form.setFieldTouched,
            }}
          />
        </Content>
        <Actions>
          <Button color={colors.gray.secondary} onPress={handleClose}>
            <Text>Cancel</Text>
          </Button>
          <ButtonSpace />
          <Button onPress={handleClose}>
            <Text>Save</Text>
          </Button>
        </Actions>
      </Container>
    )}
  </Modal>
);

export default PartialPaymentModal;
