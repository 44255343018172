// Libraries
import React from 'react';

// App
import {Emoji, Modal, Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

const Container = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const Wrapper = Styled.View`
  width: ${(props) => props.width}px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
  border-radius: 5px;
`;

const Circle = Styled.View`
  align-self: center;
  align-items: center;
  justify-content: center;
  top: -20px;
  height: 80px;
  width: 80px;
  border-radius: 50px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
`;

const EmojiText = Styled.H1`
  margin-top: 5px;
`;

const Content = Styled.View`
  margin-horizontal: 30px;
`;

const Title = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
  text-align: center;
`;

const Subtitle = Styled.H7`
  margin-top: 15px;
  color: ${colors.gray.tertiary};
  text-align: center;
`;

const Actions = Styled.View`
  align-items: center;
  margin-vertical: 20px;
`;

const SubmitButton = Styled.Button`
  flex-direction: row;
  align-items: center;
  height: 40px;
  padding-horizontal: 20px;
`;

const SubmitText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const PaymentErrorModal = ({isOpen, handleClose, title, subtitle}) => (
  <Modal.Content isOpen={isOpen} onClose={handleClose}>
    <Container pointerEvents={'box-none'}>
      <Wrapper width={400}>
        <Circle>
          <Emoji component={EmojiText} name={'warning'} />
        </Circle>
        <Content>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
          <Actions>
            <SubmitButton onPress={handleClose}>
              <SubmitText>Okay</SubmitText>
            </SubmitButton>
          </Actions>
        </Content>
      </Wrapper>
    </Container>
  </Modal.Content>
);

export default PaymentErrorModal;
