// Libraries
import React from 'react';

// Components
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

const CompleteJobMutation = ({jobId, onSuccess, onError, children}) => (
  <Mutation
    variables={{
      jobId,
    }}
    mutation={CompleteJobMutation.mutation}
    onCompleted={({completeJob: {job, errors}}) => {
      if (job) {
        onSuccess(job);
      } else {
        onError(errors);
      }
    }}
  >
    {(handleSubmit, {loading, error}) => children({loading, handleSubmit})}
  </Mutation>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
CompleteJobMutation.mutation = gql`
  mutation CompleteJobMutation(
    $jobId: Int,
  ) {
    completeJob(
      jobId: $jobId,
    ) {
      ${gql.errors}
      job {
        id
        uuid
      }
    }
  }
`;

export default CompleteJobMutation;
