// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

const Container = Styled.View`
`;

const Content = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 10px;
  padding-horizontal: 10px;
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const Info = Styled.View`
  flex: 1;
`;

const Name = Styled.H5`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const Actions = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Button = Styled.Button`
  width: 80px;
`;

const Text = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const JobCustomDocumentTemplateItem = ({text, onPress}) => {
  return (
    <Container>
      <Content>
        <Info>
          <Name>{text}</Name>
        </Info>
        <Actions>
          <Button color={colors.blue.interactive} onPress={onPress}>
            <Text>New</Text>
          </Button>
        </Actions>
      </Content>
      <Line />
    </Container>
  );
};

export default JobCustomDocumentTemplateItem;
