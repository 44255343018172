// Libraries
import React from 'react';

// Supermove
import {Modal, ScrollView, Styled} from '@supermove/components';
import {EmployeeForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {Organization} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';

// App
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';
import UserRole from '@shared/modules/User/enums/UserRole';
import {useCreateCrewEmployeeForm} from 'modules/User/logic';

const Wrapper = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Container = Styled.View`
  padding-vertical: 16px;
  width: 600px;
  background-color: ${colors.white};
`;

const Name = Styled.H4`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
  text-align: center;
`;

const Subtitle = Styled.H6`
  margin-top: 4px;
  color: ${colors.gray.primary};
  text-align: center;
`;

const Content = Styled.View`
  margin-vertical: 16px;
  height: 280px;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
`;

const Rows = Styled.View`
  padding: 16px;
`;

const Row = Styled.View`
  flex-direction: row;
  z-index: ${(props) => 100 - props.index};
`;

const RowSpace = Styled.View`
  height: 16px;
`;

const FieldSpace = Styled.View`
  width: 8px;
`;

const Actions = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin-horizontal: 16px;
`;

const Button = Styled.LoadingButton`
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const ButtonSpace = Styled.View`
  width: 8px;
`;

const PositionField = ({organization, form}) => {
  const field = 'employeeForm.moverPositionIds.0';

  const moverPositions = organization.features.isEnabledMoverPositionMultibranch
    ? Organization.getCompanySettingsMoverPositionsByRole({organization, role: UserRole.EMPLOYEE})
    : organization.moverPositions;

  return (
    <FieldInput
      {...form}
      component={DropdownInput}
      name={field}
      errorName={'employeeForm.moverPositionIds'}
      label={'Position'}
      isResponsive
      isRequired
      input={{
        options: moverPositions.map((moverPosition) => ({
          label: moverPosition.name,
          value: Number(moverPosition.id),
        })),
        placeholder: 'Select one',
        setFieldValue: form.setFieldValue,
        style: {
          flex: 1,
        },
      }}
      style={{flex: 1}}
    />
  );
};

const CreateCrewEmployeeModal = ({organization, isOpen, onClose}) => {
  const {form, submitting, handleSubmit} = useCreateCrewEmployeeForm({
    employeeForm: EmployeeForm.new({organizationId: organization.id}),
    onSuccess: () => onClose(),
    onError: (errors) => console.log({errors}),
  });

  return (
    <Modal.Content isOpen={isOpen} onClose={onClose}>
      <Wrapper pointerEvents={'box-none'}>
        <Container>
          <Name>Add New Mover</Name>
          <Subtitle>Fill in the details to add a new mover.</Subtitle>
          <Content>
            <ScrollView style={{flex: 1}}>
              <Rows>
                <Row index={0}>
                  <FieldInput
                    {...form}
                    name={'employeeForm.names.0'}
                    label={'First Name'}
                    input={{
                      placeholder: 'Enter first name',
                    }}
                    style={{
                      flex: 1,
                    }}
                    isResponsive
                    isRequired
                  />
                  <FieldSpace />
                  <FieldInput
                    {...form}
                    name={'employeeForm.names.1'}
                    label={'Last Name'}
                    input={{
                      placeholder: 'Enter last name',
                    }}
                    style={{
                      flex: 1,
                    }}
                    isResponsive
                  />
                </Row>
                <RowSpace />
                <Row index={1}>
                  <PositionField organization={organization} form={form} />
                </Row>
                <RowSpace />
                <Row index={2}>
                  <FieldInput
                    {...form}
                    name={'employeeForm.branchCode'}
                    label={'Branch Code'}
                    input={{
                      placeholder: 'Enter branch code',
                    }}
                    style={{
                      flex: 1,
                    }}
                    isResponsive
                  />
                </Row>
              </Rows>
            </ScrollView>
          </Content>
          <Actions>
            <Button color={colors.gray.secondary} onPress={onClose}>
              <Text>Cancel</Text>
            </Button>
            <ButtonSpace />
            <Button loading={submitting} onPress={handleSubmit}>
              <Text>Save</Text>
            </Button>
          </Actions>
        </Container>
      </Wrapper>
    </Modal.Content>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateCrewEmployeeModal.fragment = gql`
  ${Organization.getCompanySettingsMoverPositionsByRole.fragment}

  fragment CreateCrewEmployeeModal on Organization {
    id
    moverPositions {
      id
      name
    }
    features {
      isEnabledMoverPositionMultibranch: isEnabled(feature: "MOVER_POSITION_MULTIBRANCH")
    }
    ...Organization_getCompanySettingsMoverPositionsByRole
  }
`;

export default CreateCrewEmployeeModal;
