// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';

// App
import JobMoveTimesheetView from 'modules/Job/Crew/Timesheet/components/JobMoveTimesheetView';

// Components
import CommercialCustomerInformationView from './CommercialCustomerInformationView';
import CommercialHeadingInformationNorCal from './CommercialHeadingInformationNorCal';
import CommercialMoveTimesheetTable from './CommercialMoveTimesheetTable';
import DocumentBillingInformation from './DocumentBillingInformation';
import ProvisionalJobBillView from './ProvisionalJobBillView';
import ValuationDeclarationTable from './ValuationDeclarationTable';

const Wrapper = Styled.View`
  margin-bottom: 10px;
  z-index: ${(props) => 100 - props.index};
`;

const SectionTitle = Styled.Text`
  font-size: 14px;
  line-height: 19px;
  ${fontWeight(700)}
  color: ${colors.gray.secondary};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const SectionBody = Styled.Text`
  margin-top: 5px;
  font-size: 14px;
  line-height: 19px;
  color: ${colors.gray.primary};
`;

const DocumentItemSection = ({index, document, documentItem, section}) => {
  switch (documentItem.kind) {
    case 'TITLE':
      return (
        <Wrapper index={index}>
          <SectionTitle>{documentItem.text}</SectionTitle>
        </Wrapper>
      );
    case 'PARAGRAPH':
      return (
        <Wrapper index={index}>
          <SectionBody>{documentItem.text}</SectionBody>
        </Wrapper>
      );

    // Custom components rendering different sections of different documents.
    case 'BILLING_INFORMATION':
      return (
        <Wrapper index={index}>
          <DocumentBillingInformation
            isEstimate
            showBillRules
            hidePrices={false}
            job={document.job}
          />
        </Wrapper>
      );
    case 'COMMERCIAL_CUSTOMER_INFORMATION':
      return (
        <Wrapper index={index} style={{paddingVertical: 20, alignItems: 'center'}}>
          <CommercialCustomerInformationView job={document.job} />
        </Wrapper>
      );
    case 'COMMERCIAL_HEADING_INFORMATION_NOR_CAL':
      return (
        <Wrapper index={index}>
          <CommercialHeadingInformationNorCal job={document.job} />
        </Wrapper>
      );
    case 'COMMERCIAL_MOVE_TIMESHEET_TABLE':
      return (
        <Wrapper index={index} style={{paddingVertical: 20}}>
          <CommercialMoveTimesheetTable job={document.job} />
        </Wrapper>
      );
    case 'PROVISIONAL_JOB_BILL':
      return (
        <Wrapper index={index}>
          <ProvisionalJobBillView
            hidePrices
            isEstimate={false}
            showBillRules={false}
            job={document.job}
          />
        </Wrapper>
      );
    case 'TIMESHEET_INFORMATION':
      return (
        <Wrapper index={index} style={{marginBottom: 20}}>
          <JobMoveTimesheetView job={document.job} />
        </Wrapper>
      );
    case 'VALUATION_DECLARATION_TABLE':
      return (
        <Wrapper index={index} style={{marginBottom: 20}}>
          <ValuationDeclarationTable job={document.job} />
        </Wrapper>
      );
    default:
      if (section) {
        return <Wrapper index={index}>{section(documentItem.kind) || null}</Wrapper>;
      }

      // Otherwise return nothing (no section render function).
      return null;
  }
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentItemSection.fragment = gql`
  ${CommercialCustomerInformationView.fragment}
  ${CommercialHeadingInformationNorCal.fragment}
  ${CommercialMoveTimesheetTable.fragment}
  ${DocumentBillingInformation.fragment}
  ${JobMoveTimesheetView.fragment}
  ${ProvisionalJobBillView.fragment}
  ${ValuationDeclarationTable.fragment}

  fragment DocumentItemSection_Document on Document {
    id
    job {
      id
      ...CommercialCustomerInformationView
      ...CommercialHeadingInformationNorCal
      ...CommercialMoveTimesheetTable
      ...DocumentBillingInformation
      ...JobMoveTimesheetView
      ...ProvisionalJobBillView
      ...ValuationDeclarationTable
    }
  }

  fragment DocumentItemSection on DocumentItem {
    kind
    text
  }
`;

export default DocumentItemSection;
