/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Job, Location} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';
import {Location as LocationUtils} from '@supermove/utils';

// App
import FieldValue from 'modules/App/components/FieldValue';

const Container = Styled.View`
  padding-vertical: 20px;
  padding-horizontal: 30px;
`;

const Title = Styled.H4`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Section = Styled.View`
  flex-direction: row;
`;

const Column = Styled.View`
  flex: 1;
`;

const ColumnSpace = Styled.View`
  width: 20px;
`;

const Row = Styled.View`
  flex-direction: row;
  margin-top: ${({vars = {}}) => (vars.isFirst ? 10 : 15)}px;
`;

const JobLocationsBlock = ({job}) => {
  return (
    <Container>
      <Title>Locations</Title>
      <Section>
        <Column>
          {job.locations.map((location, index) => (
            <Row key={location.id} vars={{isFirst: index === 0}}>
              <Column>
                <FieldValue
                  newTab
                  label={Location.getDisplayName(location, {count: job.locations.length})}
                  value={Location.getDisplayLocation(location)}
                  link={LocationUtils.createGoogleMapsUrl(location.address)}
                  style={{flex: 1}}
                />
              </Column>
              <ColumnSpace />
              <Column>
                <FieldValue
                  label={Job.FIELDS.LOCATION_NOTES}
                  value={location.notes}
                  empty={'None'}
                  style={{flex: 1}}
                />
              </Column>
            </Row>
          ))}
          <Row>
            <FieldValue
              label={'Total Distance'}
              value={Job.getTotalDistanceText(job)}
              style={{flex: 1}}
            />
          </Row>
        </Column>
      </Section>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobLocationsBlock.fragment = gql`
  ${Job.getTotalDistanceText.fragment}
  ${Location.getDisplayLocation.fragment}
  ${Location.getDisplayName.fragment}

  fragment JobLocationsBlock on Job {
    id
    locations {
      id
      address
      notes
      ...Location_getDisplayLocation
      ...Location_getDisplayName
    }
    ...Job_getTotalDistanceText
  }
`;

export default JobLocationsBlock;
