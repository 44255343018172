// Libraries
import React from 'react';

// App
import {Emoji, Modal, Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

// Components
import UnsetCurrentJobMutation from './UnsetCurrentJobMutation';

const Container = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const Wrapper = Styled.View`
  width: ${(props) => props.width}px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
  border-radius: 5px;
`;

const Circle = Styled.View`
  align-self: center;
  align-items: center;
  justify-content: center;
  top: -20px;
  height: 80px;
  width: 80px;
  border-radius: 50px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
`;

const EmojiText = Styled.H1`
  margin-top: 5px;
`;

const Content = Styled.View`
  margin-horizontal: 30px;
`;

const Title = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
  text-align: center;
`;

const Subtitle = Styled.H7`
  margin-top: 15px;
  color: ${colors.gray.tertiary};
  text-align: center;
`;

const Actions = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-vertical: 20px;
`;

const Button = Styled.LoadingButton`
  flex-direction: row;
  align-items: center;
  height: 40px;
  padding-horizontal: 10px;
`;

const Text = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const ButtonSpace = Styled.View`
  width: 10px;
`;

const ConfirmUnsetCurrentJobModal = ({userId, onSuccess, onError, trigger}) => (
  <Modal trigger={trigger}>
    {({handleClose}) => (
      <Container pointerEvents={'box-none'}>
        <Wrapper width={400}>
          <Circle>
            <Emoji component={EmojiText} name={'warning'} />
          </Circle>
          <Content>
            <Title>Confirm Stop Job</Title>
            <Subtitle>Are you sure you'd like to stop your current job?</Subtitle>
            <Actions>
              <Button color={colors.gray.secondary} onPress={handleClose}>
                <Text>Cancel</Text>
              </Button>
              <ButtonSpace />
              <UnsetCurrentJobMutation
                userId={userId}
                onSuccess={() => {
                  handleClose();
                  onSuccess();
                }}
                onError={onError}
              >
                {({loading, handleSubmit}) => (
                  <Button loading={loading} color={colors.blue.interactive} onPress={handleSubmit}>
                    <Text>Confirm</Text>
                  </Button>
                )}
              </UnsetCurrentJobMutation>
            </Actions>
          </Content>
        </Wrapper>
      </Container>
    )}
  </Modal>
);

// --------------------------------------------------
// Props
// --------------------------------------------------
ConfirmUnsetCurrentJobModal.propTypes = {};

ConfirmUnsetCurrentJobModal.defaultProps = {};

export default ConfirmUnsetCurrentJobModal;
