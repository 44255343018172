// Libraries
import {createStackNavigator, createSwitchWrapperNavigator} from '@supermove/navigation';

// App
import {AuthenticatedWrapper, RouterWrapper} from 'modules/App/components';
import OrganizationAuthentication from 'modules/Authentication/Organization';
import UsersAuthentication from 'modules/Authentication/Users';
import Home from 'modules/Home';
import CrewCaptureInventoryPage from 'modules/Inventory/CrewCaptureInventoryPage';
import ActionsJob from 'modules/Job/Actions';
import JobAttachmentsV2 from 'modules/Job/Attachment/JobAttachmentsV2';
import UpdateAttachmentJob from 'modules/Job/Attachment/Update';
import ViewCustomerBillJob from 'modules/Job/Bill/Customer/View';
import PreparePostMoveListDocumentCrewJob from 'modules/Job/Crew/Document/List/PostMove/Prepare';
import PreparePreMoveListDocumentCrewJob from 'modules/Job/Crew/Document/List/PreMove/Prepare';
import StartTimeCommercialShowDocumentCrewJob from 'modules/Job/Crew/Document/Show/Commercial/StartTime';
import PrepareDuringMoveShowDocumentCrewJob from 'modules/Job/Crew/Document/Show/DuringMove/Prepare';
import PreparePostMoveShowDocumentCrewJob from 'modules/Job/Crew/Document/Show/PostMove/Prepare';
import PreparePreMoveShowDocumentCrewJob from 'modules/Job/Crew/Document/Show/PreMove/Prepare';
import PreparePostMoveShowDocumentSignatureCrewJob from 'modules/Job/Crew/DocumentSignature/Show/PostMove/Prepare';
import PreparePreMoveShowDocumentSignatureCrewJob from 'modules/Job/Crew/DocumentSignature/Show/PreMove/Prepare';
import PassFinishCrewJob from 'modules/Job/Crew/Finish/Pass';
import LocationCrewJob from 'modules/Job/Crew/Location';
import PositionCrewJob from 'modules/Job/Crew/Position';
import BillingProjectCrewJobPage from 'modules/Job/Crew/Project/Billing/BillingProjectCrewJobPage';
import RatesCrewJob from 'modules/Job/Crew/Rates';
import PassStartCrewJob from 'modules/Job/Crew/Start/Pass';
import AssignMoversPage from 'modules/Job/Crew/Timesheet/AssignMoversPage';
import BillableTimesheetActionPage from 'modules/Job/Crew/Timesheet/BillableTimesheetActionPage';
import BillableTimesheetDuringMovePage from 'modules/Job/Crew/Timesheet/BillableTimesheetDuringMovePage';
import BillableTimesheetPreMovePage from 'modules/Job/Crew/Timesheet/BillableTimesheetPreMovePage';
import EditCommercialTimesheetCrewJob from 'modules/Job/Crew/Timesheet/Commercial/Edit';
import PayrollTimesheetActionPage from 'modules/Job/Crew/Timesheet/PayrollTimesheetActionPage';
import PayrollTimesheetAfterMovePage from 'modules/Job/Crew/Timesheet/PayrollTimesheetAfterMovePage';
import PayrollTimesheetPreMovePage from 'modules/Job/Crew/Timesheet/PayrollTimesheetPreMovePage';
import EditResidentialTimesheetCrewJob from 'modules/Job/Crew/Timesheet/Residential/Edit';
import TruckCrewJob from 'modules/Job/Crew/Truck';
import EditUsersCrewJob from 'modules/Job/Crew/User/Edit';
import ConfirmCrewSlotsForJobPage from 'modules/Job/CrewSlot/Confirm/ConfirmCrewSlotsForJobPage';
import PaymentPreMoveBillCustomerJob from 'modules/Job/Customer/Bill/PreMove/Payment';
import ViewPreMoveBillCustomerJob from 'modules/Job/Customer/Bill/PreMove/View';
import AfterTimesheetListDocumentCustomerJob from 'modules/Job/Customer/Document/List/AfterTimesheet';
import PostMoveListDocumentCustomerJob from 'modules/Job/Customer/Document/List/PostMove';
import SignDocumentCustomerJob from 'modules/Job/Customer/Document/Sign';
import ViewDocumentCustomerJob from 'modules/Job/Customer/Document/View';
import AfterTimesheetListDocumentV2CustomerJobPage from 'modules/Job/Customer/DocumentV2/List/AfterTimesheet/AfterTimesheetListDocumentV2CustomerJobPage';
import PostMoveListDocumentV2CustomerJobPage from 'modules/Job/Customer/DocumentV2/List/PostMove/PostMoveListDocumentV2CustomerJobPage';
import SignDocumentV2CustomerJobPage from 'modules/Job/Customer/DocumentV2/Sign/SignDocumentV2CustomerJobPage';
import ViewDocumentV2CustomerJobPage from 'modules/Job/Customer/DocumentV2/View/ViewDocumentV2CustomerJobPage';
import PassFinishCustomerJob from 'modules/Job/Customer/Finish/Pass';
import PaymentBillingProjectCustomerJobPage from 'modules/Job/Customer/Project/Billing/Payment/PaymentBillingProjectCustomerJobPage';
import ViewPreMoveBillingProjectCustomerJobPage from 'modules/Job/Customer/Project/Billing/PreMove/View/ViewPreMoveBillingProjectCustomerJobPage';
import ReviewBillingProjectCustomerJobPage from 'modules/Job/Customer/Project/Billing/Review/ReviewBillingProjectCustomerJobPage';
import StartCustomerJob from 'modules/Job/Customer/Start';
import PassStartCustomerJob from 'modules/Job/Customer/Start/Pass';
import ChangeOrderDocumentJob from 'modules/Job/Document/ChangeOrder';
import ListDocumentJob from 'modules/Job/Document/List';
import RiskyMoveDocumentJob from 'modules/Job/Document/RiskyMove';
import SignDocumentJob from 'modules/Job/Document/Sign';
import ConfirmJobUsersForJobPage from 'modules/Job/JobUser/Confirm/ConfirmJobUsersForJobPage';
import ListJobs from 'modules/Job/List';
import EditBillingProjectJobPage from 'modules/Job/Project/Billing/Edit/EditBillingProjectJobPage';
import AuthorizeDotNetMethodNewPaymentBillingProjectJobPage from 'modules/Job/Project/Billing/Payment/New/Method/AuthorizeDotNet/AuthorizeDotNetMethodNewPaymentBillingProjectJobPage';
import CashMethodNewPaymentBillingProjectJobPage from 'modules/Job/Project/Billing/Payment/New/Method/Cash/CashMethodNewPaymentBillingProjectJobPage';
import CheckMethodNewPaymentBillingProjectJobPage from 'modules/Job/Project/Billing/Payment/New/Method/Check/CheckMethodNewPaymentBillingProjectJobPage';
import CreditCardMethodNewPaymentBillingProjectJobPage from 'modules/Job/Project/Billing/Payment/New/Method/CreditCard/CreditCardMethodNewPaymentBillingProjectJobPage';
import ExternalMethodNewPaymentBillingProjectJobPage from 'modules/Job/Project/Billing/Payment/New/Method/External/ExternalMethodNewPaymentBillingProjectJobPage';
import InvoiceMethodNewPaymentBillingProjectJobPage from 'modules/Job/Project/Billing/Payment/New/Method/Invoice/InvoiceMethodNewPaymentBillingProjectJobPage';
import OtherMethodNewPaymentBillingProjectJobPage from 'modules/Job/Project/Billing/Payment/New/Method/Other/OtherMethodNewPaymentBillingProjectJobPage';
import PayEngineCreditCardMethodNewPaymentBillingProjectJobPage from 'modules/Job/Project/Billing/Payment/New/Method/PayEngineCreditCard/PayEngineCreditCardMethodNewPaymentBillingProjectJobPage';
import PayEngineSaveCardMethodNewPaymentBillingProjectJobPage from 'modules/Job/Project/Billing/Payment/New/Method/PayEngineSaveCard/PayEngineSaveCardMethodNewPaymentBillingProjectJobPage';
import PaypalMethodNewPaymentBillingProjectJobPage from 'modules/Job/Project/Billing/Payment/New/Method/Paypal/PaypalMethodNewPaymentBillingProjectJobPage';
import SaveCardMethodNewPaymentBillingProjectJobPage from 'modules/Job/Project/Billing/Payment/New/Method/SaveCard/SaveCardMethodNewPaymentBillingProjectJobPage';
import VenmoMethodNewPaymentBillingProjectJobPage from 'modules/Job/Project/Billing/Payment/New/Method/Venmo/VenmoMethodNewPaymentBillingProjectJobPage';
import NewPaymentBillingProjectJobPage from 'modules/Job/Project/Billing/Payment/New/NewPaymentBillingProjectJobPage';
import TipNewPaymentBillingProjectJobPage from 'modules/Job/Project/Billing/Payment/New/Tip/TipNewPaymentBillingProjectJobPage';
import ShowJob from 'modules/Job/Show';
import ConfirmUsersJob from 'modules/Job/User/Confirm';
import Settings from 'modules/Settings';
import SettingsCodePush from 'modules/Settings/CodePush';
import SettingsDebug from 'modules/Settings/Debug';
import ConnectReaderStripeSettings from 'modules/Settings/Stripe/Reader/Connect';
import StatusReaderStripeSettings from 'modules/Settings/Stripe/Reader/Status';
import SettingsUpdate from 'modules/Settings/Update';
import Start from 'modules/Start';

const Authentication = createStackNavigator(
  {
    OrganizationAuthentication: {
      path: 'organization',
      screen: OrganizationAuthentication,
    },
    UsersAuthentication: {
      path: 'users',
      screen: UsersAuthentication,
    },
  },
  {
    initialRouteName: 'OrganizationAuthentication',
    headerMode: 'none',
    backBehavior: 'history',
  },
);

const App = createStackNavigator(
  {
    Home: {
      path: '',
      screen: Home,
    },
    Settings: {
      path: 'settings',
      screen: Settings,
    },
    SettingsDebug: {
      path: 'settings/debug',
      screen: SettingsDebug,
    },
    SettingsCodePush: {
      path: 'settings/code-push',
      screen: SettingsCodePush,
    },
    SettingsUpdate: {
      path: 'settings/update',
      screen: SettingsUpdate,
    },

    // Stripe Terminal
    StatusReaderStripeSettings: {
      path: 'settings/stripe/readers/status',
      screen: StatusReaderStripeSettings,
    },
    ConnectReaderStripeSettings: {
      path: 'settings/stripe/readers/connect',
      screen: ConnectReaderStripeSettings,
    },

    // Jobs
    ListJobs: {
      path: 'jobs',
      screen: ListJobs,
    },
    ShowJob: {
      path: 'jobs/:uuid',
      screen: ShowJob,
    },

    // Actions
    ActionsJob: {
      path: 'jobs/:uuid/actions',
      screen: ActionsJob,
    },
    JobTimesheetActionJob: {
      path: 'jobs/:uuid/actions/job-timesheet',
      screen: BillableTimesheetActionPage,
    },
    CrewTimesheetActionJob: {
      path: 'jobs/:uuid/actions/crew-timesheet',
      screen: PayrollTimesheetActionPage,
    },

    // Nested Pages
    MoversCrewJob: {
      path: 'jobs/:uuid/crew/movers',
      screen: AssignMoversPage,
    },
    UpdateAttachmentJob: {
      path: 'jobs/:uuid/attachments/:attachmentUuid/edit',
      screen: UpdateAttachmentJob,
    },
    NewJobAttachmentsV2: {
      path: 'jobs/:jobUuid/attachments/upload',
      screen: JobAttachmentsV2,
    },

    // Crew - Pre Move
    ConfirmJobUsersForJobPage: {
      path: 'jobs/:uuid/crews/movers/confirm',
      screen: ConfirmJobUsersForJobPage,
    },
    ConfirmCrewSlotsForJobPage: {
      path: 'jobs/:uuid/crews/trucks/confirm',
      screen: ConfirmCrewSlotsForJobPage,
    },
    TruckCrewJob: {
      // DEPRECATED(mark): This is no longer used.
      path: 'jobs/:uuid/crew/truck',
      screen: TruckCrewJob,
    },
    PositionCrewJob: {
      path: 'jobs/:uuid/crew/position',
      screen: PositionCrewJob,
    },
    LocationCrewJob: {
      path: 'jobs/:uuid/crew/location',
      screen: LocationCrewJob,
    },
    JobTimesheetPreMoveCrewJob: {
      path: 'jobs/:uuid/crew/pre-move/job-timesheet',
      screen: BillableTimesheetPreMovePage,
    },
    CrewTimesheetPreMoveCrewJob: {
      path: 'jobs/:uuid/crew/pre-move/crew-timesheet',
      screen: PayrollTimesheetPreMovePage,
    },
    PreparePreMoveListDocumentCrewJob: {
      // DEPRECATED(mark): This is no longer used.
      path: 'jobs/:uuid/crew/documents/pre-move/prepare',
      screen: PreparePreMoveListDocumentCrewJob,
    },
    PreparePreMoveShowDocumentCrewJob: {
      // DEPRECATED(mark): This is no longer used.
      path: 'jobs/:uuid/crew/documents/:documentUuid/pre-move/prepare',
      screen: PreparePreMoveShowDocumentCrewJob,
    },
    StartTimeCommercialShowDocumentCrewJob: {
      // Specific for the COMMERCIAL_BILL_OF_LADING document.
      path: 'jobs/:uuid/crew/documents/:documentUuid/commercial/start-time',
      screen: StartTimeCommercialShowDocumentCrewJob,
    },
    PreparePreMoveShowDocumentSignatureCrewJob: {
      path: 'jobs/:uuid/crew/documents/:documentUuid/pre-move/:index/prepare',
      screen: PreparePreMoveShowDocumentSignatureCrewJob,
    },
    PassStartCrewJob: {
      path: 'jobs/:uuid/crew/start/pass',
      screen: PassStartCrewJob,
    },

    // Customer - Pre Move
    StartCustomerJob: {
      path: 'jobs/:uuid/customer/start',
      screen: StartCustomerJob,
    },
    ViewPreMoveBillCustomerJob: {
      path: 'jobs/:uuid/customer/bills/:billUuid/pre-move/view',
      screen: ViewPreMoveBillCustomerJob,
    },
    // Enabled by project billing feature flag
    ViewPreMoveBillingProjectCustomerJobPage: {
      path: 'jobs/:uuid/customer/project/billing/pre-move/view',
      screen: ViewPreMoveBillingProjectCustomerJobPage,
    },
    PaymentPreMoveBillCustomerJob: {
      path: 'jobs/:uuid/customer/bills/:billUuid/pre-move/payment',
      screen: PaymentPreMoveBillCustomerJob,
    },
    PassStartCustomerJob: {
      path: 'jobs/:uuid/customer/start/pass',
      screen: PassStartCustomerJob,
    },

    // Customer - After Timesheet
    AfterTimesheetListDocumentCustomerJob: {
      path: 'jobs/:uuid/customer/documents/after-timesheet',
      screen: AfterTimesheetListDocumentCustomerJob,
    },
    AfterTimesheetListDocumentV2CustomerJobPage: {
      path: 'jobs/:jobUuid/customer/documents/v2/after-timesheet',
      screen: AfterTimesheetListDocumentV2CustomerJobPage,
    },

    // Customer - Post Move
    PostMoveListDocumentCustomerJob: {
      path: 'jobs/:uuid/customer/documents/post-move',
      screen: PostMoveListDocumentCustomerJob,
    },
    PostMoveListDocumentV2CustomerJobPage: {
      path: 'jobs/:jobUuid/customer/documents/v2/post-move',
      screen: PostMoveListDocumentV2CustomerJobPage,
    },

    // Customer - Documents
    SignDocumentCustomerJob: {
      path: 'jobs/:uuid/customer/documents/:documentUuid/:step/sign',
      screen: SignDocumentCustomerJob,
    },
    SignDocumentV2CustomerJobPage: {
      path: 'jobs/:jobUuid/customer/documents/v2/:documentUuid/:step/sign',
      screen: SignDocumentV2CustomerJobPage,
    },
    ViewDocumentCustomerJob: {
      path: 'jobs/:uuid/customer/documents/:documentUuid/view',
      screen: ViewDocumentCustomerJob,
    },
    ViewDocumentV2CustomerJobPage: {
      path: 'jobs/:jobUuid/customer/documents/v2/:documentUuid/view',
      screen: ViewDocumentV2CustomerJobPage,
    },

    // Crew - During Move
    EditResidentialTimesheetCrewJob: {
      path: 'jobs/:uuid/crew/timesheet/residential/edit',
      screen: EditResidentialTimesheetCrewJob,
    },
    EditCommercialTimesheetCrewJob: {
      path: 'jobs/:uuid/crew/timesheet/commercial/edit',
      screen: EditCommercialTimesheetCrewJob,
    },
    EditUsersCrewJob: {
      path: 'jobs/:uuid/crew/users/edit',
      screen: EditUsersCrewJob,
    },
    PrepareDuringMoveShowDocumentCrewJob: {
      path: 'jobs/:uuid/crew/documents/:documentUuid/during-move/prepare',
      screen: PrepareDuringMoveShowDocumentCrewJob,
    },
    JobTimesheetDuringMoveCrewJob: {
      path: 'jobs/:uuid/crew/during-move/job-timesheet',
      screen: BillableTimesheetDuringMovePage,
    },

    // Crew - Post Move
    PreparePostMoveListDocumentCrewJob: {
      // DEPRECATED(mark): This is no longer used.
      path: 'jobs/:uuid/crew/documents/post-move/prepare',
      screen: PreparePostMoveListDocumentCrewJob,
    },
    PreparePostMoveShowDocumentCrewJob: {
      // DEPRECATED(mark): This is no longer used.
      path: 'jobs/:uuid/crew/documents/:documentUuid/post-move/prepare',
      screen: PreparePostMoveShowDocumentCrewJob,
    },
    PreparePostMoveShowDocumentSignatureCrewJob: {
      path: 'jobs/:uuid/crew/documents/:documentUuid/post-move/:index/prepare',
      screen: PreparePostMoveShowDocumentSignatureCrewJob,
    },
    RatesCrewJob: {
      path: 'jobs/:uuid/crew/rates',
      screen: RatesCrewJob,
    },
    // Enabled by project billing feature flag
    BillingProjectCrewJobPage: {
      path: 'jobs/:uuid/crew/project/billing',
      screen: BillingProjectCrewJobPage,
    },
    PassFinishCrewJob: {
      path: 'jobs/:uuid/crew/finish/pass',
      screen: PassFinishCrewJob,
    },
    // Enabled by additional payments feature flag
    EditBillingProjectJobPage: {
      path: 'jobs/:jobUuid/project/billing/edit',
      screen: EditBillingProjectJobPage,
    },
    NewPaymentBillingProjectJobPage: {
      path: 'jobs/:jobUuid/project/billing/payments/new',
      screen: NewPaymentBillingProjectJobPage,
    },
    TipNewPaymentBillingProjectJobPage: {
      path: 'jobs/:jobUuid/project/billing/payments/new/tip',
      screen: TipNewPaymentBillingProjectJobPage,
    },
    // Project billing payment methods
    CashMethodNewPaymentBillingProjectJobPage: {
      path: 'jobs/:jobUuid/project/billing/payments/new/method/cash',
      screen: CashMethodNewPaymentBillingProjectJobPage,
    },
    CheckMethodNewPaymentBillingProjectJobPage: {
      path: 'jobs/:jobUuid/project/billing/payments/new/method/check',
      screen: CheckMethodNewPaymentBillingProjectJobPage,
    },
    AuthorizeDotNetMethodNewPaymentBillingProjectJobPage: {
      path: 'jobs/:jobUuid/project/billing/payments/new/method/authorize-dot-net',
      screen: AuthorizeDotNetMethodNewPaymentBillingProjectJobPage,
    },
    CreditCardMethodNewPaymentBillingProjectJobPage: {
      path: 'jobs/:jobUuid/project/billing/payments/new/method/credit-card',
      screen: CreditCardMethodNewPaymentBillingProjectJobPage,
    },
    PayEngineCreditCardMethodNewPaymentBillingProjectJobPage: {
      path: 'jobs/:jobUuid/project/billing/payments/new/method/payengine-credit-card',
      screen: PayEngineCreditCardMethodNewPaymentBillingProjectJobPage,
    },
    ExternalMethodNewPaymentBillingProjectJobPage: {
      path: 'jobs/:jobUuid/project/billing/payments/new/method/external',
      screen: ExternalMethodNewPaymentBillingProjectJobPage,
    },
    InvoiceMethodNewPaymentBillingProjectJobPage: {
      path: 'jobs/:jobUuid/project/billing/payments/new/method/invoice',
      screen: InvoiceMethodNewPaymentBillingProjectJobPage,
    },
    OtherMethodNewPaymentBillingProjectJobPage: {
      path: 'jobs/:jobUuid/project/billing/payments/new/method/other',
      screen: OtherMethodNewPaymentBillingProjectJobPage,
    },
    PaypalMethodNewPaymentBillingProjectJobPage: {
      path: 'jobs/:jobUuid/project/billing/payments/new/method/paypal',
      screen: PaypalMethodNewPaymentBillingProjectJobPage,
    },
    VenmoMethodNewPaymentBillingProjectJobPage: {
      path: 'jobs/:jobUuid/project/billing/payments/new/method/venmo',
      screen: VenmoMethodNewPaymentBillingProjectJobPage,
    },
    SaveCardMethodNewPaymentBillingProjectJobPage: {
      path: 'jobs/:jobUuid/project/billing/payments/new/method/save-card',
      screen: SaveCardMethodNewPaymentBillingProjectJobPage,
    },
    PayEngineSaveCardMethodNewPaymentBillingProjectJobPage: {
      path: 'jobs/:jobUuid/project/billing/payments/new/method/payengine-save-card',
      screen: PayEngineSaveCardMethodNewPaymentBillingProjectJobPage,
    },

    // Crew - After Move
    CrewTimesheetAfterMoveCrewJob: {
      path: 'jobs/:uuid/crew/after-move/crew-timesheet',
      screen: PayrollTimesheetAfterMovePage,
    },

    // Customer - Post Move
    PassFinishCustomerJob: {
      path: 'jobs/:uuid/customer/finish/pass',
      screen: PassFinishCustomerJob,
    },

    // Bills - Customer
    // DEPRECATED ROUTE. Use ReviewBillingProjectCustomerJobPage
    ViewCustomerBillJob: {
      path: 'jobs/:uuid/customer/bills/:billUuid/view',
      screen: ViewCustomerBillJob,
    },
    ReviewBillingProjectCustomerJobPage: {
      path: 'jobs/:uuid/customer/project/billing/review',
      screen: ReviewBillingProjectCustomerJobPage,
    },
    PaymentBillingProjectCustomerJobPage: {
      path: 'jobs/:uuid/customer/project/billing/payment',
      screen: PaymentBillingProjectCustomerJobPage,
    },
    // Documents
    ListDocumentJob: {
      path: 'jobs/:uuid/documents',
      screen: ListDocumentJob,
    },
    ChangeOrderDocumentJob: {
      path: 'jobs/:uuid/documents/change-order',
      screen: ChangeOrderDocumentJob,
    },
    RiskyMoveDocumentJob: {
      path: 'jobs/:uuid/documents/risky-move',
      screen: RiskyMoveDocumentJob,
    },
    SignDocumentJob: {
      path: 'jobs/:uuid/documents/:documentUuid/sign',
      screen: SignDocumentJob,
    },

    // Users
    ConfirmUsersJob: {
      path: 'jobs/:uuid/users/confirm',
      screen: ConfirmUsersJob,
    },

    // Inventory
    CrewCaptureInventory: {
      path: 'jobs/:uuid/inventory',
      screen: CrewCaptureInventoryPage,
    },
  },
  {
    initialRouteName: 'Home',
    headerMode: 'none',
    backBehavior: 'history',
  },
);

const Authenticated = createSwitchWrapperNavigator(
  {
    App: {
      path: '',
      screen: App,
    },
  },
  {
    initialRouteName: 'App',
    backBehavior: 'history',
    wrapper: AuthenticatedWrapper,
  },
);

// A SwitchWrapperNavigator is a SwitchNavigator with an extra component rendered
// which has access to the `navigation` prop.
const Router = createSwitchWrapperNavigator(
  {
    Start: {
      path: 'start',
      screen: Start,
    },
    Authentication: {
      path: 'login',
      screen: Authentication,
    },
    Authenticated: {
      path: '',
      screen: Authenticated,
    },
  },
  {
    initialRouteName: 'Start',
    backBehavior: 'history',
    wrapper: RouterWrapper,
  },
);

export default Router;
