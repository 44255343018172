// Libraries
import {BackButton} from 'components';
import _ from 'lodash';
import React from 'react';

// Supermove
import {Form, Loading, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {JobUser} from '@supermove/models';
import {DeviceInfo} from '@supermove/sdk';
import {colors, fontWeight} from '@supermove/styles';

// App
import {AppInfo} from 'config';
import {PageLoadingIndicator} from 'modules/App/components';
import {CrewJobPage} from 'modules/Job/Crew/components';
import {ConfirmCrewForJobMutation} from 'modules/Job/User/components';
import {OrganizationUsersGrid, SelectableUser} from 'modules/Organization/components';
import {CreateCrewEmployeeButton} from 'modules/User/components';

const Container = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
  margin-top: 20px;
`;

const Info = Styled.View`
  margin-horizontal: 40px;
`;

const Title = Styled.H2`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Subtitle = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Users = Styled.View`
  margin-top: 20px;
`;

const User = Styled.View`
  flex: ${1 / 6};
  margin-bottom: 20px;
`;

const Footer = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  background-color: ${colors.gray.background};
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
`;

const Group = Styled.View`
  flex: 1;
  flex-direction: row;
`;

const Button = Styled.LoadingButton`
  align-self: stretch;
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const ButtonSpace = Styled.View`
  width: 10px;
`;

const addUserToEmployeeIds = ({user, employeeIds}) => {
  return _.uniqBy([...employeeIds, user.id], (id) => id);
};

const removeUserFromEmployeeIds = ({user, employeeIds}) => {
  return _.filter(employeeIds, (id) => id !== user.id);
};

const EditUsersCrewJobPage = () => (
  <CrewJobPage
    fetchPolicy={'network-only'}
    getVariables={({params}) => ({
      uuid: params.uuid,
      role: 'employee',
      appName: 'Crew',
      appPlatform: DeviceInfo.getPlatform(),
      releaseBuild: AppInfo.getBuildNumber(),
      deviceUniqueId: DeviceInfo.getUniqueId(),
    })}
    query={EditUsersCrewJobPage.query}
  >
    {({navigator, params, loading, data, refetch}) => (
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => (
          <Form
            initialValues={{
              jobId: data.job.id,
              userId: data.viewer.id,
              appInstallId: _.get(data, 'device.appInstall.id'),
              employeeIds: JobUser.getSelected(data.job.jobUsers).map((jobUser) => jobUser.user.id),
            }}
            onSubmit={() => {}}
          >
            {(form) => (
              <ConfirmCrewForJobMutation
                jobId={form.values.jobId}
                userId={form.values.userId}
                appInstallId={form.values.appInstallId}
                employeeIds={form.values.employeeIds}
                user={data.viewer}
                onSuccess={() => navigator.goBack()}
              >
                {({loading, handleSubmit}) => (
                  <Container>
                    <ScrollView style={{flex: 1}}>
                      <Content>
                        <Info>
                          <Title>Edit Crew</Title>
                          <Subtitle>Select which members are completing this move today.</Subtitle>
                        </Info>
                        <Users>
                          <OrganizationUsersGrid
                            users={data.organization.filteredUsers}
                            extraData={form}
                            renderUser={({user}) => (
                              <User>
                                <SelectableUser
                                  isSelected={form.values.employeeIds.includes(user.id)}
                                  size={60}
                                  user={user}
                                  onSelect={() => {
                                    const {employeeIds} = form.values;
                                    if (employeeIds.includes(user.id)) {
                                      form.setFieldValue(
                                        'employeeIds',
                                        removeUserFromEmployeeIds({user, employeeIds}),
                                      );
                                    } else {
                                      form.setFieldValue(
                                        'employeeIds',
                                        addUserToEmployeeIds({user, employeeIds}),
                                      );
                                    }
                                  }}
                                />
                              </User>
                            )}
                          />
                        </Users>
                      </Content>
                    </ScrollView>
                    <Footer>
                      <Group>
                        <BackButton />
                        {data.organization.canCrewCreateEmployee && (
                          <React.Fragment>
                            <ButtonSpace />
                            <CreateCrewEmployeeButton
                              organization={data.organization}
                              onClose={() => refetch()}
                            />
                          </React.Fragment>
                        )}
                      </Group>
                      <Button
                        loading={loading}
                        color={colors.blue.interactive}
                        onPress={handleSubmit}
                      >
                        <Text>Confirm</Text>
                      </Button>
                    </Footer>
                  </Container>
                )}
              </ConfirmCrewForJobMutation>
            )}
          </Form>
        )}
      </Loading>
    )}
  </CrewJobPage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
EditUsersCrewJobPage.query = gql`
  ${CrewJobPage.fragment}
  ${SelectableUser.fragment}
  ${CreateCrewEmployeeButton.fragment}

  query EditUsersCrewJobPage(
    $uuid: String!,
    $role: String!,
    $appName: String!,
    $appPlatform: String!,
    $releaseBuild: Int!,
    $deviceUniqueId: String!,
  ) {
    ${gql.query}
    job(uuid: $uuid) {
      id
      jobUsers: activeJobUsers {
        jobId
        userId
        status
        user {
          id
        }
      }
      ...CrewJobPage
    }
    viewer {
      id
      firstName
      lastName
    }
    device(uniqueId: $deviceUniqueId) {
      id
      appInstall(
        appName: $appName,
        appPlatform: $appPlatform,
        releaseBuild: $releaseBuild,
      ) {
        id
      }
    }
    organization {
      id
      canCrewCreateEmployee
      filteredUsers(role: $role) {
        id
        ...SelectableUser
      }
      ...CreateCrewEmployeeButton
    }
  }
`;

export default EditUsersCrewJobPage;
