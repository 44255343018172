// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';
import {Storage} from '@supermove/sdk';

// App
import LogInEmployeeForm from '@shared/modules/Authentication/forms/LogInEmployeeForm';

const useLogInEmployeeMutation = ({logInEmployeeForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      logInEmployeeForm: LogInEmployeeForm.toForm(logInEmployeeForm),
    },
  });

  // Server has not been updated to use the form. We convert to a mutation and extract the
  // variables to send.
  const logInEmployeeFormMutation = LogInEmployeeForm.toMutation(form.values.logInEmployeeForm);
  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useLogInEmployeeMutation.mutation,
    variables: {
      auth: logInEmployeeFormMutation.authToken,
      tabletPin: logInEmployeeFormMutation.tabletPin,
    },
    onSuccess: async ({token, user}) => {
      // Store the token to keep the user logged in.
      await Storage.setItem('token', token);
      onSuccess({user});
    },
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useLogInEmployeeMutation.mutation = gql`
  mutation useLogInEmployeeMutation($auth: String!, $tabletPin: String!) {
    response: logInEmployee(auth: $auth, tabletPin: $tabletPin) {
      ${gql.errors}
      token
      user {
        id
      }
    }
  }
`;

export default useLogInEmployeeMutation;
