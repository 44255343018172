// Libraries
import React from 'react';

// App
import {Styled, TimeInput} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

// Components
import {Field, Modal} from 'modules/App/components';

const Container = Styled.View`
  padding: 40px;
  width: 600px;
  background-color: ${colors.white};
`;

const Title = Styled.H4`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
  text-align: center;
`;

const Subtitle = Styled.H6`
  margin-top: 3px;
  color: ${colors.gray.primary};
  text-align: center;
`;

const Content = Styled.View`
  margin-vertical: 20px;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const TextInput = Styled.TextInput.H7`
  margin-top: 3px;
`;

const Actions = Styled.View`
  margin-top: 20px;
  flex-direction: row;
  justify-content: center;
`;

const Button = Styled.Button`
  height: 40px;
  width: 100px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const StartTimeModal = ({index, form, timeRange, trigger}) => (
  <Modal trigger={trigger}>
    {({handleClose}) => (
      <Container>
        <Title>Start Time</Title>
        <Subtitle>Enter the official start time.</Subtitle>
        <Content>
          <Row>
            <Field
              {...form}
              component={TimeInput}
              name={`timeRanges.${index}.start`}
              label={'Start Time'}
              input={{
                autoFocus: true,
                component: TextInput,
                placeholder: 'Start Time',
                setFieldValue: form.setFieldValue,
              }}
              style={{
                flex: 1,
              }}
            />
          </Row>
        </Content>
        <Actions>
          <Button
            onPress={() => {
              handleClose();
            }}
          >
            <Text>Save</Text>
          </Button>
        </Actions>
      </Container>
    )}
  </Modal>
);

export default StartTimeModal;
