// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

const useCreateDocumentForJobMutation = ({jobId, template, onSuccess, onError}) => {
  const form = useForm({initialValues: {jobId, template}});
  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateDocumentForJobMutation.mutation,
    variables: {
      jobId: form.values.jobId,
      template: form.values.template,
    },
    onSuccess,
    onError,
  });

  return {
    submitting,
    handleSubmit,
  };
};

useCreateDocumentForJobMutation.mutation = gql`
  mutation useCreateDocumentForJobMutation(
    $jobId: Int!,
    $template: String!,
  ) {
    response: createDocumentForJob(
      jobId: $jobId,
      template: $template,
    ) {
      ${gql.errors}
      document {
        id
        uuid
        isStepPrepared(step: "DURING_MOVE")
      }
    }
  }
`;

export default useCreateDocumentForJobMutation;
