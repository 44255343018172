// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Notification, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Typography} from '@supermove/styles';
import {Json} from '@supermove/utils';

// Components
import DocumentAdditionalInfoEquipmentActions from './DocumentAdditionalInfoEquipmentActions';
import DocumentAdditionalInfoEquipmentInput from './DocumentAdditionalInfoEquipmentInput';
import DocumentAdditionalInfoGridFields from './DocumentAdditionalInfoGridFields';
import DocumentAdditionalInfoListActions from './DocumentAdditionalInfoListActions';
import DocumentAdditionalInfoListInput from './DocumentAdditionalInfoListInput';
import DocumentAdditionalInfoListV2Input from './DocumentAdditionalInfoListV2Input';
import DocumentAdditionalInfoRadioButtonInput from './DocumentAdditionalInfoRadioButtonInput';
import DocumentAdditionalInfoRadioButtonsWithTextInputsFields from './DocumentAdditionalInfoRadioButtonsWithTextInputsFields';
import DocumentAdditionalInfoSectionTextAreaInput from './DocumentAdditionalInfoSectionTextAreaInput';
import DocumentAdditionalInfoSelectMultipleInput from './DocumentAdditionalInfoSelectMultipleInput';

const getValue = ({form, documentAdditionalInfo, defaultValue}) => {
  return _.get(form, `values.additionalInfo.${documentAdditionalInfo.name}`, defaultValue);
};

const EquipmentHeader = Styled.View`
  flex-direction: row;
  margin-left: 40px;
  margin-bottom: 5px;
`;

const EquipmentColumn = Styled.Text`
  ${Typography.Body3}
  margin-left: 10px;
  width: ${(props) => props.width}px;
`;

const DocumentAdditionalInfoEquipmentFields = ({documentAdditionalInfo, form}) => {
  const items = getValue({form, documentAdditionalInfo, defaultValue: []});

  return (
    <React.Fragment>
      <EquipmentHeader>
        <EquipmentColumn width={200}>Name</EquipmentColumn>
        <EquipmentColumn width={100}># Taken</EquipmentColumn>
        <EquipmentColumn width={100}># Returned</EquipmentColumn>
      </EquipmentHeader>
      {items.map((item, index) => (
        <DocumentAdditionalInfoEquipmentInput
          key={index}
          isFirst={index === 0}
          isLast={index === items.length - 1}
          name={`additionalInfo.${documentAdditionalInfo.name}.${index}`}
          index={index}
          values={form.values}
          setFieldValue={form.setFieldValue}
          setFieldTouched={form.setFieldTouched}
        />
      ))}
      {items.length === 0 && <Notification.Warning text={'No Equipment'} />}
      <DocumentAdditionalInfoEquipmentActions
        name={`additionalInfo.${documentAdditionalInfo.name}`}
        form={form}
      />
    </React.Fragment>
  );
};

const DocumentAdditionalInfoListFields = ({documentAdditionalInfo, form}) => {
  const items = getValue({form, documentAdditionalInfo, defaultValue: []});

  return (
    <React.Fragment>
      {items.map((item, index) => (
        <DocumentAdditionalInfoListInput
          key={index}
          isFirst={index === 0}
          isLast={index === items.length - 1}
          name={`additionalInfo.${documentAdditionalInfo.name}.${index}`}
          index={index}
          values={form.values}
          setFieldValue={form.setFieldValue}
          setFieldTouched={form.setFieldTouched}
        />
      ))}
      {items.length === 0 && <Notification.Warning text={'No Items'} />}
      <DocumentAdditionalInfoListActions
        name={`additionalInfo.${documentAdditionalInfo.name}`}
        form={form}
      />
    </React.Fragment>
  );
};

const DocumentAdditionalInfoListV2Fields = ({documentAdditionalInfo, form}) => {
  const items = getValue({form, documentAdditionalInfo, defaultValue: []});
  const metadata = Json.toObject(documentAdditionalInfo.metadata);
  const combinedItems = _.zip(items, _.get(metadata, 'fields'));

  return (
    <React.Fragment>
      {combinedItems.map((combinedItem, index) => {
        const [item, field] = combinedItem;
        return (
          <DocumentAdditionalInfoListV2Input
            key={index}
            isFirst={index === 0}
            item={item}
            field={field}
            isLast={index === items.length - 1}
            name={`additionalInfo.${documentAdditionalInfo.name}.${index}`}
            values={form.values}
            setFieldValue={form.setFieldValue}
            setFieldTouched={form.setFieldTouched}
          />
        );
      })}
      {items.length === 0 && <Notification.Warning text={'No Items'} />}
    </React.Fragment>
  );
};

const DocumentAdditionalInfoRadioButtonsFields = ({documentAdditionalInfo, form}) => {
  const values = Json.toObject(documentAdditionalInfo.values);

  return (
    <React.Fragment>
      {values.map((value, index) => (
        <DocumentAdditionalInfoRadioButtonInput
          key={index}
          isFirst={index === 0}
          isLast={index === values.length - 1}
          name={`additionalInfo.${documentAdditionalInfo.name}`}
          index={index}
          value={value}
          values={form.values}
          setFieldValue={form.setFieldValue}
          setFieldTouched={form.setFieldTouched}
        />
      ))}
    </React.Fragment>
  );
};

const DocumentAdditionalInfoSectionTextAreasFields = ({documentAdditionalInfo, form}) => {
  const items = getValue({form, documentAdditionalInfo, defaultValue: []});

  return (
    <React.Fragment>
      {items.map((item, index) => (
        <DocumentAdditionalInfoSectionTextAreaInput
          key={index}
          isFirst={index === 0}
          isLast={index === items.length - 1}
          name={`additionalInfo.${documentAdditionalInfo.name}.${index}`}
          index={index}
          values={form.values}
          setFieldValue={form.setFieldValue}
          setFieldTouched={form.setFieldTouched}
        />
      ))}
      {items.length === 0 && <Notification.Warning text={'No Items'} />}
    </React.Fragment>
  );
};

const DocumentAdditionalInfoSelectMultipleFields = ({documentAdditionalInfo, form}) => {
  const values = Json.toObject(documentAdditionalInfo.values);

  return (
    <React.Fragment>
      {values.map((value, index) => (
        <DocumentAdditionalInfoSelectMultipleInput
          key={index}
          isFirst={index === 0}
          isLast={index === values.length - 1}
          name={`additionalInfo.${documentAdditionalInfo.name}`}
          index={index}
          value={value}
          values={form.values}
          setFieldValue={form.setFieldValue}
          setFieldTouched={form.setFieldTouched}
        />
      ))}
    </React.Fragment>
  );
};

const DocumentAdditionalInfoFields = ({documentAdditionalInfo, form}) => {
  switch (documentAdditionalInfo.kind) {
    case 'EQUIPMENT':
      return (
        <DocumentAdditionalInfoEquipmentFields
          documentAdditionalInfo={documentAdditionalInfo}
          form={form}
        />
      );
    case 'GRID':
      return (
        <DocumentAdditionalInfoGridFields
          documentAdditionalInfo={documentAdditionalInfo}
          form={form}
        />
      );
    case 'LIST':
      return (
        <DocumentAdditionalInfoListFields
          documentAdditionalInfo={documentAdditionalInfo}
          form={form}
        />
      );
    case 'LIST_V2':
      return (
        <DocumentAdditionalInfoListV2Fields
          documentAdditionalInfo={documentAdditionalInfo}
          form={form}
        />
      );
    case 'RADIO_BUTTONS':
      return (
        <DocumentAdditionalInfoRadioButtonsFields
          documentAdditionalInfo={documentAdditionalInfo}
          form={form}
        />
      );
    case 'RADIO_BUTTONS_WITH_TEXT_INPUTS':
      return (
        <DocumentAdditionalInfoRadioButtonsWithTextInputsFields
          documentAdditionalInfo={documentAdditionalInfo}
          form={form}
        />
      );
    case 'SECTION_TEXT_AREAS':
      return (
        <DocumentAdditionalInfoSectionTextAreasFields
          documentAdditionalInfo={documentAdditionalInfo}
          form={form}
        />
      );
    case 'SELECT_MULTIPLE':
      return (
        <DocumentAdditionalInfoSelectMultipleFields
          documentAdditionalInfo={documentAdditionalInfo}
          form={form}
        />
      );
    default:
      return null;
  }
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentAdditionalInfoFields.fragment = gql`
  ${DocumentAdditionalInfoGridFields.fragment}
  ${DocumentAdditionalInfoRadioButtonsWithTextInputsFields.fragment}

  fragment DocumentAdditionalInfoFields_Document on Document {
    id
  }

  fragment DocumentAdditionalInfoFields on DocumentAdditionalInfo {
    name
    kind
    values
    ...DocumentAdditionalInfoGridFields
    ...DocumentAdditionalInfoRadioButtonsWithTextInputsFields
  }
`;

export default DocumentAdditionalInfoFields;
