// App
import useBeginPaymentV3Mutation from '@shared/modules/Payment/hooks/useBeginPaymentV3Mutation';
import useFinalizePaymentMutation from '@shared/modules/Payment/hooks/useFinalizePaymentMutation';

const useBeginPaymentV3 = ({beginPaymentV3Form, onSuccess, onError, jobId}) => {
  const beginPaymentV3Mutation = useBeginPaymentV3Mutation({
    beginPaymentV3Form,
    onSuccess: () => {},
    onError,
  });

  const finalizePaymentMutation = useFinalizePaymentMutation({
    jobId,
    onSuccess,
    onError,
    response: 'DOCUMENT_NAVIGATION',
  });

  const submitting = finalizePaymentMutation.submitting || beginPaymentV3Mutation.submitting;
  const handleSubmit = async () => {
    const {data} = await beginPaymentV3Mutation.handleSubmit();
    if (!data.response.payment) {
      return;
    }
    await finalizePaymentMutation.form.setFieldValue('paymentId', data.response.payment.id);
    return finalizePaymentMutation.handleSubmit();
  };

  return {
    form: beginPaymentV3Mutation.form,
    submitting,
    handleSubmit,
  };
};

export default useBeginPaymentV3;
