// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';

const Container = Styled.View`
  padding-vertical: 20px;
  padding-horizontal: 30px;
`;

const Title = Styled.H4`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Content = Styled.View`
  padding-top: 10px;
`;

const Text = Styled.H6`
  color: ${colors.gray.primary};
`;

const Empty = Styled.H6`
  font-style: italic;
  color: ${colors.gray.secondary};
`;

const JobDescriptionBlock = ({job}) => {
  return (
    <Container>
      <Title>Job Description</Title>
      <Content>{job.description ? <Text>{job.description}</Text> : <Empty>None</Empty>}</Content>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobDescriptionBlock.fragment = gql`
  fragment JobDescriptionBlock on Job {
    id
    description
  }
`;

export default JobDescriptionBlock;
