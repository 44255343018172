// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

const PaymentSummaryTitle = Styled.Text`
  ${Typography.Heading2}
`;

const PaymentSummaryRows = Styled.View`
  border-radius: 4px;
  border: 1px solid ${colors.gray.border};
`;

const PaymentSummaryRowContainer = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  width: 600px;
  padding-vertical: 12px;
  padding-horizontal: 24px;
  ${({isLast}) => (isLast ? '' : 'border-bottom-width: 1px;')}
  ${({isLast}) => (isLast ? '' : `border-bottom-color: ${colors.gray.border};`)}
`;

const PaymentSummaryText = Styled.Text`
  ${Typography.Mobile.Body}
  ${(props) => props.style}
`;

const PaymentSummaryRow = ({description, amount, style, isLast}) => {
  return (
    <PaymentSummaryRowContainer isLast={isLast}>
      <PaymentSummaryText style={style}>{description}</PaymentSummaryText>
      <PaymentSummaryText style={style}>{amount}</PaymentSummaryText>
    </PaymentSummaryRowContainer>
  );
};

const PaymentSummaryTable = ({rows}) => {
  return (
    <React.Fragment>
      <PaymentSummaryTitle>Payment Summary</PaymentSummaryTitle>
      <Space height={16} />
      <PaymentSummaryRows>
        {rows.map((row, index) => (
          <PaymentSummaryRow
            key={index}
            description={row.description}
            amount={row.amount}
            style={row.style}
            isLast={index === rows.length - 1}
          />
        ))}
      </PaymentSummaryRows>
      <Space height={24} />
    </React.Fragment>
  );
};

export default PaymentSummaryTable;
