// Supermove
import {useForm} from '@supermove/hooks';
import {Float} from '@supermove/utils';

// App
import useFinishJobPostMovePaymentsMutation from '@shared/modules/Job/hooks/useFinishJobPostMovePaymentsMutation';
import useCreateCustomerPaymentProfileMutation from '@shared/modules/Payment/hooks/useCreateCustomerPaymentProfileMutation';

/**
 * The `handleSubmit` that is passed to the returned will set off a set of 3 API calls.
 *
 *    1. stripe.createPaymentMethod - First, we will create the payment method on Stripe.
 *    2. We take the paymentMethod that we receive back and store the id on our user's account.
 *    3. Once we have saved the payment method, we will complete the bill process with a
 *       remaining balance.
 *
 */

const useSavePaymentWithStripe = ({
  createCustomerPaymentProfileForm,
  stripePaymentForm,
  customerForm,
  jobId,
  onSuccess,
  onError,
}) => {
  const form = useForm({
    initialValues: {
      stripePaymentForm,
    },
  });
  const finishJobPostMovePaymentsMutation = useFinishJobPostMovePaymentsMutation({
    jobId,
    paymentMethod: 'STRIPE_SAVE_CARD',
  });
  const createCustomerPaymentProfileMutation = useCreateCustomerPaymentProfileMutation({
    createCustomerPaymentProfileForm,
    onSuccess: () => {
      finishJobPostMovePaymentsMutation.handleSubmit();
      onSuccess();
    },
    onError: ({error}) => {
      // Set the loading state back to false.
      form.setFieldValue('stripePaymentForm.loading', false);
      form.setFieldValue('stripePaymentForm.error', error);
      onError();
    },
  });

  const submitting =
    createCustomerPaymentProfileMutation.submitting || form.values.stripePaymentForm.loading;

  const handleSubmit = async (stripe) => {
    // Set the loading state on the entire form.
    form.setFieldValue('stripePaymentForm.loading', true);

    // Set the Stripe Account for the organization.
    const {stripePaymentForm} = form.values;
    await stripe.setAccountId({id: stripePaymentForm.stripeAccountId});

    // Call stripe to create the PaymentMethod.
    const result = await stripe.createPaymentMethod({
      params: {
        // Native uses this to submit the credit card info via the SDK.
        number: stripePaymentForm.number,
        expMonth: Float.toFloat(stripePaymentForm.expMonth),
        expYear: Float.toFloat(stripePaymentForm.expYear),
        cvc: stripePaymentForm.cvc,

        // Used on web-only.
        method: 'card',

        // Used on both to add billing details to the Stripe customer.
        billingDetails: {
          name: customerForm.firstName,
          email: customerForm.email,
          phone: customerForm.phoneNumber,
        },
      },
    });
    if (!result.error) {
      await createCustomerPaymentProfileMutation.form.setFieldValue(
        'createCustomerPaymentProfileForm.paymentMethodId',
        result.paymentMethod.id,
      );
      return createCustomerPaymentProfileMutation.handleSubmit();
    }

    // Credit card is not valid, we need to update the UI.
    form.setFieldValue('stripePaymentForm.loading', false);
    form.setFieldValue('stripePaymentForm.creditCardIsValid', false);
    onError();
  };

  return {
    form,
    submitting,
    handleSubmit: (stripe) => handleSubmit(stripe),
  };
};

export default useSavePaymentWithStripe;
