// Libraries
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation, useQuery, useState} from '@supermove/hooks';

// App
import JobStepKind from '@shared/modules/Job/enums/JobStepKind';
import JobEventForm from '@shared/modules/Job/forms/JobEventForm';
import CompleteTimesheetStepForm from '@shared/modules/Timesheet/forms/CompleteTimesheetStepForm';
import useCompletePreMoveJobTimesheetStepMutation from '@shared/modules/Timesheet/hooks/useCompletePreMoveJobTimesheetStepMutation';
import useCreateJobEventJobTimesheetUpdatedMutation from '@shared/modules/Timesheet/hooks/useCreateJobEventJobTimesheetUpdatedMutation';
import Navigation from 'core/Navigation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import BillableTimesheetPageContent from 'modules/Job/Crew/Timesheet/components/BillableTimesheetPageContent';
import CrewJobPageV2 from 'modules/Job/Crew/components/CrewJobPageV2';

const BillableTimesheetPreMovePageContent = ({job, navigator, refetch}) => {
  const [hasUpdates, setHasUpdates] = useState(false);
  const createJobEventTimesheetUpdated = useCreateJobEventJobTimesheetUpdatedMutation({
    jobEventForm: JobEventForm.new({jobId: job.id}),
    onSuccess: () => {},
    onError: (errors) => console.log({errors}),
  });

  const {submitting, handleSubmit} = useCompletePreMoveJobTimesheetStepMutation({
    completeTimesheetStepForm: CompleteTimesheetStepForm.new({jobId: job.id}),
    onSuccess: () => {
      Navigation.navigateToPreMoveStep({navigator, job, step: job.nextStepForPreMove});
    },
    onError: (errors) => console.log({errors}),
  });

  return (
    <BillableTimesheetPageContent
      job={job}
      handleSubmit={() => {
        if (hasUpdates) {
          createJobEventTimesheetUpdated.handleSubmit();
        }
        handleSubmit();
      }}
      isSubmitting={submitting}
      navigator={navigator}
      refetch={refetch}
      onUpdate={() => {
        setHasUpdates(true);
      }}
    />
  );
};

const BillableTimesheetPreMovePage = () => {
  const {params, navigator} = useNavigation();
  const {loading, data, refetch} = useQuery(BillableTimesheetPreMovePage.query, {
    fetchPolicy: 'network-only',
    variables: {
      jobUuid: params.uuid,
      currentStep: JobStepKind.CREW_PRE_MOVE_BILLABLE_TIMESHEET,
    },
  });

  return (
    <CrewJobPageV2 refetch={refetch}>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => {
          return (
            <BillableTimesheetPreMovePageContent
              job={data.job}
              navigator={navigator}
              refetch={refetch}
            />
          );
        }}
      </Loading>
    </CrewJobPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillableTimesheetPreMovePage.query = gql`
  ${Navigation.navigateToPreMoveStep.fragment}
  ${BillableTimesheetPageContent.fragment}

  query BillableTimesheetPreMovePage($jobUuid: String!, $currentStep: String!) {
    ${gql.query}
    job(uuid: $jobUuid) {
      id
      nextStepForPreMove(currentStep: $currentStep)
      ...Navigation_navigateToPreMoveStep
      ...BillableTimesheetPageContent
    }
  }
`;

export default BillableTimesheetPreMovePage;
