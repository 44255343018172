// Libraries
import React from 'react';

// App
import {Loading, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';

// Components
import {CustomerJobPage} from 'modules/Job/Customer/components';

const Container = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
  flex: 1;
  align-items: center;
  padding-top: 60px;
  padding-horizontal: 40px;
`;

const Title = Styled.H2`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
  text-align: center;
`;

const Subtitle = Styled.H5`
  max-width: 600px;
  color: ${colors.gray.primary};
  ${fontWeight(500)}
  text-align: center;
`;

const Timeline = Styled.View`
  flex: 1;
  justify-content: center;
  width: 750px;
`;

const Instructions = Styled.H3`
  margin-horizontal: 80px;
  margin-bottom: 80px;
  color: ${colors.gray.primary};
  ${fontWeight(500)}
  text-align: center;
`;

const JobFooter = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px;
  background-color: ${colors.gray.background};
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
`;

const Button = Styled.Button`
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const PassStartCustomerJobPage = () => (
  <CustomerJobPage
    getVariables={({params}) => ({uuid: params.uuid})}
    query={PassStartCustomerJobPage.query}
  >
    {({navigator, params, loading, data}) => (
      <Loading loading={loading}>
        {() => (
          <Container>
            <Content>
              <Title>{`Thank you!`}</Title>
              <Subtitle>
                {`A copy of the documents you just signed has been sent to your email ` +
                  `address: ${data.job.customer.email}.`}
              </Subtitle>
              <Timeline />
              <Instructions>Please hand this device back to your movers.</Instructions>
            </Content>
            <JobFooter>
              <Button onPress={() => navigator.navigate('ShowJob', {uuid: params.uuid})}>
                <Text>Next</Text>
              </Button>
            </JobFooter>
          </Container>
        )}
      </Loading>
    )}
  </CustomerJobPage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
PassStartCustomerJobPage.query = gql`
  ${CustomerJobPage.fragment}

  query PassStartCustomerJobPage($uuid: String!) {
    ${gql.query}
    job(uuid: $uuid) {
      id
      customer {
        id
        firstName
        email
      }
      ...CustomerJobPage
    }
  }
`;

export default PassStartCustomerJobPage;
