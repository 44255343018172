/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useModal} from '@supermove/hooks';
import {Event, Job} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import CreateDriverInventoryForJobForm from '@shared/modules/Inventory/forms/CreateDriverInventoryForJobForm';
import useCreateDriverInventoryForJobMutation from '@shared/modules/Inventory/hooks/useCreateDriverInventoryForJobMutation';
import JobStepKind from '@shared/modules/Job/enums/JobStepKind';
import Navigation from 'core/Navigation';
import CompleteJobModal from 'modules/Job/Show/components/CompleteJobModal';
import LogEventForJobMutation from 'modules/Job/components/LogEventForJobMutation';

const CompleteJobButton = ({job, refetch, children}) => {
  const {isOpen, handleOpen, handleClose} = useModal();

  return (
    <React.Fragment>
      {children({text: 'Complete Job', color: colors.green.status, handlePress: handleOpen})}
      <CompleteJobModal
        isOpen={isOpen}
        jobId={job.id}
        onSuccess={() => {
          handleClose();
          refetch();
        }}
        onClose={handleClose}
      />
    </React.Fragment>
  );
};

const JobCurrentAction = ({event, job, navigator, refetch, children}) => {
  const {handleSubmit: handleSubmitFindOrCreateDriverInventory} =
    useCreateDriverInventoryForJobMutation({
      createDriverInventoryForJobForm: CreateDriverInventoryForJobForm.new(job),
      onSuccess: () => {
        navigator.navigate('CrewCaptureInventory', {uuid: job.uuid});
      },
      onError: (errors) => console.log(errors),
    });

  switch (event) {
    case 'prepare-job':
      return children({
        text: 'Select Crew',
        color: colors.blue.interactive,
        handlePress: () => {
          navigator.navigate('ConfirmJobUsersForJobPage', {
            uuid: job.uuid,
            success: 'ConfirmCrewSlotsForJobPage',
          });
        },
      });
    case 'crew-arrive':
      return (
        <LogEventForJobMutation
          jobId={job.id}
          name={Event.MOVE_CREW_ARRIVED}
          onSuccess={() => refetch()}
          onError={(errors) => console.log({errors})}
        >
          {({loading, handleSubmit}) =>
            children({
              loading,
              color: colors.blue.interactive,
              text: 'We Have Arrived',
              handlePress: handleSubmit,
            })
          }
        </LogEventForJobMutation>
      );
    case JobStepKind.CREW_PRE_MOVE_BILLABLE_TIMESHEET:
      return children({
        text: 'Enter Job Timesheet',
        color: colors.blue.interactive,
        handlePress: () => {
          navigator.navigate('JobTimesheetPreMoveCrewJob', {uuid: job.uuid});
        },
      });
    case JobStepKind.CREW_PRE_MOVE_PAYROLL_TIMESHEET:
      return children({
        text: 'Enter Crew Hours',
        color: colors.blue.interactive,
        handlePress: () => {
          navigator.navigate('CrewTimesheetPreMoveCrewJob', {uuid: job.uuid});
        },
      });
    case 'pre-move':
      return children({
        text: 'Prepare Documents',
        color: colors.blue.interactive,
        handlePress: () => {
          Navigation.navigateToPreMovePrepareDocuments({navigator, job});
        },
      });
    case 'capture-inventory':
      return children({
        text: 'Start Inventory Capture',
        color: colors.blue.interactive,
        handlePress: () => {
          handleSubmitFindOrCreateDriverInventory();
        },
      });
    case JobStepKind.CREW_DURING_MOVE_BILLABLE_TIMESHEET:
      return children({
        text: 'Enter Job Timesheet',
        color: colors.blue.interactive,
        handlePress: () => {
          navigator.navigate('JobTimesheetDuringMoveCrewJob', {uuid: job.uuid});
        },
      });
    case 'prepare-post-move':
      return children({
        text: 'Enter Post Move Timesheet',
        color: colors.blue.interactive,
        handlePress: () => {
          const screen = Job.getTimesheetScreen(job);
          navigator.navigate(screen, {uuid: job.uuid});
        },
      });
    case 'post-move':
      // Special case for the COMMERCIAL jobs.
      if (job.kind === 'COMMERCIAL') {
        return children({
          text: 'Sign Documents',
          color: colors.blue.interactive,
          handlePress: () => {
            Navigation.navigateToPostMoveCustomerStart({navigator, job});
          },
        });
      }

      // Residential and all others.
      return children({
        text: 'Enter Timesheet',
        color: colors.blue.interactive,
        handlePress: () => {
          const screen = Job.getTimesheetScreen(job);
          navigator.navigate(screen, {uuid: job.uuid});
        },
      });
    case JobStepKind.CREW_AFTER_MOVE_PAYROLL_TIMESHEET:
      return children({
        text: 'Enter Crew Hours',
        color: colors.blue.interactive,
        handlePress: () => {
          navigator.navigate('CrewTimesheetAfterMoveCrewJob', {uuid: job.uuid});
        },
      });
    case 'finish-move':
      return children({
        text: 'Finish Move',
        color: colors.blue.interactive,
        handlePress: () => {
          if (job.hasJobStepCrewAfterMoveTimesheet) {
            navigator.navigate('EditCommercialTimesheetCrewJob', {uuid: job.uuid});
          } else {
            navigator.navigate('PassFinishCustomerJob', {uuid: job.uuid});
          }
        },
      });
    case 'complete-job':
      return <CompleteJobButton job={job} refetch={refetch} children={children} />;
    case 'done':
    default:
      return children({
        loading: false,
        color: colors.blue.interactive,
        text: '',
        handlePress: () => {},
      });
  }
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobCurrentAction.fragment = gql`
  ${Job.getTimesheetScreen.fragment}
  ${Navigation.navigateToPostMoveCustomerStart.fragment}
  ${Navigation.navigateToPreMovePrepareDocuments.fragment}

  fragment JobCurrentAction on Job {
    id
    uuid
    kind
    hasJobStepCrewAfterMoveTimesheet: hasJobStep(kind: "CREW_AFTER_MOVE_TIMESHEET")
    ...Job_getTimesheetScreen
    ...Navigation_navigateToPostMoveCustomerStart
    ...Navigation_navigateToPreMovePrepareDocuments
  }
`;

export default JobCurrentAction;
