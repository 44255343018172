// Libraries
import React from 'react';

// Supermove
import {FlatList, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';

// App
import BillBalanceItem from './BillBalanceItem';
import BillPaymentItem from './BillPaymentItem';

const Container = Styled.View`
`;

const Payments = Styled.View`
  margin-top: 20px;
`;

const Payment = Styled.View`
`;

const Balance = Styled.View`
`;

const getPayments = ({bill}) => {
  return bill.payments.filter((payment) => payment.isPaid);
};

const Header = Styled.View`
  align-self: stretch;
  flex-direction: row;
  padding-vertical: 5px;
  padding-horizontal: 10px;
  margin-bottom: 10px;
  background-color: ${colors.gray.background};
`;

const Cell = Styled.View`
  width: 140px;
`;

const CellText = Styled.H6`
  ${fontWeight(500)}
  color: ${colors.gray.secondary};
  text-align: right;
`;

const PaymentHeader = () => (
  <Header>
    <Cell
      style={{
        flex: 1,
      }}
    >
      <CellText
        style={{
          textAlign: 'left',
        }}
      >
        Payment
      </CellText>
    </Cell>
    <Cell
      style={{
        alignItems: 'flex-end',
      }}
    >
      <CellText>Amount</CellText>
    </Cell>
  </Header>
);

const BillPaymentsSummary = ({isEstimate, bill}) => (
  <Container>
    <Payments>
      <FlatList
        data={getPayments({bill})}
        listKey={'BillPaymentsSummary'}
        keyExtractor={(payment) => payment.id}
        renderItem={({item: payment, index}) => (
          <Payment>
            <BillPaymentItem
              isFirst={index === 0}
              isLast={index === getPayments({bill}).length - 1}
              payment={payment}
            />
          </Payment>
        )}
        ListHeaderComponent={PaymentHeader}
      />
    </Payments>
    {bill.isEstimateAvailable && !isEstimate && (
      <Balance>
        <BillBalanceItem bill={bill} />
      </Balance>
    )}
  </Container>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
BillPaymentsSummary.fragment = gql`
  ${BillBalanceItem.fragment}
  ${BillPaymentItem.fragment}

  fragment BillPaymentsSummary on Bill {
    id
    isEstimateAvailable
    payments {
      id
      isPaid
      ...BillPaymentItem
    }
    ...BillBalanceItem
  }
`;

export default BillPaymentsSummary;
