// Libraries
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation, useQuery} from '@supermove/hooks';
import {Job, Project} from '@supermove/models';

// App
import Navigation from 'core/Navigation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import ProjectBillingPayEngineSaveCardPaymentSection from 'modules/Project/Billing/Payment/New/Method/PayEngineSaveCard/components/ProjectBillingPayEngineSaveCardPaymentSection';
import ProjectBillingHeader from 'modules/Project/Billing/components/ProjectBillingHeader';

const Container = Styled.View`
  flex: 1;
`;

const Content = ({job}) => {
  const {params} = useNavigation();
  return (
    <Container>
      <ProjectBillingHeader
        leftButtonType={Navigation.isMainFlowPayment({params}) ? null : 'BACK'}
        title={Project.getName(job.project)}
        subtitle={Job.getFullName(job)}
      />
      <Container>
        <Space height={20} />
        <ProjectBillingPayEngineSaveCardPaymentSection job={job} />
        <Space height={20} />
      </Container>
    </Container>
  );
};

const PayEngineSaveCardMethodNewPaymentBillingProjectJobPage = () => {
  const {params} = useNavigation();
  const {loading, data, refetch} = useQuery(
    PayEngineSaveCardMethodNewPaymentBillingProjectJobPage.query,
    {
      fetchPolicy: 'network-only',
      variables: {
        jobUuid: params.jobUuid,
      },
    },
  );

  return (
    <Loading loading={loading} as={PageLoadingIndicator}>
      {() => (data ? <Content job={data.job} refetch={refetch} /> : null)}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
PayEngineSaveCardMethodNewPaymentBillingProjectJobPage.query = gql`
  ${Job.getPaymentName.fragment}
  ${Project.getName.fragment}
  ${ProjectBillingPayEngineSaveCardPaymentSection.fragment}

  query SaveCardMethodNewPaymentBillingProjectJobPage($jobUuid: String!) {
    ${gql.query}
    job(uuid: $jobUuid) {
      id
      project {
        id
        ...Project_getName
      }
      ...Job_getPaymentName
      ...ProjectBillingPayEngineSaveCardPaymentSection
    }
  }
`;

export default PayEngineSaveCardMethodNewPaymentBillingProjectJobPage;
