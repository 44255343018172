import React from 'react';

// Supermove
import {Space, Styled, Icon, CameraInput, FileDragInput} from '@supermove/components';
import PermissionDeniedModal from '@supermove/crew/src/modules/Job/Attachment/JobAttachmentsV2/components/PermissionDeniedModal';
import {Responsive, useModal} from '@supermove/hooks';
import {Typography} from '@supermove/styles';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';

const Container = Styled.View`
    justify-content: center;
`;

const AddPhotosText = Styled.Text`
    ${Typography.Responsive.PageHeading}
`;

const Row = Styled.View`
    flex-direction: row;
`;

interface AddPhotosProps {
  responsive: Responsive;
  handleAddPhotos: (file: File[]) => void;
}

const Button = ({text, icon, onPress}: {text: string; icon: string; onPress: () => void}) => {
  return (
    <SecondaryButton
      onPress={onPress}
      text={text}
      iconLeft={icon}
      iconSize={16}
      style={{flex: 1}}
      isWidthOfContainer
      isResponsive
    />
  );
};

const AddPhotos = ({responsive, handleAddPhotos}: AddPhotosProps) => {
  const permissionDeniedModal = useModal({name: 'Permission Denied Modal'});
  const inputStyles = {flex: 1};

  return (
    <Container>
      <AddPhotosText responsive={responsive}>Add Photos</AddPhotosText>
      <Space height={24} />
      <Row>
        <CameraInput
          disabled={false}
          onFilesChange={handleAddPhotos}
          onPermissionDenied={permissionDeniedModal.handleOpen}
          nativeOptions={{selectionLimit: 20}}
          webOptions={{accept: {'image/*': []}}}
          style={inputStyles}
        >
          {({onPress}: {onPress: () => void}) => (
            <Button
              text={'Take Photo'}
              icon={responsive.mobile ? null : Icon.Camera}
              onPress={onPress}
            />
          )}
        </CameraInput>
        <Space width={16} />
        <FileDragInput
          disabled={false}
          onFilesChange={handleAddPhotos}
          onPermissionDenied={permissionDeniedModal.handleOpen}
          nativeOptions={{selectionLimit: 20}}
          webOptions={{accept: {'image/*': []}}}
          style={inputStyles}
        >
          {({onPress}: {onPress: () => void}) => (
            <Button
              text={responsive.mobile ? 'Upload' : 'Upload From Device'}
              icon={responsive.mobile ? null : Icon.Upload}
              onPress={onPress}
            />
          )}
        </FileDragInput>
      </Row>
      <PermissionDeniedModal
        isOpen={permissionDeniedModal.isOpen}
        handleClose={permissionDeniedModal.handleClose}
      />
    </Container>
  );
};

export default AddPhotos;
