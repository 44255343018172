// Libraries
import React from 'react';

// Supermove
import {DevMenu, Loading, Query, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation} from '@supermove/hooks';
import {User} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';

// App
import Avatar from '../Avatar';

const Container = Styled.View`
  height: 60px;
  background-color: ${colors.black};
`;

const Content = Styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding-horizontal: 10px;
`;

const Logo = Styled.Touchable`
  margin-horizontal: 5px;
`;

const LogoText = Styled.H2`
  font-size: 36px;
  line-height: 50px;
  ${fontWeight(900)}
  color: ${colors.white};
  letter-spacing: 0.5;
  text-align: center;
`;

const Actions = Styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const SettingsButton = Styled.Touchable`
  flex-direction: row;
  align-items: center;
  height: 50px;
`;

const Info = Styled.View`
  justify-content: center;
  margin-right: 10px;
`;

const UserName = Styled.H6`
  color: ${colors.white};
`;

const OrganizationName = Styled.H8`
  margin-top: -2px;
  color: ${colors.white};
`;

const Header = () => {
  const {navigator} = useNavigation();

  return (
    <Container>
      <Query fetchPolicy={'network-only'} query={Header.query}>
        {({loading, data}) => (
          <Loading loading={loading}>
            {() => (
              <Content>
                <DevMenu>
                  {({handleOpen}) => (
                    <Logo activeOpacity={1} onLongPress={handleOpen}>
                      <LogoText>S</LogoText>
                    </Logo>
                  )}
                </DevMenu>
                <Actions>
                  <SettingsButton onPress={() => navigator.navigate('Settings')}>
                    <Info>
                      <UserName>{User.getFullName(data.viewer)}</UserName>
                      <OrganizationName>{data.viewer.viewingOrganization.name}</OrganizationName>
                    </Info>
                    <Avatar text={User.getInitials(data.viewer)} size={40} />
                  </SettingsButton>
                </Actions>
              </Content>
            )}
          </Loading>
        )}
      </Query>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
Header.query = gql`
  query Header {
    ${gql.query}
    viewer {
      id
      firstName
      lastName
      viewingOrganization {
        id
        name
      }
    }
  }
`;

export default Header;
