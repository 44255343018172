// Libraries
import React from 'react';

// Supermove
import {Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation, useQuery} from '@supermove/hooks';
import {Job, Project} from '@supermove/models';

// App
import Navigation from 'core/Navigation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import ProjectBillingSaveCardPaymentSection from 'modules/Project/Billing/Payment/New/Method/SaveCard/components/ProjectBillingSaveCardPaymentSection';
import ProjectBillingHeader from 'modules/Project/Billing/components/ProjectBillingHeader';

const Container = Styled.View`
  flex: 1;
`;

const SaveCardMethodNewPaymentBillingProjectJobContent = ({job}) => {
  const {params} = useNavigation();
  return (
    <Container>
      <ProjectBillingHeader
        leftButtonType={Navigation.isMainFlowPayment({params}) ? null : 'BACK'}
        title={Project.getName(job.project)}
        subtitle={Job.getFullName(job)}
      />
      <Container>
        <ScrollView>
          <Space height={20} />
          <ProjectBillingSaveCardPaymentSection job={job} />
          <Space height={20} />
        </ScrollView>
      </Container>
    </Container>
  );
};

const SaveCardMethodNewPaymentBillingProjectJobPage = () => {
  const {params} = useNavigation();
  const {loading, data, refetch} = useQuery(SaveCardMethodNewPaymentBillingProjectJobPage.query, {
    fetchPolicy: 'network-only',
    variables: {
      jobUuid: params.jobUuid,
    },
  });

  return (
    <Loading loading={loading} as={PageLoadingIndicator}>
      {() =>
        data ? (
          <SaveCardMethodNewPaymentBillingProjectJobContent job={data.job} refetch={refetch} />
        ) : null
      }
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SaveCardMethodNewPaymentBillingProjectJobPage.query = gql`
  ${Job.getPaymentName.fragment}
  ${Project.getName.fragment}
  ${ProjectBillingSaveCardPaymentSection.fragment}

  query SaveCardMethodNewPaymentBillingProjectJobPage($jobUuid: String!) {
    ${gql.query}
    job(uuid: $jobUuid) {
      id
      project {
        id
        ...Project_getName
      }
      ...Job_getPaymentName
      ...ProjectBillingSaveCardPaymentSection
    }
  }
`;

export default SaveCardMethodNewPaymentBillingProjectJobPage;
