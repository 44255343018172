// Libraries
import React from 'react';

// Supermove
import {Loading, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Payment, Job} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import Navigation from 'core/Navigation';
import {PageLoadingIndicator} from 'modules/App/components';
import {BillPaymentsSummary} from 'modules/Bill/components';
import {
  PartialPaymentForm,
  PartialPaymentModal,
  PaymentOption,
} from 'modules/Job/Bill/Customer/Payment/components';
import {CustomerJobProgress, CustomerJobPage} from 'modules/Job/Customer/components';

const Container = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
  margin-horizontal: 40px;
`;

const Info = Styled.View`
  align-items: center;
`;

const Title = Styled.H2`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Subtitle = Styled.H5`
  ${fontWeight(500)}
  width: 600px;
  color: ${colors.gray.primary};
  text-align: center;
`;

const Total = Styled.H3`
  ${fontWeight(700)}
  margin-top: 20px;
  color: ${colors.gray.primary};
`;

const Options = Styled.View`
  margin-vertical: 20px;
`;

const Option = Styled.View`
  margin-vertical: 5px;
`;

const PartialPaymentLink = Styled.Touchable`
  margin-bottom: 20px;
`;

const PartialPaymentText = Styled.H5`
  ${fontWeight(500)}
  color: ${colors.blue.interactive};
  text-decoration-line: underline;
`;

const Payments = Styled.View`
  align-self: center;
  width: 500px;
  margin-vertical: 40px;
`;

const PaymentsTitle = Styled.H3`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
  text-align: center;
`;

const Footer = Styled.View`
  flex-direction: row;
  justify-content: flex-start;
  padding: 10px;
  background-color: ${colors.gray.background};
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
`;

const Group = Styled.View`
  flex: 1;
  flex-direction: row;
`;

const Button = Styled.Button`
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const PaymentPreMoveBillCustomerJobPage = () => (
  <CustomerJobPage
    getVariables={({params}) => ({
      uuid: params.uuid,
      billUuid: params.billUuid,
    })}
    query={PaymentPreMoveBillCustomerJobPage.query}
    onFocus={({refetch}) => refetch()}
  >
    {({navigator, params, loading, data}) => (
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => {
          const payments = data.bill.payments.filter((payment) => payment.isPaid);
          const hasPayments = payments.length > 0;

          const paymentMethods = Job.getCrewPaymentMethods(data.job);

          return (
            <PartialPaymentForm amount={data.bill.preMoveBalance}>
              {(form) => (
                <Container>
                  <ScrollView style={{flex: 1}}>
                    <CustomerJobProgress
                      selected={'rates'}
                      items={CustomerJobProgress.items.start}
                    />
                    <Content>
                      <Info>
                        <Title>Great! We're ready to accept your payment.</Title>
                        <Subtitle>
                          {'Select which option you would like to use to complete ' +
                            'your payment.'}
                        </Subtitle>
                        <Total>{`Amount: ${form.values.amount}`}</Total>
                        <Options>
                          {paymentMethods.map((paymentMethod, index) => (
                            <Option key={index}>
                              <PaymentOption
                                name={Payment.getActionForMethod({method: paymentMethod})}
                                onPress={() => {
                                  const screen = Payment.getScreenForMethod({
                                    method: paymentMethod,
                                  });
                                  navigator.navigate(screen, {
                                    uuid: params.uuid,
                                    billUuid: params.billUuid,
                                    amount: Currency.toMutation(form.values.amount),
                                    step: 'CREW_PRE_MOVE_PAYMENTS',
                                  });
                                }}
                              />
                            </Option>
                          ))}
                        </Options>
                        <PartialPaymentModal
                          form={form}
                          trigger={({handleOpen}) => (
                            <PartialPaymentLink onPress={handleOpen}>
                              <PartialPaymentText>
                                {`I'd like to use multiple payment methods`}
                              </PartialPaymentText>
                            </PartialPaymentLink>
                          )}
                        />
                      </Info>
                      {hasPayments && (
                        <Payments>
                          <PaymentsTitle>Previous Payments</PaymentsTitle>
                          <BillPaymentsSummary bill={data.bill} />
                        </Payments>
                      )}
                    </Content>
                  </ScrollView>
                  <Footer>
                    <Group>
                      <Button color={colors.gray.secondary} onPress={() => navigator.goBack()}>
                        <Text>Back</Text>
                      </Button>
                    </Group>
                    {data.job.hasJobFeatureCrewPreMoveSkipPayment && (
                      <Button
                        color={hasPayments ? colors.blue.interactive : colors.gray.secondary}
                        onPress={() => {
                          Navigation.navigateToNextIncompleteDocumentForPreMove({
                            navigator,
                            job: data.job,
                          });
                        }}
                      >
                        <Text>{hasPayments ? 'Next' : 'Skip'}</Text>
                      </Button>
                    )}
                  </Footer>
                </Container>
              )}
            </PartialPaymentForm>
          );
        }}
      </Loading>
    )}
  </CustomerJobPage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
PaymentPreMoveBillCustomerJobPage.query = gql`
  ${Navigation.navigateToNextIncompleteDocumentForPreMove.fragment}
  ${BillPaymentsSummary.fragment}
  ${CustomerJobPage.fragment}
  ${Job.getCrewPaymentMethods.fragment}

  query PaymentPreMoveBillCustomerJobPage($uuid: String!, $billUuid: String!) {
    ${gql.query}
    job(uuid: $uuid) {
      id
      hasJobFeatureCrewPreMoveSkipPayment: hasJobFeature(kind: "CREW_PRE_MOVE_SKIP_PAYMENT")
      customer {
        id
      }
      ...Job_getCrewPaymentMethods
      ...Navigation_navigateToNextIncompleteDocumentForPreMove
      ...CustomerJobPage
    }
    bill(uuid: $billUuid) {
      id
      uuid
      kind
      preMoveBalance
      payments {
        id
        isPaid
      }
      ...BillPaymentsSummary
    }
  }
`;

export default PaymentPreMoveBillCustomerJobPage;
