// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {RadioInput, Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

const Container = Styled.View`
`;

const Content = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 10px;
  padding-horizontal: 10px;
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const DocumentAdditionalInfoRadioButtonInput = ({
  isFirst,
  isLast,
  name,
  index,
  value,
  values,
  setFieldValue,
  setFieldTouched,
}) => (
  <Container>
    {isFirst && <Line />}
    <Content>
      <RadioInput
        isSelected={value === _.get(values, name)}
        color={colors.blue.interactive}
        label={value}
        value={value}
        onSelect={(value) => setFieldValue(name, value)}
      />
    </Content>
    <Line />
  </Container>
);

export default DocumentAdditionalInfoRadioButtonInput;
