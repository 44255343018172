// Libraries
import _ from 'lodash';

// Supermove
import {JobUserForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const getJobUserFormsForCrew = (confirmJobUsersForJobForm, {crew, field}) => {
  return confirmJobUsersForJobForm.jobUserForms.reduce((result, jobUserForm, index) => {
    if (_.toString(jobUserForm.crewId) === _.toString(crew.id)) {
      return [...result, {...jobUserForm, field: `${field}.jobUserForms.${index}`}];
    }
    return result;
  }, []);
};

// When starting a job on the crew app we need an appInstallId.
// In subsequent uses of this form on a job to update movers,
// we don't need to pass the appInstallId.
const initialize = withFragment(
  (job, {appInstallId} = {}) => ({
    jobId: job.id,
    appInstallId,
    jobUserForms: job.jobUsers.map((jobUser) => JobUserForm.edit(jobUser)),
  }),
  gql`
    ${JobUserForm.edit.fragment}

    fragment ConfirmJobUsersForJobForm_initialize on Job {
      id
      jobUsers: activeAvailableJobUsers {
        id
        status
        ...JobUserForm_edit
      }
    }
  `,
);

const toForm = ({jobId, appInstallId, jobUserForms}) => ({
  jobId,
  appInstallId,
  jobUserForms: jobUserForms.map((jobUserForm) => JobUserForm.toForm(jobUserForm)),
});

const toMutation = ({jobId, appInstallId, jobUserForms}) => ({
  jobId,
  appInstallId,
  jobUserForms: jobUserForms.map((jobUserForm) => JobUserForm.toMutation(jobUserForm)),
});

const ConfirmJobUsersForJobForm = {
  initialize,
  toForm,
  toMutation,

  // Helpers
  getJobUserFormsForCrew,
};

export default ConfirmJobUsersForJobForm;
