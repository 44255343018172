// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Bill} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

const Row = Styled.View`
  flex-direction: row;
`;

const TitleColumn = Styled.View`
  justify-content: center;
  min-height: 38;
  flex: 1;
`;

const DescriptionText = Styled.H7`
  color: ${(props) => (props.color ? props.color : colors.gray.secondary)};
  ${fontWeight(500)}
`;

const BillHeaderRowTouchable = Styled.Touchable`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 38px;
`;

const HeaderMainText = Styled.H6`
  color: ${colors.gray.primary};
  ${fontWeight(700)}
`;

const BadgeContainer = Styled.View`
  padding-horizontal: 8px;
  background-color: ${(props) => (props.isHighlighted ? colors.blue.accent : colors.gray.border)};
  border-radius: 4px;
  justify-content: center;
  height: 20px;
`;

const BadgeText = Styled.H8`
  font-size: 10px;
  color: ${colors.gray.primary}
  ${fontWeight(700)}
`;

const TotalColumn = Styled.View`
  justify-content: ${(props) => props.justify};
`;

const ProjectBillsListItemHeader = ({
  bill,
  isHighlighted,
  isEditable,
  isCollapsible,
  isExpanded,
  expandedBills,
  setExpandedBills,
  index,
}) => {
  const handlePress = () => {
    const newExpandedBills = _.map(expandedBills, (isExpanded, billIndex) => {
      return billIndex === index ? !isExpanded : isExpanded;
    });
    setExpandedBills(newExpandedBills);
  };
  return (
    <BillHeaderRowTouchable
      activeOpacity={0.8}
      onPress={handlePress}
      disabled={!isEditable && !isCollapsible}
    >
      <TitleColumn justify={'center'}>
        {!!bill.job && (
          <React.Fragment>
            <Row>
              <React.Fragment>
                {!!bill.job.day && (
                  <React.Fragment>
                    <BadgeContainer isHighlighted={isHighlighted}>
                      <BadgeText>
                        {Datetime.convertToDisplayDate(
                          bill.job.day.value,
                          Datetime.DISPLAY_MONTH_DAY,
                        )}
                      </BadgeText>
                    </BadgeContainer>
                    <Space width={4} />
                  </React.Fragment>
                )}
                <BadgeContainer isHighlighted={isHighlighted}>
                  <BadgeText>{Bill.getJobName(bill)}</BadgeText>
                </BadgeContainer>
              </React.Fragment>
            </Row>
            <Space height={4} />
          </React.Fragment>
        )}
        <HeaderMainText numberOfLines={2}>{bill.title}</HeaderMainText>
        {!!bill.description && (
          <React.Fragment>
            <Space height={2} />
            <DescriptionText>{bill.description}</DescriptionText>
          </React.Fragment>
        )}
      </TitleColumn>
      <TotalColumn justify={bill.description ? 'flex-start' : 'center'}>
        {!isExpanded && (
          <HeaderMainText numberOfLines={1}>
            {bill.isEstimateAvailable ? Bill.getEstimateBalance(bill) : 'TBD'}
          </HeaderMainText>
        )}
      </TotalColumn>
    </BillHeaderRowTouchable>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

ProjectBillsListItemHeader.fragment = gql`
  ${Bill.getJobName.fragment}

  fragment ProjectBillsListItemHeader on Bill {
    id
    description
    total
    job {
      id
      day {
        id
        value
      }
    }
    ...Bill_getJobName
  }
`;

export default ProjectBillsListItemHeader;
