// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Analytics} from '@supermove/analytics';
import {Lifecycle} from '@supermove/components';
import {gql} from '@supermove/graphql';

/**
 * Called on app start when we first get the user.
 */
const trackUser = ({analytics, viewer}) => {
  const user = {
    // These keys should be camelcase to signify specific keys on
    // analytics services.
    id: _.get(viewer, 'id', ''),
    firstName: _.get(viewer, 'firstName', ''),
    lastName: _.get(viewer, 'lastName', ''),
    email: _.get(viewer, 'email', ''),

    // These key names are passed directly to the payload for analytics.
    Role: _.get(viewer, 'role', ''),
    Organization: _.get(viewer, 'organization.slug', ''),
  };

  analytics.identify(user);
};

const UserAnalyticsWrapper = ({viewer}) => {
  return (
    <Analytics.Consumer>
      {(analytics) => (
        <Lifecycle
          viewer={viewer}
          onMount={async () => {
            trackUser({analytics, viewer});
          }}
          onUpdate={async (previousProps) => {
            if (_.get(previousProps, 'viewer.id') !== _.get(viewer, 'id')) {
              trackUser({analytics, viewer});
            }
          }}
        />
      )}
    </Analytics.Consumer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UserAnalyticsWrapper.fragment = gql`
  fragment UserAnalyticsWrapper on User {
    id
    firstName
    lastName
    email
    role
    organization {
      id
      slug
    }
  }
`;

export default UserAnalyticsWrapper;
