// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Checkbox, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {User} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';
import {Duration} from '@supermove/utils';

// App
import TimeRangeFormsList from './TimeRangeFormsList';

const getBackgroundColor = ({isErrored, isSelected}) => {
  if (isSelected) {
    return colors.alpha(colors.blue.interactive, 0.1);
  } else if (isErrored) {
    return colors.alpha(colors.orange.status, 0.1);
  } else {
    return colors.white;
  }
};

const Container = Styled.View`
`;

const Content = Styled.View`
  padding-horizontal: 10px;
  background-color: ${(props) => getBackgroundColor(props)};
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const SelectTouchable = Styled.Touchable`
  flex-direction: row;
  padding-vertical: 10px;
`;

const SelectContainer = Styled.View`
  margin-horizontal: 20px;
  justify-content: center;
`;

const UserInfo = Styled.View`
  width: 240px;
`;

const Name = Styled.H5`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const Details = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const BranchCode = Styled.H6`
  color: ${colors.gray.secondary};
`;

const Separator = Styled.H6`
  margin-horizontal: 5px;
  color: ${colors.gray.secondary};
`;

const Position = Styled.H6`
  flex: 1;
  color: ${colors.gray.secondary};
`;

const Totals = Styled.View`
  flex-direction: row;
`;

const Total = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 65px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-left-color: ${(props) => (props.isFirst ? colors.blue.accent : colors.transparent)};
  border-right-color: ${colors.blue.accent};
`;

const TotalTime = Styled.H6`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const getIsErrored = ({index, form}) => {
  const jobUserTimesheetForms = _.get(
    form,
    'errors.commercialJobTimesheetForm.jobUserTimesheetForms',
    [],
  );
  const errors = _.find(jobUserTimesheetForms, (jobUserTimesheetForm, jobUserIndex) => {
    return jobUserIndex === index;
  });
  return _.get(errors, 'timeRangeForms', []).length > 0;
};

const JobUserTimesheetFormItem = ({
  isFirst,
  isLast,
  index,
  jobUser,
  jobUserTimesheetForm,
  form,
}) => {
  const isErrored = getIsErrored({index, form});
  const isSelected = false;
  const onToggle = () => {
    const field = `commercialJobTimesheetForm.jobUserTimesheetForms.${index}`;
    form.setFieldValue(`${field}.isSelected`, !jobUserTimesheetForm.isSelected);
  };

  return (
    <Container>
      {isFirst && <Line />}
      <Content isErrored={isErrored} isSelected={isSelected}>
        <ScrollView horizontal>
          <SelectTouchable onPress={onToggle}>
            <SelectContainer>
              <Checkbox
                checked={jobUserTimesheetForm.isSelected}
                color={colors.blue.interactive}
                size={25}
                onChange={onToggle}
              />
            </SelectContainer>
            <UserInfo>
              <Name numberOfLines={1}>{User.getFullName(jobUser.user)}</Name>
              <Details>
                <BranchCode>{jobUser.branchCode}</BranchCode>
                <Separator>{'|'}</Separator>
                <Position numberOfLines={1}>{jobUser.position}</Position>
              </Details>
            </UserInfo>
          </SelectTouchable>
          <TimeRangeFormsList timeRangeForms={jobUserTimesheetForm.timeRangeForms} />
          <Totals>
            <Total>
              <TotalTime>{Duration.toHours(jobUser.timesheet.totalTimeDrive)}</TotalTime>
            </Total>
            <Total>
              <TotalTime>{Duration.toHours(jobUser.timesheet.totalTimeWithoutBreak)}</TotalTime>
            </Total>
          </Totals>
        </ScrollView>
      </Content>
      <Line />
    </Container>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
JobUserTimesheetFormItem.propTypes = {
  jobUser: PropTypes.object.isRequired,
};

JobUserTimesheetFormItem.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobUserTimesheetFormItem.fragment = gql`
  fragment JobUserTimesheetFormItem on JobUser {
    id
    branchCode
    position
    timesheet {
      totalTimeDrive
      totalTimeWithoutBreak
    }
    user {
      id
      firstName
      lastName
    }
  }
`;

export default JobUserTimesheetFormItem;
