// Libraries
import React from 'react';

// App
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Datetime} from '@supermove/utils';

// Components

const LogEventForJobMutation = ({jobId, name, onSuccess, onError, children}) => (
  <Mutation
    variables={{
      jobId,
      name,
      properties: '{}',
      timestamp: Datetime.toTimestamp(Datetime.now),
    }}
    mutation={LogEventForJobMutation.mutation}
    onCompleted={({logEventForJob: {event, errors}}) => {
      if (event) {
        onSuccess(event);
      } else {
        onError(errors);
      }
    }}
  >
    {(handleSubmit, {loading, error}) => children({loading, handleSubmit})}
  </Mutation>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
LogEventForJobMutation.mutation = gql`
  mutation LogEventForJobMutation(
    $jobId: Int!,
    $name: String!,
    $properties: JSONString!,
    $timestamp: String!,
  ) {
    logEventForJob(
      jobId: $jobId,
      name: $name,
      properties: $properties,
      timestamp: $timestamp,
    ) {
      ${gql.errors}
      event {
        id
      }
    }
  }
`;

export default LogEventForJobMutation;
