// Libraries
import React from 'react';

// Supermove
import {Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation, useQuery} from '@supermove/hooks';
import {Job, Project} from '@supermove/models';

// App
import BeginPaymentV3Form from '@shared/modules/Payment/forms/BeginPaymentV3Form';
import useBeginPaymentV3 from '@shared/modules/Payment/hooks/useBeginPaymentV3';
import Navigation from 'core/Navigation';
import PageFooter from 'modules/App/components/PageFooter';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import ProjectBillingPaymentWithDescriptionSection from 'modules/Project/Billing/Payment/New/Method/Check/components/ProjectBillingPaymentWithDescriptionSection';
import ProjectBillingHeader from 'modules/Project/Billing/components/ProjectBillingHeader';

const Container = Styled.View`
  flex: 1;
`;

const CheckMethodNewPaymentBillingProjectJobContent = ({job}) => {
  const {params, navigator} = useNavigation();
  const beginPaymentV3Form = BeginPaymentV3Form.new({
    billId: job.project.currentPrimaryBill.id,
    customerId: job.project.currentPrimaryBill.customerId,
    name: params.paymentName,
    amount: params.paymentAmount,
    method: 'CHECK',
    tipAmount: params.tipAmount,
    tipName: Job.getTipName(job),
    jobId: job.id,
  });
  const {form, handleSubmit, submitting} = useBeginPaymentV3({
    jobId: job.id,
    beginPaymentV3Form,
    onSuccess: ({payment}) => Navigation.navigateFromPaymentSuccess({navigator, params, payment}),
    onError: (errors) => console.log({errors}),
  });

  const isMainFlow = Navigation.isMainFlowPayment({params});

  return (
    <Container>
      <ProjectBillingHeader
        leftButtonType={isMainFlow ? null : 'BACK'}
        title={Project.getName(job.project)}
        subtitle={Job.getFullName(job)}
      />
      <Container>
        <ScrollView>
          <Space height={20} />
          <ProjectBillingPaymentWithDescriptionSection
            form={form}
            handleSubmit={handleSubmit}
            submitting={submitting}
            descriptionField={'beginPaymentV3Form.description'}
            amountField={'beginPaymentV3Form.amount'}
            descriptionInputPlaceholder='Last 4 digits'
            button='Paid'
            pageTitle='Please pay the amount in full'
            pageSubtitle='When you are ready, the crew will handle your payment.'
          />
          <Space height={20} />
        </ScrollView>
      </Container>
      {isMainFlow && (
        <PageFooter>
          <PageFooter.Row>
            <PageFooter.BackButton />
          </PageFooter.Row>
        </PageFooter>
      )}
    </Container>
  );
};

const CheckMethodNewPaymentBillingProjectJobPage = () => {
  const {params} = useNavigation();
  const {loading, data, refetch} = useQuery(CheckMethodNewPaymentBillingProjectJobPage.query, {
    fetchPolicy: 'network-only',
    variables: {
      jobUuid: params.jobUuid,
    },
  });

  return (
    <Loading loading={loading} as={PageLoadingIndicator}>
      {() =>
        data ? (
          <CheckMethodNewPaymentBillingProjectJobContent job={data.job} refetch={refetch} />
        ) : null
      }
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CheckMethodNewPaymentBillingProjectJobPage.query = gql`
  ${Job.getFullName.fragment}
  ${Job.getTipName.fragment}
  ${Project.getName.fragment}

  query CheckMethodNewPaymentBillingProjectJobPage($jobUuid: String!) {
    ${gql.query}
    job(uuid: $jobUuid) {
      id
      project {
        id
        currentPrimaryBill {
          id
          customerId
        }
        ...Project_getName
      }
      ...Job_getFullName
      ...Job_getTipName
    }
  }
`;

export default CheckMethodNewPaymentBillingProjectJobPage;
