// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigation} from '@supermove/hooks';
import {colors, fontWeight, Typography} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import UpdateAttachmentForm from '@shared/modules/File/forms/UpdateAttachmentForm';
import useUpdateAttachmentForm from '@shared/modules/File/hooks/useUpdateAttachmentForm';
import Field from 'modules/App/components/Field';
import AttachmentViewer from 'modules/Job/Attachment/components/AttachmentViewer';
import DeleteAttachmentModal from 'modules/Job/Attachment/components/DeleteAttachmentModal';
import JobAttachmentsList from 'modules/Job/Attachment/components/JobAttachmentsList';
import JobNavigation from 'modules/Job/components/JobNavigation';
import JobPage from 'modules/Job/components/JobPage';

const Container = Styled.View`
  align-items: center;
`;

const Title = Styled.H4`
  flex: 1;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Header = Styled.View`
`;

const Line = Styled.View`
  margin-vertical: 20px;
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const Row = Styled.View`
  flex-direction: row;
`;

const TitleRow = Styled.View`
  flex-direction: row;
  width: 600px;
`;

const TextInput = Styled.TextInput.H7`
  margin-top: 3px;
`;

const Button = Styled.LoadingButton`
  width: 640px;
`;

const Text = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const DeleteButton = Styled.Button`
  padding-horizontal: 24px;
  background-color: ${colors.alpha(colors.Pink600, 0.1)};
`;

const ButtonText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.Pink600};
`;

const DescriptionField = ({form}) => {
  return (
    <Field
      {...form}
      component={TextInput}
      name={'updateAttachmentForm.description'}
      input={{
        placeholder: `Enter a description`,
      }}
      style={{width: 640}}
    />
  );
};

/**
 * Assumes there is more than 1 attachment in attachments.
 * Returns the previous attachment UUID after the given attachment is deleted.
 */
const getPreviousAttachmentUuid = ({attachments, attachment}) => {
  const selectedIndex = _.findIndex(
    attachments,
    (currentAttachment) => currentAttachment.uuid === attachment.uuid,
  );

  if (selectedIndex === 0) {
    return attachments[1].uuid;
  } else {
    return attachments[selectedIndex - 1].uuid;
  }
};

const onDeleteHandler = ({job, attachments, attachment, navigator}) => {
  if (attachments.length > 1) {
    navigator.navigate('UpdateAttachmentJob', {
      uuid: job.uuid,
      attachmentUuid: getPreviousAttachmentUuid({attachments, attachment}),
    });
  } else {
    navigator.navigate('ShowJob', {uuid: job.uuid});
  }
};

const UpdateAttachmentJobContent = ({job, attachment, refetch}) => {
  const {navigator} = useNavigation();
  const deleteAttachmentModal = useModal();

  const attachments = job.filteredCrewAttachments;
  const updateAttachmentForm = UpdateAttachmentForm.edit(attachment);

  const {form, submitting, handleSubmit} = useUpdateAttachmentForm({
    updateAttachmentForm,
    onSuccess: () => {
      refetch();
    },
    onError: (errors) => console.log({errors}),
  });

  return (
    <ScrollView>
      <Space height={24} />
      <Header>
        <Row>
          <Space width={12} />
          <Title>{pluralize('Photo', attachments.length, true)}</Title>
        </Row>
        <Space height={12} />
        <Row>
          <Space width={12} />
          <JobAttachmentsList
            uuid={job.uuid}
            attachments={attachments}
            onPress={({attachmentUuid}) => {
              navigator.navigate('UpdateAttachmentJob', {
                uuid: job.uuid,
                attachmentUuid,
              });
            }}
            onDelete={() => onDeleteHandler({job, attachments, attachment, navigator})}
          />
        </Row>
        <Line />
      </Header>
      <Container>
        <TitleRow>
          <Title>Update Photo</Title>
          <DeleteButton onPress={deleteAttachmentModal.handleOpen}>
            <Icon color={colors.Pink600} size={Icon.Sizes.Large} source={Icon.Trash} />
            <Space width={8} />
            <ButtonText>Delete</ButtonText>
          </DeleteButton>
        </TitleRow>
        <Space height={12} />
        <AttachmentViewer attachment={attachment} />
        <Space height={12} />
        <DescriptionField form={form} />
        <Space height={12} />
        <Button loading={submitting} onPress={handleSubmit}>
          <Text>Update Photo</Text>
        </Button>
        <Space height={24} />
      </Container>
      <DeleteAttachmentModal
        isOpen={deleteAttachmentModal.isOpen}
        attachmentId={attachment.id}
        handleClose={deleteAttachmentModal.handleClose}
        onSuccess={() => onDeleteHandler({job, attachments, attachment, navigator})}
      />
    </ScrollView>
  );
};

const UpdateAttachmentJobPage = () => {
  return (
    <JobPage
      fetchPolicy={'network-only'}
      getVariables={({params}) => ({uuid: params.uuid, attachmentUuid: params.attachmentUuid})}
      query={UpdateAttachmentJobPage.query}
      navigation={(props) => <JobNavigation {...props} />}
    >
      {({params, data, refetch}) => (
        <UpdateAttachmentJobContent job={data.job} attachment={data.attachment} refetch={refetch} />
      )}
    </JobPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateAttachmentJobPage.query = gql`
  ${AttachmentViewer.fragment}
  ${JobPage.fragment}
  ${JobNavigation.fragment}
  ${JobAttachmentsList.fragment}
  ${UpdateAttachmentForm.edit.fragment}

  query UpdateAttachmentJobPage($uuid: String!, $attachmentUuid: String!) {
    ${gql.query}
    job(uuid: $uuid) {
      id
      uuid
      filteredCrewAttachments {
        id
        uuid
        ...JobAttachmentsList
      }
      ...JobPage
      ...JobNavigation
    }
    attachment(uuid: $attachmentUuid) {
      id
      uuid
      ...AttachmentViewer
      ...UpdateAttachmentForm_edit
    }
  }
`;

export default UpdateAttachmentJobPage;
