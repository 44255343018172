// Libraries
import React from 'react';

// Supermove
import {FlatList, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';

// App
import BillBillRuleItem from './BillBillRuleItem';

const Item = Styled.View`
`;

const Header = Styled.View`
  align-self: stretch;
  flex-direction: row;
  padding-vertical: 5px;
  padding-horizontal: 10px;
  margin-bottom: 10px;
  background-color: ${colors.gray.background};
`;

const Cell = Styled.View`
  width: 140px;
`;

const CellText = Styled.H6`
  ${fontWeight(500)}
  color: ${colors.gray.secondary};
  text-align: right;
`;

const BillRuleHeader = () => (
  <Header>
    <Cell
      style={{
        flex: 1,
      }}
    >
      <CellText
        style={{
          textAlign: 'left',
        }}
      >
        Rule
      </CellText>
    </Cell>
  </Header>
);

const BillBillRulesList = ({bill}) => {
  return (
    <FlatList
      data={bill.billRulesForCustomer}
      listKey={'BillBillRulesList'}
      keyExtractor={(billRule, index) => String(index)}
      renderItem={({item: billRule, index}) => (
        <Item>
          <BillBillRuleItem
            isFirst={index === 0}
            isLast={index === bill.billRulesForCustomer.length - 1}
            index={index}
            billRule={billRule}
          />
        </Item>
      )}
      ListHeaderComponent={() => (bill.billRulesForCustomer.length > 0 ? <BillRuleHeader /> : null)}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillBillRulesList.fragment = gql`
  ${BillBillRuleItem.fragment}

  fragment BillBillRulesList on Bill {
    id
    billRulesForCustomer {
      ...BillBillRuleItem
    }
  }
`;

export default BillBillRulesList;
