// Libraries
import React from 'react';

// Components
import {KeyboardView, Modal as BaseModal, Styled} from '@supermove/components';

const Overlay = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Modal = ({trigger, children}) => (
  <BaseModal trigger={trigger}>
    {({handleClose}) => (
      <KeyboardView>
        <Overlay pointerEvents={'box-none'}>{children({handleClose})}</Overlay>
      </KeyboardView>
    )}
  </BaseModal>
);

export default Modal;
