// Libraries
import React from 'react';

// App
import {colors} from '@supermove/styles';

// Components
import {Timeline} from 'modules/Job/components';

const CrewJobProgress = ({selected, items}) => (
  <Timeline
    selectedColor={colors.blue.interactive}
    defaultColor={colors.gray.border}
    selected={selected}
    items={items}
  />
);

// --------------------------------------------------
// Navigation
// --------------------------------------------------
CrewJobProgress.items = {
  pre: [
    {
      key: 'select-user',
      name: 'Select Crew',
    },
    {
      key: 'truck',
      name: 'Confirm Truck',
    },
    {
      key: 'position',
      name: 'Track Location',
    },
    {
      key: 'location',
      name: 'Drive',
    },
  ],
  start: [
    {
      key: 'documents',
      name: 'Documents',
    },
    {
      key: 'start',
      name: 'Start Time',
    },
    {
      key: 'bill',
      name: 'Update Bill',
    },
  ],
  finish: [
    {
      key: 'finish',
      name: 'Enter Times',
    },
    {
      key: 'documents',
      name: 'Documents',
    },
    {
      key: 'rates',
      name: 'Select Rates',
    },
    {
      key: 'bill',
      name: 'Finalize Bill',
    },
  ],
};

export default CrewJobProgress;
