/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';

// App
import ActiveJobUsersList from 'modules/Job/components/ActiveJobUsersList';

const Container = Styled.View`
  padding-vertical: 20px;
  padding-horizontal: 30px;
`;

const Title = Styled.H4`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Content = Styled.View`
  padding-top: 10px;
`;

const JobCrewBlock = ({job}) => {
  return (
    <Container>
      <Title>Crew Members</Title>
      <Content>
        <ActiveJobUsersList job={job} />
      </Content>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobCrewBlock.fragment = gql`
  ${ActiveJobUsersList.fragment}

  fragment JobCrewBlock on Job {
    id
    ...ActiveJobUsersList
  }
`;

export default JobCrewBlock;
