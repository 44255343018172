// Libraries
import React from 'react';

// Supermove
import {Badge, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation} from '@supermove/hooks';
import {Document, DocumentTemplate} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import DocumentCompletionStatus from '@shared/modules/Document/enums/DocumentCompletionStatus';

const Container = Styled.View`
`;

const Content = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 10px;
  padding-horizontal: 10px;
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const Name = Styled.Text`
  ${Typography.Body1}
`;

const Button = Styled.Button`
  width: 80px;
`;

const Text = Styled.Text`
  ${Typography.Label1}
  color: ${colors.blue.interactive};
`;

const getIsFeatureFlaggedDocument = ({document}) => {
  return DocumentTemplate.FEATURE_FLAGGED_TEMPLATES.includes(document.template);
};

const getBadgeText = ({document}) => {
  if (getIsFeatureFlaggedDocument({document})) {
    if (document.isCompleted) {
      return DocumentCompletionStatus.COMPLETED;
    }
    return 'INCOMPLETE';
  }
  return document.completionStatus;
};

const ViewDocumentButton = ({document}) => {
  const {navigator} = useNavigation();
  return (
    <Button
      color={colors.blue.accent}
      onPress={() => {
        navigator.push('ViewDocumentV2CustomerJobPage', {
          jobUuid: document.job.uuid,
          documentUuid: document.uuid,
        });
      }}
    >
      <Text>View</Text>
    </Button>
  );
};

const SignDocumentButton = ({document}) => {
  const {navigator} = useNavigation();
  return (
    <Button
      color={colors.blue.accent}
      onPress={() => {
        navigator.push('SignDocumentV2CustomerJobPage', {
          jobUuid: document.job.uuid,
          documentUuid: document.uuid,
          step: Document.formatStepAsParam(document.step),
          success: 'BACK',
        });
      }}
    >
      <Text>Sign</Text>
    </Button>
  );
};

const DisabledButton = () => {
  return (
    <Button disabled>
      <Text style={{color: colors.white}}>View</Text>
    </Button>
  );
};

const ActionButton = ({document}) => {
  const isBeforeMoveAndDocumentNotCompleted = document.job.isNotStarted && !document.isCompleted;
  // There are are two documents for DURING_MOVE that do not follow document v2 patterns.
  // They are surfaced on the crew app via feature flag, and will continue to follow
  // document v1 patterns and pages. By design, they are not meant to be viewed or edited
  // on the crew app after their initial creation. The customer can see them via email.
  const isFeatureFlaggedDocument = DocumentTemplate.FEATURE_FLAGGED_TEMPLATES.includes(
    document.template,
  );
  if (isFeatureFlaggedDocument || isBeforeMoveAndDocumentNotCompleted) {
    return <DisabledButton />;
  }
  if (document.isCompleted) {
    return <ViewDocumentButton document={document} />;
  }
  return <SignDocumentButton document={document} />;
};

const JobDocumentV2Item = ({isFirst, document}) => {
  return (
    <Container>
      {isFirst && <Line />}
      <Content>
        <Name>{document.displayName}</Name>
        <Space style={{flex: 1}} />
        <Badge
          color={document.isCompleted ? colors.green.status : colors.gray.secondary}
          style={{width: 150}}
        >
          {getBadgeText({document})}
        </Badge>
        <Space width={10} />
        <ActionButton document={document} />
      </Content>
      <Line />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobDocumentV2Item.fragment = gql`
  fragment JobDocumentV2Item on Document {
    id
    uuid
    displayName
    step
    isSkipped
    isCompleted
    completionStatus
    template
    job {
      id
      uuid
      isNotStarted
    }
  }
`;

export default JobDocumentV2Item;
