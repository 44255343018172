/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';

// App
import CommercialEquipmentsList from 'modules/Job/CommercialCatalog/components/CommercialEquipmentsList';
import CommercialMaterialsList from 'modules/Job/CommercialCatalog/components/CommercialMaterialsList';

const Container = Styled.View`
  padding-vertical: 20px;
  padding-horizontal: 30px;
`;

const Title = Styled.H4`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Content = Styled.View`
  padding-top: 10px;
`;

const JobCommercialCatalogBlock = ({job}) => {
  if (!job.organization.settings.isEquipmentAndMaterialsEnabled) {
    return null;
  }

  return (
    <Container>
      <Title>Equipment & Materials</Title>
      <Content>
        <CommercialEquipmentsList job={job} />
        <Space height={10} />
        <CommercialMaterialsList job={job} />
      </Content>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobCommercialCatalogBlock.fragment = gql`
  ${CommercialEquipmentsList.fragment}
  ${CommercialMaterialsList.fragment}

  fragment JobCommercialCatalogBlock on Job {
    id
    organization {
      id
      settings {
        id
        isEquipmentAndMaterialsEnabled
      }
    }
    ...CommercialEquipmentsList
    ...CommercialMaterialsList
  }
`;

export default JobCommercialCatalogBlock;
