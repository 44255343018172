// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Components
import {Form, Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

const CreateSignatureForDocumentForm = ({
  documentId,
  customerId,
  date,
  kind,
  placement,
  onSuccess,
  onError,
  children,
}) => (
  <Form
    initialValues={{
      documentId,
      customerId,
      date,
      kind,
      image: '',
      placement,
    }}
    onSubmit={(values) => {}}
  >
    {({values, submitForm, setMutationErrors, ...form}) => (
      <Mutation
        variables={{
          documentId: values.documentId,
          customerId: values.customerId,
          date: values.date,
          kind: values.kind,
          image: values.image,
          placement: values.placement,
        }}
        mutation={CreateSignatureForDocumentForm.mutation}
        onCompleted={({createSignatureForDocument: {documentSignature, errors}}) => {
          submitForm();
          setMutationErrors(errors);

          if (documentSignature) {
            onSuccess(documentSignature);
          } else {
            onError(errors);
          }
        }}
      >
        {(handleSubmit, {loading, error}) =>
          children({
            ...form,
            loading,
            values,
            handleSubmit,
          })
        }
      </Mutation>
    )}
  </Form>
);

// --------------------------------------------------
// Props
// --------------------------------------------------
CreateSignatureForDocumentForm.propTypes = {
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};

CreateSignatureForDocumentForm.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------

// NOTE(mark): We fetch the isStepCompleted for the BILL document so that we
// can navigate to the correct next incomplete document.
CreateSignatureForDocumentForm.mutation = gql`
  mutation CreateSignatureForDocumentForm(
    $documentId: Int!,
    $customerId: Int!,
    $date: String,
    $kind: String,
    $image: String,
    $placement: String,
  ) {
    createSignatureForDocument(
      documentId: $documentId,
      customerId: $customerId,
      date: $date,
      kind: $kind,
      image: $image,
      placement: $placement,
    ) {
      ${gql.errors}
      documentSignature {
        id
        document {
          id
          isStepCompleted(step: "POST_MOVE")
        }
      }
    }
  }
`;

export default CreateSignatureForDocumentForm;
