// Libraries
import React from 'react';

// Supermove
import {Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigation} from '@supermove/hooks';
import {Payment, Job} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import useFinishJobPostMovePaymentsMutation from '@shared/modules/Job/hooks/useFinishJobPostMovePaymentsMutation';
import PaymentForm from '@shared/modules/Payment/forms/PaymentForm';
import usePaymentForm from '@shared/modules/Payment/hooks/usePaymentForm';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import BillPaymentsSummary from 'modules/Bill/components/BillPaymentsSummary';
import PaymentOption from 'modules/Job/Bill/Customer/Payment/components/PaymentOption';
import PaymentAmountModal from 'modules/Job/Customer/Project/Billing/Payment/components/PaymentAmountModal';
import CustomerJobPage from 'modules/Job/Customer/components/CustomerJobPage';
import CustomerJobProgress from 'modules/Job/Customer/components/CustomerJobProgress';

const Container = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
  margin-horizontal: 40px;
`;

const Info = Styled.View`
  align-items: center;
`;

const Title = Styled.H2`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Subtitle = Styled.H5`
  ${fontWeight(500)}
  width: 600px;
  color: ${colors.gray.primary};
  text-align: center;
`;

const LargeText = Styled.H4`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const PartialPaymentLink = Styled.Touchable`
`;

const PartialPaymentText = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.blue.interactive};
  text-decoration-line: underline;
`;

const Payments = Styled.View`
  align-self: center;
  width: 500px;
`;

const PaymentsTitle = Styled.H4`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
  text-align: center;
`;

const Footer = Styled.View`
  flex-direction: row;
  justify-content: flex-start;
  padding: 10px;
  background-color: ${colors.gray.background};
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
`;

const Button = Styled.Button`
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const PreviousPaymentsSection = ({bill}) => {
  const payments = bill.payments.filter((payment) => payment.isPaid);

  if (payments.length === 0) {
    return null;
  }

  return (
    <Payments>
      <Space height={40} />
      <PaymentsTitle>Previous Payments</PaymentsTitle>
      <BillPaymentsSummary bill={bill} />
      <Space height={40} />
    </Payments>
  );
};

const PaymentBillingProjectCustomerJobContent = ({job}) => {
  const {params, navigator} = useNavigation();
  const partialPaymentModal = useModal();
  const bill = job.project.currentPrimaryBill;
  const {balance} = job.project.currentAggregateBill;
  const paymentForm = PaymentForm.new({amount: balance});
  const {form} = usePaymentForm({paymentForm});
  const {submitting, handleSubmit} = useFinishJobPostMovePaymentsMutation({
    jobId: job.id,
    paymentMethod: null,
  });
  const paymentMethods = Job.getCrewPaymentMethods(job);

  if (balance <= 0) {
    return (
      <ScrollView style={{flex: 1}}>
        <CustomerJobProgress selected={'payment'} items={CustomerJobProgress.getItems(bill)} />
        <Content>
          <Info>
            <Title>No payment is required at this time.</Title>
            <Subtitle>Press next to continue.</Subtitle>
            <Space height={40} />
            <PaymentOption loading={submitting} name={'Next'} onPress={handleSubmit} />
          </Info>
          <PreviousPaymentsSection bill={bill} />
        </Content>
      </ScrollView>
    );
  }

  return (
    <Container>
      <ScrollView style={{flex: 1}}>
        <CustomerJobProgress selected={'payment'} items={CustomerJobProgress.getItems(bill)} />
        <Content>
          <Info>
            <Title>Great! We're ready to accept your payment.</Title>
            <Subtitle>Please select the pament method you would like to use.</Subtitle>
            <Space height={20} />
            <LargeText>{`Payment Amount: ${form.values.paymentForm.amount}`}</LargeText>
            <Space height={20} />
            <PartialPaymentLink onPress={partialPaymentModal.handleOpen}>
              <PartialPaymentText>I'd like to make a partial payment</PartialPaymentText>
            </PartialPaymentLink>
            <Space height={20} />
            {paymentMethods.map((paymentMethod, index) => (
              <React.Fragment key={index}>
                {index > 0 && <Space height={8} />}
                <PaymentOption
                  name={Payment.getActionForMethod({method: paymentMethod})}
                  onPress={() => {
                    const screen = Payment.getScreenForMethod({method: paymentMethod});
                    navigator.navigate(screen, {
                      uuid: params.uuid,
                      billUuid: bill.uuid,
                      amount: Currency.toMutation(form.values.paymentForm.amount),
                      step: 'CREW_POST_MOVE_PAYMENTS',
                    });
                  }}
                />
              </React.Fragment>
            ))}
            <Space height={20} />
          </Info>
          <PreviousPaymentsSection bill={bill} />
        </Content>
      </ScrollView>
      <Footer>
        <Button color={colors.gray.secondary} onPress={() => navigator.goBack()}>
          <Text>Back</Text>
        </Button>
      </Footer>
      <PaymentAmountModal
        form={form}
        paymentAmountField={'paymentForm.amount'}
        paymentNameField={'paymentForm.name'}
        key={partialPaymentModal.isOpen}
        isOpen={partialPaymentModal.isOpen}
        handleClose={partialPaymentModal.handleClose}
        project={job.project}
      />
    </Container>
  );
};

const PaymentBillingProjectCustomerJobPage = () => (
  <CustomerJobPage
    getVariables={({params}) => ({uuid: params.uuid})}
    query={PaymentBillingProjectCustomerJobPage.query}
    onFocus={({refetch}) => refetch()}
  >
    {({loading, data}) => (
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => <PaymentBillingProjectCustomerJobContent job={data.job} />}
      </Loading>
    )}
  </CustomerJobPage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
PaymentBillingProjectCustomerJobPage.query = gql`
  ${CustomerJobPage.fragment}
  ${BillPaymentsSummary.fragment}
  ${PaymentAmountModal.fragment}
  ${Job.getCrewPaymentMethods.fragment}

  query PaymentBillingProjectCustomerJobPage($uuid: String!) {
    ${gql.query}
    job(uuid: $uuid) {
      id
      customer {
        id
      }
      project {
        id
        currentPrimaryBill {
          id
          uuid
          kind
          balance
          payments {
            id
            isPaid
          }
          ...BillPaymentsSummary
        }
        currentAggregateBill {
          balance
        }
        ...PaymentAmountModal
      }
      ...Job_getCrewPaymentMethods
      ...CustomerJobPage
    }
  }
`;

export default PaymentBillingProjectCustomerJobPage;
