// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

const Container = Styled.View`
  align-items: center;
  justify-content: center;
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  border-radius: ${(props) => props.size / 2}px;
  background-color: ${(props) => props.color};
`;

const Text = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const Avatar = ({text, size, color}) => (
  <Container color={color} size={size}>
    <Text numberOfLines={1}>{text}</Text>
  </Container>
);

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
Avatar.propTypes = {
  color: PropTypes.string,
};

Avatar.defaultProps = {
  color: colors.blue.interactive,
};

export default Avatar;
