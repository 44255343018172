// Libraries
import React from 'react';

import {gql} from '@supermove/graphql';

// Components
import {BillSummary} from 'modules/Bill/components';

const ProvisionalJobBillView = ({isEstimate, hidePrices, showBillRules, job}) => {
  return (
    <BillSummary
      isEstimate={isEstimate}
      hidePrices={hidePrices}
      showBillRules={showBillRules}
      bill={job.mainBill}
      showJobBill
      refetch={() => {}}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProvisionalJobBillView.fragment = gql`
  ${BillSummary.fragment}

  fragment ProvisionalJobBillView on Job {
    id
    mainBill {
      id
      ...BillSummary
    }
  }
`;

export default ProvisionalJobBillView;
