// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation, useNavigation} from '@supermove/hooks';

// App
import Navigation from 'core/Navigation';

const useFinishJobPostMovePaymentsMutation = ({jobId, paymentMethod, onSuccess, onError}) => {
  const {navigator, params} = useNavigation();
  const form = useForm({
    initialValues: {
      jobId,
      paymentMethod,
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useFinishJobPostMovePaymentsMutation.mutation,
    variables: {
      jobId: form.values.jobId,
      paymentMethod: form.values.paymentMethod,
    },
    onSuccess: async ({job}) => {
      if (onSuccess) {
        await onSuccess();
      }
      // We need to re-request the project's currentPrimaryBill in order to check some properties
      // on the result (boolean values changed, job events logged, etc).
      const bill = job.project.currentPrimaryBill;
      Navigation.navigateFromBillSuccess({navigator, params, bill});
    },
    onError: (errors) => console.log({errors}),
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useFinishJobPostMovePaymentsMutation.mutation = gql`
  ${Navigation.navigateFromBillSuccess.fragment}

  mutation useFinishJobPostMovePaymentsMutation($jobId: Int!, $paymentMethod: String) {
    response: finishJobPostMovePayments(jobId: $jobId, paymentMethod: $paymentMethod) {
      ${gql.errors}
      job {
        id
        project {
          id
          currentPrimaryBill {
            id
            ...Navigation_navigateFromBillSuccess
          }
        }
      }
    }
  }
`;

export default useFinishJobPostMovePaymentsMutation;
