// Libraries
import _ from 'lodash';
import React, {memo} from 'react';

// Supermove
import {Styled, Icon, Loading, Space} from '@supermove/components';
import {Form, Responsive, useMemo, useEffect, useModal} from '@supermove/hooks';
import {Platform} from '@supermove/sdk';
import {colors, Typography} from '@supermove/styles';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import IconButton from '@shared/design/components/IconButton';
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import {CreateAttachmentsFormType} from '@shared/modules/File/forms/BulkCreateAttachmentsForm';
import {UploadFileFormType} from '@shared/modules/File/forms/UploadFileForm';
import useUploadFileForm from '@shared/modules/File/hooks/useUploadFileForm';
import RemoveAttachmentModalV2 from 'modules/Job/Attachment/JobAttachmentsV2/components/RemoveAttachmentModalV2';

const FILE_SIZE_LIMIT_MB = 10;

const ROW_HEIGHT = 120;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const LoadingContainer = Styled.View`
  height: ${ROW_HEIGHT}px;
  width: ${ROW_HEIGHT}px;
  border-radius: 4px;
`;

const PreviewErrorBackground = Styled.View`
  background-color: ${colors.red.accent};
  height: ${ROW_HEIGHT}px;
  width: ${ROW_HEIGHT}px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
`;

const ErrorContainer = Styled.View`
  flex: 1;
`;

const ErrorMessage = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.red.warning};
`;

const Image = Styled.Image`
  height: ${ROW_HEIGHT}px;
  width: ${ROW_HEIGHT}px;
`;

const DescriptionContainer = Styled.View`
  flex: 1;
  min-height: ${ROW_HEIGHT}px;
`;

const getUri = (file?: (File & {uri?: string}) | null) => {
  if (!file) {
    return '';
  }
  return Platform.select({
    web: () => URL.createObjectURL(file),
    default: () => file.uri,
  })();
};

const AttachmentPreview = memo(
  ({
    accessibilityLabel,
    isLoading,
    mimeType,
    uri,
    hasError,
  }: {
    accessibilityLabel?: string;
    hasError?: boolean;
    mimeType?: string;
    uri?: string;
    isLoading?: boolean;
  }) => {
    if (hasError) {
      return (
        <PreviewErrorBackground>
          <Icon source={Icon.ExclamationTriangle} color={colors.red.warning} />
        </PreviewErrorBackground>
      );
    }
    return (
      <LoadingContainer style={{backgroundColor: isLoading ? colors.white : undefined}}>
        <Loading loading={!!isLoading} as={PageLoadingIndicator}>
          {() => {
            switch (mimeType) {
              case 'image/jpeg':
              case 'image/png':
                return (
                  <Image
                    resizeMode={'cover'}
                    source={{uri}}
                    accessibilityLabel={accessibilityLabel}
                  />
                );
              default:
                return null;
            }
          }}
        </Loading>
      </LoadingContainer>
    );
  },
);

const AttachmentDescription = ({
  form,
  name,
}: {
  form: Form<CreateAttachmentsFormType>;
  name: string;
}) => {
  return (
    <DescriptionContainer>
      <FieldInput
        {...form}
        name={name}
        input={{
          placeholder: 'Enter notes (optional)',
          style: {minHeight: ROW_HEIGHT, paddingTop: 8, textAlignVertical: 'top'},
          multiline: true,
        }}
      />
    </DescriptionContainer>
  );
};

const AttachmentActionButton = ({
  hasError,
  hasLoaded,
  onPressRemoveAttachment,
}: {
  hasError: boolean;
  hasLoaded: boolean;
  onPressRemoveAttachment: () => void;
}) => {
  if (hasError) {
    return (
      <React.Fragment>
        <Space width={24} />
        <IconButton onPress={onPressRemoveAttachment} source={Icon.Xmark} size={20} isDestructive />
      </React.Fragment>
    );
  }
  if (hasLoaded) {
    return (
      <React.Fragment>
        <Space width={32} />
        <IconButton onPress={onPressRemoveAttachment} source={Icon.Trash} isDestructive />
      </React.Fragment>
    );
  }

  // Create element so alignment is correct
  return <Space width={50} />;
};

const MobileActionButton = ({
  onPressRemoveAttachment,
  isVisible,
}: {
  onPressRemoveAttachment: () => void;
  isVisible: boolean;
}) => {
  if (!isVisible) {
    return null;
  }

  return (
    <React.Fragment>
      <Space height={16} />
      <SecondaryButton
        onPress={onPressRemoveAttachment}
        iconLeft={Icon.Trash}
        iconSize={16}
        isWidthOfContainer
        textColor={colors.red.warning}
        isResponsive
        text='Remove'
      />
    </React.Fragment>
  );
};

const Error = ({
  error,
  responsive,
  file,
}: {
  error: unknown;
  responsive: Responsive;
  file?: File | null;
}) => {
  // Some file names are very long and makes the actual error less readable, so we shorten it
  const shortenFileName =
    (file?.name?.length ?? 0) > 50 ? `${file?.name.slice(0, 50)}...` : file?.name;

  // Known errors
  if (error && Array.isArray(error) && typeof error[0]?.message === 'string') {
    const ErrorFragment = ({children}: {children: React.ReactNode}) => (
      <ErrorContainer>
        <ErrorMessage responsive={responsive}>{children}</ErrorMessage>
        <Space width={32} />
      </ErrorContainer>
    );

    if (error[0].message.includes('Unsupported file type')) {
      return (
        <ErrorFragment>
          {shortenFileName} has unsupported file type "{file?.type ?? ''}"
        </ErrorFragment>
      );
    } else if (error[0].message.includes(useUploadFileForm.ERR_FILE_SIZE_EXCEEDED)) {
      return (
        <ErrorFragment>
          {shortenFileName} is too large to upload. Please select a smaller file.
        </ErrorFragment>
      );
    }
  }

  return (
    <ErrorMessage responsive={responsive}>
      {shortenFileName} could not be uploaded. Please try again.
    </ErrorMessage>
  );
};

export interface JobAttachmentItemV2Props {
  createAttachmentsForm: Form<CreateAttachmentsFormType>;
  formItemName: string;
  handleRemove: () => void;
  handleUploadAttachmentSuccess: (id: string) => void;
  responsive: Responsive;
  uploadFileForm: UploadFileFormType;
}

const JobAttachmentItemV2 = ({
  createAttachmentsForm,
  formItemName,
  handleRemove,
  handleUploadAttachmentSuccess,
  responsive,
  uploadFileForm,
}: JobAttachmentItemV2Props) => {
  const removeAttachmentModal = useModal();

  const handleError = (error: any) => {
    console.warn('Error uploading file', error);
    form.setFieldValue('uploadFileForm.error', error);
    createAttachmentsForm.setFieldError(formItemName, error);
  };

  const {form, handleSubmit: handleUpload} = useUploadFileForm({
    uploadFileForm,
    onSuccess: ({file}) => handleUploadAttachmentSuccess(file.id),
    onError: handleError,
    limitMb: FILE_SIZE_LIMIT_MB,
  });

  useEffect(() => {
    if (!form.values.uploadFileForm.attemptedUpload) {
      form.setFieldValue('uploadFileForm.attemptedUpload', true);
      handleUpload();
    }
  }, [form, form.values.uploadFileForm, handleUpload]);

  const err = form.values.uploadFileForm?.error;
  const createAttachmentForm = _.get(createAttachmentsForm.values, formItemName);
  // Memoized to prevent the image from flashing on re-renders
  const uri = useMemo(() => getUri(uploadFileForm?.file), [uploadFileForm.file]);

  const onPressRemoveAttachment = () => {
    if (err) {
      handleRemove();
    } else {
      removeAttachmentModal.handleOpen();
    }
  };

  return (
    <React.Fragment>
      <Row>
        <AttachmentPreview
          mimeType={uploadFileForm?.file?.type}
          uri={uri}
          accessibilityLabel={uploadFileForm?.file?.name}
          isLoading={createAttachmentForm.fileId === null && !err}
          hasError={!!form.errors.uploadFileForm}
        />
        <Space width={24} />
        {err ? (
          <Error error={err} responsive={responsive} file={uploadFileForm.file} />
        ) : (
          <AttachmentDescription
            form={createAttachmentsForm}
            name={`${formItemName}.description`}
          />
        )}
        {responsive.mobile ? null : (
          <AttachmentActionButton
            hasError={!!err}
            hasLoaded={createAttachmentForm.fileId !== null}
            onPressRemoveAttachment={onPressRemoveAttachment}
          />
        )}
        <RemoveAttachmentModalV2
          isOpen={removeAttachmentModal.isOpen}
          handleClose={removeAttachmentModal.handleClose}
          handleRemove={handleRemove}
        />
      </Row>
      {responsive.mobile ? (
        <MobileActionButton
          onPressRemoveAttachment={onPressRemoveAttachment}
          isVisible={createAttachmentForm.fileId !== null || err}
        />
      ) : null}
    </React.Fragment>
  );
};

export default JobAttachmentItemV2;
