// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

const Container = Styled.View`
  flex-direction: row;
  background-color: ${colors.gray.background};
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
`;

const Content = Styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
`;

const DateText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
  text-transform: uppercase;
`;

const JobDateItem = ({date}) => {
  return (
    <Container>
      <Content>
        <DateText>{Datetime.convertToDisplayDate(date)}</DateText>
      </Content>
    </Container>
  );
};

export default JobDateItem;
