// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';
import {Datetime, Json, Phone} from '@supermove/utils';

// Components

const Container = Styled.View`
  align-self: stretch;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const Field = Styled.View`
  flex: 1;
`;

const Label = Styled.Text`
  font-size: 14px;
  line-height: 19px;
  ${fontWeight(700)}
  color: ${colors.gray.secondary};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const Value = Styled.Text`
  margin-top: 5px;
  font-size: 14px;
  line-height: 19px;
  color: ${colors.gray.primary};
`;

const FieldValue = ({numberOfLines, label, value}) => (
  <Field>
    <Label numberOfLines={numberOfLines}>{label}</Label>
    <Value numberOfLines={numberOfLines}>{value}</Value>
  </Field>
);

// TODO(mark): Copied, make sure to consolidate.
const getAdditionalItems = ({job}) => {
  const jobFormAdditionalItems = Json.toCamelCaseKeys(
    Json.parse(job.organization.settings.jobFormAdditionalItems),
  );
  const additionalItems = Json.toCamelCaseKeys(Json.parse(job.additionalItems));

  const getLabel = ({jobFormAdditionalItems, key}) => {
    return _.get(jobFormAdditionalItems, `${key}.label`);
  };

  const reducer = (all, value, key) => {
    if (value) {
      return [...all, getLabel({jobFormAdditionalItems, key})];
    }

    // Nothing to add if the value is false.
    return all;
  };

  return _.reduce(additionalItems, reducer, []);
};

const CommercialCustomerInformationView = ({job}) => (
  <Container>
    <Row>
      <FieldValue numberOfLines={2} label={'Customer'} value={job.customer.fullName} />
      <FieldValue
        numberOfLines={1}
        label={'Phone Number'}
        value={Phone.display(job.customer.phoneNumber)}
      />
      <FieldValue
        numberOfLines={1}
        label={'Date'}
        value={Datetime.convertToDisplayDate(job.day.value)}
      />
      <FieldValue
        numberOfLines={1}
        label={job.organization.jobStartTimeField}
        value={
          `${Datetime.convertToDisplayTime(job.startTime1)} - ` +
          `${Datetime.convertToDisplayTime(job.startTime2)}`
        }
      />
    </Row>
    <Row style={{marginTop: 20}}>
      <FieldValue numberOfLines={1} label={'Pick-Up Location'} value={job.startLocation.address} />
      <FieldValue numberOfLines={1} label={'Drop-Off Location'} value={job.endLocation.address} />
    </Row>
    {job.additionalLocations.length > 0 && (
      <Row style={{marginTop: 20}}>
        <FieldValue
          label={'Additional Stops'}
          value={job.additionalLocations.map((location) => location.address).join('\n')}
        />
      </Row>
    )}
    {getAdditionalItems({job}).length > 0 && (
      <Row style={{marginTop: 20}}>
        <FieldValue label={'Additional Items'} value={getAdditionalItems({job}).join(', ')} />
      </Row>
    )}
    {!!job.noteToCustomer && (
      <Row style={{marginTop: 20}}>
        <FieldValue label={'Important Notes'} value={job.noteToCustomer} />
      </Row>
    )}
  </Container>
);

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
CommercialCustomerInformationView.propTypes = {
  job: PropTypes.object.isRequired,
};

CommercialCustomerInformationView.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
CommercialCustomerInformationView.fragment = gql`
  fragment CommercialCustomerInformationView on Job {
    id
    startTime1
    startTime2
    crewSize
    hourlyRate
    travelFee
    noteToCustomer
    additionalItems
    organization {
      id
      slug
      jobStartTimeField
      settings {
        id
        jobFormAdditionalItems
      }
    }
    day {
      id
      value
    }
    customer {
      id
      fullName
      phoneNumber
    }
    startLocation {
      id
      address
    }
    endLocation {
      id
      address
    }
    additionalLocations {
      id
      address
    }
  }
`;

export default CommercialCustomerInformationView;
