// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {FlatList, Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import Notification from 'modules/App/components/Notification';
import CreateJobUsersForCrewModal from 'modules/Job/JobUser/Confirm/components/CreateJobUsersForCrewModal';
import JobUserFormItem from 'modules/Job/User/Confirm/components/JobUserFormItem';

const handleDeleteJobUser = ({form, index: removeIndex}) => {
  const {jobUserForms} = form.values.confirmJobUsersForJobForm;
  const newJobUserForms = jobUserForms.filter((jobUserForm, index) => index !== removeIndex);

  // Set the new jobUserForms on the main form.
  form.setFieldValue(`confirmJobUsersForJobForm.jobUserForms`, newJobUserForms);
};

const Header = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const CrewDetails = Styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

const Name = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const RequiredMoversBadge = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 2px;
  padding-horizontal: 6px;
  background-color: ${colors.gray.tertiary};
  border-radius: 2px;
`;

const RequiredMoversCount = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const RequiredMoversCountBadge = ({count}) => {
  return (
    <RequiredMoversBadge>
      <RequiredMoversCount>{count}</RequiredMoversCount>
      <Space width={4} />
      <Icon source={Icon.User} color={colors.white} size={16} />
    </RequiredMoversBadge>
  );
};

const RoundBadge = Styled.View`
  padding-vertical: 2px;
  padding-horizontal: 8px;
  background-color: ${colors.orange.status};
  border-radius: 20px;
`;

const RoundText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const PrimaryCrewBadge = () => {
  return (
    <RoundBadge>
      <RoundText>Primary</RoundText>
    </RoundBadge>
  );
};

const ActionButton = Styled.LoadingButton`
  height: 30px;
  padding-horizontal: 15px;
`;

const ActionText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const Container = Styled.View`
`;

const CrewHeader = ({crew, handleOpen}) => {
  return (
    <Header>
      <CrewDetails>
        <Name>{crew.organization.name}</Name>
        <Space width={8} />
        <RequiredMoversCountBadge count={crew.numberOfRequiredMovers} />
        <Space width={8} />
        {crew.isPrimary && <PrimaryCrewBadge />}
      </CrewDetails>
      <ActionButton color={colors.blue.interactive} onPress={handleOpen}>
        <ActionText>Add</ActionText>
      </ActionButton>
    </Header>
  );
};

const Section = Styled.View`
`;

const CrewSection = ({crew, jobUserForms, organization, form}) => {
  const {isOpen, handleOpen, handleClose} = useModal();

  return (
    <Section>
      <CrewHeader crew={crew} handleOpen={handleOpen} />
      <Space height={5} />
      <FlatList
        data={jobUserForms}
        keyExtractor={(jobUserForm, index) => String(index)}
        extraData={form}
        renderItem={({item: jobUserForm}) => {
          const {index} = jobUserForm;
          const field = `confirmJobUsersForJobForm.jobUserForms.${index}`;

          return (
            <JobUserFormItem
              field={field}
              form={form}
              jobUserForm={jobUserForm}
              organization={organization}
              onDelete={() => handleDeleteJobUser({form, index})}
            />
          );
        }}
        ListEmptyComponent={() => (
          <Notification
            color={colors.orange.status}
            text={
              `No movers have been assigned to this crew. Add movers by tapping the ` +
              `"Add" button.`
            }
            style={{
              marginVertical: 10,
            }}
          />
        )}
      />
      <Space height={10} />
      <CreateJobUsersForCrewModal
        crew={crew}
        isOpen={isOpen}
        onClose={handleClose}
        onSubmit={(jobUserForms) => {
          // Merge the new jobUserForms with the existing ones.
          const newJobUserForms = [
            ...form.values.confirmJobUsersForJobForm.jobUserForms,
            ...jobUserForms,
          ];
          form.setFieldValue('confirmJobUsersForJobForm.jobUserForms', newJobUserForms);
          handleClose();
        }}
      />
    </Section>
  );
};

const getSortedCrews = ({crews}) => {
  return _.sortBy(crews, ['organization.name']);
};

const getJobUserFormsByCrewId = ({jobUserForms}) => {
  // We inject the index so that the jobUserForm has a way to update the correct form.
  const jobUserFormsWithIndex = _.map(jobUserForms, (jobUserForm, index) => {
    return {...jobUserForm, crewId: String(jobUserForm.crewId), index};
  });
  return _.groupBy(jobUserFormsWithIndex, 'crewId');
};

const ConfirmJobCrewsSection = ({job, form}) => {
  const {crews, organization} = job;
  const sortedCrews = getSortedCrews({crews});
  const {jobUserForms} = form.values.confirmJobUsersForJobForm;
  const jobUserFormsByCrewId = getJobUserFormsByCrewId({jobUserForms});

  return (
    <Container>
      {sortedCrews.map((crew) => {
        const jobUserForms = _.get(jobUserFormsByCrewId, String(crew.id));
        return (
          <CrewSection
            key={crew.id}
            crew={crew}
            jobUserForms={jobUserForms}
            organization={organization}
            form={form}
          />
        );
      })}
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ConfirmJobCrewsSection.fragment = gql`
  ${CreateJobUsersForCrewModal.fragment}
  ${JobUserFormItem.fragment}

  fragment ConfirmJobCrewsSection on Job {
    id
    organization {
      id
      hasMultipleOrganizations
      ...JobUserFormItem
    }
    crews {
      id
      isPrimary
      numberOfRequiredMovers
      organization {
        id
        name
      }
      ...CreateJobUsersForCrewModal
    }
  }
`;

export default ConfirmJobCrewsSection;
