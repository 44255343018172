// Libraries
import React from 'react';

// Supermove
import {Emoji, Modal, Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

const Container = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const Wrapper = Styled.View`
  width: ${(props) => props.width}px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
  border-radius: 5px;
`;

const Circle = Styled.View`
  align-self: center;
  align-items: center;
  justify-content: center;
  top: -20px;
  height: 80px;
  width: 80px;
  border-radius: 50px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
`;

const EmojiText = Styled.H1`
  margin-top: 5px;
`;

const Content = Styled.View`
  margin-horizontal: 30px;
`;

const Title = Styled.H5`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
  text-align: center;
`;

const Subtitle = Styled.H7`
  margin-top: 15px;
  color: ${colors.gray.tertiary};
  text-align: center;
`;

const Actions = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-vertical: 20px;
`;

const Indicator = Styled.Loading`
`;

const CodePushModal = ({isOpen, onClose}) => {
  return (
    <Modal.Content isOpen={isOpen} onClose={onClose}>
      <Container pointerEvents={'box-none'}>
        <Wrapper width={400}>
          <Circle>
            <Emoji component={EmojiText} name={'gift'} />
          </Circle>
          <Content>
            <Title>Upgrading Supermove</Title>
            <Subtitle>
              A new version of Supermove is automatically installing. Please do not close the app.
              This process will take less than a minute.
            </Subtitle>
            <Actions>
              <Indicator size={'large'} color={colors.gray.secondary} />
            </Actions>
          </Content>
        </Wrapper>
      </Container>
    </Modal.Content>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
CodePushModal.propTypes = {};

CodePushModal.defaultProps = {};

export default CodePushModal;
