// Libraries
import {gql} from '@supermove/graphql';
import {useMutation} from '@supermove/hooks';

const MUTATION = gql`
  mutation useDownloadDocument($documentId: Int!) {
    downloadDocument(documentId: $documentId) {
      url
    }
  }
`;

const useDownloadDocument = ({documentId}) => {
  const [handleSubmit, {loading, data}] = useMutation(MUTATION, {
    variables: {documentId},
  });

  return {
    submitting: loading,
    handleSubmit,
    data,
  };
};

export default useDownloadDocument;
