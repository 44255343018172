// Libraries
import React from 'react';

// Supermove
import {ExternalLink, Icon, Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation, useQuery} from '@supermove/hooks';
import {Job, Project} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import BeginPaymentV3Form from '@shared/modules/Payment/forms/BeginPaymentV3Form';
import useBeginPaymentV3 from '@shared/modules/Payment/hooks/useBeginPaymentV3';
import Navigation from 'core/Navigation';
import PageFooter from 'modules/App/components/PageFooter';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import ProjectBillingPaymentWithDescriptionSection from 'modules/Project/Billing/Payment/New/Method/Check/components/ProjectBillingPaymentWithDescriptionSection';
import ProjectBillingHeader from 'modules/Project/Billing/components/ProjectBillingHeader';

const Container = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const ExternalLinkText = Styled.Text`
  ${Typography.Label1}
  color: ${colors.blue.interactive}
  font-style: italic;
`;

const AuthorizeDotNetLink = ({job}) => {
  const {params} = useNavigation();
  return (
    <React.Fragment>
      <Space height={16} />
      <ExternalLink url={Job.getAuthorizeDotNetUrlWithAmount(job, {amount: params.paymentAmount})}>
        <Row>
          <ExternalLinkText>Tap to pay with authorize.net</ExternalLinkText>
          <Space width={8} />
          <Icon source={Icon.ArrowRight} size={12} color={colors.blue.interactive} />
        </Row>
      </ExternalLink>
      <Space height={16} />
    </React.Fragment>
  );
};

const AuthorizeDotNetMethodNewPaymentBillingProjectJobContent = ({job}) => {
  const {params, navigator} = useNavigation();
  const beginPaymentV3Form = BeginPaymentV3Form.new({
    billId: job.project.currentPrimaryBill.id,
    customerId: job.project.currentPrimaryBill.customerId,
    name: params.paymentName,
    amount: params.paymentAmount,
    method: 'AUTHORIZE_DOT_NET',
    tipAmount: params.tipAmount,
    tipName: Job.getTipName(job),
    jobId: job.id,
  });
  const {form, handleSubmit, submitting} = useBeginPaymentV3({
    jobId: job.id,
    beginPaymentV3Form,
    onSuccess: ({payment}) => Navigation.navigateFromPaymentSuccess({navigator, params, payment}),
    onError: (errors) => console.log({errors}),
  });

  const isMainFlow = Navigation.isMainFlowPayment({params});

  return (
    <Container>
      <ProjectBillingHeader
        leftButtonType={isMainFlow ? null : 'BACK'}
        title={Project.getName(job.project)}
        subtitle={Job.getFullName(job)}
      />
      <Container>
        <ScrollView>
          <Space height={20} />
          <ProjectBillingPaymentWithDescriptionSection
            form={form}
            handleSubmit={handleSubmit}
            submitting={submitting}
            descriptionField={'beginPaymentV3Form.description'}
            amountField={'beginPaymentV3Form.amount'}
            descriptionInputPlaceholder='Enter payment ID'
            inputLabel='Payment ID'
            button='Paid & Continue'
            pageTitle='Please pay the amount in full'
            pageSubtitle='When you are ready, the crew will handle your payment.'
            PaymentAmountHeader={
              job.authorizeDotNetUrl && (() => <AuthorizeDotNetLink job={job} />)
            }
          />
          <Space height={20} />
        </ScrollView>
      </Container>
      {isMainFlow && (
        <PageFooter>
          <PageFooter.Row>
            <PageFooter.BackButton />
          </PageFooter.Row>
        </PageFooter>
      )}
    </Container>
  );
};

const AuthorizeDotNetMethodNewPaymentBillingProjectJobPage = () => {
  const {params} = useNavigation();
  const {loading, data, refetch} = useQuery(
    AuthorizeDotNetMethodNewPaymentBillingProjectJobPage.query,
    {
      fetchPolicy: 'network-only',
      variables: {
        jobUuid: params.jobUuid,
      },
    },
  );

  return (
    <Loading loading={loading} as={PageLoadingIndicator}>
      {() =>
        data ? (
          <AuthorizeDotNetMethodNewPaymentBillingProjectJobContent
            job={data.job}
            refetch={refetch}
          />
        ) : null
      }
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AuthorizeDotNetMethodNewPaymentBillingProjectJobPage.query = gql`
  ${Job.getAuthorizeDotNetUrlWithAmount.fragment}
  ${Job.getFullName.fragment}
  ${Job.getTipName.fragment}
  ${Project.getName.fragment}

  query AuthorizeDotNetMethodNewPaymentBillingProjectJobPage($jobUuid: String!) {
    ${gql.query}
    job(uuid: $jobUuid) {
      id
      authorizeDotNetUrl
      project {
        id
        currentPrimaryBill {
          id
          customerId
        }
        ...Project_getName
      }
      ...Job_getAuthorizeDotNetUrlWithAmount
      ...Job_getFullName
      ...Job_getTipName
    }
  }
`;

export default AuthorizeDotNetMethodNewPaymentBillingProjectJobPage;
