// Libraries
import React from 'react';

// Components
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

const ConfirmTimesForMoveMutation = ({move, onSuccess, onError, children}) => (
  <Mutation
    variables={{
      moveId: move.id,
    }}
    mutation={ConfirmTimesForMoveMutation.mutation}
    onCompleted={({confirmTimesForMove: {move, errors}}) => {
      if (move) {
        onSuccess(move);
      } else {
        onError(errors);
      }
    }}
  >
    {(handleSubmit, {loading, error}) => children({loading, handleSubmit})}
  </Mutation>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
ConfirmTimesForMoveMutation.fragment = gql`
  fragment ConfirmTimesForMoveMutation on Move {
    id
  }
`;

ConfirmTimesForMoveMutation.mutation = gql`
  mutation ConfirmTimesForMoveMutation(
    $moveId: Int!,
  ) {
    confirmTimesForMove(
      moveId: $moveId,
    ) {
      ${gql.errors}
      move {
        id
      }
    }
  }
`;

export default ConfirmTimesForMoveMutation;
