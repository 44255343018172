// Libraries
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation, useQuery} from '@supermove/hooks';
import {Job, Project} from '@supermove/models';

// App
import Navigation from 'core/Navigation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import ProjectBillingCreditCardPaymentSection from 'modules/Project/Billing/Payment/New/Method/CreditCard/components/ProjectBillingCreditCardPaymentSection';
import ProjectBillingHeader from 'modules/Project/Billing/components/ProjectBillingHeader';

const Container = Styled.View`
  flex: 1;
`;

const CreditCardMethodNewPaymentBillingProjectJobContent = ({job}) => {
  const {params} = useNavigation();
  const isMainFlow = Navigation.isMainFlowPayment({params});
  return (
    <Container>
      <ProjectBillingHeader
        leftButtonType={isMainFlow ? null : 'BACK'}
        title={Project.getName(job.project)}
        subtitle={Job.getFullName(job)}
      />
      <Container>
        <Space height={20} />
        <ProjectBillingCreditCardPaymentSection job={job} />
      </Container>
    </Container>
  );
};

const CreditCardMethodNewPaymentBillingProjectJobPage = () => {
  const {params} = useNavigation();
  const {loading, data, refetch} = useQuery(CreditCardMethodNewPaymentBillingProjectJobPage.query, {
    fetchPolicy: 'network-only',
    variables: {
      jobUuid: params.jobUuid,
    },
  });

  return (
    <Loading loading={loading} as={PageLoadingIndicator}>
      {() =>
        data ? (
          <CreditCardMethodNewPaymentBillingProjectJobContent job={data.job} refetch={refetch} />
        ) : null
      }
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreditCardMethodNewPaymentBillingProjectJobPage.query = gql`
  ${Job.getFullName.fragment}
  ${Job.getPaymentName.fragment}
  ${Project.getName.fragment}
  ${ProjectBillingCreditCardPaymentSection.fragment}

  query CreditCardMethodNewPaymentBillingProjectJobPage($jobUuid: String!) {
    ${gql.query}
    job(uuid: $jobUuid) {
      id
      project {
        id
        ...Project_getName
      }
      ...Job_getFullName
      ...Job_getPaymentName
      ...ProjectBillingCreditCardPaymentSection
    }
  }
`;

export default CreditCardMethodNewPaymentBillingProjectJobPage;
