// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation} from '@supermove/hooks';
import {Job} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import JobLocationStatus from './JobLocationStatus';

const Container = Styled.View`
  flex-direction: row;
  height: ${(props) => (props.view === 'CUSTOMER' ? 0 : 40)}px;
  padding-horizontal: 10px;
  background-color: ${(props) => props.color};
  overflow: hidden;
`;

const Filler = Styled.View`
  width: ${(props) => props.width}px;
`;

const Info = Styled.Touchable`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const Text = Styled.Text`
  ${Typography.Label2}
  color: ${colors.white};
`;

const ActionsButton = Styled.Touchable`
  align-self: center;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 100px;
  background-color: ${colors.alpha(colors.white, 0.4)};
  border-color: ${colors.alpha(colors.white, 0.6)};
  border-width: 1px;
  border-radius: 3px;
`;

const ActionsText = Styled.H6`
  color: ${colors.gray.primary};
`;

const getText = ({view, job}) => {
  switch (view) {
    case 'NONE':
      return `${Job.getFullName(job)} is in progress - tap to view`;
    case 'EMPLOYEE':
      return 'CREW MODE';
    case 'CUSTOMER':
    default:
      return '';
  }
};

const getColor = ({view}) => {
  switch (view) {
    case 'NONE':
      return colors.orange.status;
    case 'EMPLOYEE':
      return colors.gray.primary;
    case 'CUSTOMER':
    default:
      return colors.gray.primary;
  }
};

const getLeftWidth = ({job, view}) => {
  return 0;
};

const getRightWidth = ({job, view}) => {
  if (view !== 'EMPLOYEE') {
    return 110;
  }

  return 0;
};

const JobInProgressBanner = ({view, job}) => {
  const {params, navigator} = useNavigation();

  return (
    <Container view={view} color={getColor({view})}>
      <JobLocationStatus />
      <Filler width={getLeftWidth({job, view})} />
      <Info
        disabled={view !== 'NONE'}
        view={view}
        activeOpacity={1}
        onPress={() => navigator.navigate('ShowJob', {uuid: job.uuid})}
      >
        <Text>{getText({view, job})}</Text>
      </Info>
      <Filler width={getRightWidth({job, view})} />
      {view === 'EMPLOYEE' && (
        <ActionsButton onPress={() => navigator.navigate('ActionsJob', {uuid: params.uuid})}>
          <ActionsText>Actions</ActionsText>
        </ActionsButton>
      )}
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobInProgressBanner.fragment = gql`
  ${Job.getFullName.fragment}

  fragment JobInProgressBanner on Job {
    id
    uuid
    ...Job_getFullName
  }
`;

export default JobInProgressBanner;
