// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

// App
import BackButton from 'components/BackButton';

const Footer = Styled.View`
  height: 61px;
  background-color: ${colors.gray.background};
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
`;

const Row = Styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding-horizontal: 12px;
`;

const ButtonTouchable = Styled.LoadingButton`
  height: 40px;
  padding-horizontal: 20px;
  background-color: ${({color}) => color}
`;

const ButtonText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const BackButtonContainer = Styled.View`
`;

const Button = ({onPress, color, disabled, children}) => {
  return (
    <ButtonTouchable onPress={onPress} color={color} disabled={disabled}>
      <ButtonText>{children}</ButtonText>
    </ButtonTouchable>
  );
};

const Back = ({disabled}) => {
  return (
    <BackButtonContainer>
      <BackButton disabled={disabled} />
    </BackButtonContainer>
  );
};

const PageFooter = ({children}) => {
  return <Footer>{children}</Footer>;
};

PageFooter.Row = Row;
PageFooter.Button = Button;
PageFooter.BackButton = Back;

export default PageFooter;
