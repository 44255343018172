// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {BillRule} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';

const Container = Styled.View`
`;

const Content = Styled.View`
  flex-direction: row;
  align-items: center;
  height: ${(props) => (props.editable ? 50 : 35)}px;
  padding-horizontal: 10px;
`;

const Name = Styled.H5`
  flex: 1;
  margin-right: 10px;
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Cell = Styled.View`
  width: 100px;
`;

const Quantity = Styled.H6`
`;

const BillBillRuleItem = ({isFirst, isLast, index, billRule}) => (
  <Container>
    <Content>
      <Name numberOfLines={1}>{billRule.name}</Name>
      <Cell
        style={{
          flex: 1,
          alignItems: 'flex-end',
        }}
      >
        <Quantity>{BillRule.getDisplayValues(billRule)}</Quantity>
      </Cell>
    </Content>
  </Container>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
BillBillRuleItem.fragment = gql`
  ${BillRule.getDisplayValues.fragment}

  fragment BillBillRuleItem on BillRule {
    name
    ...BillRule_getDisplayValues
  }
`;

export default BillBillRuleItem;
