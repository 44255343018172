// Supermove
import {gql} from '@supermove/graphql';
import {useEffect, useMutation} from '@supermove/hooks';
import {Datetime} from '@supermove/utils';

const MUTATION = gql`
  mutation CreatePositionForDeviceMutation(
    $deviceId: Int!,
    $timestamp: String!,
    $latitude: Float!,
    $longitude: Float!,
    $accuracy: Int,
    $altitude: Int,
    $floor: Int,
    $heading: Int,
    $speed: Int,
  ) {
    response: createPositionForDevice(
      deviceId: $deviceId,
      timestamp: $timestamp,
      latitude: $latitude,
      longitude: $longitude,
      accuracy: $accuracy,
      altitude: $altitude,
      floor: $floor,
      heading: $heading,
      speed: $speed,
    ) {
      ${gql.errors}
      position {
        id
      }
    }
  }
`;

const useCreatePositionForDeviceMutation = ({deviceId, location}) => {
  const [handleSubmit, {loading}] = useMutation(MUTATION, {
    variables: {
      deviceId,
    },
    onCompleted: ({response: {position, errors}}) => {
      // NOTE(mark): Nothing to do here, we just continue logging.
    },
  });

  useEffect(() => {
    if (!deviceId || !location) {
      return;
    }

    // Timestamp needs to be set dynamically when we submit to get the current time.
    const timestamp = Datetime.toTimestamp(Datetime.now);
    const {latitude, longitude, accuracy, altitude, floor, heading, speed} = location;
    const variables = {
      latitude,
      longitude,
      accuracy,
      altitude,
      floor,
      heading,
      speed,
      timestamp,
    };

    // Submit the createPositionForDevice mutation.
    console.log(`[Location]: New for device: ${deviceId}.`);
    handleSubmit({variables});
  }, [deviceId, location, handleSubmit]);

  return {
    loading,
  };
};

export default useCreatePositionForDeviceMutation;
