// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, Typography} from '@supermove/styles';

// App
import Badge from '@shared/design/components/Badge';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Checkbox from '@shared/design/components/Checkbox';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const MoverContainer = Styled.ButtonV2`
  padding-horizontal: 24px;
  padding-vertical: 16px;
  background-color: ${colors.white};
`;

const NameText = Styled.Text`
  ${Typography.Mobile.Body}
`;

const AssignMoversMoverContent = ({
  name,
  moverPosition,
  handleToggle,
  handleRemove,
  handleEdit,
  isSelected,
  isViewer,
  responsive,
}) => {
  return (
    <MoverContainer disabled={!handleToggle || isViewer} onPress={handleToggle}>
      <Row>
        {handleToggle && (
          <React.Fragment>
            <Row>
              <Checkbox.CheckMark
                isMobile={!responsive.desktop}
                isChecked={isSelected}
                isDisabled={isViewer}
                style={{marginTop: 0}}
              />
            </Row>
            <Space width={16} />
          </React.Fragment>
        )}
        <NameText>{name}</NameText>
        <Space width={16} />
        <Badge label={moverPosition.name} color={colors.gray.secondary} size={Badge.SIZE.LARGE} />
        <Space style={{flex: 1}} />
        {handleEdit && (
          <TertiaryButton onPress={handleEdit} isResponsive isHitSlop slopRadius={8}>
            <Icon source={Icon.Pen} size={16} color={colors.blue.interactive} />
          </TertiaryButton>
        )}
        {handleRemove && (
          <React.Fragment>
            <Space width={16} />
            <TertiaryButton onPress={handleRemove} isResponsive isHitSlop slopRadius={8}>
              <Icon source={Icon.Trash} size={16} color={colors.red.warning} />
            </TertiaryButton>
          </React.Fragment>
        )}
      </Row>
    </MoverContainer>
  );
};

const AssignMoversMover = ({
  jobUserForm,
  organization,
  handleToggle,
  handleRemove,
  handleEdit,
  isSelected,
  isViewer,
  responsive,
}) => {
  return (
    <AssignMoversMoverContent
      name={jobUserForm.firstName}
      moverPosition={organization.companySettings.allMoverPositions.find(
        (moverPosition) =>
          _.toString(moverPosition.id) === _.toString(jobUserForm.primaryMoverPositionId),
      )}
      handleToggle={handleToggle}
      handleRemove={handleRemove}
      handleEdit={handleEdit}
      isSelected={isSelected}
      isViewer={isViewer}
      responsive={responsive}
    />
  );
};

AssignMoversMover.User = ({user, organization, handleToggle, isSelected, responsive}) => {
  return (
    <AssignMoversMoverContent
      name={user.fullName}
      moverPosition={organization.companySettings.allMoverPositions.find(
        (moverPosition) =>
          _.toString(moverPosition.id) ===
          _.toString(_.find(user.userMoverPositions, {isPrimary: true})?.moverPositionId),
      )}
      handleToggle={handleToggle}
      isSelected={isSelected}
      responsive={responsive}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AssignMoversMover.fragment = gql`
  fragment AssignMoversMover on Organization {
    id
    companySettings {
      allMoverPositions {
        id
        name
      }
    }
  }
`;

AssignMoversMover.User.fragment = gql`
  fragment AssignMoversMover_User on User {
    id
    fullName
    userMoverPositions {
      id
      isPrimary
      moverPositionId
    }
  }
`;

export default AssignMoversMover;
