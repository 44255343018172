// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {User} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';

// Components
import {Avatar} from 'modules/App/components';

const Container = Styled.View`
  align-items: center;
`;

const Name = Styled.H7`
  margin-top: 5px;
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const UserAvatar = ({color, size, user, onSelect}) => (
  <Container>
    <Avatar text={User.getInitials(user)} size={size} color={color} />
    <Name numberOfLines={1}>{`${user.firstName} ${user.lastName}`}</Name>
  </Container>
);

// --------------------------------------------------
// Props
// --------------------------------------------------
UserAvatar.propTypes = {
  user: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  size: PropTypes.number,
};

UserAvatar.defaultProps = {
  size: 60,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UserAvatar.fragment = gql`
  fragment UserAvatar on User {
    id
    firstName
    lastName
  }
`;

export default UserAvatar;
