// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {KeyboardView, Ref, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Document} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import {
  CreateSignatureErrorModal,
  SignatureCapture,
} from 'modules/Job/Customer/Document/Sign/components';
import {RiskyMoveDocumentItem} from 'modules/Job/Document/RiskyMove/components';
import {CreateSignatureForDocumentForm} from 'modules/Job/Document/Sign/components';
import {JobNavigation, JobPage} from 'modules/Job/components';

const Container = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
  margin-vertical: 20px;
  margin-horizontal: 40px;
`;

const Title = Styled.H2`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Subtitle = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Items = Styled.View`
  margin-vertical: 20px;
`;

const Signature = Styled.View`
  align-items: center;
  margin-vertical: 40px;
`;

const Footer = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  background-color: ${colors.gray.background};
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
`;

const Group = Styled.View`
  flex-direction: row;
`;

const Button = Styled.LoadingButton`
  height: 40px;
  padding-horizontal: 20px;
`;

const ButtonSpace = Styled.View`
  width: 10px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const getTitle = ({document}) => {
  switch (document.template) {
    case 'CHANGE_ORDER':
      return 'Change Order Agreement';
    case 'RISKY_MOVE':
      return 'Risky Move Agreement';
    default:
      return '';
  }
};

const getSubtitle = ({document, job}) => {
  switch (document.template) {
    case 'CHANGE_ORDER':
      return (
        `Customer acknowledges and agrees that cost for performing the additional ` +
        `services shall be paid by Company as indicated above and such services ` +
        `and the costs thereof are not included in the original estimate, bid price, ` +
        `or agreement.\n\n` +
        `The "Not to Exceed" price is now: ` +
        `${Currency.display(Document.getAdditionalInfo(document).notToExceedPrice)}\n\n` +
        `The following items have changed resulting in a change of scope:`
      );
    case 'RISKY_MOVE':
      return (
        `This is to confirm that I have been informed by ` +
        `${job.organization.name} that the conveyance of the item(s) ` +
        `listed below involve a "risky move."`
      );
    default:
      return '';
  }
};

const SignDocumentJobPage = () => (
  <JobPage
    getVariables={({params}) => ({uuid: params.uuid, documentUuid: params.documentUuid})}
    query={SignDocumentJobPage.query}
    navigation={(props) => <JobNavigation {...props} />}
  >
    {({navigator, params, data}) => (
      <CreateSignatureErrorModal
        trigger={({handleOpen}) => (
          <CreateSignatureForDocumentForm
            documentId={data.document.id}
            customerId={data.job.customer.id}
            date={data.job.day.value}
            kind={'FULL_TRACED'}
            placement={'SIGNATURE'}
            onSuccess={() => {
              // Use the `success` param if passed through.
              const screen = params.success || 'ListDocumentJob';

              // Hack to handle the back case.
              if (_.startsWith(screen, 'BACK-')) {
                const count = _.last(_.split(screen, 'BACK-'));
                return navigator.pop(count);
              }

              // Always clear out the `success` param.
              navigator.navigate(screen, {uuid: params.uuid, success: undefined});
            }}
            onError={() => handleOpen()}
          >
            {(form) => (
              <Ref>
                {(signatureRef) => (
                  <Container>
                    <KeyboardView>
                      <ScrollView alwaysBounceVertical={false} style={{flex: 1}}>
                        <Content>
                          <Title>{getTitle({document: data.document})}</Title>
                          <Subtitle>
                            {getSubtitle({document: data.document, job: data.job})}
                          </Subtitle>
                          <Items>
                            {Document.getAdditionalInfo(data.document).items.map((item, index) => (
                              <RiskyMoveDocumentItem
                                key={index}
                                isFirst={index === 0}
                                isLast={
                                  index ===
                                  Document.getAdditionalInfo(data.document).items.length - 1
                                }
                                index={index}
                                item={item}
                              />
                            ))}
                          </Items>
                          <Signature>
                            <SignatureCapture
                              innerRef={signatureRef}
                              onSave={(image) => {
                                form.handleSubmit({variables: {image}});
                              }}
                            />
                          </Signature>
                        </Content>
                      </ScrollView>
                    </KeyboardView>
                    <Footer>
                      <Group>
                        <Button color={colors.gray.secondary} onPress={() => navigator.goBack()}>
                          <Text>Back</Text>
                        </Button>
                        <ButtonSpace />
                        <Button
                          color={colors.gray.secondary}
                          onPress={() => signatureRef.current.clear()}
                        >
                          <Text>Clear</Text>
                        </Button>
                      </Group>
                      <Button
                        loading={form.loading}
                        color={colors.blue.interactive}
                        onPress={() => {
                          // Native module doesn't let us do this declaratively. We grab the signature
                          // at the end and save it. This triggers onSave which will submit the form.
                          signatureRef.current.save();
                        }}
                      >
                        <Text>Next</Text>
                      </Button>
                    </Footer>
                  </Container>
                )}
              </Ref>
            )}
          </CreateSignatureForDocumentForm>
        )}
      />
    )}
  </JobPage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
SignDocumentJobPage.query = gql`
  ${JobPage.fragment}
  ${JobNavigation.fragment}

  query SignDocumentJobPage($uuid: String!, $documentUuid: String!) {
    ${gql.query}
    document(uuid: $documentUuid) {
      id
      template
      additionalInfo
    }
    job(uuid: $uuid) {
      id
      organization {
        id
        name
      }
      customer {
        id
      }
      day {
        id
        value
      }
      ...JobPage
      ...JobNavigation
    }
  }
`;

export default SignDocumentJobPage;
