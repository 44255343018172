// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// Components
import UserAvatar from './UserAvatar';

const Touchable = Styled.Touchable`
`;

const SelectableUser = ({isSelected, size, user, onSelect}) => (
  <Touchable onPress={() => onSelect(user)}>
    <UserAvatar
      color={isSelected ? colors.green.status : colors.blue.interactive}
      size={size}
      user={user}
    />
  </Touchable>
);

// --------------------------------------------------
// Props
// --------------------------------------------------
SelectableUser.propTypes = {
  onSelect: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  isSelected: PropTypes.bool,
  size: PropTypes.number,
};

SelectableUser.defaultProps = {
  isSelected: false,
  size: 60,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SelectableUser.fragment = gql`
  ${UserAvatar.fragment}

  fragment SelectableUser on User {
    id
    ...UserAvatar
  }
`;

export default SelectableUser;
