// Libraries
import React from 'react';

// Supermove
import {Loading, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';

// App
import Navigation from 'core/Navigation';
import {PageLoadingIndicator} from 'modules/App/components';
import {
  ConfirmTimesForMoveErrorModal,
  ConfirmTimesForMoveMutation,
  EmployeeMoveTimesChart,
  EmployeeMoveTimesForm,
  EmployeeMoveTimesList,
} from 'modules/Job/Crew/Timesheet/components';
import {CrewJobPage} from 'modules/Job/Crew/components';

const Container = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
  margin-top: 20px;
  margin-horizontal: 40px;
`;

const Title = Styled.H2`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Subtitle = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Chart = Styled.View`
  margin-vertical: 20px;
`;

const JobFooter = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  background-color: ${colors.gray.background};
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
`;

const Button = Styled.LoadingButton`
  align-self: stretch;
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const EditResidentialTimesheetCrewJobPage = () => (
  <CrewJobPage
    fetchPolicy={'network-only'}
    getVariables={({params}) => ({uuid: params.uuid})}
    query={EditResidentialTimesheetCrewJobPage.query}
  >
    {({navigator, params, loading, data, refetch}) => (
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => (
          <ConfirmTimesForMoveErrorModal
            trigger={({handleOpen}) => (
              <ConfirmTimesForMoveMutation
                move={data.job.move}
                onSuccess={(move) => {
                  Navigation.navigateFromTimesheetSuccess({navigator, job: data.job});
                }}
                onError={(errors) => handleOpen()}
              >
                {({loading, handleSubmit}) => (
                  <EmployeeMoveTimesForm
                    move={data.job.move}
                    onSuccess={() => {}}
                    onError={(errors) => console.log({errors})}
                  >
                    {(form) => (
                      <Container>
                        <ScrollView style={{flex: 1}}>
                          <Content>
                            <Title>Track Moving Times</Title>
                            <Subtitle>
                              {`Make sure all times are recorded. Include drive time ` +
                                `as well as any breaks that the crew took.`}
                            </Subtitle>
                            <Chart>
                              <EmployeeMoveTimesChart
                                hasConfirmedTimes={data.job.move.hasConfirmedTimes}
                                timeRanges={form.values.timeRanges}
                              />
                            </Chart>
                            <EmployeeMoveTimesList
                              organization={data.job.organization}
                              form={form}
                            />
                          </Content>
                        </ScrollView>
                        <JobFooter>
                          <Button color={colors.gray.secondary} onPress={() => navigator.goBack()}>
                            <Text>Back</Text>
                          </Button>
                          <Button
                            loading={loading}
                            color={colors.blue.interactive}
                            onPress={handleSubmit}
                          >
                            <Text>Submit</Text>
                          </Button>
                        </JobFooter>
                      </Container>
                    )}
                  </EmployeeMoveTimesForm>
                )}
              </ConfirmTimesForMoveMutation>
            )}
          />
        )}
      </Loading>
    )}
  </CrewJobPage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------

// NOTE(mark): Organization is here because we need the slug for getTimeRangeKinds.
EditResidentialTimesheetCrewJobPage.query = gql`
  ${Navigation.navigateFromTimesheetSuccess.fragment}
  ${ConfirmTimesForMoveMutation.fragment}
  ${CrewJobPage.fragment}
  ${EmployeeMoveTimesForm.fragment}

  query EditResidentialTimesheetCrewJobPage($uuid: String!) {
    ${gql.query}
    job(uuid: $uuid) {
      id
      organization {
        id
        slug
        canCrewEditMoveStartTime
      }
      move {
        id
        hasConfirmedTimes
        ...EmployeeMoveTimesForm
        ...ConfirmTimesForMoveMutation
      }
      ...CrewJobPage
      ...Navigation_navigateFromTimesheetSuccess
    }
  }
`;

export default EditResidentialTimesheetCrewJobPage;
